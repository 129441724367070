import React from 'react'

import { SvgProps } from './props'

export const LogoutSVG: React.FC<SvgProps> = (props) => {
  const classes = 'fill-current ' + props.className ?? ''

  return (
    <svg className={classes} viewBox="0 0 20 20">
      <title>{props.alt ?? 'Logout'}</title>
      <path d="M19 10l-6-5v3H6v4h7v3l6-5zM3 3h8V1H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H3V3z" />
    </svg>
  )
}
