import React from 'react'
import { Droppable } from 'react-beautiful-dnd'

import { classnames } from '../../../../@styles/tailwindcss-classnames'

export const REMOVE_DROPPABLE_ID = 'BNB<REMOVE>ONLINE'

export interface IRemoveCardDroppable {
  deleteDroppableRef: React.MutableRefObject<HTMLDivElement | null>
  highlight?: boolean
}
const RemoveCardDroppable: React.FC<IRemoveCardDroppable> = ({
  deleteDroppableRef,
  highlight = false,
}: IRemoveCardDroppable) => {
  return (
    <Droppable direction="vertical" droppableId={REMOVE_DROPPABLE_ID}>
      {(provided, snapshot) => {
        return (
          <div
            ref={(el) => {
              provided.innerRef(el)
              deleteDroppableRef.current = el
            }}
            className={classnames(
              'absolute',
              // 'left-0',
              'top-0',
              'z-50',
              'px-3',
              'py-1',
              'w-56',
              'rounded-cardborder',
              'text-sm',
              'font-sans',
              'font-semibold',
              'uppercase',
              'transition-all',
              'ease-in-out',
              'duration-300',
              'shadow-md',
              'h-full',
              'text-center',
              'align-top',
              'hover:text-gray-800',
              'hover:bg-warning',
              highlight || snapshot.isDraggingOver
                ? classnames('text-gray-800', 'bg-warning')
                : classnames('text-gray-900', 'bg-warning-dark'),
            )}
            style={snapshot.isDraggingOver ? { height: '19rem' } : {}}
            {...provided.droppableProps}
          >
            <span className="cursor-default mb-auto">Drop card to remove</span>
          </div>
        )
      }}
    </Droppable>
  )
}

export default RemoveCardDroppable
