import React from 'react'

import { buttonAccent } from '../../../../@styles/styles'
import YoutubeVideo from '../../../../components/YoutubeVideo'

const COMMUNITY_VIDEOS = [
  {
    title: 'First steps with the browser extension',
    youtubeId: 'lfiGOkc3j8E',
  },
  {
    title: 'Quest for the Janklord - Sealed Jank Commander Gameplay',
    youtubeId: 'PcfF67RlQdY',
  },
]

const CommunitySection: React.FC = () => {
  return (
    <div className="relative flex flex-col justify-start items-center my-5">
      <h2 className="text-2xl font-semibold uppercase mt-3 mb-4" id="support">
        Community
      </h2>
      <div className="relative w-10/12 flex flex-row justify-start items-center px-8">
        {COMMUNITY_VIDEOS.map((video, index) => (
          <div
            key={video.youtubeId}
            className={'flex-grow flex-shrink-0' + (index < COMMUNITY_VIDEOS.length - 1 ? ' mr-8' : '')}
          >
            <YoutubeVideo {...video} />
          </div>
        ))}
      </div>
      <div className="relative flex flex-row justify-center my-5">
        <div className="p-2 w-3/12 flex flex-col justify-between items-center bg-blue-700 mx-3 rounded-lg">
          <img
            alt="Patreon"
            className="w-4/12 h-4/12 p-1"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Patreon_logomark.svg/800px-Patreon_logomark.svg.png"
          />
          <a
            className={buttonAccent}
            href="https://www.patreon.com/buildandbrew"
            rel="noopener noreferrer"
            target="_blank"
          >
            Pledge on Patreon
          </a>
        </div>
        <div className="p-2 w-3/12 flex flex-col justify-between items-center bg-blue-700 mx-3 rounded-lg">
          <img
            alt="Twitter"
            className="w-4/12 h-4/12 p-1 rounded-lg"
            src="https://www.flaticon.com/svg/static/icons/svg/124/124021.svg"
          />
          <a
            className={buttonAccent}
            href="https://twitter.com/BuildandbrewO"
            rel="noopener noreferrer"
            target="_blank"
          >
            Follow on Twitter
          </a>
        </div>
        <div className="p-2 w-3/12 flex flex-col justify-between items-center bg-blue-700 mx-3 rounded-lg">
          <img
            alt="Discord"
            className="w-4/12 h-4/12 p-1"
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/Font_Awesome_5_brands_discord_color.svg"
          />
          <a className={buttonAccent} href="https://discord.gg/eqPYyQrjdH" rel="noopener noreferrer" target="_blank">
            Join Discord
          </a>
        </div>
      </div>
    </div>
  )
}

export default CommunitySection
