import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFirestore } from 'react-redux-firebase'

import { useSelectorTyped } from '../../../redux-store'
import { classnames } from '../../../@styles/tailwindcss-classnames'
import * as styles from '../../../@styles/styles'

import { deckTeaserStyle } from './DeckTeaser'

const CreateNewDeckTeaser: React.FC = () => {
  const history = useHistory()
  const [deckTitle, setDeckTitle] = useState('')
  const firestore = useFirestore()
  const uid = useSelectorTyped((state) => state.firebase.auth?.uid)

  const handleChange = ({ currentTarget: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (name === 'createDeck') {
      setDeckTitle(value)
    }
  }

  const [creating, setCreating] = useState(false)
  const [error, setError] = useState()

  const createNewDeck = (deckTitle: string) => {
    if (deckTitle === '' || !uid) return

    if (creating) return
    setCreating(true)
    firestore
      .collection('decks')
      .add({
        name: deckTitle,
        owner: uid,
        cardsQuantity: 1,
        commanders: [],
        created: firestore.FieldValue.serverTimestamp(),
        public: true,
      })
      .then((docRef) => {
        docRef.update({
          id: docRef.id,
        })
        history.push(`/decks/${docRef.id}`)
      })
      .catch((error) => {
        setError(error)
        setCreating(false)
      })
  }

  return (
    <div className={classnames(deckTeaserStyle, 'px-0')}>
      <form action="">
        <input
          className="my-3 w-10/12 mx-auto border-2"
          name="createDeck"
          placeholder="Enter Deck Name"
          type="text"
          value={deckTitle}
          onChange={handleChange}
        />
        <button
          className={styles.buttonPrimary}
          disabled={deckTitle === ''}
          onClick={(event) => {
            event.preventDefault()
            createNewDeck(deckTitle)
          }}
        >
          {creating ? 'Creating...' : 'Create New Deck!'}
        </button>
        {error && <i>{JSON.stringify(error)}</i>}
      </form>
    </div>
  )
}

export default CreateNewDeckTeaser
