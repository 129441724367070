import React, { useEffect, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Link, useHistory } from 'react-router-dom'

import { LoginSVG } from '../../../assets/images'
import { classnames } from '../../../@styles/tailwindcss-classnames'
import { useSelectorTyped } from '../../../redux-store'

const inputStyle = classnames(
  'shadow',
  'appearance-none',
  'border',
  'rounded',
  'w-full',
  'py-2',
  'px-4',
  'bg-gray-300',
  'text-gray-900',
  'placeholder-gray-800',
  'hover:bg-gray-200',
  'hover:text-gray-700',
  'leading-tight',
  'focus:outline-none',
  'focus:shadow-outline',
)
const inputWarning = (warn: boolean) =>
  classnames(inputStyle, {
    'border-red-500': warn,
    'text-warning': warn,
    'placeholder-warning': warn,
  })

const translateAuthError = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/invalid-email':
      return 'Wrong email format'
    case 'auth/invalid-password':
    case 'auth/wrong-password':
    case 'auth/user-not-found':
      return 'Wrong email or password'
    case 'auth/too-many-requests':
      return 'Too many failed login attempts, retry later on'
    default:
      console.log(errorCode)

      return 'Error while authenticating'
  }
}

export const SignInButton: React.FC = () => {
  const firebase = useFirebase()
  const history = useHistory()

  const redirectToUserOverview = (uid?: string) => {
    if (!uid) return
    const route = `/users/${uid}`

    try {
      history.push(route)
    } catch (error) {
      console.error('Error while redirecting to', route, '!\n', error)
    }
  }
  // const signInWithGoogle = () => {
  //   firebase
  //     .login({
  //       provider: 'google',
  //       type: 'popup',
  //     })
  //     .then((userCredentials) => redirectToUserOverview(userCredentials.user?.uid))
  //     .catch((signInError) => setError(translateAuthError(signInError?.code)))
  // }

  const [email, setEmail] = useState('')
  const authEmail = useSelectorTyped((state) => state.firebase.auth.email)
  useEffect(() => {
    if (email === '' && authEmail) setEmail(authEmail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authEmail])

  const [password, setPassword] = useState('')
  const [passwordTouched, setPasswordTouched] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const signInWithAccount = (email: string, password: string) => {
    if (!email || !password) return
    firebase
      .login({ email, password })
      .then((userCredentials) => redirectToUserOverview(userCredentials.user?.uid))
      .catch((signInError) => setError(translateAuthError(signInError?.code)))
  }

  const sendPasswordResetEmail = (event: React.MouseEvent) => {
    event.preventDefault()
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        const message = 'Password reset email sent!'
        setError(message)
        setTimeout(() => setError(null), 4000)
      })
      .catch((error) => console.error('Password reset email error:', error))
  }

  const handleSubmit = (event: React.FormEvent | React.MouseEvent) => {
    event.preventDefault()
    signInWithAccount(email, password)
  }

  return (
    <form className="inline-block group relative" onSubmit={handleSubmit}>
      <button
        className="w-full flex flex-row justify-end items-center p-2 hover:bg-gray-200 text-blue-600 hover:text-blue-800"
        tabIndex={3}
        type="submit"
        onClick={handleSubmit}
      >
        <span className="text-lg font-semibold tracking-tight uppercase mr-1">Sign In</span>
        <LoginSVG className="flex-shrink-0 w-6 h-6 m-1" />
      </button>
      <div className={classnames('hidden', 'group-hover:block', 'absolute', 'z-10', 'right-0', 'top-100')}>
        <div className="h-full w-56 mt-3 mr-2 p-3 rounded-xl bg-gray-100 border-1 border-gray-300 shadow-2xl flex flex-col justify-start items-end">
          <div className="flex-auto my-2 relative">
            <input
              className={inputStyle}
              id="email"
              placeholder="Your email address"
              tabIndex={1}
              type="text"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value.trim())
                setError(null)
              }}
            />
            {error ? (
              <span className="inline-block mx-1 font-semibold text-sm text-warning-dark">{error}</span>
            ) : (
              <Link to="/join">
                <span className="inline-block mx-1 font-semibold text-sm text-blue-600 hover:text-blue-800">
                  Create new account
                </span>
              </Link>
            )}
          </div>
          <div className="flex-auto my-2 relative">
            <input
              className={inputWarning(passwordTouched && password === '')}
              id="password"
              placeholder="Password"
              tabIndex={2}
              type="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value)
                setPasswordTouched(true)
                setError(null)
              }}
            />
            <span
              className="inline-block mx-1 font-semibold text-sm text-gray-500 hover:text-gray-600"
              onClick={sendPasswordResetEmail}
            >
              Forgot Password?
            </span>
          </div>
        </div>
        {/* <div className="text-center">
          <h1 className="text-2xl text-teal-700 mb-4">Sign In</h1>
          <div className="flex flex-col ">
            <button
              onClick={(event) => {
                event.preventDefault()
                signInWithGoogle()
              }}
              className={identiyProviderLoginButton}
            >
              Sign In with Google
            </button>
            <h2 className="text-2xl text-teal-700 my-6">Sign In with your Email and Password</h2>
            {error && <strong className="text-orange-700">Error while logging in: {JSON.stringify(error)}</strong>}
            <EmailSignIn onSubmit={signInWithAccount} />
          </div>
        </div> */}
      </div>
    </form>
  )
}
