import React, { useCallback } from 'react'

export interface ICompanion {
  platform: string
  description: string
  version: string
  platformIconUrl: string
  downloadUrl: string
  backgroundImageUri: string
  disabledReason?: string
}

const Companion: React.FC<ICompanion> = ({
  platform,
  downloadUrl,
  description,
  platformIconUrl,
  backgroundImageUri,
  disabledReason,
}) => {
  const disabled = !!disabledReason || !downloadUrl

  const clickHandler = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()

      if (disabled || !downloadUrl) return

      window.open(downloadUrl, '_blank')
    },
    [disabled, downloadUrl],
  )

  return (
    <button
      key={platform}
      className={
        'flex-shrink-0 relative w-48 h-48 m-3 box-content rounded-2xl border-gray-500 border flex flex-col justify-end items-start group shadow-md hover:shadow-lg ' +
        (disabled ? 'cursor-default' : 'cursor-pointer')
      }
      disabled={disabled}
      onClick={clickHandler}
    >
      <div
        className="absolute w-48 h-48 rounded-2xl bg-gray-400"
        id="backgroundImage"
        style={{
          backgroundImage: `url(${backgroundImageUri})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          filter: 'brightness(1.15)',
        }}
        title={`${platform} ${description}`}
      />
      <div
        className="absolute w-48 h-48 rounded-2xl"
        style={{
          background:
            'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8))',
        }}
      />
      {disabledReason && (
        <span className="absolute right-0 top-0 mt-1 -mr-3 font-semibold text-lg transform rotate-12 text-gray-100 bg-warning-dark shadow-lg py-1 px-3">
          {disabledReason}
        </span>
      )}
      <div className="w-full relative flex flex-row justify-start items-center p-2">
        <img alt={`${platform} Logo`} className="flex-shrink-0 w-auto h-10" src={platformIconUrl} />
        <div className="ml-2 flex flex-col justify-center items-start">
          <span className="text-gray-100 leading-none group-hover:text-white font-semibold text-xl">{platform}</span>
          <span className="text-gray-300 leading-none text-sm">{description}</span>
        </div>
      </div>
    </button>
  )
}

export default Companion
