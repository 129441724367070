import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
export { default as firebase } from './firebase'

export interface Commander {
  id: string
  name: string
  avatarUri: string
}
export interface Deck {
  id: string
  name: string
  commanders: Commander[]
  cardsQuantity?: number
  created: firebase.firestore.Timestamp
  owner: string
}

export interface Card {
  id: string
  imageUri: string
  categories?: string[]
  quantity?: number
}

export interface User {
  displayName: string
  avatarUrl: string
  created?: firebase.firestore.Timestamp
}

export interface Schema {
  decks: Deck
  publicDecks: Deck
  users: User
}

export interface Profile {
  name: string
  email: string
  avatarUrl: string
  displayName: string
}

export const reducers = {
  firebase: firebaseReducer,
  firestore: firestoreReducer,
}
