import React from 'react'

import { Order, OrderBy } from '../../lib/sort'

export const AVAILABLE_ORDER_OPTIONS: OrderOption[] = [
  { order: 'categories', displayName: 'Custom Categories', orderBy: { x: 'categories', y: 'cmc' } },
  { order: 'cmc', displayName: 'Converted Mana Cost', orderBy: { x: 'cmc', y: 'name' } },
  { order: 'cardType', displayName: 'Card Type', orderBy: { x: 'cardType', y: 'cmc' } },
]

export interface OrderOption {
  order: Order
  displayName: string
  orderBy: OrderBy
}

export interface ISortOrderSelector {
  sortOrder: OrderBy
  setSortOrder: (sortOrder: OrderBy) => void
}
const SortOrderSelector: React.FC<ISortOrderSelector> = ({ sortOrder, setSortOrder }) => {
  return (
    <div className="flex-auto flex-grow-1 flex flex-row justify-start items-center">
      <span className="text-semibold text-light-secondary leading-none mr-1 base">Sort by</span>
      {AVAILABLE_ORDER_OPTIONS.map((orderOption) => (
        <label
          key={orderOption.order}
          className="ml-3 inline-flex text-lg items-center text-gray-400 hover:text-gray-200 font-sans font-semibold"
        >
          <input
            checked={sortOrder.x === orderOption.orderBy.x}
            className="flex-shrink-0 flex-grow-0 bg-gray-400 appearance-none border w-3 h-3 rounded-full checked:bg-accent checked:border-transparent"
            name="sortCardsBy"
            type="radio"
            value={orderOption.order}
            onChange={() => setSortOrder(orderOption.orderBy)}
          />
          <span className="ml-1">{orderOption.displayName}</span>
        </label>
      ))}
    </div>
  )
}

export default SortOrderSelector
