import React from 'react'
import { Link } from 'react-router-dom'

import { classnames } from '../../@styles/tailwindcss-classnames'
import { buttonAccent, buttonPrimary, buttonWarning } from '../../@styles/styles'

import { Account } from './components/Account'
import Brand from './components/Brand'
import MainMenu from './components/MainMenu'

const icon = classnames('flex-auto', 'w-4', 'h-4', 'm-1')
const image = classnames(icon, 'rounded-full', 'bg-accent')
const buttonLayout = classnames('flex-auto', 'flex', 'flex-row', 'justify-end')
export const styles = {
  icon,
  image,
  button: {
    primary: classnames(buttonLayout, buttonPrimary),
    accent: classnames(buttonLayout, buttonAccent),
    warning: classnames(buttonLayout, buttonWarning),
  },
}

export const NavBar: React.FC = () => {
  return (
    <header className="w-full p-1 flex flex-col md:flex-row justify-between items-center" role="banner">
      <Link className="mr-auto flex-1" to="/">
        <Brand />
      </Link>
      <MainMenu />
      <div className="ml-auto flex-1 flex flex-row justify-end">
        <Account />
      </div>
    </header>
  )
}
