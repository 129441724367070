import React from 'react'
import { useFirestoreConnect, isLoaded, isEmpty, WhereOptions } from 'react-redux-firebase'
import { RouteComponentProps } from 'react-router-dom'

import { DecksOverview } from '../../components/DecksOverview'
import { useSelectorTyped } from '../../redux-store'
import { Deck } from '../../redux-store/firebase'
import Page from '../Page'

import UserInfo from './components/UserInfo'

interface IUser {
  uid: string
}
const User: React.FC<RouteComponentProps<IUser>> = (props: RouteComponentProps<IUser>) => {
  const { params } = props.match

  const authUid = useSelectorTyped((state) => state.firebase.auth?.uid)

  // const lastLoginDate = new Intl.DateTimeFormat('de-DE', {
  //   year: 'numeric',
  //   month: '2-digit',
  //   day: '2-digit',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   second: '2-digit',
  // }).format(parseInt(lastLoginAt, 10))

  const reduxPathUser = `user_${params.uid}`
  useFirestoreConnect({
    collection: 'users',
    doc: params.uid,
    storeAs: reduxPathUser,
  })
  const user = useSelectorTyped((state) => state.firestore.data[reduxPathUser])

  const reduxPathDecks = `user_${params.uid}_decks`
  const where: WhereOptions = ['owner', '==', params.uid]
  useFirestoreConnect({
    collection: 'decks',
    where: authUid === params.uid ? where : [where, ['public', '==', true]],
    orderBy: ['created', 'desc'], //TODO orderBy favorite; updatedAt/frecency instead
    storeAs: reduxPathDecks,
  })
  const decks: Deck[] = useSelectorTyped((state) => state.firestore.ordered[reduxPathDecks])

  return (
    <Page>
      <UserInfo isLoaded={isLoaded(user) && !isEmpty(user)} user={user} />
      <div className="flex flex-row justify-center my-6 w-full" role="main">
        {!isLoaded(decks) ? (
          <p>Loading decks...</p>
        ) : (
          <>
            {isEmpty(decks) && (
              <p>
                No decks found! <strong>Create new deck</strong> to get started!
              </p>
            )}
            <DecksOverview
              decks={isEmpty(decks) ? [] : decks}
              displayNewDeckTeaser={authUid === params.uid}
              isLoaded={isLoaded(decks)}
              showOwner={false}
            />
          </>
        )}
      </div>
    </Page>
  )
}

export default User
