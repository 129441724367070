import { Action, AnyAction, combineReducers, ReducersMapObject } from 'redux'
import { FirebaseReducer, FirestoreReducer } from 'react-redux-firebase'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { Profile, reducers as firebaseReducers, Schema } from './firebase'
import scryfall, { IScryfall } from './scryfall'

// Enhance the Action interface with the option of a payload.
// While still importing the Action interface from redux.
/* eslint-disable @typescript-eslint/no-explicit-any */
declare module 'redux' {
  export interface Action<T = any, P = any> {
    type: T
    payload?: P
  }
}
/* eslint-enable */

export interface RootState {
  firebase: FirebaseReducer.Reducer<Profile, Schema>
  firestore: FirestoreReducer.Reducer
  scryfall: IScryfall
}

export const useSelectorTyped: TypedUseSelectorHook<RootState> = useSelector
export type TypedDispatch = ThunkDispatch<RootState, unknown, Action<string>>
export type ThunkResult<R, A extends Action = Action<string>> = ThunkAction<Promise<R>, RootState, unknown, A>

const rootReducers = combineReducers<RootState>({
  ...firebaseReducers,
  scryfall,
} as ReducersMapObject<RootState, AnyAction>)

export default rootReducers
