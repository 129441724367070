import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Scry from 'scryfall-sdk'

import { classnames } from '../../../@styles/tailwindcss-classnames'
import { Deck } from '../../../redux-store/firebase'
import { Card } from '../../../redux-store/firebase'

interface IDeckInfo {
  isLoaded: boolean
  deck: Deck
  cards: Card[]
  cardsInfo: {
    [id: string]: Scry.Card
  }
}

const nameStyle = classnames('text-4xl', 'font-bold', 'tracking-wide', 'text-accent')
const ownerStyle = classnames('text-xl', 'font-semibold', 'text-gray-600')

const DeckInfo: React.FC<IDeckInfo> = ({ isLoaded, deck, cards, cardsInfo }) => {
  const [legalInCommander, setLegalInCommander] = useState(false)
  const [numberOfCards, setNumberOfCards] = useState(0)
  const [priceTotal, setPriceTotal] = useState(0)
  useEffect(() => {
    let priceTotal = 0
    let numberOfCards = 0
    cards.forEach((c) => {
      const scryfallInfo = cardsInfo[c.id]
      numberOfCards += c.quantity ?? 1
      priceTotal += scryfallInfo?.prices?.eur ? parseFloat(scryfallInfo.prices.eur) : 0
    })

    setLegalInCommander(
      // numberOfCards === 100 &&
      !cards.some((c) => cardsInfo[c.id] && cardsInfo[c.id].legalities.commander !== 'legal'),
    )
    setNumberOfCards(numberOfCards)
    setPriceTotal(priceTotal)
  }, [cards, cardsInfo, setLegalInCommander, setNumberOfCards, setPriceTotal])

  return (
    <div className="relative w-full bg-blue-600">
      {!isLoaded ? (
        <div className="flex flex-row justify-start items-center w-full p-4">
          <span className={nameStyle}>Loading deck info...</span>
        </div>
      ) : (
        <div className="relative">
          <div className="flex flex-col justify-evenly items-start w-full h-full p-4">
            <span key={0} className={nameStyle}>
              {deck.name}
            </span>
            <Link key={1} to={`/users/${deck.owner}`}>
              <span className={ownerStyle}>
                By <span className="text-gray-400">{deck.owner}</span>
              </span>
            </Link>
            <div className="flex flex-row justify-start items-center">
              <span className="text-gray-400 text-base font-normal mr-16">{numberOfCards} / 100 Cards</span>
              <span
                className={
                  'text-base font-normal mr-16 ' +
                  (legalInCommander ? 'text-accent-dark' : 'text-warning font-semibold')
                }
              >
                {legalInCommander ? 'legal' : 'not legal'}
              </span>
              <span className="text-gray-400 text-base font-normal mr-16">
                {Math.round(Math.random() * 9999)} Likes
              </span>
              <span className="text-gray-400 text-base font-normal">~{priceTotal.toFixed(0)}€</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeckInfo
