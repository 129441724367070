export const swapElements = <T>(list: Array<T>, startIndex: number, endIndex: number): Array<T> => {
  const result = Array.from(list)
  const [moving] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, moving)

  return result
}

export const move = <T>(
  source: Array<T>,
  destination: Array<T>,
  sourceIndex: number,
  destinationIndex: number,
): { source: Array<T>; destination: Array<T> } => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)

  const [moving] = sourceClone.splice(sourceIndex, 1)
  destClone.splice(destinationIndex, 0, moving)

  return {
    source: sourceClone,
    destination: destClone,
  }
}
