import Scry from 'scryfall-sdk'

import { CardInfoMap } from '../Deck'

const CARD_TYPE_ORDER = ['Creature', 'Artifact', 'Enchantment', 'Planeswalker', 'Sorcery', 'Instant', 'Land']

// TODO write test for this function with all special cases!
const getType = (card: Scry.Card) => {
  const type = card.type_line.replace('Legendary', '').replace('Tribal', '').split('—')[0].trim()
  const types = type.split(' ')

  return types.length === 1 ? type : CARD_TYPE_ORDER.find((t) => types.includes(t)) ?? type
}

export type Order = 'name' | 'categories' | 'cmc' | 'cardType'
export type OrderBy =
  | { x: 'categories'; y: 'name' | 'cmc' | 'cardType' }
  | { x: 'cardType'; y: 'name' | 'cmc' }
  | { x: 'cmc'; y: 'name' | 'cardType' | 'categories' }

export const DEFAULT_CATEGORY_NAME = 'Uncategorized'
export const LANDS_CATEGORY_COLUMN_NAME = 'Lands'

const sortSplit = (cards: Scry.Card[], order: Order, cardInfo: CardInfoMap): [string, ...Scry.Card[]][] => {
  switch (order) {
    case 'cmc':
      return cards.reduce((acc: [string, ...Scry.Card[]][], card) => {
        let cmc = card.cmc || 0

        while (cmc >= 0 && !acc[cmc]) {
          acc[cmc] = ['CMC ' + cmc]
          cmc--
        }
        acc[card.cmc].push(card)

        return acc
      }, [])
    case 'cardType':
      return cards.reduce((acc: [string, ...Scry.Card[]][], card) => {
        const type = getType(card)
        const index = CARD_TYPE_ORDER.indexOf(type)

        if (!acc[index]) acc[index] = [type + 's']
        acc[index].push(card)

        return acc
      }, [])
    case 'categories': {
      const cardsCategoryMap = cards.reduce(
        (acc: { [cat: string]: [string, ...Scry.Card[]] }, card) => {
          const categories = cardInfo[card.oracle_id] ? cardInfo[card.oracle_id].categories : []
          const category =
            !categories || categories.length === 0
              ? getType(card) === 'Land'
                ? LANDS_CATEGORY_COLUMN_NAME
                : DEFAULT_CATEGORY_NAME
              : categories[0]

          const array = acc[category] ?? [category]
          array.push(card)
          acc[category] = array

          return acc
        },
        { [DEFAULT_CATEGORY_NAME]: [DEFAULT_CATEGORY_NAME] },
      )

      if (cardsCategoryMap[DEFAULT_CATEGORY_NAME].length === 1) delete cardsCategoryMap[DEFAULT_CATEGORY_NAME]

      return Object.values(cardsCategoryMap)
    }
    default:
      throw new Error(`sortSplit() by ${order} not supported!`)
  }
}

export const sort = (cards: Scry.Card[], order: Order): Scry.Card[] => {
  switch (order) {
    case 'name':
      return cards.sort((a, b) => a.name.localeCompare(b.name))
    case 'cmc':
      return cards.sort((a, b) => a.cmc - b.cmc) // ascending
    case 'cardType':
      return cards.sort((a, b) => CARD_TYPE_ORDER.indexOf(getType(a)) - CARD_TYPE_ORDER.indexOf(getType(b)))
    case 'categories':
      throw new Error('sort() by categories not implemented yet!')
    default:
      return cards
  }
}

export const sortAll = (
  cards: Scry.Card[],
  orderBy: OrderBy,
  cardInfo: CardInfoMap = {},
): [string, ...Scry.Card[]][] => {
  const columns = sortSplit(cards, orderBy.x, cardInfo)

  return columns.map(([info, ...column]) => [info, ...sort(column, orderBy.y)])
}
