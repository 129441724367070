import React from 'react'

import { LogoSVG } from '../../../assets/images'

const Brand: React.FC = () => {
  return (
    <div className="flex-auto flex flex-row justify-start items-center group" id="brand">
      <LogoSVG alt="Build and Brew logo" className="flex-shrink-0 w-10 ml-1" />
      <span className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold text-gray-900 transition-colors duration-300 ease-in-out group-hover:text-gray-800 mx-2">
        Build and Brew.
      </span>
      <span className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold text-accent-dark transition-colors duration-300 ease-in-out group-hover:text-accent">
        Online!
      </span>
    </div>
  )
}

export default Brand
