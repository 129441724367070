import { MutableRefObject, useRef } from 'react'
import { useRecoilState } from 'recoil'

import { MousePosition, mousePosition as mousePositionAtom } from '../recoil/mouse-position'

type Validator = (componentRefBoundingRect: DOMRect, mousePosition: MousePosition) => boolean

const defaultValidator: Validator = (componentRefBoundingRect, mousePosition) =>
  mousePosition.x >= componentRefBoundingRect.x &&
  mousePosition.x <= componentRefBoundingRect.x + componentRefBoundingRect.width &&
  mousePosition.y >= componentRefBoundingRect.y &&
  mousePosition.y <= componentRefBoundingRect.y + componentRefBoundingRect.height

interface useMouseOverProps {
  validator?: Validator
}

export const useMouseOver = <T extends HTMLElement>({
  validator = defaultValidator,
}: useMouseOverProps = {}): readonly [MutableRefObject<null | T>, boolean] => {
  const componentRef = useRef<T | null>(null)
  const [mousePosition] = useRecoilState(mousePositionAtom)

  const over: boolean =
    componentRef.current?.getBoundingClientRect() != null &&
    validator(componentRef.current.getBoundingClientRect(), mousePosition)

  return [componentRef, over] as const
}
