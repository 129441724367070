import React from 'react'

import { classnames, TBackgroundColor, TTextColor } from '../@styles/tailwindcss-classnames'

interface IProgressBar {
  progress: number
  max?: number
  showLabel?: boolean
  backgroundColor?: TBackgroundColor
  textColor?: TTextColor
}

const ProgressBar: React.FC<IProgressBar> = ({
  progress,
  max,
  backgroundColor = 'bg-primary',
  textColor = 'text-accent',
  showLabel = true,
}: IProgressBar) => {
  let width = Math.min(100, Math.abs(max ? (progress / max) * 100 : progress)) // for integers

  if (width > 0 && width < 1) width *= 100 // for floats

  return (
    <div className="shadow w-full bg-grey-light">
      <div
        className={classnames('text-xs', 'leading-none', 'py-1', 'text-center', backgroundColor, textColor)}
        style={{ width: width + '%' }}
      >
        {showLabel ? `${width}%` : ''}
      </div>
    </div>
  )
}

export default ProgressBar
