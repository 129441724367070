import React, { PropsWithChildren } from 'react'

import { Footer } from '../components/Footer'
import { NavBar } from '../components/NavBar'

const Page: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div className="relative flex flex-col w-full min-h-screen overflow-x-hidden overflow-y-auto bg-gray-100">
      <NavBar />
      <div className="flex-1 w-full">{children}</div>
      <Footer />
    </div>
  )
}

export default Page
