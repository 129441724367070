import React from 'react'

import Companion, { ICompanion } from './Companion'

const companions: ICompanion[] = [
  {
    platform: 'Firefox',
    description: 'Browser Add-On',
    version: '1.3.2',
    platformIconUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Firefox_logo%2C_2019.svg/1200px-Firefox_logo%2C_2019.svg.png',
    downloadUrl: 'https://addons.mozilla.org/en-US/firefox/addon/build-and-brew-online/',
    backgroundImageUri: process.env.PUBLIC_URL + '/assets/images/pls-76-thunderscape-familiar.jpg',
  },
  {
    platform: 'Chrome',
    description: 'Browser Add-On',
    version: '1.3.0',
    platformIconUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/240px-Google_Chrome_icon_%28September_2014%29.svg.png',
    downloadUrl: 'https://chrome.google.com/webstore/detail/buildandbrewonline-compan/cagjfckmkgnlchgfjbbldmhdehidjfmh?hl=en&authuser=0',
    backgroundImageUri: process.env.PUBLIC_URL + '/assets/images/rtr-218-judge-s-familiar.jpg',
  },
  {
    platform: 'Android',
    description: 'App',
    version: '0.0.0',
    platformIconUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Android_robot.svg/204px-Android_robot.svg.png',
    downloadUrl: '',
    disabledReason: 'Planned',
    backgroundImageUri: process.env.PUBLIC_URL + '/assets/images/dom-220-jhoira-s-familiar.jpg',
  },
]

const Companions: React.FC = () => {
  return (
    <div className="mt-3 flex flex-col justify-start items-center" id="companions">
      <h2 className="text-2xl text-gray-200 font-semibold uppercase my-3">Get your companion</h2>
      <div className="relative w-50vw flex flex-col md:flex-row justify-center items-center">
        {companions.map((companion) => (
          <Companion key={companion.platform} {...companion} />
        ))}
      </div>
      <div className="my-3 w-50vw text-justify">
        <span>
          The core of the holistic deck building experience is defined by our cross-platform companions. Install the
          add-on for the browser of your choice and get the buildandbrew.online sidebar on supported third-party
          MtG&reg; Websites. This enables you to easily drag-and-drop card images, card links, and even text selections
          of card names directly into your decks without the hassle of manually copy-and-pasting names.
          <br />
          Since we're still in early development, let us know if you've got any feedback, feature requests, or bug
          reports!
        </span>
      </div>
    </div>
  )
}

export default Companions
