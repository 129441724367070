import React from 'react'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'

import { useSelectorTyped } from '../../redux-store'
import { DecksOverview } from '../../components/DecksOverview'
import Page from '../Page'

import Companions from './components/companions/Companions'
import Banner from './components/Banner/Banner'
import CommunitySection from './components/community/CommunitySection'

const Home: React.FC = () => {
  const auth = useSelectorTyped((state) => state.firebase.auth)
  useFirestoreConnect({
    collection: 'decks',
    where: ['public', '==', true],
    orderBy: ['created', 'desc'],
    limit: 8,
    storeAs: 'publicDecks',
  })
  const decks = useSelectorTyped((state) => state.firestore.ordered.publicDecks)

  return (
    <Page>
      <Banner />
      <div className="relative -mt-32 mb-5">
        <Companions />
      </div>
      <div className="flex justify-center">
        <hr className="w-full mx-32 border border-gray-500 my-3" />
      </div>
      <div className="w-full relative flex flex-col justify-start items-center my-5">
        <h2 className="text-2xl font-semibold uppercase my-3" id="decks">
          Latest Community Decks
        </h2>
        {!isLoaded(decks) ? (
          <p>Loading public decks...</p>
        ) : isEmpty(decks) ? (
          <p>
            No decks found! <strong>Create a new deck</strong> to get started!
          </p>
        ) : (
          <DecksOverview decks={decks} displayNewDeckTeaser={!isEmpty(auth)} isLoaded={isLoaded(decks)} showOwner />
        )}
      </div>
      <div className="flex justify-center">
        <hr className="w-full mx-32 border border-gray-500 my-3" />
      </div>
      <div className="relative my-5">
        <CommunitySection />
      </div>
    </Page>
  )
}

export default Home
