import { Action } from 'redux'
import Scry, { setRetry, Cards, CardIdentifier, error } from 'scryfall-sdk'

import { ThunkResult } from '..'

import { ScryfallError } from './reducer'

setRetry(3, 2000)

export enum ScryfallActionTypes {
  LOADING = 'SCRYFALL_LOADING',
  ERROR = 'SCRYFALL_ERROR',
  ADD_CARDS = 'SCRYFALL_ADD_CARDS',
}

export type LoadingAction = Action<ScryfallActionTypes.LOADING, boolean>
const setLoading = (value: boolean) => ({ type: ScryfallActionTypes.LOADING, payload: value })

export type ErrorAction = Action<ScryfallActionTypes.ERROR, ScryfallError | null>
const setError = (error: ScryfallError | null) => ({ type: ScryfallActionTypes.ERROR, payload: error })

export interface LoadCardsPayload {
  cards: Scry.Card[]
}
export type LoadCardsAction = Action<ScryfallActionTypes.ADD_CARDS, LoadCardsPayload>
export const loadCards = (cardScryfallIds: string[]): ThunkResult<void> => async (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch(setError(null))

  try {
    const {
      scryfall: { cards: alreadyLoadedCards },
    } = getState()

    const cardIdentifiers = cardScryfallIds.filter((id) => !alreadyLoadedCards[id]).map((id) => CardIdentifier.byId(id))

    if (cardIdentifiers.length === 0) {
      dispatch(setLoading(false))

      return Promise.resolve()
    }

    const cards = await Cards.collection(...cardIdentifiers).waitForAll()
    const scryfallError = error()

    if (scryfallError || (cards['not_found'] && cards['not_found'].length > 0)) {
      throw new Error(
        scryfallError ? scryfallError.details : `${cards['not_found'].length} cards couldn't be fetched from Scryfall`,
      )
    }
    dispatch({ type: ScryfallActionTypes.ADD_CARDS, payload: { cards } })
    dispatch(setLoading(false))

    return Promise.resolve()
  } catch (e) {
    dispatch(setError({ message: e.message ?? JSON.stringify(e) }))
    dispatch(setLoading(false))

    return Promise.reject()
  }
}

export type ScryfallActions = LoadingAction | ErrorAction | LoadCardsAction
