import { RouteProps } from 'react-router-dom'

import Deck from './deck/Deck'
import Home from './home/Home'
import User from './user/User'
import SignUp from './signup/SignUp'
import Search from './search/Search'
import PrivacyPolicy from './privacypolicy/PrivacyPolicy'

const routeConfig: RouteProps[] = [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/search',
    component: Search,
  },
  // {
  //   path: '/decks',
  //   exact: true,
  //   component: Decks,
  // },
  {
    path: '/decks/:id',
    component: Deck,
  },
  // {
  //   path: '/users',
  //   exact: true,
  //   component: Users,
  // },
  {
    path: '/users/:uid',
    component: User,
  },
  {
    path: '/join',
    component: SignUp,
  },
  {
    path: '/privacypolicy',
    component: PrivacyPolicy,
  },
]
// TODO 404 not found

export default routeConfig
