import React, { useCallback, useEffect, useState } from 'react'
import Scry, { Cards as CardsSdk } from 'scryfall-sdk'

import { classnames } from '../@styles/tailwindcss-classnames'
import { FlipCardSVG } from '../assets/images/FlipCardSVG'

const Card: React.FC<{ card: Scry.Card }> = ({ card }) => {
  const [loaded, setLoaded] = useState(false)
  const [imageUri, setImageUri] = useState<string>('')

  const [face, setFace] = useState(0)
  const flip = useCallback(() => {
    setFace(1 - face)
  }, [face, setFace])

  const doubleSided = card.card_faces && card.card_faces.length > 1
  const meldResult = card.all_parts && card.all_parts.find((part) => part.component === 'meld_result')
  const [meldResultImage, setMeldResultImage] = useState('')
  useEffect(() => {
    if (meldResult) {
      CardsSdk.byId(meldResult.id)
        .then((card) => setMeldResultImage(card.image_uris?.normal ?? ''))
        .catch()
    }
  }, [meldResult, setMeldResultImage])
  useEffect(() => {
    if (meldResult) {
      setImageUri(face === 0 ? card.image_uris?.normal ?? '' : meldResultImage)
    } else {
      setImageUri(
        (doubleSided ? card.card_faces && card.card_faces[face].image_uris?.normal : card.image_uris?.normal) ?? '',
      )
    }
  }, [card, doubleSided, meldResult, meldResultImage, face, setImageUri])

  return (
    <div
      className={classnames(
        'relative',
        'rounded-cardborder',
        'shadow-sm',
        card.border_color === 'black' || card.border_color === 'borderless'
          ? classnames('border-gray-600', 'border-t', 'bg-black')
          : classnames('border-gray-400', 'border', card.border_color === 'white' ? 'bg-white' : 'bg-transparent'),
      )}
    >
      {(doubleSided || meldResult) && (
        <button
          className="absolute right-0 mr-4 mt-8 w-8 h-8 transition-colors duration-200 rounded-full bg-warning bg-opacity-50 hover:bg-opacity-100 text-gray-100 font-semibold"
          onClick={flip}
        >
          <FlipCardSVG alt="show the card's back" className="m-1" />
        </button>
      )}
      {!loaded && (
        <div className="relative w-full pt-cardIndexRatio">
          <div className="absolute top-0 left-0 box-border rounded-xl border-black w-full h-full bg-gray-400 bg-opacity-75 flex flex-col justify-start items-center border-cardborder">
            <span className="text-dark text-lg tracking-tighter">Loading...</span>
          </div>
        </div>
      )}
      <img
        alt={`The card ${card.name}`}
        className="w-full rounded-cardborder"
        hidden={!loaded}
        src={imageUri}
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}

export default Card
