import React, { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

import Page from '../Page'

import SignUpForm from './components/SignUpForm'

const DEFAULT_AVATAR_URL =
  'https://c1.scryfall.com/file/scryfall-cards/art_crop/front/5/a/5aa90ab6-2686-4462-8725-5d4370c05437.jpg?1562488395'

export const SignUp: React.FC = () => {
  const firebase = useFirebase()
  const history = useHistory()

  const [error, setError] = useState<string | null>(null)

  const redirectToUserOverview = (uid: string) => {
    const route = `/users/${uid}`

    try {
      history.push(route)
    } catch (error) {
      console.error('Error while redirecting to', route, '!\n', error)
    }
  }

  const signInWithAccount = (email: string, password: string, nickname?: string) => {
    firebase
      .createUser(
        { email, password, signIn: true },
        { email, displayName: nickname ?? email, avatarUrl: DEFAULT_AVATAR_URL },
      )
      .then((userInfo) => redirectToUserOverview(userInfo.uid))
      .catch((error) => setError(error.message))
  }

  return (
    <Page>
      <div className="w-full flex flex-col text-center">
        <span className="text-2xl font-semibold text-dark">Join the Build and Brew multiverse</span>
        <span className="text-lg text-dark-secondary my-6">Sign Up with your Email Address and Password</span>
        <SignUpForm onSubmit={signInWithAccount} />
        {error && <strong className="text-warning">Error while creating account: {JSON.stringify(error)}</strong>}
      </div>
    </Page>
  )
}

export default SignUp
