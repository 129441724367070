import React, { PropsWithChildren } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import Scry from 'scryfall-sdk'

import { classnames } from '../../../../@styles/tailwindcss-classnames'
import { Card } from '../../../../redux-store/firebase'

import ColumnHeader, { NEW_CATEGORY_COLUMN_NAME, NEW_CATEGORY_ID } from './ColumnHeader'

export type ColumnInfo = [string, ...Scry.Card[]]

interface ICategoryColumn {
  name: string
  cards: (Card & { oracleId: string })[]
  cardsInfo: { [id: string]: Scry.Card }
  isDropDisabled?: boolean
  allowRenaming?: boolean
  rename: (newName: string) => void
}
const CategoryColumn: React.FC<PropsWithChildren<ICategoryColumn>> = ({
  children,
  name,
  cards,
  cardsInfo,
  rename,
  allowRenaming = false,
  isDropDisabled = false,
}) => {
  const isNewCategory = name === NEW_CATEGORY_COLUMN_NAME

  return (
    <div key={name} className={classnames('flex-auto', 'flex-grow-0', 'h-full', 'px-2')}>
      <ColumnHeader
        allowRenaming={allowRenaming}
        cards={cards}
        cardsInfo={cardsInfo}
        columnName={name}
        isNewCategory={isNewCategory}
        rename={rename}
      />

      <Droppable
        direction="vertical"
        droppableId={isNewCategory ? NEW_CATEGORY_ID : name}
        isDropDisabled={isDropDisabled}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={classnames(
              'h-full',
              'flex',
              'flex-col',
              'justify-start',
              'items-center',
              'rounded-cardborder',
              'transition-colors',
              'duration-300',
              snapshot.isDraggingOver && snapshot.draggingFromThisWith
                ? 'bg-gray-300'
                : snapshot.isDraggingOver && !snapshot.draggingFromThisWith
                ? 'bg-teal-400'
                : !snapshot.isDraggingOver && snapshot.draggingFromThisWith
                ? 'bg-red-300'
                : 'bg-transparent',
              { [classnames('bg-gray-300', 'my-1')]: cards.length === 0 },
            )}
            style={
              cards.length === 0 && !snapshot.isDraggingOver
                ? { paddingTop: '139%', width: '12rem' }
                : { width: '12rem' }
            }
            {...provided.droppableProps}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default CategoryColumn
