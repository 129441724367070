import React from 'react'
import { Link } from 'react-router-dom'

export const Footer: React.FC = () => {
  return (
    <footer
      className="bg-gray-300 text-dark-secondary text-xs p-3 flex flex-row justify-between items-start"
      role="contentinfo"
    >
      <div className="flex-grow">
        The art on the Build And Brew Firefox&reg; companion is of the card
        <a
          className="appearance-none"
          href="https://scryfall.com/card/pls/76/thunderscape-familiar"
          rel="noreferrer"
          target="_blank"
        >
          <i> Thunderscape Familiar</i>
        </a>
        . It is illustrated by Daren Bader and is property of Wizards of the Coast LLC. The art on the Build And Brew
        Chrome&reg; companion is of the card
        <a
          className="appearance-none"
          href="https://scryfall.com/card/rtr/218/judges-familiar"
          rel="noreferrer"
          target="_blank"
        >
          <i> Judge's Familiar</i>
        </a>
        . It is illustrated by Jack Wang and is property of Wizards of the Coast LLC. The art on the Build And Brew
        Android&reg; companion is of the card
        <a
          className="appearance-none"
          href="https://scryfall.com/card/dom/220/jhoiras-familiar"
          rel="noreferrer"
          target="_blank"
        >
          <i> Jhoira's Familiar</i>
        </a>
        . It is illustrated by Kev Walker and is property of Wizards of the Coast LLC.
        <br />
        Wizards of the Coast, Magic: The Gathering, and their logos are trademarks of Wizards of the Coast LLC in the
        United States and other countries. <span>&copy;</span> 1993-2020 Wizards. All Rights Reserved.
        <br />
        RapidEDH is not affiliated with, endorsed, sponsored, or specifically approved by Wizards of the Coast LLC.
        RapidEDH may use the trademarks and other intellectual property of Wizards of the Coast LLC, which is permitted
        under Wizards' Fan Site Policy. MAGIC: THE GATHERING&reg; is a trademark of Wizards of the Coast. For more
        information about Wizards of the Coast or any of Wizards' trademarks or other intellectual property, please
        visit their website at{' '}
        <a className="appearance-none" href="https://company.wizards.com/" rel="noreferrer" target="_blank">
          https://company.wizards.com/
        </a>
        . Card prices and other card data are provided by{' '}
        <a className="appearance-none" href="https://scryfall.com/" rel="noreferrer" target="_blank">
          Scryfall
        </a>
        . Scryfall makes no guarantee about its price information and recommends you see stores for final prices and
        details.
      </div>
      <div className="flex-shrink w-3/12">
        <Link to="/privacypolicy">
          <div className="font-bold hover:text-dark">Privacy Policy</div>
        </Link>
        <Link to="/join">
          <div className="font-bold hover:text-dark">Sign Up</div>
        </Link>
      </div>
    </footer>
  )
}
