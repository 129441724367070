import React from 'react'

import { Deck } from '../../redux-store/firebase'

import DeckTeaser from './components/DeckTeaser'
import CreateNewDeckTeaser from './components/CreateNewDeckTeaser'

interface IDecksOverview {
  decks: Deck[]
  isLoaded: boolean
  displayNewDeckTeaser?: boolean
  showOwner: boolean
}

export const DecksOverview: React.FC<IDecksOverview> = (props: IDecksOverview) => {
  const deckTeasers = props.isLoaded
    ? props.decks.map((deck) => <DeckTeaser key={deck.id} {...deck} showOwner={props.showOwner} />)
    : []

  if (props.displayNewDeckTeaser) deckTeasers.push(<CreateNewDeckTeaser key="createNewDeckTeaser" />)

  return (
    <div className="m-auto flex flex-wrap justify-center">
      {/* {error && <strong>Error: {JSON.stringify(error)}</strong>} */}
      {deckTeasers}
    </div>
  )
}
