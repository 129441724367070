import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import routeConfig from './pages/route-config'

const App: React.FC = () => {
  const routes = routeConfig.map((routeProps) => <Route key={routeProps.path as string} {...routeProps} />)

  return (
    <div className="antialiased">
      <RecoilRoot>
        <Router>
          <Switch>{routes}</Switch>
        </Router>
      </RecoilRoot>
    </div>
  )
}

export default App
