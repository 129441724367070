import React from 'react'

import { SvgProps } from './props'

export const LoginSVG: React.FC<SvgProps> = (props) => {
  const classes = 'fill-current ' + props.className ?? ''

  return (
    <svg className={classes} viewBox="0 0 20 20">
      <title>{props.alt ?? 'Login'}</title>
      <path d="M14 10L8 5v3H1v4h7v3l6-5zm3 7H9v2h8c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2H9v2h8v14z" />
    </svg>
  )
}
