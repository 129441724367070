import React from 'react'
import { Link } from 'react-router-dom'

interface Menu {
  name: string
  linkTo: string
}
const menus: Menu[] = [
  {
    name: 'decks',
    linkTo: '/#decks',
  },
  {
    name: 'companions',
    linkTo: '/#companions',
  },
  {
    name: 'search',
    linkTo: '/#search',
  },
]

const MainMenu: React.FC = () => {
  return (
    <div className="flex-1 flex flex-row justify-center items-center">
      {menus.map((menu) => (
        <Link key={menu.name} to={menu.linkTo}>
          <div className="relative h-full p-2 hover:bg-gray-200 text-center group">
            <span className="text-lg font-normal tracking-tighter uppercase text-dark-secondary group-hover:text-dark">
              {menu.name}
            </span>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default MainMenu
