import React from 'react'
import { isEmpty } from 'react-redux-firebase'
import { Link } from 'react-router-dom'

import { buttonAccent } from '../../../../@styles/styles'
import { useSelectorTyped } from '../../../../redux-store'

import SearchBar from './components/SearchBar'

const Banner: React.FC = () => {
  const auth = useSelectorTyped((state) => state.firebase.auth)

  return (
    <div className="w-full relative bg-blue-700 flex flex-col justify-center items-center" style={{ height: '28rem' }}>
      <div className="relative flex flex-row justify-center items-start">
        <h1 className="text-center text-3xl md:text-5xl text-light font-semibold mb-4">
          The holistic MtG deck building companion
        </h1>
        <span className="absolute right-0 top-0 -mt-10 text-center font-semibold text-lg transform text-gray-100 bg-warning-dark shadow-lg py-1 px-3 uppercase">
          Alpha Version
        </span>
      </div>
      <div className="w-full flex flex-row justify-center items-center" id="search">
        <SearchBar />
        {isEmpty(auth) && (
          <Link to="/join">
            <span className={buttonAccent}>Join us!</span>
          </Link>
        )}
      </div>
    </div>
  )
}
export default Banner
