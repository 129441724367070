import React, { useState } from 'react'

import { YouTubeSVG } from '../assets/images/YouTubeSVG'

interface IYoutubeVideo {
  title: string
  youtubeId: string
}
const YoutubeVideo: React.FC<IYoutubeVideo> = ({ title, youtubeId }) => {
  const [loading, setLoading] = useState(true)

  return (
    <div
      className="video relative"
      style={{
        paddingBottom: '56.25%' /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-300 flex flex-col justify-center items-center">
          <div
            className="absolute w-full h-full"
            style={{
              background:
                'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.8))',
            }}
          />
          <span className="absolute top-0 left-0 ml-3 mt-2 truncate text-white font-normal text-xl">{title}</span>
          <YouTubeSVG className="w-2/12" />
          <span className="text-dark-secondary font-normal text-xl pl-2">Loading...</span>
        </div>
      )}
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        className="absolute top-0 left-0 w-full h-full"
        frameBorder="0"
        hidden={loading}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title={title}
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </div>
  )
}

export default YoutubeVideo
