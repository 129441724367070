import React from 'react'

import { SvgProps } from './props'

export const LogoSVG: React.FC<SvgProps> = (props) => {
	const classes = 'fill-current ' + props.className ?? ''

	return (
		<svg className={classes} viewBox="0 0 1200 1200">
			<title>{props.alt ?? 'Build and Brew . online logo'}</title>
			<g>
				<path
					d="M796.1,978.4L755.7,938L620,802.3l-318.2,318.2c283.1,162.3,645.2,68.5,813.2-212.8
		c57.1-95.5,84.5-200.6,85-304.3l-375,375C817,986.4,804.1,986.4,796.1,978.4z"
					fill="#004682"
				/>
				<path
					d="M443.8,626.1c-8-8-8-20.9,0-28.8l161.7-161.7L429.3,259.3c-8-8-20.9-8-28.8,0L367,292.9L2.8,657.1
		C20.4,840.4,122,1013.2,292.3,1115c3.1,1.9,6.3,3.7,9.5,5.5L620,802.3L443.8,626.1z"
					fill="#FFFFFF"
				/>
				<path
					d="M605.5,435.5l176.2,176.2c8,8,8,20.9,0,28.8L620,802.3L755.7,938l40.4,40.4c8,8,20.9,8,28.8,0l375-375
		c0.9-193.9-93.1-384.1-262.8-499.6L605.5,435.5z"
					fill="#FFFFFF"
				/>
				<path
					d="M443.8,626.1L620,802.3l161.7-161.7c8-8,8-20.9,0-28.8L605.5,435.5L443.8,597.3
		C435.8,605.2,435.8,618.1,443.8,626.1z"
					fill="#FFCD00"
				/>
				<path
					d="M85,292.3C16.8,406.4-9.1,534.1,2.8,657.1L367,292.9l33.5-33.5c8-8,20.9-8,28.8,0l176.2,176.2l331.7-331.7
		c-9.6-6.5-19.4-12.8-29.5-18.8C623.3-84.9,255,7.9,85,292.3z"
					fill="#004682"
				/>
			</g>
		</svg>
	)
}
