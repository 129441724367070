import React, { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

export interface MousePosition {
  x: number
  y: number
}

export const mousePosition = atom<MousePosition>({
  key: 'mousePosition',
  default: { x: 0, y: 0 },
})

export const withMousePositionListener = <P,>(BaseComponent: React.ComponentType<P>): React.FC<P> => (props) => {
  const [, setMousePosition] = useRecoilState(mousePosition)
  const handleMouseMovement = (event: MouseEvent) => {
    setMousePosition({ x: event.clientX, y: event.clientY })
  }
  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMovement)
    document.addEventListener('dragover', handleMouseMovement)

    return () => {
      document.removeEventListener('mousemove', handleMouseMovement)
      document.removeEventListener('dragover', handleMouseMovement)
    }
  }, [])

  return <BaseComponent {...props} />
}
