import React from 'react'
import { Link } from 'react-router-dom'
import { useFirebase } from 'react-redux-firebase'

import { LogoutSVG } from '../../../assets/images'
import { classnames } from '../../../@styles/tailwindcss-classnames'
import { useSelectorTyped } from '../../../redux-store'
import { SettingsSVG } from '../../../assets/images/SettingsSVG'

import { SignInButton } from './SignInButton'

export const Account: React.FC = () => {
  const { isLoaded: isAuthLoaded, isEmpty: isAuthEmpty, uid } = useSelectorTyped((state) => state.firebase.auth)
  const { isLoaded: isProfileLoaded, isEmpty: isProfileEmpty, displayName, avatarUrl } = useSelectorTyped(
    (state) => state.firebase.profile,
  )
  const userReady = isAuthLoaded && !isAuthEmpty && isProfileLoaded && !isProfileEmpty

  const firebase = useFirebase()
  const signOut = (event: React.MouseEvent) => {
    event.preventDefault()

    if (userReady) {
      firebase
        .auth()
        .signOut()
        .catch((error) => console.error(error))
    }
  }

  if (!userReady) return <SignInButton />

  return (
    <div className="inline-block group relative">
      <Link to={`/users/${uid}`}>
        <div className="h-full p-2 hover:bg-gray-200 flex flex-row justify-end items-center group">
          <span className="text-lg font-normal tracking-tighter uppercase text-dark-secondary group-hover:text-dark mr-1">
            {displayName}
          </span>
          <img
            alt={`${displayName}'s Avatar`}
            className="flex-shrink-0 w-6 h-6 rounded-full m-1 bg-dark-secondary"
            referrerPolicy="no-referrer"
            src={avatarUrl}
          />
        </div>
      </Link>
      <div className={classnames('hidden', 'group-hover:block', 'absolute', 'right-0', 'top-100', 'z-10')}>
        <div className="h-full w-40 mt-3 mr-2 p-3 rounded-xl bg-gray-100 border-1 border-gray-300 shadow-2xl flex flex-col justify-start items-end">
          <div
            className="w-full flex flex-row justify-end items-center p-2 hover:bg-gray-200 text-dark-secondary hover:text-dark"
            onClick={signOut}
          >
            <span className="text-lg font-normal tracking-tighter uppercase mr-1">Sign out</span>
            <LogoutSVG className="flex-shrink-0 w-6 h-6 m-1" />
          </div>
          <div className="w-full flex flex-row justify-end items-center p-2 hover:bg-gray-200 text-dark-secondary hover:text-dark">
            <span className="text-lg font-normal tracking-tighter uppercase mr-1">Settings</span>
            <SettingsSVG className="flex-shrink-0 w-6 h-6 m-1" />
          </div>
        </div>
      </div>
    </div>
  )
}
