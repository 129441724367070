import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import Scry from 'scryfall-sdk'

import { classnames } from '../../../@styles/tailwindcss-classnames'
import Card from '../../../components/Card'
import { Card as CardInfo, Deck as DeckInfo } from '../../../redux-store/firebase'

interface ICardView {
  card: Scry.Card
  cardInfo: CardInfo
  deckInfo: DeckInfo
  containerProps: {
    index: number
    uniqueId: string
    isDragDisabled?: boolean
    noOverlap?: boolean
  }
}

/**
 * Display deck related info (e.g. quantity, isCommander) and card prices (→ affiliate links!)
 */
const CardView: React.FC<ICardView> = ({
  card,
  cardInfo,
  deckInfo,
  containerProps: { isDragDisabled = false, noOverlap = false, ...dragProps },
}) => {
  return (
    <Draggable draggableId={dragProps.uniqueId} index={dragProps.index} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <div
          className={classnames('relative', 'box-border', 'w-48', 'm-1', 'transition-spacing', 'duration-500', {
            'cursor-move': !isDragDisabled,
            '-mb-48': !noOverlap,
            'hover:mb-2': !noOverlap,
          })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="relative">
            <Card card={card} />
            {deckInfo.commanders.find((cmdr) => cmdr.name === card.name) && (
              <div className="absolute pointer-events-none top-0 left-0 w-full h-full box-border border-accent-dark rounded-cardborder bg-opacity-0 border-cardborder" />
            )}
            {cardInfo && cardInfo.quantity && cardInfo.quantity > 1 && (
              <p className="inline-block absolute pointer-events-none top-0 left-0 mt-8 bg-blue-700 border-blue-900 border-1 rounded-r-md py-1 px-2 text-gray-100 text-xl shadow-lg font-semibold">
                {cardInfo.quantity}
              </p>
            )}
          </div>
          <div className="w-full px-4 flex justify-center items-center">
            {/* TODO localize priority */}
            <span className="text-gray-500 text-lg font-semibold tracking-tighter ">
              {card.prices.eur ? `${card.prices.eur} €` : `${card.prices.usd ?? '–'} $`}
            </span>
          </div>
        </div>
      )}
    </Draggable>
  )
}

export default CardView
