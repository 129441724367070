import React from 'react'

import { SvgProps } from './props'

export const FlipCardSVG: React.FC<SvgProps> = (props) => {
  const classes = 'fill-current hover:animate-spin ' + props.className ?? ''

  return (
    <svg className={classes} viewBox="0 0 20 20">
      {props.alt ?? <title>props.alt</title>}
      <path d="M19.315 10h-2.372v-.205c-.108-4.434-3.724-7.996-8.169-7.996C4.259 1.799.6 5.471.6 10s3.659 8.199 8.174 8.199a8.13 8.13 0 0 0 5.033-1.738l-1.406-1.504a6.099 6.099 0 0 1-3.627 1.193c-3.386 0-6.131-2.754-6.131-6.15s2.745-6.15 6.131-6.15c3.317 0 6.018 2.643 6.125 5.945V10h-2.672l3.494 3.894L19.315 10z" />
    </svg>
  )
}
