import { classnames } from './tailwindcss-classnames'

export const button = classnames(
  'inline-block',
  'border-none',
  'px-4',
  'py-1',
  'shadow-lg',
  'rounded-lg',
  'uppercase',
  'text-sm',
  'font-semibold',
  'tracking-wider',
  'focus:outline-none',
  'focus:shadow-outline',
)

export const buttonPrimary = classnames(
  button,
  'bg-primary',
  'text-accent',
  'hover:bg-primary-light',
  'hover:text-dark',
)

export const buttonAccent = classnames(button, 'bg-accent-dark', 'text-dark', 'hover:bg-accent')

export const buttonWarning = classnames(button, 'bg-warning-dark', 'text-dark', 'hover:bg-warning')

export const alertButton = classnames(button, 'bg-red-100')

export const disabled = classnames('opacity-25', 'bg-gray-100')
