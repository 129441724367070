import React, { useEffect, useState } from 'react'
import { useFirestoreConnect, isLoaded, isEmpty, useFirestore, ReduxFirestoreQuerySetting } from 'react-redux-firebase'
import { RouteComponentProps } from 'react-router-dom'

import { useSelectorTyped } from '../../redux-store'
import { DecksOverview } from '../../components/DecksOverview'
import Page from '../Page'
import { Deck } from '../../redux-store/firebase'

const searchTermRegex = /(?:^\?|&)q=([\w %]+)(?:$|&)/
const getSearchTerm = (query: string): string => {
  if (!query) return ''
  const matches = searchTermRegex.exec(query)

  if (!matches) return ''

  return matches[1].replace(/%20/g, ' ').trim()
}

const Search: React.FC<RouteComponentProps> = ({ location }) => {
  const { search } = location
  const searchTerm: string = getSearchTerm(search)
  console.log(searchTerm)
  const auth = useSelectorTyped((state) => state.firebase.auth)

  const publicResultsReduxPath = 'publicSearchResults'
  useFirestoreConnect({
    collection: 'decks',
    where: [
      ['public', '==', true],
      ['name', '==', searchTerm],
    ],
    orderBy: [['created', 'desc']],
    storeAs: publicResultsReduxPath,
  })
  const publicResults: Deck[] = useSelectorTyped((state) => state.firestore.ordered[publicResultsReduxPath])

  const firestore = useFirestore()
  const privateResultsReduxPath = `privateSearchResults`
  useEffect(() => {
    if (auth?.uid) {
      console.log('Set new listener', auth, !!firestore)
      const collection: ReduxFirestoreQuerySetting = {
        collection: 'decks',
        where: [
          ['owner', '==', auth?.uid],
          ['public', '==', false],
          ['name', '==', searchTerm],
        ],
        orderBy: ['created', 'desc'],
        storeAs: privateResultsReduxPath,
      }
      firestore.setListener(collection)

      return () => firestore.unsetListener(collection)
    }
  }, [auth?.uid, searchTermRegex, firestore])
  const privateResults: Deck[] = useSelectorTyped((state) => state.firestore.ordered[privateResultsReduxPath])

  const [results, setResults] = useState<Deck[]>([])
  useEffect(() => {
    const allResults: Deck[] = (privateResults || []).concat(publicResults || [])
    // TODO sort again
    console.log(privateResults)
    console.log(publicResults)
    console.log(allResults)
    setResults(allResults)
  }, [auth, publicResults, setResults])

  const loaded = isLoaded(publicResults)
  const empty = results.length === 0

  return (
    <Page>
      <div className="w-full flex flex-col justify-center items-center">
        <hr className="w-50vw mx-32 border border-gray-500 my-3" />
        <span className="my-3 text-lg text-gray-700">
          <b>This search is still in development.</b> Due to third party restrictions, a deck is only shown if it has
          the exact same name as your input. Give the search another try in a couple of weeks! :)
        </span>
        <hr className="w-50vw mx-32 border border-gray-500 my-3" />
      </div>
      <div className="w-full relative flex flex-col justify-start items-center my-5">
        {!loaded ? (
          <p>Searching...</p>
        ) : empty ? (
          <p>No decks found!</p>
        ) : (
          <DecksOverview decks={results} displayNewDeckTeaser={!isEmpty(auth)} isLoaded={loaded} showOwner />
        )}
      </div>
    </Page>
  )
}

export default Search
