import React, { useState } from 'react'
import Scry from 'scryfall-sdk'

import { Card } from '../../../../redux-store/firebase'

export const NEW_CATEGORY_COLUMN_NAME = ''
export const NEW_CATEGORY_ID = 'newCategory'
export const NEW_CATEGORY_DISPLAY_NAME = 'New Category'

interface IColumnHeader {
  columnName: string
  cards: (Card & { oracleId: string })[]
  cardsInfo: { [id: string]: Scry.Card }
  isNewCategory?: boolean
  allowRenaming?: boolean
  rename: (newName: string) => void
}
const ColumnHeader: React.FC<IColumnHeader> = ({
  columnName,
  cards,
  isNewCategory = false,
  rename,
  allowRenaming = false,
}) => {
  const [name, setName] = useState(columnName)

  const nameDisplay = (
    <input
      className={
        'appearance-none flex-auto flex-shrink flex-grow w-0 uppercase tracking-tight text-blue-900 text-lg font-bold px-1 mr-1 bg-transparent focus:bg-gray-300 placeholder-gray-700 rounded-cardborder' +
        (allowRenaming ? ' hover:bg-gray-300 hover:placeholder-gray-900' : '')
      }
      disabled={!allowRenaming}
      id={isNewCategory ? NEW_CATEGORY_ID : columnName}
      placeholder={isNewCategory ? NEW_CATEGORY_DISPLAY_NAME : columnName}
      type="text"
      value={name}
      onChange={(event) => setName(event.target.value)}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          ;(event.target as HTMLInputElement).blur()
          const inputValue = name.trim()

          if (!inputValue || inputValue === columnName) return

          rename(inputValue)
        } else if (event.key === 'Escape') {
          ;(event.target as HTMLInputElement).blur()
          setName(columnName)
        }
      }}
    />
  )

  const showInfo = !isNewCategory
  const numberOfCards = cards.reduce((result, c) => result + (c.quantity ?? 1), 0)
  const info: JSX.Element[] = []
  info.push(
    <span key="number of cards" className="text-gray-500 text-base font-semibold self-center">
      {numberOfCards}
    </span>,
  )

  return (
    <div className="flex flex-row justify-between">
      {nameDisplay}
      {showInfo && <div className="flex-shrink flex flex-row justify-end items-center ml-2">{info}</div>}
    </div>
  )
}

export default ColumnHeader
