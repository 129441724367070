import React from 'react'

import SortOrderSelector, { ISortOrderSelector } from './SortOrderSelector'
import RemoveCardDroppable, { IRemoveCardDroppable } from './RemoveCardDroppable'

interface IToolBar {
  canUserManipulateDeck?: boolean
  cardSortOrder: ISortOrderSelector
  removeCardProps: IRemoveCardDroppable
}

const ToolBar: React.FC<IToolBar> = ({ canUserManipulateDeck = false, cardSortOrder, removeCardProps }: IToolBar) => {
  return (
    <div className="flex flex-row justify-between items-start py-2 px-3 bg-blue-800 shadow-lg mb-2">
      <SortOrderSelector {...cardSortOrder} />
      {canUserManipulateDeck && (
        <div className="flex-auto flex-grow-1 relative h-6 flex flex-row justify-end items-start">
          <RemoveCardDroppable {...removeCardProps} />
        </div>
      )}
    </div>
  )
}

export default ToolBar
