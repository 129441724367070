import { Reducer } from 'redux'
import Scry from 'scryfall-sdk'

import { ScryfallActions, ScryfallActionTypes } from './actions'

export interface ScryfallError {
  message: string
}

export interface IScryfall {
  loading: boolean
  error: ScryfallError | null
  cards: {
    [id: string]: Scry.Card
  }
}

const initialState: IScryfall = {
  loading: false,
  error: null,
  cards: {},
}

const scryfall: Reducer<IScryfall, ScryfallActions> = (state = initialState, action) => {
  console.log('Reducing', action.type, ':', action.payload)

  switch (action.type) {
    case ScryfallActionTypes.LOADING:
      return { ...state, loading: action.payload ?? false }
    case ScryfallActionTypes.ERROR:
      return { ...state, error: action.payload ?? null }
    case ScryfallActionTypes.ADD_CARDS: {
      const cards = action.payload?.cards ?? ([] as Scry.Card[])
      const map = cards.reduce((acc, card) => {
        acc[card.id] = card

        return acc
      }, {} as { [id: string]: Scry.Card })

      return { ...state, cards: { ...state.cards, ...map } }
    }
    default:
      return state
  }
}

export default scryfall
