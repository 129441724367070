import React from 'react'
import { useHistory } from 'react-router-dom'

import { classnames } from '../../../@styles/tailwindcss-classnames'
import { Deck } from '../../../redux-store/firebase'

export const deckTeaserStyle = classnames(
  'relative',
  'box-border',
  'm-2',
  'border-2',
  'hover:border-gray-800',
  'rounded-lg',
  'shadow-md',
  'hover:shadow-xl',
  'w-64',
  'text-center',
  'bg-blue-900',
  'group',
)

interface IDeckTeaser {
  showOwner?: boolean
}

const DeckTeaser: React.FC<IDeckTeaser & Deck> = (props: IDeckTeaser & Deck) => {
  const history = useHistory()

  if (!props.id) return null //TODO show "Not Found or no Access"

  const commanders = props.commanders ?? []
  const commanderNames =
    commanders.length === 0
      ? 'No Commander yet'
      : commanders.length === 1
      ? commanders[0].name
      : commanders.map((cmdr) => cmdr.name.split(',')[0]).reduce((all, next) => all + ' | ' + next)

  const handleClick = () => {
    //TODO use a props.method to push deck data to correct redux storage before pushing, so the /deck/:id page can start rendering the already fetched data!
    history.push(`/decks/${props.id}`)
  }

  return (
    <div className={deckTeaserStyle} onClick={handleClick}>
      {props.commanders.length > 0 && (
        <div
          className="absolute w-full h-full"
          id="backgroundImage"
          style={{
            backgroundImage: `url(${props.commanders[0].avatarUri})`,
            backgroundPosition: 'center bottom',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            filter: 'blur(2px) brightness(0.5)',
          }}
        />
      )}
      <div className="relative w-full h-full p-8">
        <div>
          <h3 className="text-accent-light text-xl font-semibold group-hover:text-accent">{props.name}</h3>
          <span className="text-gray-200 text-lg font-semibold">{commanderNames}</span>
          {props.created && (
            <p className="text-gray-400 text-base">
              Created at{' '}
              {props.created.toDate().toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })}{' '}
              {props.showOwner && <span>by {props.owner}</span>}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default DeckTeaser
