import React from 'react'

import Page from '../Page'

const PrivacyPolicy: React.FC = () => {
  return (
    <Page>
      <div>
        <h1 className="text-5xl text-light font-semibold">Privacy Policy</h1>
        <br />
        <h3 className="text-xl font-semibold">BuildandBrew Privacy Policy</h3>
        <i>Last revised on 28th November 2020.</i> <br /> Rapid EDH (“BuildandBrew”) operates the domain
        www.buildandbrew.online and the BuildandBrew browser extension (“B&B-Service”). BuildandBrew collects personally
        identifiable information from you solely for the purposes described in the "Usage of User Information" section
        below. We will not sell your personally identifiable information to advertisers or spammers. We will only share
        the information we collect from you as described in the section below entitled "How We Share Your User
        Information." This Privacy Policy is designed to show you what information about you we collect through the
        B&B-Service, and how we use and share that information. Your use of web browser software applications (the
        "Browser Software") in general and your relationship with the author(s) of the Browser Software are subject to
        the Privacy Policy and other terms and policies of the Browser Software, which we strongly recommend that you
        review before you start using BuildandBrew.online. We do not control and are not responsible for how the Browser
        Software processes your information.
        <h2 className="text-2xl font-semibold uppercase my-3">Information We Collect About You</h2>
        <h3 className="text-xl font-semibold">Website Visit Information</h3>
        The B&B-Service enables you to find cards within the websites you visit and sort them into the corresponding
        deck. In order to facilitate this, the B&B-Service must have access to read the information present on all
        websites you visit. The B&B-Service does not store information collected in this way. When first using
        theB&B-Service, you will be asked to grant us access to read and change all your data on the websites you visit.
        <br />
        Cookies are being used to help us analyze our service and to improve the user experience of our service.
        <br />
        Cookies are files with little amount of data which may include an anonymous unique identifier. Cookies are sent
        to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and
        scripts to collect and track information and to improve and analyze our Service.
        <br />
        <br />
        <h3 className="text-xl font-semibold">User Information / Personal Data</h3>
        When using our service, you will need to identify yourself by providing certain personal data in order to
        identify you as a unique member of our community. In addition, we may collect information we receive from you in
        connection with your requests for customer support, your user feedback or other communications you direct to us.
        We may also collect information related to your usage of the B&B-Service to improve our software (including
        Cookies). The purposes of this collection are described in the section below.
        <br />
        <br />
        <h3 className="text-xl font-semibold">Usage of User Information</h3>
        We will only use your User Information for the following purposes:
        <br />
        <ul>
          <li>to provide technical support and to respond to your enquiries</li>
          <li>to communicate about new features or promotions of the B&B-Service</li>
          <li>to prevent fraud or potentially illegal activities and to enforce our Terms of Use</li>
          <li>to solicit your feedback to improve the B&B-Service and your experience using it</li>
          <li>to administer and improve the B&B-Service</li>
        </ul>
        We will not sell your personally identifiable User Information to anyone, including advertisers or spammers. You
        may choose to not receive communications from us about new features or promotions of the B&B-Service by opting
        out of such communications.
        <br />
        <br />
        <h3 className="text-xl font-semibold">Sharing of User Information</h3>
        The Company may disclose your personal information to a third-party for a business purpose or sell your personal
        information, subject to your right to opt-out of those sales (see ‘Sales of Personal Information’ below). When
        we disclose personal information for a business purpose, we enter a contract that describes the purpose and
        requires the recipient to both keep that personal information confidential and not use it for any purpose except
        performing the contract. The CCPA prohibits third parties who purchase the personal information we hold from
        reselling it unless you have received explicit notice and an opportunity to opt-out of further sales (see ‘Sales
        of Personal Information’ below). We may share your personal information with the following categories of third
        parties:
        <ul>
          <li>Subsidiaries and affiliates.</li>
          <li>Contractors and service providers.</li>
          <li>Data aggregators.</li>
        </ul>
        Third parties with whom we partner to offer products and services to you.
        <br />
        <br />
        <h3 className="text-xl font-semibold">How We Protect Your User Information</h3>
        We maintain administrative, technical and physical safeguards designed to protect the User Information that we
        collect about you against accidental, unlawful or unauthorised destruction, loss, alteration, access, disclosure
        or use. While we cannot guarantee that no accidental, unlawful or unauthorised destruction, loss, alteration,
        access, disclosure or use of your information will occur, we will work hard to prevent any such occurrences.
        Sales of Personal Information In the preceding twelve (12) months, the company has sold the following categories
        of personal information collected through our ad-supported services:
        <ul>
          <li>Category A: Identifiers.</li>
          <li>Category F: Internet or other similar network activity.</li>
          <li>Category G: Geolocation Data.</li>
          <li>Category K: Inferences drawn from other personal information.</li>
        </ul>
        <br />
        The company and our advertising partners collect the personal information identified above (such as the cookies
        stored on your browser, the advertising identifier on your mobile device, or the IP address of your device) when
        you visit our websites, apps, and other products, services and platforms, or open our emails. We, and our
        partners, use this information to tailor and deliver ads to you on our websites, apps, emails, and other
        products, services and platforms, or to help tailor ads to you when you visit others’ sites (or use others’
        apps). To tailor ads that may be more relevant to you, we and/or our partners may share the information we
        collect with third parties. Transfers of User Information to Other Countries We use databases located in
        different jurisdictions. We may transfer User Information we collect about you to our databases outside your
        country of residence. Even if the laws in the country to which the information is transferred do not require us
        to provide adequate protection for your data, we will nevertheless seek to ensure that information transferred
        to such country is adequately protected. Your Privacy Rights and Choices You may stop the B&B-Service from using
        your Website Visit Information at any time by (i) Uninstalling the Browser Extension Software (ii) Uninstalling
        your Browser Software. Following your de-installation of the B&B-Service, certain of your information may still
        be preserved in a non-personally identifiable form as part of aggregate historical data. Subject to applicable
        law, we provide you with access to the User Information we have retained about you and the ability to review,
        correct, update, block or delete the information where it is inaccurate. Subject to applicable law, we may limit
        or deny access to your User Information where providing such access is unreasonably burdensome or expensive
        under the circumstances. Please submit your request as specified in the "Contact Us" section below.
        <br />
        <br />
        <h3 className="text-xl font-semibold">Updates to This Privacy Policy</h3>
        This Privacy Policy may be updated periodically and without prior notice to you to reflect changes in our
        information processing practices or for any other purpose. We will post a notice in the Info section of the
        AutocardAnywhere Service's profile to notify you of any significant changes to the Privacy Policy and indicate
        at the top of the Privacy Policy when it was most recently updated.
        <br />
        <br />
      </div>
    </Page>
  )
}

export default PrivacyPolicy
