import React from 'react'

import { classnames } from '../../../../../@styles/tailwindcss-classnames'

const searchBarStyle = classnames(
  'flex-shrink',
  'flex-grow',
  'min-w-0',
  'w-33vw',
  'appearance-none',
  'border',
  'rounded-full',
  'py-2',
  'px-3',
  'text-gray-800',
  'placeholder-gray-800',
  'hover:text-black',
  'focus:text-black',
  'focus:placeholder-black',
  'leading-tight',
  'shadow-lg',
  'focus:outline-none',
  'focus:shadow-outline',
  'font-medium',
  'mr-3',
  'truncate',
)

const SearchBar: React.FC = () => {
  return (
    <div>
      <input
        className={searchBarStyle}
        placeholder="Still in development! Soon you can search for decks, users, commanders, and many more!"
        disabled
      />
    </div>
  )
}

export default SearchBar
