import React from 'react'
import ReactDOM from 'react-dom'
import './assets/tailwind.output.css'
import { Provider } from 'react-redux'
import { createFirestoreInstance } from 'redux-firestore'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { firebase } from './redux-store/firebase'
import rootReducers from './redux-store'

const initialState = {}
const middleware = [thunk]
const store = createStore(rootReducers, initialState, composeWithDevTools(applyMiddleware(...middleware)))

const reactReduxFirebaseConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
}

const reactReduxFirebaseProps = {
  firebase,
  config: reactReduxFirebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
