import React, { useEffect, useState } from 'react'

import { classnames } from '../../../@styles/tailwindcss-classnames'
import { buttonAccent } from '../../../@styles/styles'
import ProgressBar from '../../../components/ProgressBar'

const inputStyle = classnames(
  'shadow',
  'appearance-none',
  'border',
  'rounded',
  'w-full',
  'py-2',
  'px-3',
  'text-gray-700',
  'leading-tight',
  'focus:outline-none',
  'focus:shadow-outline',
)
const inputWarning = (warn: boolean) =>
  classnames(inputStyle, {
    'border-red-500': warn,
    'mb-4': !warn,
  })
const labelStyle = classnames('block', 'text-gray-700', 'text-sm', 'font-bold', 'mb-2')

const passwordStrengthToString = (passwordStrength: number) => {
  if (passwordStrength < 1) {
    return 'Weak'
  } else if (passwordStrength < 2) {
    return 'Okay'
  } else if (passwordStrength < 3) {
    return 'Good'
  }

  return 'Strong'
}

const WEBSITE_DICTIONARY = ['build', 'brew', 'buildbrew', 'buildandbrew', 'magic', 'mtg']
const estimatePasswordStrength = (password: string, email: string, nickname: string) => {
  if (!window.zxcvbn) return 0
  const userDictionary = [email, nickname, ...email.split(/[@,.-]/)]

  return window.zxcvbn(password, [...WEBSITE_DICTIONARY, ...userDictionary]).score
}

interface IEmailSignIn {
  onSubmit: (email: string, password: string, nickname: string) => void
}

const SignUpForm: React.FC<IEmailSignIn> = (props: IEmailSignIn) => {
  const [email, setEmail] = useState('')
  const [nickname, setNickname] = useState('')
  const [nicknameTouched, setNicknameTouched] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordTouched, setPasswordTouched] = useState(false)

  useEffect(() => {
    if (!nicknameTouched && email) setNickname(email)
  }, [email])

  const passwordStrength = estimatePasswordStrength(password, email, nickname)

  const handleSubmit = (event: React.FormEvent | React.MouseEvent) => {
    event.preventDefault()
    props.onSubmit(email, password, nickname.trim())
  }

  return (
    <div className="w-full max-w-xs self-center">
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className={labelStyle} htmlFor="email">
            Email
          </label>
          <input
            className={inputStyle}
            id="email"
            placeholder="Your email address"
            type="text"
            value={email}
            onChange={(event) => setEmail(event.target.value.trim())}
          />
        </div>
        <div className="mb-4">
          <label className={labelStyle} htmlFor="nickname">
            Nickname
          </label>
          <input
            className={inputStyle}
            id="nickname"
            placeholder="Your nickname"
            type="text"
            value={nickname}
            onChange={(event) => {
              const value = event.target.value
              setNickname(value)
              setNicknameTouched(value !== '')
            }}
          />
        </div>
        <div className="mb-6">
          <label className={labelStyle} htmlFor="password">
            Password
          </label>
          <input
            className={inputWarning(passwordTouched && password === '')}
            id="password"
            placeholder="******************"
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value)
              setPasswordTouched(true)
            }}
            // TODO Show password icon
          />
          {passwordTouched &&
            (password === '' ? (
              <p className="text-warning text-xs italic">Please choose a password.</p>
            ) : (
              <>
                <ProgressBar max={4} progress={passwordStrength} showLabel={false} />
                <p className="text-accent text-xs italic">{passwordStrengthToString(passwordStrength)} password!</p>
              </>
            ))}
        </div>
        <div className="flex items-center justify-center">
          <button
            className={buttonAccent}
            disabled={email === '' || password === ''}
            type="submit"
            onClick={handleSubmit}
          >
            Join!
          </button>
        </div>
      </form>
    </div>
  )
}

export default SignUpForm
