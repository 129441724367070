import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd'
import { useFirestoreConnect, isLoaded, isEmpty, useFirestore } from 'react-redux-firebase'
import { useDispatch } from 'react-redux'
import Scry from 'scryfall-sdk'

import { Deck as DeckModel, Card as CardModel } from '../../redux-store/firebase'
import { RootState, TypedDispatch, useSelectorTyped } from '../../redux-store'
import { loadCards } from '../../redux-store/scryfall'
import Page from '../Page'
import { move } from '../../utils/collections'
import { useMouseOver } from '../../common/hooks/useMouseOver'
import { withMousePositionListener } from '../../common/recoil/mouse-position'

import DeckInfo from './components/DeckInfo'
import CardView from './components/CardView'
import { AVAILABLE_ORDER_OPTIONS } from './components/toolbar/SortOrderSelector'
import { DEFAULT_CATEGORY_NAME, LANDS_CATEGORY_COLUMN_NAME, OrderBy, sort, sortAll } from './lib/sort'
import ToolBar from './components/toolbar/ToolBar'
import { REMOVE_DROPPABLE_ID } from './components/toolbar/RemoveCardDroppable'
import { NEW_CATEGORY_COLUMN_NAME, NEW_CATEGORY_ID } from './components/columns/ColumnHeader'
import CategoryColumn from './components/columns/CategoryColumn'

export interface CardInfoMap {
  [oracleId: string]: CardModel
}

export interface IDeck {
  id: string
  //TODO additional parameter, e.g. ?sort=categories|cmc|type
}

const deckInfoSelector = (state: RootState, params: IDeck): DeckModel =>
  state.firestore.data.decks ? state.firestore.data.decks[params.id] : null
const cardsSelector = (state: RootState, reduxPath: string): { [id: string]: CardModel } =>
  state.firestore.data ? state.firestore.data[reduxPath] : {}

const Deck: React.FC<RouteComponentProps<IDeck>> = (props: RouteComponentProps<IDeck>) => {
  const { params } = props.match
  const reduxPath = `deck_${params.id}_cards`
  const firestore = useFirestore()
  // TODO use react-redux-firebase populate methods to correctly store the cards in a deck and load the user in place of the owner ID
  useFirestoreConnect([
    { collection: 'decks', doc: params.id },
    {
      collection: 'decks',
      doc: params.id,
      subcollections: [
        {
          collection: 'cards',
        },
      ],
      storeAs: reduxPath,
    },
  ])
  const info = useSelectorTyped((state) => deckInfoSelector(state, params))
  const cardObject: CardInfoMap = useSelectorTyped((state) => cardsSelector(state, reduxPath))
  const [cards, setCards] = useState<(CardModel & { key: string })[]>([])
  useEffect(
    () =>
      cardObject && setCards(Object.keys(cardObject).map((id: string) => Object.assign({ key: id }, cardObject[id]))),
    [cardObject],
  )

  const uid = useSelectorTyped((state) => state.firebase.auth?.uid)
  const isOwner: boolean = !!uid && !!info && uid === info.owner

  const dispatch = useDispatch<TypedDispatch>()
  useEffect(() => {
    if (cardObject) {
      const ids = Object.values(cardObject)
        .filter(Boolean)
        .map((card) => card.id)
      dispatch(loadCards(ids))
        .then(() => console.log('Successful scryfall api request'))
        .catch(() => console.error('error on scryfall api request'))
    }
  }, [dispatch, cardObject])
  const { cards: cardInfo /*, loading: cardInfoLoading, error: cardInfoError */ } = useSelectorTyped(
    (state) => state.scryfall,
  )

  const [sortOrder, setSortOrder] = useState<OrderBy>(AVAILABLE_ORDER_OPTIONS[0].orderBy) // TODO store in firestore.deck
  const [columns, setColumns] = useState<[string, ...Scry.Card[]][]>([])

  const updateColumns = useCallback(() => {
    const newColumns =
      cardInfo && cards ? sortAll(cards.map((card) => cardInfo[card.id]).filter(Boolean), sortOrder, cardObject) : []

    if (isOwner && newColumns.length > 0 && sortOrder.x === 'categories') {
      // TODO once a deck aggregates categories and we provide blueprints and
      // info.categories.forEach(category => { if(!columns.find(column => column[0] === category)) columns.push(category))
      newColumns.push([''])
    }
    setColumns(newColumns)
  }, [cardInfo, sortOrder, cards, isOwner])
  useEffect(updateColumns, [updateColumns])

  const [deleteDroppableRef, hoverDeleteDroppable] = useMouseOver<HTMLDivElement>()

  const [newCategoryNames, setNewCategoryNames] = useState<{ [id: string]: string }>({})
  useEffect(() => {
    const newNames: { [id: string]: string } = {}
    columns.filter(Boolean).forEach(([name]) => (newNames[name] = newCategoryNames[name] ?? name))
    setNewCategoryNames(newNames)
  }, [columns])

  const findUniqueCategoryName = (preferredName: string): string => {
    let newName = preferredName
    let counter = 1
    const matcher = (nn: string) => columns.find((c) => c[0] === nn)

    while (matcher(newName)) {
      newName = preferredName + counter
      counter++
    }

    return newName
  }

  const columnElements = columns.filter(Boolean).map(([column, ...columnCards]) => {
    if (column === 'CMC 0' && columnCards.length === 0) return null

    const isDragDisabled = !isOwner
    const isDropDisabled = isDragDisabled || sortOrder.x !== 'categories'
    const disableCategoryRenaming =
      isDropDisabled || column === DEFAULT_CATEGORY_NAME || column === LANDS_CATEGORY_COLUMN_NAME

    const rename = (inputValue: string) => {
      const newName = findUniqueCategoryName(inputValue)
      console.log('Rename category', column, 'to', newName)

      const newColumns = [...columns]
      const oldColumnIndex = columns.findIndex(([name, ..._]) => name === column)

      if (oldColumnIndex >= 0) {
        const [, ...oldColumnCards] = columns[oldColumnIndex]
        newColumns.splice(oldColumnIndex, 1, [newName, ...oldColumnCards])
        setColumns(newColumns)

        const batch = firestore.batch()
        const newCards = [...cards]
        const deckCardsRef = firestore.collection('decks').doc(params.id).collection('cards')
        oldColumnCards.forEach((c) => {
          const cardUpdate = { ...cardObject[c.oracle_id] }

          if (!cardUpdate.categories) {
            cardUpdate.categories = []
          } else {
            cardUpdate.categories = [...cardUpdate.categories]
            cardUpdate.categories.splice(
              cardUpdate.categories.findIndex((categoryName) => categoryName === column),
              1,
            )
          }
          cardUpdate.categories.push(newName)
          batch.update(deckCardsRef.doc(c.oracle_id), cardUpdate)
          newCards.splice(
            newCards.findIndex((nc) => nc.key === c.oracle_id),
            1,
            Object.assign({ key: c.oracle_id }, cardUpdate),
          )
        })
        batch.commit().catch((e) => {
          console.error('Renaming Category failed!', e)
          setNewCategoryNames({ ...newCategoryNames, [column]: column })
        })
        setCards(newCards)
      }
    }

    // TODO this is a workaround. If this is the way we need the data, provide this while sorting!
    const cardsInColumn = columnCards.map((cardInfo) =>
      Object.assign({ oracleId: cardInfo.oracle_id }, cardObject[cardInfo.oracle_id]),
    )
    const columnCardsInfo = columnCards.reduce((map, card) => {
      map[card.id] = card

      return map
    }, {} as { [oracleId: string]: Scry.Card })

    return (
      <CategoryColumn
        key={column}
        allowRenaming={!disableCategoryRenaming}
        cards={cardsInColumn}
        cardsInfo={columnCardsInfo}
        isDropDisabled={isDropDisabled || hoverDeleteDroppable}
        name={column}
        rename={rename}
      >
        {columnCards.map((card, index) => (
          <CardView
            key={card.id}
            card={card}
            cardInfo={cardObject[card.oracle_id]}
            containerProps={{
              index,
              uniqueId: column + card.oracle_id,
              isDragDisabled,
              noOverlap: index === columnCards.length - 1,
            }}
            deckInfo={info}
          />
        ))}
      </CategoryColumn>
    )
  })
  // const categoryOrder = Object.keys(categories) // TODO this should be saved in firestore and only be loaded here -> this would support empty columns as well!

  const [dragging, setDragging] = useState(false)

  //TODO this should be synchronous but isn't because of the firestore.update call -> flickering on update...
  const onDragEnd: OnDragEndResponder = (result) => {
    setDragging(false)

    if (!columns || columns.length === 0 || !cards) return
    const { destination, source, draggableId } = result

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) return

    const sourceColumnName = source.droppableId
    const destinationColumnName = destination.droppableId === NEW_CATEGORY_ID ? '' : destination.droppableId

    if (destinationColumnName === REMOVE_DROPPABLE_ID) {
      const cardOracleId = draggableId.replace(sourceColumnName, '')

      const sourceColumnIndex = columns.findIndex((c) => c && c.length > 0 && c[0] === sourceColumnName)

      if (sourceColumnIndex >= 0) {
        const [, ...sourceColumn] = columns[sourceColumnIndex]
        sourceColumn.splice(source.index, 1)

        const newColumns = [...columns]
        newColumns[sourceColumnIndex] = [sourceColumnName, ...sourceColumn]

        setColumns(newColumns)
      }

      const newCards = [...cards]
      newCards.splice(
        newCards.findIndex((nc) => nc.key === cardOracleId),
        1,
      )
      setCards(newCards)

      return firestore
        .delete({
          collection: 'decks',
          doc: params.id,
          subcollections: [
            {
              collection: 'cards',
              doc: cardOracleId,
            },
          ],
        })
        .then(() => console.log('Successfully removed the card from the deck!'))
        .catch((e) => console.error(e))
    }

    if (sourceColumnName !== destinationColumnName) {
      const sourceColumnIndex = columns.findIndex((c) => c.length > 0 && c[0] === sourceColumnName)

      if (sourceColumnIndex < 0) return
      const destinationColumnIndex = columns.findIndex((c) => c.length > 0 && c[0] === destinationColumnName)

      if (destinationColumnIndex < 0) return

      const [, ...sourceColumn] = columns[sourceColumnIndex]
      const [, ...destinationColumn] = columns[destinationColumnIndex]

      const { source: newSourceColumn, destination: newDestinationColumn } = move(
        sourceColumn,
        destinationColumn,
        source.index,
        destination.index,
      )

      const newColumns = [...columns]
      newColumns[sourceColumnIndex] = [sourceColumnName, ...newSourceColumn]
      console.log(columns[sourceColumnIndex], newColumns[sourceColumnIndex])

      const newName = newCategoryNames[''].trim()
      const newDestinationColumnName =
        destinationColumnName === ''
          ? findUniqueCategoryName(newName === '' ? NEW_CATEGORY_COLUMN_NAME : newName)
          : destinationColumnName
      newColumns[destinationColumnIndex] = [newDestinationColumnName, ...sort(newDestinationColumn, sortOrder.y)]
      console.log(columns[destinationColumnIndex], newColumns[destinationColumnIndex])

      if (destinationColumnName === '') {
        setNewCategoryNames({ ...newCategoryNames, '': '' })
        newColumns.push([''])
      }
      setColumns(newColumns)

      const cardOracleId = draggableId.replace(sourceColumnName, '')
      const cardUpdate = { ...cardObject[cardOracleId] }

      if (!cardUpdate.categories || newDestinationColumnName === DEFAULT_CATEGORY_NAME) {
        cardUpdate.categories = []
      } else {
        cardUpdate.categories = [...cardUpdate.categories]
        cardUpdate.categories.splice(
          cardUpdate.categories.findIndex((cc) => cc === sourceColumnName),
          1,
        )
      }

      if (newDestinationColumnName !== DEFAULT_CATEGORY_NAME) cardUpdate.categories.push(newDestinationColumnName)
      console.log('cardUpdate', cardUpdate)

      const newCards = [...cards]
      newCards.splice(
        newCards.findIndex((nc) => nc.key === cardOracleId),
        1,
        Object.assign({ key: cardOracleId }, cardUpdate),
      )
      console.log(cards, newCards)
      setCards(newCards)

      firestore
        .update(
          {
            collection: 'decks',
            doc: params.id,
            subcollections: [
              {
                collection: 'cards',
                doc: cardOracleId,
              },
            ],
          },
          cardUpdate,
        )
        .catch((e) => console.error(e))
    }
  }

  const drawAttentionToRemoveArea = () => {
    setDragging(true)
    setTimeout(() => setDragging(false), 400)
  }

  return (
    <Page>
      <DeckInfo cards={cards} cardsInfo={cardInfo} deck={info} isLoaded={info && isLoaded(info)} />
      {/* {cardsError && <strong>Error: {JSON.stringify(cardsError)}</strong>} */}
      {!isLoaded(cards) ? (
        <span>Loading deck list...</span>
      ) : isEmpty(cards) ? (
        <div>
          <span>This deck is empty!</span>
          <br />
          <span>
            Start dragging cards into this deck via the
            <a
              href="https://addons.mozilla.org/en-US/firefox/addon/build-and-brew-online/"
              rel="noreferrer"
              target="_blank"
              title="Get the firefox browser add-on! Directly drag and drop all Magic cards on supported websites into your decks!"
            >
              Firefox browser add-on
            </a>
            !
          </span>
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={drawAttentionToRemoveArea}>
          <ToolBar
            canUserManipulateDeck={isOwner}
            cardSortOrder={{ setSortOrder, sortOrder }}
            removeCardProps={{ deleteDroppableRef, highlight: dragging }}
          />
          <div className="flex flex-row justify-start overflow-x-auto">{columnElements}</div>
        </DragDropContext>
      )}
    </Page>
  )
}

export default withMousePositionListener(Deck)
