import React from 'react'

import { classnames } from '../../../@styles/tailwindcss-classnames'
import { User } from '../../../redux-store/firebase'

interface IUserInfo {
  isLoaded: boolean
  user: User
}

const nameStyle = classnames('text-4xl', 'font-bold', 'tracking-wide', 'text-accent')
const memberSinceStyle = classnames('text-xl', 'font-semibold', 'text-light')

const UserInfo: React.FC<IUserInfo> = ({ isLoaded, user }: IUserInfo) => {
  return (
    <div className="w-full bg-blue-700 p-2">
      {!isLoaded ? (
        <span className={nameStyle}>Loading User Info...</span> // TODO show and animate UI skeleton
      ) : (
        <div className="flex flex-row justify-start items-center">
          <img
            alt={`${user.displayName}'s Avatar`}
            className="w-20 h-20 rounded-full m-2 flex-shrink-0"
            referrerPolicy="no-referrer"
            src={user.avatarUrl}
          />
          <div className="flex flex-col justify-around items-start">
            <span className={classnames(nameStyle, 'flex-auto')}>{user.displayName}</span>
            <span className={classnames(memberSinceStyle, 'flex-auto')}>Alpha Tester</span>
            {/* TODO props.user.createdAt => Member Since span */}
          </div>
        </div>
      )}
    </div>
  )
}

export default UserInfo
