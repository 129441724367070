/* eslint-disable */
/* tslint:disable */
import classnamesLib from 'classnames';

export type TScreenReaders = 
  | 'sr-only'
  | 'not-sr-only';

export type TAccessibility =
  | TScreenReaders

export type TBackgroundAttachment = 
  | 'bg-fixed'
  | 'bg-local'
  | 'bg-scroll';

export type TBackgroundClip = 
  | 'bg-clip-border'
  | 'bg-clip-padding'
  | 'bg-clip-content'
  | 'bg-clip-text';

export type TBackgroundColor = 
  | 'bg-transparent'
  | 'bg-current'
  | 'bg-black'
  | 'bg-white'
  | 'bg-gray-100'
  | 'bg-gray-200'
  | 'bg-gray-300'
  | 'bg-gray-400'
  | 'bg-gray-500'
  | 'bg-gray-600'
  | 'bg-gray-700'
  | 'bg-gray-800'
  | 'bg-gray-900'
  | 'bg-red-100'
  | 'bg-red-200'
  | 'bg-red-300'
  | 'bg-red-400'
  | 'bg-red-500'
  | 'bg-red-600'
  | 'bg-red-700'
  | 'bg-red-800'
  | 'bg-red-900'
  | 'bg-orange-100'
  | 'bg-orange-200'
  | 'bg-orange-300'
  | 'bg-orange-400'
  | 'bg-orange-500'
  | 'bg-orange-600'
  | 'bg-orange-700'
  | 'bg-orange-800'
  | 'bg-orange-900'
  | 'bg-yellow-100'
  | 'bg-yellow-200'
  | 'bg-yellow-300'
  | 'bg-yellow-400'
  | 'bg-yellow-500'
  | 'bg-yellow-600'
  | 'bg-yellow-700'
  | 'bg-yellow-800'
  | 'bg-yellow-900'
  | 'bg-green-100'
  | 'bg-green-200'
  | 'bg-green-300'
  | 'bg-green-400'
  | 'bg-green-500'
  | 'bg-green-600'
  | 'bg-green-700'
  | 'bg-green-800'
  | 'bg-green-900'
  | 'bg-teal-100'
  | 'bg-teal-200'
  | 'bg-teal-300'
  | 'bg-teal-400'
  | 'bg-teal-500'
  | 'bg-teal-600'
  | 'bg-teal-700'
  | 'bg-teal-800'
  | 'bg-teal-900'
  | 'bg-blue-100'
  | 'bg-blue-200'
  | 'bg-blue-300'
  | 'bg-blue-400'
  | 'bg-blue-500'
  | 'bg-blue-600'
  | 'bg-blue-700'
  | 'bg-blue-800'
  | 'bg-blue-900'
  | 'bg-indigo-100'
  | 'bg-indigo-200'
  | 'bg-indigo-300'
  | 'bg-indigo-400'
  | 'bg-indigo-500'
  | 'bg-indigo-600'
  | 'bg-indigo-700'
  | 'bg-indigo-800'
  | 'bg-indigo-900'
  | 'bg-purple-100'
  | 'bg-purple-200'
  | 'bg-purple-300'
  | 'bg-purple-400'
  | 'bg-purple-500'
  | 'bg-purple-600'
  | 'bg-purple-700'
  | 'bg-purple-800'
  | 'bg-purple-900'
  | 'bg-pink-100'
  | 'bg-pink-200'
  | 'bg-pink-300'
  | 'bg-pink-400'
  | 'bg-pink-500'
  | 'bg-pink-600'
  | 'bg-pink-700'
  | 'bg-pink-800'
  | 'bg-pink-900'
  | 'bg-primary-light'
  | 'bg-primary'
  | 'bg-primary-dark'
  | 'bg-accent-dark'
  | 'bg-accent'
  | 'bg-accent-light'
  | 'bg-warning-dark'
  | 'bg-warning'
  | 'bg-warning-light'
  | 'bg-dark'
  | 'bg-dark-secondary'
  | 'bg-light'
  | 'bg-light-secondary'
  | 'bg-light-hint';

export type TBackgroundOpacity = 
  | 'bg-opacity-0'
  | 'bg-opacity-25'
  | 'bg-opacity-50'
  | 'bg-opacity-75'
  | 'bg-opacity-100';

export type TBackgroundPosition = 
  | 'bg-bottom'
  | 'bg-center'
  | 'bg-left'
  | 'bg-left-bottom'
  | 'bg-left-top'
  | 'bg-right'
  | 'bg-right-bottom'
  | 'bg-right-top'
  | 'bg-top';

export type TBackgroundRepeat = 
  | 'bg-repeat'
  | 'bg-no-repeat'
  | 'bg-repeat-x'
  | 'bg-repeat-y'
  | 'bg-repeat-round'
  | 'bg-repeat-space';

export type TBackgroundSize = 
  | 'bg-auto'
  | 'bg-cover'
  | 'bg-contain';

export type TBackgroundImage = 
  | 'bg-none'
  | 'bg-gradient-to-t'
  | 'bg-gradient-to-tr'
  | 'bg-gradient-to-r'
  | 'bg-gradient-to-br'
  | 'bg-gradient-to-b'
  | 'bg-gradient-to-bl'
  | 'bg-gradient-to-l'
  | 'bg-gradient-to-tl';

export type TGradientColorStops = 
  | 'from-transparent'
  | 'via-transparent'
  | 'to-transparent'
  | 'from-current'
  | 'via-current'
  | 'to-current'
  | 'from-black'
  | 'via-black'
  | 'to-black'
  | 'from-white'
  | 'via-white'
  | 'to-white'
  | 'from-gray-100'
  | 'via-gray-100'
  | 'to-gray-100'
  | 'from-gray-200'
  | 'via-gray-200'
  | 'to-gray-200'
  | 'from-gray-300'
  | 'via-gray-300'
  | 'to-gray-300'
  | 'from-gray-400'
  | 'via-gray-400'
  | 'to-gray-400'
  | 'from-gray-500'
  | 'via-gray-500'
  | 'to-gray-500'
  | 'from-gray-600'
  | 'via-gray-600'
  | 'to-gray-600'
  | 'from-gray-700'
  | 'via-gray-700'
  | 'to-gray-700'
  | 'from-gray-800'
  | 'via-gray-800'
  | 'to-gray-800'
  | 'from-gray-900'
  | 'via-gray-900'
  | 'to-gray-900'
  | 'from-red-100'
  | 'via-red-100'
  | 'to-red-100'
  | 'from-red-200'
  | 'via-red-200'
  | 'to-red-200'
  | 'from-red-300'
  | 'via-red-300'
  | 'to-red-300'
  | 'from-red-400'
  | 'via-red-400'
  | 'to-red-400'
  | 'from-red-500'
  | 'via-red-500'
  | 'to-red-500'
  | 'from-red-600'
  | 'via-red-600'
  | 'to-red-600'
  | 'from-red-700'
  | 'via-red-700'
  | 'to-red-700'
  | 'from-red-800'
  | 'via-red-800'
  | 'to-red-800'
  | 'from-red-900'
  | 'via-red-900'
  | 'to-red-900'
  | 'from-orange-100'
  | 'via-orange-100'
  | 'to-orange-100'
  | 'from-orange-200'
  | 'via-orange-200'
  | 'to-orange-200'
  | 'from-orange-300'
  | 'via-orange-300'
  | 'to-orange-300'
  | 'from-orange-400'
  | 'via-orange-400'
  | 'to-orange-400'
  | 'from-orange-500'
  | 'via-orange-500'
  | 'to-orange-500'
  | 'from-orange-600'
  | 'via-orange-600'
  | 'to-orange-600'
  | 'from-orange-700'
  | 'via-orange-700'
  | 'to-orange-700'
  | 'from-orange-800'
  | 'via-orange-800'
  | 'to-orange-800'
  | 'from-orange-900'
  | 'via-orange-900'
  | 'to-orange-900'
  | 'from-yellow-100'
  | 'via-yellow-100'
  | 'to-yellow-100'
  | 'from-yellow-200'
  | 'via-yellow-200'
  | 'to-yellow-200'
  | 'from-yellow-300'
  | 'via-yellow-300'
  | 'to-yellow-300'
  | 'from-yellow-400'
  | 'via-yellow-400'
  | 'to-yellow-400'
  | 'from-yellow-500'
  | 'via-yellow-500'
  | 'to-yellow-500'
  | 'from-yellow-600'
  | 'via-yellow-600'
  | 'to-yellow-600'
  | 'from-yellow-700'
  | 'via-yellow-700'
  | 'to-yellow-700'
  | 'from-yellow-800'
  | 'via-yellow-800'
  | 'to-yellow-800'
  | 'from-yellow-900'
  | 'via-yellow-900'
  | 'to-yellow-900'
  | 'from-green-100'
  | 'via-green-100'
  | 'to-green-100'
  | 'from-green-200'
  | 'via-green-200'
  | 'to-green-200'
  | 'from-green-300'
  | 'via-green-300'
  | 'to-green-300'
  | 'from-green-400'
  | 'via-green-400'
  | 'to-green-400'
  | 'from-green-500'
  | 'via-green-500'
  | 'to-green-500'
  | 'from-green-600'
  | 'via-green-600'
  | 'to-green-600'
  | 'from-green-700'
  | 'via-green-700'
  | 'to-green-700'
  | 'from-green-800'
  | 'via-green-800'
  | 'to-green-800'
  | 'from-green-900'
  | 'via-green-900'
  | 'to-green-900'
  | 'from-teal-100'
  | 'via-teal-100'
  | 'to-teal-100'
  | 'from-teal-200'
  | 'via-teal-200'
  | 'to-teal-200'
  | 'from-teal-300'
  | 'via-teal-300'
  | 'to-teal-300'
  | 'from-teal-400'
  | 'via-teal-400'
  | 'to-teal-400'
  | 'from-teal-500'
  | 'via-teal-500'
  | 'to-teal-500'
  | 'from-teal-600'
  | 'via-teal-600'
  | 'to-teal-600'
  | 'from-teal-700'
  | 'via-teal-700'
  | 'to-teal-700'
  | 'from-teal-800'
  | 'via-teal-800'
  | 'to-teal-800'
  | 'from-teal-900'
  | 'via-teal-900'
  | 'to-teal-900'
  | 'from-blue-100'
  | 'via-blue-100'
  | 'to-blue-100'
  | 'from-blue-200'
  | 'via-blue-200'
  | 'to-blue-200'
  | 'from-blue-300'
  | 'via-blue-300'
  | 'to-blue-300'
  | 'from-blue-400'
  | 'via-blue-400'
  | 'to-blue-400'
  | 'from-blue-500'
  | 'via-blue-500'
  | 'to-blue-500'
  | 'from-blue-600'
  | 'via-blue-600'
  | 'to-blue-600'
  | 'from-blue-700'
  | 'via-blue-700'
  | 'to-blue-700'
  | 'from-blue-800'
  | 'via-blue-800'
  | 'to-blue-800'
  | 'from-blue-900'
  | 'via-blue-900'
  | 'to-blue-900'
  | 'from-indigo-100'
  | 'via-indigo-100'
  | 'to-indigo-100'
  | 'from-indigo-200'
  | 'via-indigo-200'
  | 'to-indigo-200'
  | 'from-indigo-300'
  | 'via-indigo-300'
  | 'to-indigo-300'
  | 'from-indigo-400'
  | 'via-indigo-400'
  | 'to-indigo-400'
  | 'from-indigo-500'
  | 'via-indigo-500'
  | 'to-indigo-500'
  | 'from-indigo-600'
  | 'via-indigo-600'
  | 'to-indigo-600'
  | 'from-indigo-700'
  | 'via-indigo-700'
  | 'to-indigo-700'
  | 'from-indigo-800'
  | 'via-indigo-800'
  | 'to-indigo-800'
  | 'from-indigo-900'
  | 'via-indigo-900'
  | 'to-indigo-900'
  | 'from-purple-100'
  | 'via-purple-100'
  | 'to-purple-100'
  | 'from-purple-200'
  | 'via-purple-200'
  | 'to-purple-200'
  | 'from-purple-300'
  | 'via-purple-300'
  | 'to-purple-300'
  | 'from-purple-400'
  | 'via-purple-400'
  | 'to-purple-400'
  | 'from-purple-500'
  | 'via-purple-500'
  | 'to-purple-500'
  | 'from-purple-600'
  | 'via-purple-600'
  | 'to-purple-600'
  | 'from-purple-700'
  | 'via-purple-700'
  | 'to-purple-700'
  | 'from-purple-800'
  | 'via-purple-800'
  | 'to-purple-800'
  | 'from-purple-900'
  | 'via-purple-900'
  | 'to-purple-900'
  | 'from-pink-100'
  | 'via-pink-100'
  | 'to-pink-100'
  | 'from-pink-200'
  | 'via-pink-200'
  | 'to-pink-200'
  | 'from-pink-300'
  | 'via-pink-300'
  | 'to-pink-300'
  | 'from-pink-400'
  | 'via-pink-400'
  | 'to-pink-400'
  | 'from-pink-500'
  | 'via-pink-500'
  | 'to-pink-500'
  | 'from-pink-600'
  | 'via-pink-600'
  | 'to-pink-600'
  | 'from-pink-700'
  | 'via-pink-700'
  | 'to-pink-700'
  | 'from-pink-800'
  | 'via-pink-800'
  | 'to-pink-800'
  | 'from-pink-900'
  | 'via-pink-900'
  | 'to-pink-900'
  | 'from-primary-light'
  | 'via-primary-light'
  | 'to-primary-light'
  | 'from-primary'
  | 'via-primary'
  | 'to-primary'
  | 'from-primary-dark'
  | 'via-primary-dark'
  | 'to-primary-dark'
  | 'from-accent-dark'
  | 'via-accent-dark'
  | 'to-accent-dark'
  | 'from-accent'
  | 'via-accent'
  | 'to-accent'
  | 'from-accent-light'
  | 'via-accent-light'
  | 'to-accent-light'
  | 'from-warning-dark'
  | 'via-warning-dark'
  | 'to-warning-dark'
  | 'from-warning'
  | 'via-warning'
  | 'to-warning'
  | 'from-warning-light'
  | 'via-warning-light'
  | 'to-warning-light'
  | 'from-dark'
  | 'via-dark'
  | 'to-dark'
  | 'from-dark-secondary'
  | 'via-dark-secondary'
  | 'to-dark-secondary'
  | 'from-light'
  | 'via-light'
  | 'to-light'
  | 'from-light-secondary'
  | 'via-light-secondary'
  | 'to-light-secondary'
  | 'from-light-hint'
  | 'via-light-hint'
  | 'to-light-hint';

export type TBackgrounds =
  | TBackgroundAttachment
  | TBackgroundClip
  | TBackgroundColor
  | TBackgroundOpacity
  | TBackgroundPosition
  | TBackgroundRepeat
  | TBackgroundSize
  | TBackgroundImage
  | TGradientColorStops

export type TBorderColor = 
  | 'border-transparent'
  | 'border-current'
  | 'border-black'
  | 'border-white'
  | 'border-gray-100'
  | 'border-gray-200'
  | 'border-gray-300'
  | 'border-gray-400'
  | 'border-gray-500'
  | 'border-gray-600'
  | 'border-gray-700'
  | 'border-gray-800'
  | 'border-gray-900'
  | 'border-red-100'
  | 'border-red-200'
  | 'border-red-300'
  | 'border-red-400'
  | 'border-red-500'
  | 'border-red-600'
  | 'border-red-700'
  | 'border-red-800'
  | 'border-red-900'
  | 'border-orange-100'
  | 'border-orange-200'
  | 'border-orange-300'
  | 'border-orange-400'
  | 'border-orange-500'
  | 'border-orange-600'
  | 'border-orange-700'
  | 'border-orange-800'
  | 'border-orange-900'
  | 'border-yellow-100'
  | 'border-yellow-200'
  | 'border-yellow-300'
  | 'border-yellow-400'
  | 'border-yellow-500'
  | 'border-yellow-600'
  | 'border-yellow-700'
  | 'border-yellow-800'
  | 'border-yellow-900'
  | 'border-green-100'
  | 'border-green-200'
  | 'border-green-300'
  | 'border-green-400'
  | 'border-green-500'
  | 'border-green-600'
  | 'border-green-700'
  | 'border-green-800'
  | 'border-green-900'
  | 'border-teal-100'
  | 'border-teal-200'
  | 'border-teal-300'
  | 'border-teal-400'
  | 'border-teal-500'
  | 'border-teal-600'
  | 'border-teal-700'
  | 'border-teal-800'
  | 'border-teal-900'
  | 'border-blue-100'
  | 'border-blue-200'
  | 'border-blue-300'
  | 'border-blue-400'
  | 'border-blue-500'
  | 'border-blue-600'
  | 'border-blue-700'
  | 'border-blue-800'
  | 'border-blue-900'
  | 'border-indigo-100'
  | 'border-indigo-200'
  | 'border-indigo-300'
  | 'border-indigo-400'
  | 'border-indigo-500'
  | 'border-indigo-600'
  | 'border-indigo-700'
  | 'border-indigo-800'
  | 'border-indigo-900'
  | 'border-purple-100'
  | 'border-purple-200'
  | 'border-purple-300'
  | 'border-purple-400'
  | 'border-purple-500'
  | 'border-purple-600'
  | 'border-purple-700'
  | 'border-purple-800'
  | 'border-purple-900'
  | 'border-pink-100'
  | 'border-pink-200'
  | 'border-pink-300'
  | 'border-pink-400'
  | 'border-pink-500'
  | 'border-pink-600'
  | 'border-pink-700'
  | 'border-pink-800'
  | 'border-pink-900';

export type TBorderOpacity = 
  | 'border-opacity-0'
  | 'border-opacity-25'
  | 'border-opacity-50'
  | 'border-opacity-75'
  | 'border-opacity-100';

export type TBorderStyle = 
  | 'border-solid'
  | 'border-dashed'
  | 'border-dotted'
  | 'border-double'
  | 'border-none';

export type TBorderWidth = 
  | 'border-t-0'
  | 'border-r-0'
  | 'border-b-0'
  | 'border-l-0'
  | 'border-0'
  | 'border-t-2'
  | 'border-r-2'
  | 'border-b-2'
  | 'border-l-2'
  | 'border-2'
  | 'border-t-4'
  | 'border-r-4'
  | 'border-b-4'
  | 'border-l-4'
  | 'border-4'
  | 'border-t-8'
  | 'border-r-8'
  | 'border-b-8'
  | 'border-l-8'
  | 'border-8'
  | 'border-t'
  | 'border-r'
  | 'border-b'
  | 'border-l'
  | 'border'
  | 'border-t-cardborder'
  | 'border-r-cardborder'
  | 'border-b-cardborder'
  | 'border-l-cardborder'
  | 'border-cardborder';

export type TBorderRadius = 
  | 'rounded-t-none'
  | 'rounded-r-none'
  | 'rounded-b-none'
  | 'rounded-l-none'
  | 'rounded-tr-none'
  | 'rounded-tl-none'
  | 'rounded-br-none'
  | 'rounded-bl-none'
  | 'rounded-none'
  | 'rounded-t-sm'
  | 'rounded-r-sm'
  | 'rounded-b-sm'
  | 'rounded-l-sm'
  | 'rounded-tr-sm'
  | 'rounded-tl-sm'
  | 'rounded-br-sm'
  | 'rounded-bl-sm'
  | 'rounded-sm'
  | 'rounded-t'
  | 'rounded-r'
  | 'rounded-b'
  | 'rounded-l'
  | 'rounded-tr'
  | 'rounded-tl'
  | 'rounded-br'
  | 'rounded-bl'
  | 'rounded'
  | 'rounded-t-md'
  | 'rounded-r-md'
  | 'rounded-b-md'
  | 'rounded-l-md'
  | 'rounded-tr-md'
  | 'rounded-tl-md'
  | 'rounded-br-md'
  | 'rounded-bl-md'
  | 'rounded-md'
  | 'rounded-t-lg'
  | 'rounded-r-lg'
  | 'rounded-b-lg'
  | 'rounded-l-lg'
  | 'rounded-tr-lg'
  | 'rounded-tl-lg'
  | 'rounded-br-lg'
  | 'rounded-bl-lg'
  | 'rounded-lg'
  | 'rounded-t-xl'
  | 'rounded-r-xl'
  | 'rounded-b-xl'
  | 'rounded-l-xl'
  | 'rounded-tr-xl'
  | 'rounded-tl-xl'
  | 'rounded-br-xl'
  | 'rounded-bl-xl'
  | 'rounded-xl'
  | 'rounded-t-2xl'
  | 'rounded-r-2xl'
  | 'rounded-b-2xl'
  | 'rounded-l-2xl'
  | 'rounded-tr-2xl'
  | 'rounded-tl-2xl'
  | 'rounded-br-2xl'
  | 'rounded-bl-2xl'
  | 'rounded-2xl'
  | 'rounded-t-3xl'
  | 'rounded-r-3xl'
  | 'rounded-b-3xl'
  | 'rounded-l-3xl'
  | 'rounded-tr-3xl'
  | 'rounded-tl-3xl'
  | 'rounded-br-3xl'
  | 'rounded-bl-3xl'
  | 'rounded-3xl'
  | 'rounded-t-full'
  | 'rounded-r-full'
  | 'rounded-b-full'
  | 'rounded-l-full'
  | 'rounded-tr-full'
  | 'rounded-tl-full'
  | 'rounded-br-full'
  | 'rounded-bl-full'
  | 'rounded-full'
  | 'rounded-t-cardborder'
  | 'rounded-r-cardborder'
  | 'rounded-b-cardborder'
  | 'rounded-l-cardborder'
  | 'rounded-tr-cardborder'
  | 'rounded-tl-cardborder'
  | 'rounded-br-cardborder'
  | 'rounded-bl-cardborder'
  | 'rounded-cardborder';

export type TDivideWidth = 
  | 'divide-x-0'
  | 'divide-y-0'
  | 'divide-x-2'
  | 'divide-y-2'
  | 'divide-x-4'
  | 'divide-y-4'
  | 'divide-x-8'
  | 'divide-y-8'
  | 'divide-x'
  | 'divide-y'
  | 'divide-x-cardborder'
  | 'divide-y-cardborder'
  | 'divide-x-reverse'
  | 'divide-y-reverse';

export type TDivideOpacity = 
  | 'divide-opacity-0'
  | 'divide-opacity-25'
  | 'divide-opacity-50'
  | 'divide-opacity-75'
  | 'divide-opacity-100';

export type TDivideColor = 
  | 'divide-transparent'
  | 'divide-current'
  | 'divide-black'
  | 'divide-white'
  | 'divide-gray-100'
  | 'divide-gray-200'
  | 'divide-gray-300'
  | 'divide-gray-400'
  | 'divide-gray-500'
  | 'divide-gray-600'
  | 'divide-gray-700'
  | 'divide-gray-800'
  | 'divide-gray-900'
  | 'divide-red-100'
  | 'divide-red-200'
  | 'divide-red-300'
  | 'divide-red-400'
  | 'divide-red-500'
  | 'divide-red-600'
  | 'divide-red-700'
  | 'divide-red-800'
  | 'divide-red-900'
  | 'divide-orange-100'
  | 'divide-orange-200'
  | 'divide-orange-300'
  | 'divide-orange-400'
  | 'divide-orange-500'
  | 'divide-orange-600'
  | 'divide-orange-700'
  | 'divide-orange-800'
  | 'divide-orange-900'
  | 'divide-yellow-100'
  | 'divide-yellow-200'
  | 'divide-yellow-300'
  | 'divide-yellow-400'
  | 'divide-yellow-500'
  | 'divide-yellow-600'
  | 'divide-yellow-700'
  | 'divide-yellow-800'
  | 'divide-yellow-900'
  | 'divide-green-100'
  | 'divide-green-200'
  | 'divide-green-300'
  | 'divide-green-400'
  | 'divide-green-500'
  | 'divide-green-600'
  | 'divide-green-700'
  | 'divide-green-800'
  | 'divide-green-900'
  | 'divide-teal-100'
  | 'divide-teal-200'
  | 'divide-teal-300'
  | 'divide-teal-400'
  | 'divide-teal-500'
  | 'divide-teal-600'
  | 'divide-teal-700'
  | 'divide-teal-800'
  | 'divide-teal-900'
  | 'divide-blue-100'
  | 'divide-blue-200'
  | 'divide-blue-300'
  | 'divide-blue-400'
  | 'divide-blue-500'
  | 'divide-blue-600'
  | 'divide-blue-700'
  | 'divide-blue-800'
  | 'divide-blue-900'
  | 'divide-indigo-100'
  | 'divide-indigo-200'
  | 'divide-indigo-300'
  | 'divide-indigo-400'
  | 'divide-indigo-500'
  | 'divide-indigo-600'
  | 'divide-indigo-700'
  | 'divide-indigo-800'
  | 'divide-indigo-900'
  | 'divide-purple-100'
  | 'divide-purple-200'
  | 'divide-purple-300'
  | 'divide-purple-400'
  | 'divide-purple-500'
  | 'divide-purple-600'
  | 'divide-purple-700'
  | 'divide-purple-800'
  | 'divide-purple-900'
  | 'divide-pink-100'
  | 'divide-pink-200'
  | 'divide-pink-300'
  | 'divide-pink-400'
  | 'divide-pink-500'
  | 'divide-pink-600'
  | 'divide-pink-700'
  | 'divide-pink-800'
  | 'divide-pink-900';

export type TDivideStyle = 
  | 'divide-solid'
  | 'divide-dashed'
  | 'divide-dotted'
  | 'divide-double'
  | 'divide-none';

export type TBorders =
  | TBorderColor
  | TBorderOpacity
  | TBorderStyle
  | TBorderWidth
  | TBorderRadius
  | TDivideWidth
  | TDivideOpacity
  | TDivideColor
  | TDivideStyle

export type TBorderCollapse = 
  | 'border-collapse'
  | 'border-separate';

export type TTableLayout = 
  | 'table-auto'
  | 'table-fixed';

export type TTables =
  | TBorderCollapse
  | TTableLayout

export type TBoxShadow = 
  | 'shadow-xs'
  | 'shadow-sm'
  | 'shadow'
  | 'shadow-md'
  | 'shadow-lg'
  | 'shadow-xl'
  | 'shadow-2xl'
  | 'shadow-inner'
  | 'shadow-outline'
  | 'shadow-none';

export type TOpacity = 
  | 'opacity-0'
  | 'opacity-25'
  | 'opacity-50'
  | 'opacity-75'
  | 'opacity-100';

export type TEffects =
  | TBoxShadow
  | TOpacity

export type TTransitionProperty = 
  | 'transition-none'
  | 'transition-all'
  | 'transition'
  | 'transition-colors'
  | 'transition-opacity'
  | 'transition-shadow'
  | 'transition-transform'
  | 'transition-height'
  | 'transition-spacing';

export type TTransitionDuration = 
  | 'duration-75'
  | 'duration-100'
  | 'duration-150'
  | 'duration-200'
  | 'duration-300'
  | 'duration-500'
  | 'duration-700'
  | 'duration-1000';

export type TTransitionTimingFunction = 
  | 'ease-linear'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out';

export type TTransitionDelay = 
  | 'delay-75'
  | 'delay-100'
  | 'delay-150'
  | 'delay-200'
  | 'delay-300'
  | 'delay-500'
  | 'delay-700'
  | 'delay-1000';

export type TAnimation = 
  | 'animate-none'
  | 'animate-spin'
  | 'animate-ping'
  | 'animate-pulse'
  | 'animate-bounce';

export type TTransitionsAndAnimations =
  | TTransitionProperty
  | TTransitionDuration
  | TTransitionTimingFunction
  | TTransitionDelay
  | TAnimation

export type TFlexDirection = 
  | 'flex-row'
  | 'flex-row-reverse'
  | 'flex-col'
  | 'flex-col-reverse';

export type TFlexWrap = 
  | 'flex-no-wrap'
  | 'flex-wrap'
  | 'flex-wrap-reverse';

export type TAlignItems = 
  | 'items-stretch'
  | 'items-start'
  | 'items-center'
  | 'items-end'
  | 'items-baseline';

export type TAlignContent = 
  | 'content-start'
  | 'content-center'
  | 'content-end'
  | 'content-between'
  | 'content-around';

export type TAlignSelf = 
  | 'self-auto'
  | 'self-start'
  | 'self-center'
  | 'self-end'
  | 'self-stretch';

export type TPlaceContent = 
  | 'place-content-start'
  | 'place-content-center'
  | 'place-content-end'
  | 'place-content-between'
  | 'place-content-around'
  | 'place-content-evenly'
  | 'place-content-stretch';

export type TPlaceItems = 
  | 'place-items-auto'
  | 'place-items-start'
  | 'place-items-center'
  | 'place-items-end'
  | 'place-items-stretch';

export type TPlaceSelf = 
  | 'place-self-auto'
  | 'place-self-start'
  | 'place-self-center'
  | 'place-self-end'
  | 'place-self-stretch';

export type TJustifyContent = 
  | 'justify-start'
  | 'justify-center'
  | 'justify-end'
  | 'justify-between'
  | 'justify-around';

export type TJustifyItems = 
  | 'justify-items-auto'
  | 'justify-items-start'
  | 'justify-items-center'
  | 'justify-items-end'
  | 'justify-items-stretch';

export type TJustifySelf = 
  | 'justify-self-auto'
  | 'justify-self-start'
  | 'justify-self-center'
  | 'justify-self-end'
  | 'justify-self-stretch';

export type TFlex = 
  | 'flex-initial'
  | 'flex-1'
  | 'flex-auto'
  | 'flex-none';

export type TFlexGrow = 
  | 'flex-grow-0'
  | 'flex-grow';

export type TFlexShrink = 
  | 'flex-shrink-0'
  | 'flex-shrink';

export type TOrder = 
  | 'order-1'
  | 'order-2'
  | 'order-3'
  | 'order-4'
  | 'order-5'
  | 'order-6'
  | 'order-7'
  | 'order-8'
  | 'order-9'
  | 'order-10'
  | 'order-11'
  | 'order-12'
  | 'order-first'
  | 'order-last'
  | 'order-none';

export type TFlexBox =
  | TFlexDirection
  | TFlexWrap
  | TAlignItems
  | TAlignContent
  | TAlignSelf
  | TPlaceContent
  | TPlaceItems
  | TPlaceSelf
  | TJustifyContent
  | TJustifyItems
  | TJustifySelf
  | TFlex
  | TFlexGrow
  | TFlexShrink
  | TOrder

export type TGridTemplateColumns = 
  | 'grid-cols-1'
  | 'grid-cols-2'
  | 'grid-cols-3'
  | 'grid-cols-4'
  | 'grid-cols-5'
  | 'grid-cols-6'
  | 'grid-cols-7'
  | 'grid-cols-8'
  | 'grid-cols-9'
  | 'grid-cols-10'
  | 'grid-cols-11'
  | 'grid-cols-12'
  | 'grid-cols-none';

export type TGridAutoColumns = 
  | 'auto-cols-auto'
  | 'auto-cols-min'
  | 'auto-cols-max'
  | 'auto-cols-fr';

export type TGridColumn = 
  | 'col-auto'
  | 'col-span-1'
  | 'col-span-2'
  | 'col-span-3'
  | 'col-span-4'
  | 'col-span-5'
  | 'col-span-6'
  | 'col-span-7'
  | 'col-span-8'
  | 'col-span-9'
  | 'col-span-10'
  | 'col-span-11'
  | 'col-span-12'
  | 'col-span-full';

export type TGridColumnStart = 
  | 'col-start-1'
  | 'col-start-2'
  | 'col-start-3'
  | 'col-start-4'
  | 'col-start-5'
  | 'col-start-6'
  | 'col-start-7'
  | 'col-start-8'
  | 'col-start-9'
  | 'col-start-10'
  | 'col-start-11'
  | 'col-start-12'
  | 'col-start-13'
  | 'col-start-auto';

export type TGridColumnEnd = 
  | 'col-end-1'
  | 'col-end-2'
  | 'col-end-3'
  | 'col-end-4'
  | 'col-end-5'
  | 'col-end-6'
  | 'col-end-7'
  | 'col-end-8'
  | 'col-end-9'
  | 'col-end-10'
  | 'col-end-11'
  | 'col-end-12'
  | 'col-end-13'
  | 'col-end-auto';

export type TGridTemplateRows = 
  | 'grid-rows-1'
  | 'grid-rows-2'
  | 'grid-rows-3'
  | 'grid-rows-4'
  | 'grid-rows-5'
  | 'grid-rows-6'
  | 'grid-rows-none';

export type TGridAutoRows = 
  | 'auto-rows-auto'
  | 'auto-rows-min'
  | 'auto-rows-max'
  | 'auto-rows-fr';

export type TGridRow = 
  | 'row-auto'
  | 'row-span-1'
  | 'row-span-2'
  | 'row-span-3'
  | 'row-span-4'
  | 'row-span-5'
  | 'row-span-6'
  | 'row-span-full';

export type TGridRowStart = 
  | 'row-start-1'
  | 'row-start-2'
  | 'row-start-3'
  | 'row-start-4'
  | 'row-start-5'
  | 'row-start-6'
  | 'row-start-7'
  | 'row-start-auto';

export type TGridRowEnd = 
  | 'row-end-1'
  | 'row-end-2'
  | 'row-end-3'
  | 'row-end-4'
  | 'row-end-5'
  | 'row-end-6'
  | 'row-end-7'
  | 'row-end-auto';

export type TGap = 
  | 'gap-0'
  | 'gap-1'
  | 'gap-2'
  | 'gap-3'
  | 'gap-4'
  | 'gap-5'
  | 'gap-6'
  | 'gap-8'
  | 'gap-10'
  | 'gap-12'
  | 'gap-16'
  | 'gap-20'
  | 'gap-24'
  | 'gap-32'
  | 'gap-40'
  | 'gap-48'
  | 'gap-56'
  | 'gap-64'
  | 'gap-px'
  | 'gap-cardIndexRatio'
  | 'gap-y-0'
  | 'gap-y-1'
  | 'gap-y-2'
  | 'gap-y-3'
  | 'gap-y-4'
  | 'gap-y-5'
  | 'gap-y-6'
  | 'gap-y-8'
  | 'gap-y-10'
  | 'gap-y-12'
  | 'gap-y-16'
  | 'gap-y-20'
  | 'gap-y-24'
  | 'gap-y-32'
  | 'gap-y-40'
  | 'gap-y-48'
  | 'gap-y-56'
  | 'gap-y-64'
  | 'gap-y-px'
  | 'gap-y-cardIndexRatio'
  | 'gap-x-0'
  | 'gap-x-1'
  | 'gap-x-2'
  | 'gap-x-3'
  | 'gap-x-4'
  | 'gap-x-5'
  | 'gap-x-6'
  | 'gap-x-8'
  | 'gap-x-10'
  | 'gap-x-12'
  | 'gap-x-16'
  | 'gap-x-20'
  | 'gap-x-24'
  | 'gap-x-32'
  | 'gap-x-40'
  | 'gap-x-48'
  | 'gap-x-56'
  | 'gap-x-64'
  | 'gap-x-px'
  | 'gap-x-cardIndexRatio';

export type TGridAutoFlow = 
  | 'grid-flow-row'
  | 'grid-flow-col'
  | 'grid-flow-row-dense'
  | 'grid-flow-col-dense';

export type TGrid =
  | TGridTemplateColumns
  | TGridAutoColumns
  | TGridColumn
  | TGridColumnStart
  | TGridColumnEnd
  | TGridTemplateRows
  | TGridAutoRows
  | TGridRow
  | TGridRowStart
  | TGridRowEnd
  | TGap
  | TGridAutoFlow

export type TPadding = 
  | 'p-0'
  | 'p-1'
  | 'p-2'
  | 'p-3'
  | 'p-4'
  | 'p-5'
  | 'p-6'
  | 'p-8'
  | 'p-10'
  | 'p-12'
  | 'p-16'
  | 'p-20'
  | 'p-24'
  | 'p-32'
  | 'p-40'
  | 'p-48'
  | 'p-56'
  | 'p-64'
  | 'p-px'
  | 'p-cardIndexRatio'
  | 'py-0'
  | 'py-1'
  | 'py-2'
  | 'py-3'
  | 'py-4'
  | 'py-5'
  | 'py-6'
  | 'py-8'
  | 'py-10'
  | 'py-12'
  | 'py-16'
  | 'py-20'
  | 'py-24'
  | 'py-32'
  | 'py-40'
  | 'py-48'
  | 'py-56'
  | 'py-64'
  | 'py-px'
  | 'py-cardIndexRatio'
  | 'px-0'
  | 'px-1'
  | 'px-2'
  | 'px-3'
  | 'px-4'
  | 'px-5'
  | 'px-6'
  | 'px-8'
  | 'px-10'
  | 'px-12'
  | 'px-16'
  | 'px-20'
  | 'px-24'
  | 'px-32'
  | 'px-40'
  | 'px-48'
  | 'px-56'
  | 'px-64'
  | 'px-px'
  | 'px-cardIndexRatio'
  | 'pt-0'
  | 'pt-1'
  | 'pt-2'
  | 'pt-3'
  | 'pt-4'
  | 'pt-5'
  | 'pt-6'
  | 'pt-8'
  | 'pt-10'
  | 'pt-12'
  | 'pt-16'
  | 'pt-20'
  | 'pt-24'
  | 'pt-32'
  | 'pt-40'
  | 'pt-48'
  | 'pt-56'
  | 'pt-64'
  | 'pt-px'
  | 'pt-cardIndexRatio'
  | 'pr-0'
  | 'pr-1'
  | 'pr-2'
  | 'pr-3'
  | 'pr-4'
  | 'pr-5'
  | 'pr-6'
  | 'pr-8'
  | 'pr-10'
  | 'pr-12'
  | 'pr-16'
  | 'pr-20'
  | 'pr-24'
  | 'pr-32'
  | 'pr-40'
  | 'pr-48'
  | 'pr-56'
  | 'pr-64'
  | 'pr-px'
  | 'pr-cardIndexRatio'
  | 'pb-0'
  | 'pb-1'
  | 'pb-2'
  | 'pb-3'
  | 'pb-4'
  | 'pb-5'
  | 'pb-6'
  | 'pb-8'
  | 'pb-10'
  | 'pb-12'
  | 'pb-16'
  | 'pb-20'
  | 'pb-24'
  | 'pb-32'
  | 'pb-40'
  | 'pb-48'
  | 'pb-56'
  | 'pb-64'
  | 'pb-px'
  | 'pb-cardIndexRatio'
  | 'pl-0'
  | 'pl-1'
  | 'pl-2'
  | 'pl-3'
  | 'pl-4'
  | 'pl-5'
  | 'pl-6'
  | 'pl-8'
  | 'pl-10'
  | 'pl-12'
  | 'pl-16'
  | 'pl-20'
  | 'pl-24'
  | 'pl-32'
  | 'pl-40'
  | 'pl-48'
  | 'pl-56'
  | 'pl-64'
  | 'pl-px'
  | 'pl-cardIndexRatio';

export type TMargin = 
  | 'm-0'
  | 'm-1'
  | 'm-2'
  | 'm-3'
  | 'm-4'
  | 'm-5'
  | 'm-6'
  | 'm-8'
  | 'm-10'
  | 'm-12'
  | 'm-16'
  | 'm-20'
  | 'm-24'
  | 'm-32'
  | 'm-40'
  | 'm-48'
  | 'm-56'
  | 'm-64'
  | 'm-auto'
  | 'm-px'
  | '-m-0'
  | '-m-1'
  | '-m-2'
  | '-m-3'
  | '-m-4'
  | '-m-5'
  | '-m-6'
  | '-m-8'
  | '-m-10'
  | '-m-12'
  | '-m-16'
  | '-m-20'
  | '-m-24'
  | '-m-32'
  | '-m-40'
  | '-m-48'
  | '-m-56'
  | '-m-64'
  | '-m-px'
  | 'my-0'
  | 'my-1'
  | 'my-2'
  | 'my-3'
  | 'my-4'
  | 'my-5'
  | 'my-6'
  | 'my-8'
  | 'my-10'
  | 'my-12'
  | 'my-16'
  | 'my-20'
  | 'my-24'
  | 'my-32'
  | 'my-40'
  | 'my-48'
  | 'my-56'
  | 'my-64'
  | 'my-auto'
  | 'my-px'
  | '-my-0'
  | '-my-1'
  | '-my-2'
  | '-my-3'
  | '-my-4'
  | '-my-5'
  | '-my-6'
  | '-my-8'
  | '-my-10'
  | '-my-12'
  | '-my-16'
  | '-my-20'
  | '-my-24'
  | '-my-32'
  | '-my-40'
  | '-my-48'
  | '-my-56'
  | '-my-64'
  | '-my-px'
  | 'mx-0'
  | 'mx-1'
  | 'mx-2'
  | 'mx-3'
  | 'mx-4'
  | 'mx-5'
  | 'mx-6'
  | 'mx-8'
  | 'mx-10'
  | 'mx-12'
  | 'mx-16'
  | 'mx-20'
  | 'mx-24'
  | 'mx-32'
  | 'mx-40'
  | 'mx-48'
  | 'mx-56'
  | 'mx-64'
  | 'mx-auto'
  | 'mx-px'
  | '-mx-0'
  | '-mx-1'
  | '-mx-2'
  | '-mx-3'
  | '-mx-4'
  | '-mx-5'
  | '-mx-6'
  | '-mx-8'
  | '-mx-10'
  | '-mx-12'
  | '-mx-16'
  | '-mx-20'
  | '-mx-24'
  | '-mx-32'
  | '-mx-40'
  | '-mx-48'
  | '-mx-56'
  | '-mx-64'
  | '-mx-px'
  | 'mt-0'
  | 'mt-1'
  | 'mt-2'
  | 'mt-3'
  | 'mt-4'
  | 'mt-5'
  | 'mt-6'
  | 'mt-8'
  | 'mt-10'
  | 'mt-12'
  | 'mt-16'
  | 'mt-20'
  | 'mt-24'
  | 'mt-32'
  | 'mt-40'
  | 'mt-48'
  | 'mt-56'
  | 'mt-64'
  | 'mt-auto'
  | 'mt-px'
  | '-mt-0'
  | '-mt-1'
  | '-mt-2'
  | '-mt-3'
  | '-mt-4'
  | '-mt-5'
  | '-mt-6'
  | '-mt-8'
  | '-mt-10'
  | '-mt-12'
  | '-mt-16'
  | '-mt-20'
  | '-mt-24'
  | '-mt-32'
  | '-mt-40'
  | '-mt-48'
  | '-mt-56'
  | '-mt-64'
  | '-mt-px'
  | 'mr-0'
  | 'mr-1'
  | 'mr-2'
  | 'mr-3'
  | 'mr-4'
  | 'mr-5'
  | 'mr-6'
  | 'mr-8'
  | 'mr-10'
  | 'mr-12'
  | 'mr-16'
  | 'mr-20'
  | 'mr-24'
  | 'mr-32'
  | 'mr-40'
  | 'mr-48'
  | 'mr-56'
  | 'mr-64'
  | 'mr-auto'
  | 'mr-px'
  | '-mr-0'
  | '-mr-1'
  | '-mr-2'
  | '-mr-3'
  | '-mr-4'
  | '-mr-5'
  | '-mr-6'
  | '-mr-8'
  | '-mr-10'
  | '-mr-12'
  | '-mr-16'
  | '-mr-20'
  | '-mr-24'
  | '-mr-32'
  | '-mr-40'
  | '-mr-48'
  | '-mr-56'
  | '-mr-64'
  | '-mr-px'
  | 'mb-0'
  | 'mb-1'
  | 'mb-2'
  | 'mb-3'
  | 'mb-4'
  | 'mb-5'
  | 'mb-6'
  | 'mb-8'
  | 'mb-10'
  | 'mb-12'
  | 'mb-16'
  | 'mb-20'
  | 'mb-24'
  | 'mb-32'
  | 'mb-40'
  | 'mb-48'
  | 'mb-56'
  | 'mb-64'
  | 'mb-auto'
  | 'mb-px'
  | '-mb-0'
  | '-mb-1'
  | '-mb-2'
  | '-mb-3'
  | '-mb-4'
  | '-mb-5'
  | '-mb-6'
  | '-mb-8'
  | '-mb-10'
  | '-mb-12'
  | '-mb-16'
  | '-mb-20'
  | '-mb-24'
  | '-mb-32'
  | '-mb-40'
  | '-mb-48'
  | '-mb-56'
  | '-mb-64'
  | '-mb-px'
  | 'ml-0'
  | 'ml-1'
  | 'ml-2'
  | 'ml-3'
  | 'ml-4'
  | 'ml-5'
  | 'ml-6'
  | 'ml-8'
  | 'ml-10'
  | 'ml-12'
  | 'ml-16'
  | 'ml-20'
  | 'ml-24'
  | 'ml-32'
  | 'ml-40'
  | 'ml-48'
  | 'ml-56'
  | 'ml-64'
  | 'ml-auto'
  | 'ml-px'
  | '-ml-0'
  | '-ml-1'
  | '-ml-2'
  | '-ml-3'
  | '-ml-4'
  | '-ml-5'
  | '-ml-6'
  | '-ml-8'
  | '-ml-10'
  | '-ml-12'
  | '-ml-16'
  | '-ml-20'
  | '-ml-24'
  | '-ml-32'
  | '-ml-40'
  | '-ml-48'
  | '-ml-56'
  | '-ml-64'
  | '-ml-px';

export type TSpace = 
  | 'space-x-0'
  | 'space-x-1'
  | 'space-x-2'
  | 'space-x-3'
  | 'space-x-4'
  | 'space-x-5'
  | 'space-x-6'
  | 'space-x-8'
  | 'space-x-10'
  | 'space-x-12'
  | 'space-x-16'
  | 'space-x-20'
  | 'space-x-24'
  | 'space-x-32'
  | 'space-x-40'
  | 'space-x-48'
  | 'space-x-56'
  | 'space-x-64'
  | 'space-x-px'
  | '-space-x-0'
  | '-space-x-1'
  | '-space-x-2'
  | '-space-x-3'
  | '-space-x-4'
  | '-space-x-5'
  | '-space-x-6'
  | '-space-x-8'
  | '-space-x-10'
  | '-space-x-12'
  | '-space-x-16'
  | '-space-x-20'
  | '-space-x-24'
  | '-space-x-32'
  | '-space-x-40'
  | '-space-x-48'
  | '-space-x-56'
  | '-space-x-64'
  | '-space-x-px'
  | 'space-x-reverse'
  | 'space-y-0'
  | 'space-y-1'
  | 'space-y-2'
  | 'space-y-3'
  | 'space-y-4'
  | 'space-y-5'
  | 'space-y-6'
  | 'space-y-8'
  | 'space-y-10'
  | 'space-y-12'
  | 'space-y-16'
  | 'space-y-20'
  | 'space-y-24'
  | 'space-y-32'
  | 'space-y-40'
  | 'space-y-48'
  | 'space-y-56'
  | 'space-y-64'
  | 'space-y-px'
  | '-space-y-0'
  | '-space-y-1'
  | '-space-y-2'
  | '-space-y-3'
  | '-space-y-4'
  | '-space-y-5'
  | '-space-y-6'
  | '-space-y-8'
  | '-space-y-10'
  | '-space-y-12'
  | '-space-y-16'
  | '-space-y-20'
  | '-space-y-24'
  | '-space-y-32'
  | '-space-y-40'
  | '-space-y-48'
  | '-space-y-56'
  | '-space-y-64'
  | '-space-y-px'
  | 'space-y-reverse';

export type TSpacing =
  | TPadding
  | TMargin
  | TSpace

export type TAppearance = 
  | 'appearance-none';

export type TCursor = 
  | 'cursor-auto'
  | 'cursor-default'
  | 'cursor-pointer'
  | 'cursor-wait'
  | 'cursor-text'
  | 'cursor-move'
  | 'cursor-not-allowed';

export type TOutline = 
  | 'outline-none'
  | 'outline-white'
  | 'outline-black';

export type TPointerEvents = 
  | 'pointer-events-none'
  | 'pointer-events-auto';

export type TResize = 
  | 'resize-none'
  | 'resize'
  | 'resize-y'
  | 'resize-x';

export type TUserSelect = 
  | 'select-none'
  | 'select-text'
  | 'select-all'
  | 'select-auto';

export type TInteractivity =
  | TAppearance
  | TCursor
  | TOutline
  | TPointerEvents
  | TResize
  | TUserSelect

export type TDisplay = 
  | 'block'
  | 'inline-block'
  | 'inline'
  | 'flex'
  | 'inline-flex'
  | 'grid'
  | 'inline-grid'
  | 'table'
  | 'table-row'
  | 'table-cell'
  | 'contents'
  | 'hidden';

export type TBoxSizing = 
  | 'box-border'
  | 'box-content';

export type TContainer = 
  | 'container';

export type TFloat = 
  | 'float-right'
  | 'float-left'
  | 'float-none'
  | 'clearfix';

export type TClear = 
  | 'clear-left'
  | 'clear-right'
  | 'clear-both'
  | 'clear-none';

export type TObjectFit = 
  | 'object-contain'
  | 'object-cover'
  | 'object-fill'
  | 'object-none'
  | 'object-scale-down';

export type TObjectPosition = 
  | 'object-bottom'
  | 'object-center'
  | 'object-left'
  | 'object-left-bottom'
  | 'object-left-top'
  | 'object-right'
  | 'object-right-bottom'
  | 'object-right-top'
  | 'object-top';

export type TOverflow = 
  | 'overflow-auto'
  | 'overflow-hidden'
  | 'overflow-visible'
  | 'overflow-scroll'
  | 'overflow-x-auto'
  | 'overflow-y-auto'
  | 'overflow-x-hidden'
  | 'overflow-y-hidden'
  | 'overflow-x-visible'
  | 'overflow-y-visible'
  | 'overflow-x-scroll'
  | 'overflow-y-scroll'
  | 'scrolling-touch'
  | 'scrolling-auto';

export type TOverscrollBehavior = 
  | 'overscroll-auto'
  | 'overscroll-contain'
  | 'overscroll-none'
  | 'overscroll-y-auto'
  | 'overscroll-y-contain'
  | 'overscroll-y-none'
  | 'overscroll-x-auto'
  | 'overscroll-x-contain'
  | 'overscroll-x-none';

export type TPosition = 
  | 'static'
  | 'fixed'
  | 'absolute'
  | 'relative'
  | 'sticky';

export type TInset = 
  | 'inset-0'
  | 'inset-x-0'
  | 'inset-y-0'
  | 'top-0'
  | 'right-0'
  | 'bottom-0'
  | 'left-0'
  | 'inset-100'
  | 'inset-x-100'
  | 'inset-y-100'
  | 'top-100'
  | 'right-100'
  | 'bottom-100'
  | 'left-100'
  | 'inset-auto'
  | 'inset-x-auto'
  | 'inset-y-auto'
  | 'top-auto'
  | 'right-auto'
  | 'bottom-auto'
  | 'left-auto'
  | '-inset-50'
  | '-inset-x-50'
  | '-inset-y-50'
  | '-top-50'
  | '-right-50'
  | '-bottom-50'
  | '-left-50';

export type TVisibility = 
  | 'visible'
  | 'invisible';

export type TZIndex = 
  | 'z-0'
  | 'z-10'
  | 'z-20'
  | 'z-30'
  | 'z-40'
  | 'z-50'
  | 'z-auto';

export type TLayout =
  | TDisplay
  | TBoxSizing
  | TContainer
  | TFloat
  | TClear
  | TObjectFit
  | TObjectPosition
  | TOverflow
  | TOverscrollBehavior
  | TPosition
  | TInset
  | TVisibility
  | TZIndex

export type TWidth = 
  | 'w-0'
  | 'w-1'
  | 'w-2'
  | 'w-3'
  | 'w-4'
  | 'w-5'
  | 'w-6'
  | 'w-8'
  | 'w-10'
  | 'w-12'
  | 'w-16'
  | 'w-20'
  | 'w-24'
  | 'w-32'
  | 'w-40'
  | 'w-48'
  | 'w-56'
  | 'w-64'
  | 'w-auto'
  | 'w-px'
  | 'w-1/2'
  | 'w-1/3'
  | 'w-2/3'
  | 'w-1/4'
  | 'w-2/4'
  | 'w-3/4'
  | 'w-1/5'
  | 'w-2/5'
  | 'w-3/5'
  | 'w-4/5'
  | 'w-1/6'
  | 'w-2/6'
  | 'w-3/6'
  | 'w-4/6'
  | 'w-5/6'
  | 'w-1/12'
  | 'w-2/12'
  | 'w-3/12'
  | 'w-4/12'
  | 'w-5/12'
  | 'w-6/12'
  | 'w-7/12'
  | 'w-8/12'
  | 'w-9/12'
  | 'w-10/12'
  | 'w-11/12'
  | 'w-full'
  | 'w-screen'
  | 'w-33vw'
  | 'w-40vw'
  | 'w-50vw';

export type TMinWidth = 
  | 'min-w-0'
  | 'min-w-full';

export type TMaxWidth = 
  | 'max-w-none'
  | 'max-w-xs'
  | 'max-w-sm'
  | 'max-w-md'
  | 'max-w-lg'
  | 'max-w-xl'
  | 'max-w-2xl'
  | 'max-w-3xl'
  | 'max-w-4xl'
  | 'max-w-5xl'
  | 'max-w-6xl'
  | 'max-w-full'
  | 'max-w-screen-sm'
  | 'max-w-screen-md'
  | 'max-w-screen-lg'
  | 'max-w-screen-xl';

export type THeight = 
  | 'h-0'
  | 'h-1'
  | 'h-2'
  | 'h-3'
  | 'h-4'
  | 'h-5'
  | 'h-6'
  | 'h-8'
  | 'h-10'
  | 'h-12'
  | 'h-16'
  | 'h-20'
  | 'h-24'
  | 'h-32'
  | 'h-40'
  | 'h-48'
  | 'h-56'
  | 'h-64'
  | 'h-auto'
  | 'h-px'
  | 'h-full'
  | 'h-screen';

export type TMinHeight = 
  | 'min-h-0'
  | 'min-h-full'
  | 'min-h-screen';

export type TMaxHeight = 
  | 'max-h-full'
  | 'max-h-screen';

export type TSizing =
  | TWidth
  | TMinWidth
  | TMaxWidth
  | THeight
  | TMinHeight
  | TMaxHeight

export type TFill = 
  | 'fill-current';

export type TStroke = 
  | 'stroke-current';

export type TStrokeWidth = 
  | 'stroke-0'
  | 'stroke-1'
  | 'stroke-2';

export type TSVG =
  | TFill
  | TStroke
  | TStrokeWidth

export type TScale = 
  | 'scale-0'
  | 'scale-50'
  | 'scale-75'
  | 'scale-90'
  | 'scale-95'
  | 'scale-100'
  | 'scale-105'
  | 'scale-110'
  | 'scale-125'
  | 'scale-150'
  | 'scale-x-0'
  | 'scale-x-50'
  | 'scale-x-75'
  | 'scale-x-90'
  | 'scale-x-95'
  | 'scale-x-100'
  | 'scale-x-105'
  | 'scale-x-110'
  | 'scale-x-125'
  | 'scale-x-150'
  | 'scale-y-0'
  | 'scale-y-50'
  | 'scale-y-75'
  | 'scale-y-90'
  | 'scale-y-95'
  | 'scale-y-100'
  | 'scale-y-105'
  | 'scale-y-110'
  | 'scale-y-125'
  | 'scale-y-150';

export type TRotate = 
  | 'rotate-0'
  | 'rotate-1'
  | 'rotate-2'
  | 'rotate-3'
  | 'rotate-6'
  | 'rotate-12'
  | 'rotate-45'
  | 'rotate-90'
  | 'rotate-180'
  | '-rotate-180'
  | '-rotate-90'
  | '-rotate-45'
  | '-rotate-12'
  | '-rotate-6'
  | '-rotate-3'
  | '-rotate-2'
  | '-rotate-1';

export type TTranslate = 
  | 'translate-x-0'
  | 'translate-x-1'
  | 'translate-x-2'
  | 'translate-x-3'
  | 'translate-x-4'
  | 'translate-x-5'
  | 'translate-x-6'
  | 'translate-x-8'
  | 'translate-x-10'
  | 'translate-x-12'
  | 'translate-x-16'
  | 'translate-x-20'
  | 'translate-x-24'
  | 'translate-x-32'
  | 'translate-x-40'
  | 'translate-x-48'
  | 'translate-x-56'
  | 'translate-x-64'
  | 'translate-x-px'
  | '-translate-x-0'
  | '-translate-x-1'
  | '-translate-x-2'
  | '-translate-x-3'
  | '-translate-x-4'
  | '-translate-x-5'
  | '-translate-x-6'
  | '-translate-x-8'
  | '-translate-x-10'
  | '-translate-x-12'
  | '-translate-x-16'
  | '-translate-x-20'
  | '-translate-x-24'
  | '-translate-x-32'
  | '-translate-x-40'
  | '-translate-x-48'
  | '-translate-x-56'
  | '-translate-x-64'
  | '-translate-x-px'
  | '-translate-x-full'
  | '-translate-x-1/2'
  | 'translate-x-1/2'
  | 'translate-x-full'
  | 'translate-y-0'
  | 'translate-y-1'
  | 'translate-y-2'
  | 'translate-y-3'
  | 'translate-y-4'
  | 'translate-y-5'
  | 'translate-y-6'
  | 'translate-y-8'
  | 'translate-y-10'
  | 'translate-y-12'
  | 'translate-y-16'
  | 'translate-y-20'
  | 'translate-y-24'
  | 'translate-y-32'
  | 'translate-y-40'
  | 'translate-y-48'
  | 'translate-y-56'
  | 'translate-y-64'
  | 'translate-y-px'
  | '-translate-y-0'
  | '-translate-y-1'
  | '-translate-y-2'
  | '-translate-y-3'
  | '-translate-y-4'
  | '-translate-y-5'
  | '-translate-y-6'
  | '-translate-y-8'
  | '-translate-y-10'
  | '-translate-y-12'
  | '-translate-y-16'
  | '-translate-y-20'
  | '-translate-y-24'
  | '-translate-y-32'
  | '-translate-y-40'
  | '-translate-y-48'
  | '-translate-y-56'
  | '-translate-y-64'
  | '-translate-y-px'
  | '-translate-y-full'
  | '-translate-y-1/2'
  | 'translate-y-1/2'
  | 'translate-y-full';

export type TSkew = 
  | 'skew-x-0'
  | 'skew-x-1'
  | 'skew-x-2'
  | 'skew-x-3'
  | 'skew-x-6'
  | 'skew-x-12'
  | '-skew-x-12'
  | '-skew-x-6'
  | '-skew-x-3'
  | '-skew-x-2'
  | '-skew-x-1'
  | 'skew-y-0'
  | 'skew-y-1'
  | 'skew-y-2'
  | 'skew-y-3'
  | 'skew-y-6'
  | 'skew-y-12'
  | '-skew-y-12'
  | '-skew-y-6'
  | '-skew-y-3'
  | '-skew-y-2'
  | '-skew-y-1';

export type TTransformOrigin = 
  | 'origin-center'
  | 'origin-top'
  | 'origin-top-right'
  | 'origin-right'
  | 'origin-bottom-right'
  | 'origin-bottom'
  | 'origin-bottom-left'
  | 'origin-left'
  | 'origin-top-left';

export type TTransforms =
  | TScale
  | TRotate
  | TTranslate
  | TSkew
  | TTransformOrigin

export type TFontFamily = 
  | 'font-sans'
  | 'font-serif'
  | 'font-mono';

export type TFontSize = 
  | 'text-xs'
  | 'text-sm'
  | 'text-base'
  | 'text-lg'
  | 'text-xl'
  | 'text-2xl'
  | 'text-3xl'
  | 'text-4xl'
  | 'text-5xl'
  | 'text-6xl';

export type TFontSmoothing = 
  | 'antialiased'
  | 'subpixel-antialiased';

export type TFontStyle = 
  | 'italic'
  | 'non-italic';

export type TFontWeight = 
  | 'font-hairline'
  | 'font-thin'
  | 'font-light'
  | 'font-normal'
  | 'font-medium'
  | 'font-semibold'
  | 'font-bold'
  | 'font-extrabold'
  | 'font-black';

export type TFontVariantNumeric = 
  | 'normal-nums'
  | 'ordinal'
  | 'slashed-zero'
  | 'lining-nums'
  | 'oldstyle-nums'
  | 'proportional-nums'
  | 'tabular-nums'
  | 'diagonal-fractions'
  | 'stacked-fractions';

export type TLetterSpacing = 
  | 'tracking-tighter'
  | 'tracking-tight'
  | 'tracking-normal'
  | 'tracking-wide'
  | 'tracking-wider'
  | 'tracking-widest';

export type TLineHeight = 
  | 'leading-3'
  | 'leading-4'
  | 'leading-5'
  | 'leading-6'
  | 'leading-7'
  | 'leading-8'
  | 'leading-9'
  | 'leading-10'
  | 'leading-none'
  | 'leading-tight'
  | 'leading-snug'
  | 'leading-normal'
  | 'leading-relaxed'
  | 'leading-loose';

export type TListStyleType = 
  | 'list-none'
  | 'list-disc'
  | 'list-decimal';

export type TListStylePosition = 
  | 'list-inside'
  | 'list-outside';

export type TPlaceholderColor = 
  | 'placeholder-transparent'
  | 'placeholder-current'
  | 'placeholder-black'
  | 'placeholder-white'
  | 'placeholder-gray-100'
  | 'placeholder-gray-200'
  | 'placeholder-gray-300'
  | 'placeholder-gray-400'
  | 'placeholder-gray-500'
  | 'placeholder-gray-600'
  | 'placeholder-gray-700'
  | 'placeholder-gray-800'
  | 'placeholder-gray-900'
  | 'placeholder-red-100'
  | 'placeholder-red-200'
  | 'placeholder-red-300'
  | 'placeholder-red-400'
  | 'placeholder-red-500'
  | 'placeholder-red-600'
  | 'placeholder-red-700'
  | 'placeholder-red-800'
  | 'placeholder-red-900'
  | 'placeholder-orange-100'
  | 'placeholder-orange-200'
  | 'placeholder-orange-300'
  | 'placeholder-orange-400'
  | 'placeholder-orange-500'
  | 'placeholder-orange-600'
  | 'placeholder-orange-700'
  | 'placeholder-orange-800'
  | 'placeholder-orange-900'
  | 'placeholder-yellow-100'
  | 'placeholder-yellow-200'
  | 'placeholder-yellow-300'
  | 'placeholder-yellow-400'
  | 'placeholder-yellow-500'
  | 'placeholder-yellow-600'
  | 'placeholder-yellow-700'
  | 'placeholder-yellow-800'
  | 'placeholder-yellow-900'
  | 'placeholder-green-100'
  | 'placeholder-green-200'
  | 'placeholder-green-300'
  | 'placeholder-green-400'
  | 'placeholder-green-500'
  | 'placeholder-green-600'
  | 'placeholder-green-700'
  | 'placeholder-green-800'
  | 'placeholder-green-900'
  | 'placeholder-teal-100'
  | 'placeholder-teal-200'
  | 'placeholder-teal-300'
  | 'placeholder-teal-400'
  | 'placeholder-teal-500'
  | 'placeholder-teal-600'
  | 'placeholder-teal-700'
  | 'placeholder-teal-800'
  | 'placeholder-teal-900'
  | 'placeholder-blue-100'
  | 'placeholder-blue-200'
  | 'placeholder-blue-300'
  | 'placeholder-blue-400'
  | 'placeholder-blue-500'
  | 'placeholder-blue-600'
  | 'placeholder-blue-700'
  | 'placeholder-blue-800'
  | 'placeholder-blue-900'
  | 'placeholder-indigo-100'
  | 'placeholder-indigo-200'
  | 'placeholder-indigo-300'
  | 'placeholder-indigo-400'
  | 'placeholder-indigo-500'
  | 'placeholder-indigo-600'
  | 'placeholder-indigo-700'
  | 'placeholder-indigo-800'
  | 'placeholder-indigo-900'
  | 'placeholder-purple-100'
  | 'placeholder-purple-200'
  | 'placeholder-purple-300'
  | 'placeholder-purple-400'
  | 'placeholder-purple-500'
  | 'placeholder-purple-600'
  | 'placeholder-purple-700'
  | 'placeholder-purple-800'
  | 'placeholder-purple-900'
  | 'placeholder-pink-100'
  | 'placeholder-pink-200'
  | 'placeholder-pink-300'
  | 'placeholder-pink-400'
  | 'placeholder-pink-500'
  | 'placeholder-pink-600'
  | 'placeholder-pink-700'
  | 'placeholder-pink-800'
  | 'placeholder-pink-900'
  | 'placeholder-primary-light'
  | 'placeholder-primary'
  | 'placeholder-primary-dark'
  | 'placeholder-accent-dark'
  | 'placeholder-accent'
  | 'placeholder-accent-light'
  | 'placeholder-warning-dark'
  | 'placeholder-warning'
  | 'placeholder-warning-light'
  | 'placeholder-dark'
  | 'placeholder-dark-secondary'
  | 'placeholder-light'
  | 'placeholder-light-secondary'
  | 'placeholder-light-hint';

export type TPlaceholderOpacity = 
  | 'placeholder-opacity-0'
  | 'placeholder-opacity-25'
  | 'placeholder-opacity-50'
  | 'placeholder-opacity-75'
  | 'placeholder-opacity-100';

export type TTextAlign = 
  | 'text-left'
  | 'text-center'
  | 'text-right'
  | 'text-justify';

export type TTextColor = 
  | 'text-transparent'
  | 'text-current'
  | 'text-black'
  | 'text-white'
  | 'text-gray-100'
  | 'text-gray-200'
  | 'text-gray-300'
  | 'text-gray-400'
  | 'text-gray-500'
  | 'text-gray-600'
  | 'text-gray-700'
  | 'text-gray-800'
  | 'text-gray-900'
  | 'text-red-100'
  | 'text-red-200'
  | 'text-red-300'
  | 'text-red-400'
  | 'text-red-500'
  | 'text-red-600'
  | 'text-red-700'
  | 'text-red-800'
  | 'text-red-900'
  | 'text-orange-100'
  | 'text-orange-200'
  | 'text-orange-300'
  | 'text-orange-400'
  | 'text-orange-500'
  | 'text-orange-600'
  | 'text-orange-700'
  | 'text-orange-800'
  | 'text-orange-900'
  | 'text-yellow-100'
  | 'text-yellow-200'
  | 'text-yellow-300'
  | 'text-yellow-400'
  | 'text-yellow-500'
  | 'text-yellow-600'
  | 'text-yellow-700'
  | 'text-yellow-800'
  | 'text-yellow-900'
  | 'text-green-100'
  | 'text-green-200'
  | 'text-green-300'
  | 'text-green-400'
  | 'text-green-500'
  | 'text-green-600'
  | 'text-green-700'
  | 'text-green-800'
  | 'text-green-900'
  | 'text-teal-100'
  | 'text-teal-200'
  | 'text-teal-300'
  | 'text-teal-400'
  | 'text-teal-500'
  | 'text-teal-600'
  | 'text-teal-700'
  | 'text-teal-800'
  | 'text-teal-900'
  | 'text-blue-100'
  | 'text-blue-200'
  | 'text-blue-300'
  | 'text-blue-400'
  | 'text-blue-500'
  | 'text-blue-600'
  | 'text-blue-700'
  | 'text-blue-800'
  | 'text-blue-900'
  | 'text-indigo-100'
  | 'text-indigo-200'
  | 'text-indigo-300'
  | 'text-indigo-400'
  | 'text-indigo-500'
  | 'text-indigo-600'
  | 'text-indigo-700'
  | 'text-indigo-800'
  | 'text-indigo-900'
  | 'text-purple-100'
  | 'text-purple-200'
  | 'text-purple-300'
  | 'text-purple-400'
  | 'text-purple-500'
  | 'text-purple-600'
  | 'text-purple-700'
  | 'text-purple-800'
  | 'text-purple-900'
  | 'text-pink-100'
  | 'text-pink-200'
  | 'text-pink-300'
  | 'text-pink-400'
  | 'text-pink-500'
  | 'text-pink-600'
  | 'text-pink-700'
  | 'text-pink-800'
  | 'text-pink-900'
  | 'text-primary-light'
  | 'text-primary'
  | 'text-primary-dark'
  | 'text-accent-dark'
  | 'text-accent'
  | 'text-accent-light'
  | 'text-warning-dark'
  | 'text-warning'
  | 'text-warning-light'
  | 'text-dark'
  | 'text-dark-secondary'
  | 'text-light'
  | 'text-light-secondary'
  | 'text-light-hint';

export type TTextDecoration = 
  | 'underline'
  | 'line-through'
  | 'no-underline';

export type TTextOpacity = 
  | 'text-opacity-0'
  | 'text-opacity-25'
  | 'text-opacity-50'
  | 'text-opacity-75'
  | 'text-opacity-100';

export type TTextTransform = 
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'normal-case';

export type TVerticalAlign = 
  | 'align-baseline'
  | 'align-top'
  | 'align-middle'
  | 'align-bottom'
  | 'align-text-top'
  | 'align-text-bottom';

export type TWhitespace = 
  | 'whitespace-normal'
  | 'whitespace-no-wrap'
  | 'whitespace-pre'
  | 'whitespace-pre-line'
  | 'whitespace-pre-wrap';

export type TWordBreak = 
  | 'break-normal'
  | 'break-words'
  | 'break-all'
  | 'truncate';

export type TTypography =
  | TFontFamily
  | TFontSize
  | TFontSmoothing
  | TFontStyle
  | TFontWeight
  | TFontVariantNumeric
  | TLetterSpacing
  | TLineHeight
  | TListStyleType
  | TListStylePosition
  | TPlaceholderColor
  | TPlaceholderOpacity
  | TTextAlign
  | TTextColor
  | TTextDecoration
  | TTextOpacity
  | TTextTransform
  | TVerticalAlign
  | TWhitespace
  | TWordBreak


export type TPseudoClasses =
  | 'sm:content-start'
  | 'md:content-start'
  | 'lg:content-start'
  | 'xl:content-start'
  | 'sm:content-center'
  | 'md:content-center'
  | 'lg:content-center'
  | 'xl:content-center'
  | 'sm:content-end'
  | 'md:content-end'
  | 'lg:content-end'
  | 'xl:content-end'
  | 'sm:content-between'
  | 'md:content-between'
  | 'lg:content-between'
  | 'xl:content-between'
  | 'sm:content-around'
  | 'md:content-around'
  | 'lg:content-around'
  | 'xl:content-around'
  | 'sm:items-stretch'
  | 'md:items-stretch'
  | 'lg:items-stretch'
  | 'xl:items-stretch'
  | 'sm:items-start'
  | 'md:items-start'
  | 'lg:items-start'
  | 'xl:items-start'
  | 'sm:items-center'
  | 'md:items-center'
  | 'lg:items-center'
  | 'xl:items-center'
  | 'sm:items-end'
  | 'md:items-end'
  | 'lg:items-end'
  | 'xl:items-end'
  | 'sm:items-baseline'
  | 'md:items-baseline'
  | 'lg:items-baseline'
  | 'xl:items-baseline'
  | 'sm:self-auto'
  | 'md:self-auto'
  | 'lg:self-auto'
  | 'xl:self-auto'
  | 'sm:self-start'
  | 'md:self-start'
  | 'lg:self-start'
  | 'xl:self-start'
  | 'sm:self-center'
  | 'md:self-center'
  | 'lg:self-center'
  | 'xl:self-center'
  | 'sm:self-end'
  | 'md:self-end'
  | 'lg:self-end'
  | 'xl:self-end'
  | 'sm:self-stretch'
  | 'md:self-stretch'
  | 'lg:self-stretch'
  | 'xl:self-stretch'
  | 'sm:appearance-none'
  | 'md:appearance-none'
  | 'lg:appearance-none'
  | 'xl:appearance-none'
  | 'sm:bg-fixed'
  | 'md:bg-fixed'
  | 'lg:bg-fixed'
  | 'xl:bg-fixed'
  | 'sm:bg-local'
  | 'md:bg-local'
  | 'lg:bg-local'
  | 'xl:bg-local'
  | 'sm:bg-scroll'
  | 'md:bg-scroll'
  | 'lg:bg-scroll'
  | 'xl:bg-scroll'
  | 'sm:bg-clip-border'
  | 'md:bg-clip-border'
  | 'lg:bg-clip-border'
  | 'xl:bg-clip-border'
  | 'sm:bg-clip-padding'
  | 'md:bg-clip-padding'
  | 'lg:bg-clip-padding'
  | 'xl:bg-clip-padding'
  | 'sm:bg-clip-content'
  | 'md:bg-clip-content'
  | 'lg:bg-clip-content'
  | 'xl:bg-clip-content'
  | 'sm:bg-clip-text'
  | 'md:bg-clip-text'
  | 'lg:bg-clip-text'
  | 'xl:bg-clip-text'
  | 'sm:bg-transparent'
  | 'md:bg-transparent'
  | 'lg:bg-transparent'
  | 'xl:bg-transparent'
  | 'hover:bg-transparent'
  | 'focus:bg-transparent'
  | 'group-hover:bg-transparent'
  | 'group'
  | 'checked:bg-transparent'
  | 'sm:bg-current'
  | 'md:bg-current'
  | 'lg:bg-current'
  | 'xl:bg-current'
  | 'hover:bg-current'
  | 'focus:bg-current'
  | 'group-hover:bg-current'
  | 'checked:bg-current'
  | 'sm:bg-black'
  | 'md:bg-black'
  | 'lg:bg-black'
  | 'xl:bg-black'
  | 'hover:bg-black'
  | 'focus:bg-black'
  | 'group-hover:bg-black'
  | 'checked:bg-black'
  | 'sm:bg-white'
  | 'md:bg-white'
  | 'lg:bg-white'
  | 'xl:bg-white'
  | 'hover:bg-white'
  | 'focus:bg-white'
  | 'group-hover:bg-white'
  | 'checked:bg-white'
  | 'sm:bg-gray-100'
  | 'md:bg-gray-100'
  | 'lg:bg-gray-100'
  | 'xl:bg-gray-100'
  | 'hover:bg-gray-100'
  | 'focus:bg-gray-100'
  | 'group-hover:bg-gray-100'
  | 'checked:bg-gray-100'
  | 'sm:bg-gray-200'
  | 'md:bg-gray-200'
  | 'lg:bg-gray-200'
  | 'xl:bg-gray-200'
  | 'hover:bg-gray-200'
  | 'focus:bg-gray-200'
  | 'group-hover:bg-gray-200'
  | 'checked:bg-gray-200'
  | 'sm:bg-gray-300'
  | 'md:bg-gray-300'
  | 'lg:bg-gray-300'
  | 'xl:bg-gray-300'
  | 'hover:bg-gray-300'
  | 'focus:bg-gray-300'
  | 'group-hover:bg-gray-300'
  | 'checked:bg-gray-300'
  | 'sm:bg-gray-400'
  | 'md:bg-gray-400'
  | 'lg:bg-gray-400'
  | 'xl:bg-gray-400'
  | 'hover:bg-gray-400'
  | 'focus:bg-gray-400'
  | 'group-hover:bg-gray-400'
  | 'checked:bg-gray-400'
  | 'sm:bg-gray-500'
  | 'md:bg-gray-500'
  | 'lg:bg-gray-500'
  | 'xl:bg-gray-500'
  | 'hover:bg-gray-500'
  | 'focus:bg-gray-500'
  | 'group-hover:bg-gray-500'
  | 'checked:bg-gray-500'
  | 'sm:bg-gray-600'
  | 'md:bg-gray-600'
  | 'lg:bg-gray-600'
  | 'xl:bg-gray-600'
  | 'hover:bg-gray-600'
  | 'focus:bg-gray-600'
  | 'group-hover:bg-gray-600'
  | 'checked:bg-gray-600'
  | 'sm:bg-gray-700'
  | 'md:bg-gray-700'
  | 'lg:bg-gray-700'
  | 'xl:bg-gray-700'
  | 'hover:bg-gray-700'
  | 'focus:bg-gray-700'
  | 'group-hover:bg-gray-700'
  | 'checked:bg-gray-700'
  | 'sm:bg-gray-800'
  | 'md:bg-gray-800'
  | 'lg:bg-gray-800'
  | 'xl:bg-gray-800'
  | 'hover:bg-gray-800'
  | 'focus:bg-gray-800'
  | 'group-hover:bg-gray-800'
  | 'checked:bg-gray-800'
  | 'sm:bg-gray-900'
  | 'md:bg-gray-900'
  | 'lg:bg-gray-900'
  | 'xl:bg-gray-900'
  | 'hover:bg-gray-900'
  | 'focus:bg-gray-900'
  | 'group-hover:bg-gray-900'
  | 'checked:bg-gray-900'
  | 'sm:bg-red-100'
  | 'md:bg-red-100'
  | 'lg:bg-red-100'
  | 'xl:bg-red-100'
  | 'hover:bg-red-100'
  | 'focus:bg-red-100'
  | 'group-hover:bg-red-100'
  | 'checked:bg-red-100'
  | 'sm:bg-red-200'
  | 'md:bg-red-200'
  | 'lg:bg-red-200'
  | 'xl:bg-red-200'
  | 'hover:bg-red-200'
  | 'focus:bg-red-200'
  | 'group-hover:bg-red-200'
  | 'checked:bg-red-200'
  | 'sm:bg-red-300'
  | 'md:bg-red-300'
  | 'lg:bg-red-300'
  | 'xl:bg-red-300'
  | 'hover:bg-red-300'
  | 'focus:bg-red-300'
  | 'group-hover:bg-red-300'
  | 'checked:bg-red-300'
  | 'sm:bg-red-400'
  | 'md:bg-red-400'
  | 'lg:bg-red-400'
  | 'xl:bg-red-400'
  | 'hover:bg-red-400'
  | 'focus:bg-red-400'
  | 'group-hover:bg-red-400'
  | 'checked:bg-red-400'
  | 'sm:bg-red-500'
  | 'md:bg-red-500'
  | 'lg:bg-red-500'
  | 'xl:bg-red-500'
  | 'hover:bg-red-500'
  | 'focus:bg-red-500'
  | 'group-hover:bg-red-500'
  | 'checked:bg-red-500'
  | 'sm:bg-red-600'
  | 'md:bg-red-600'
  | 'lg:bg-red-600'
  | 'xl:bg-red-600'
  | 'hover:bg-red-600'
  | 'focus:bg-red-600'
  | 'group-hover:bg-red-600'
  | 'checked:bg-red-600'
  | 'sm:bg-red-700'
  | 'md:bg-red-700'
  | 'lg:bg-red-700'
  | 'xl:bg-red-700'
  | 'hover:bg-red-700'
  | 'focus:bg-red-700'
  | 'group-hover:bg-red-700'
  | 'checked:bg-red-700'
  | 'sm:bg-red-800'
  | 'md:bg-red-800'
  | 'lg:bg-red-800'
  | 'xl:bg-red-800'
  | 'hover:bg-red-800'
  | 'focus:bg-red-800'
  | 'group-hover:bg-red-800'
  | 'checked:bg-red-800'
  | 'sm:bg-red-900'
  | 'md:bg-red-900'
  | 'lg:bg-red-900'
  | 'xl:bg-red-900'
  | 'hover:bg-red-900'
  | 'focus:bg-red-900'
  | 'group-hover:bg-red-900'
  | 'checked:bg-red-900'
  | 'sm:bg-orange-100'
  | 'md:bg-orange-100'
  | 'lg:bg-orange-100'
  | 'xl:bg-orange-100'
  | 'hover:bg-orange-100'
  | 'focus:bg-orange-100'
  | 'group-hover:bg-orange-100'
  | 'checked:bg-orange-100'
  | 'sm:bg-orange-200'
  | 'md:bg-orange-200'
  | 'lg:bg-orange-200'
  | 'xl:bg-orange-200'
  | 'hover:bg-orange-200'
  | 'focus:bg-orange-200'
  | 'group-hover:bg-orange-200'
  | 'checked:bg-orange-200'
  | 'sm:bg-orange-300'
  | 'md:bg-orange-300'
  | 'lg:bg-orange-300'
  | 'xl:bg-orange-300'
  | 'hover:bg-orange-300'
  | 'focus:bg-orange-300'
  | 'group-hover:bg-orange-300'
  | 'checked:bg-orange-300'
  | 'sm:bg-orange-400'
  | 'md:bg-orange-400'
  | 'lg:bg-orange-400'
  | 'xl:bg-orange-400'
  | 'hover:bg-orange-400'
  | 'focus:bg-orange-400'
  | 'group-hover:bg-orange-400'
  | 'checked:bg-orange-400'
  | 'sm:bg-orange-500'
  | 'md:bg-orange-500'
  | 'lg:bg-orange-500'
  | 'xl:bg-orange-500'
  | 'hover:bg-orange-500'
  | 'focus:bg-orange-500'
  | 'group-hover:bg-orange-500'
  | 'checked:bg-orange-500'
  | 'sm:bg-orange-600'
  | 'md:bg-orange-600'
  | 'lg:bg-orange-600'
  | 'xl:bg-orange-600'
  | 'hover:bg-orange-600'
  | 'focus:bg-orange-600'
  | 'group-hover:bg-orange-600'
  | 'checked:bg-orange-600'
  | 'sm:bg-orange-700'
  | 'md:bg-orange-700'
  | 'lg:bg-orange-700'
  | 'xl:bg-orange-700'
  | 'hover:bg-orange-700'
  | 'focus:bg-orange-700'
  | 'group-hover:bg-orange-700'
  | 'checked:bg-orange-700'
  | 'sm:bg-orange-800'
  | 'md:bg-orange-800'
  | 'lg:bg-orange-800'
  | 'xl:bg-orange-800'
  | 'hover:bg-orange-800'
  | 'focus:bg-orange-800'
  | 'group-hover:bg-orange-800'
  | 'checked:bg-orange-800'
  | 'sm:bg-orange-900'
  | 'md:bg-orange-900'
  | 'lg:bg-orange-900'
  | 'xl:bg-orange-900'
  | 'hover:bg-orange-900'
  | 'focus:bg-orange-900'
  | 'group-hover:bg-orange-900'
  | 'checked:bg-orange-900'
  | 'sm:bg-yellow-100'
  | 'md:bg-yellow-100'
  | 'lg:bg-yellow-100'
  | 'xl:bg-yellow-100'
  | 'hover:bg-yellow-100'
  | 'focus:bg-yellow-100'
  | 'group-hover:bg-yellow-100'
  | 'checked:bg-yellow-100'
  | 'sm:bg-yellow-200'
  | 'md:bg-yellow-200'
  | 'lg:bg-yellow-200'
  | 'xl:bg-yellow-200'
  | 'hover:bg-yellow-200'
  | 'focus:bg-yellow-200'
  | 'group-hover:bg-yellow-200'
  | 'checked:bg-yellow-200'
  | 'sm:bg-yellow-300'
  | 'md:bg-yellow-300'
  | 'lg:bg-yellow-300'
  | 'xl:bg-yellow-300'
  | 'hover:bg-yellow-300'
  | 'focus:bg-yellow-300'
  | 'group-hover:bg-yellow-300'
  | 'checked:bg-yellow-300'
  | 'sm:bg-yellow-400'
  | 'md:bg-yellow-400'
  | 'lg:bg-yellow-400'
  | 'xl:bg-yellow-400'
  | 'hover:bg-yellow-400'
  | 'focus:bg-yellow-400'
  | 'group-hover:bg-yellow-400'
  | 'checked:bg-yellow-400'
  | 'sm:bg-yellow-500'
  | 'md:bg-yellow-500'
  | 'lg:bg-yellow-500'
  | 'xl:bg-yellow-500'
  | 'hover:bg-yellow-500'
  | 'focus:bg-yellow-500'
  | 'group-hover:bg-yellow-500'
  | 'checked:bg-yellow-500'
  | 'sm:bg-yellow-600'
  | 'md:bg-yellow-600'
  | 'lg:bg-yellow-600'
  | 'xl:bg-yellow-600'
  | 'hover:bg-yellow-600'
  | 'focus:bg-yellow-600'
  | 'group-hover:bg-yellow-600'
  | 'checked:bg-yellow-600'
  | 'sm:bg-yellow-700'
  | 'md:bg-yellow-700'
  | 'lg:bg-yellow-700'
  | 'xl:bg-yellow-700'
  | 'hover:bg-yellow-700'
  | 'focus:bg-yellow-700'
  | 'group-hover:bg-yellow-700'
  | 'checked:bg-yellow-700'
  | 'sm:bg-yellow-800'
  | 'md:bg-yellow-800'
  | 'lg:bg-yellow-800'
  | 'xl:bg-yellow-800'
  | 'hover:bg-yellow-800'
  | 'focus:bg-yellow-800'
  | 'group-hover:bg-yellow-800'
  | 'checked:bg-yellow-800'
  | 'sm:bg-yellow-900'
  | 'md:bg-yellow-900'
  | 'lg:bg-yellow-900'
  | 'xl:bg-yellow-900'
  | 'hover:bg-yellow-900'
  | 'focus:bg-yellow-900'
  | 'group-hover:bg-yellow-900'
  | 'checked:bg-yellow-900'
  | 'sm:bg-green-100'
  | 'md:bg-green-100'
  | 'lg:bg-green-100'
  | 'xl:bg-green-100'
  | 'hover:bg-green-100'
  | 'focus:bg-green-100'
  | 'group-hover:bg-green-100'
  | 'checked:bg-green-100'
  | 'sm:bg-green-200'
  | 'md:bg-green-200'
  | 'lg:bg-green-200'
  | 'xl:bg-green-200'
  | 'hover:bg-green-200'
  | 'focus:bg-green-200'
  | 'group-hover:bg-green-200'
  | 'checked:bg-green-200'
  | 'sm:bg-green-300'
  | 'md:bg-green-300'
  | 'lg:bg-green-300'
  | 'xl:bg-green-300'
  | 'hover:bg-green-300'
  | 'focus:bg-green-300'
  | 'group-hover:bg-green-300'
  | 'checked:bg-green-300'
  | 'sm:bg-green-400'
  | 'md:bg-green-400'
  | 'lg:bg-green-400'
  | 'xl:bg-green-400'
  | 'hover:bg-green-400'
  | 'focus:bg-green-400'
  | 'group-hover:bg-green-400'
  | 'checked:bg-green-400'
  | 'sm:bg-green-500'
  | 'md:bg-green-500'
  | 'lg:bg-green-500'
  | 'xl:bg-green-500'
  | 'hover:bg-green-500'
  | 'focus:bg-green-500'
  | 'group-hover:bg-green-500'
  | 'checked:bg-green-500'
  | 'sm:bg-green-600'
  | 'md:bg-green-600'
  | 'lg:bg-green-600'
  | 'xl:bg-green-600'
  | 'hover:bg-green-600'
  | 'focus:bg-green-600'
  | 'group-hover:bg-green-600'
  | 'checked:bg-green-600'
  | 'sm:bg-green-700'
  | 'md:bg-green-700'
  | 'lg:bg-green-700'
  | 'xl:bg-green-700'
  | 'hover:bg-green-700'
  | 'focus:bg-green-700'
  | 'group-hover:bg-green-700'
  | 'checked:bg-green-700'
  | 'sm:bg-green-800'
  | 'md:bg-green-800'
  | 'lg:bg-green-800'
  | 'xl:bg-green-800'
  | 'hover:bg-green-800'
  | 'focus:bg-green-800'
  | 'group-hover:bg-green-800'
  | 'checked:bg-green-800'
  | 'sm:bg-green-900'
  | 'md:bg-green-900'
  | 'lg:bg-green-900'
  | 'xl:bg-green-900'
  | 'hover:bg-green-900'
  | 'focus:bg-green-900'
  | 'group-hover:bg-green-900'
  | 'checked:bg-green-900'
  | 'sm:bg-teal-100'
  | 'md:bg-teal-100'
  | 'lg:bg-teal-100'
  | 'xl:bg-teal-100'
  | 'hover:bg-teal-100'
  | 'focus:bg-teal-100'
  | 'group-hover:bg-teal-100'
  | 'checked:bg-teal-100'
  | 'sm:bg-teal-200'
  | 'md:bg-teal-200'
  | 'lg:bg-teal-200'
  | 'xl:bg-teal-200'
  | 'hover:bg-teal-200'
  | 'focus:bg-teal-200'
  | 'group-hover:bg-teal-200'
  | 'checked:bg-teal-200'
  | 'sm:bg-teal-300'
  | 'md:bg-teal-300'
  | 'lg:bg-teal-300'
  | 'xl:bg-teal-300'
  | 'hover:bg-teal-300'
  | 'focus:bg-teal-300'
  | 'group-hover:bg-teal-300'
  | 'checked:bg-teal-300'
  | 'sm:bg-teal-400'
  | 'md:bg-teal-400'
  | 'lg:bg-teal-400'
  | 'xl:bg-teal-400'
  | 'hover:bg-teal-400'
  | 'focus:bg-teal-400'
  | 'group-hover:bg-teal-400'
  | 'checked:bg-teal-400'
  | 'sm:bg-teal-500'
  | 'md:bg-teal-500'
  | 'lg:bg-teal-500'
  | 'xl:bg-teal-500'
  | 'hover:bg-teal-500'
  | 'focus:bg-teal-500'
  | 'group-hover:bg-teal-500'
  | 'checked:bg-teal-500'
  | 'sm:bg-teal-600'
  | 'md:bg-teal-600'
  | 'lg:bg-teal-600'
  | 'xl:bg-teal-600'
  | 'hover:bg-teal-600'
  | 'focus:bg-teal-600'
  | 'group-hover:bg-teal-600'
  | 'checked:bg-teal-600'
  | 'sm:bg-teal-700'
  | 'md:bg-teal-700'
  | 'lg:bg-teal-700'
  | 'xl:bg-teal-700'
  | 'hover:bg-teal-700'
  | 'focus:bg-teal-700'
  | 'group-hover:bg-teal-700'
  | 'checked:bg-teal-700'
  | 'sm:bg-teal-800'
  | 'md:bg-teal-800'
  | 'lg:bg-teal-800'
  | 'xl:bg-teal-800'
  | 'hover:bg-teal-800'
  | 'focus:bg-teal-800'
  | 'group-hover:bg-teal-800'
  | 'checked:bg-teal-800'
  | 'sm:bg-teal-900'
  | 'md:bg-teal-900'
  | 'lg:bg-teal-900'
  | 'xl:bg-teal-900'
  | 'hover:bg-teal-900'
  | 'focus:bg-teal-900'
  | 'group-hover:bg-teal-900'
  | 'checked:bg-teal-900'
  | 'sm:bg-blue-100'
  | 'md:bg-blue-100'
  | 'lg:bg-blue-100'
  | 'xl:bg-blue-100'
  | 'hover:bg-blue-100'
  | 'focus:bg-blue-100'
  | 'group-hover:bg-blue-100'
  | 'checked:bg-blue-100'
  | 'sm:bg-blue-200'
  | 'md:bg-blue-200'
  | 'lg:bg-blue-200'
  | 'xl:bg-blue-200'
  | 'hover:bg-blue-200'
  | 'focus:bg-blue-200'
  | 'group-hover:bg-blue-200'
  | 'checked:bg-blue-200'
  | 'sm:bg-blue-300'
  | 'md:bg-blue-300'
  | 'lg:bg-blue-300'
  | 'xl:bg-blue-300'
  | 'hover:bg-blue-300'
  | 'focus:bg-blue-300'
  | 'group-hover:bg-blue-300'
  | 'checked:bg-blue-300'
  | 'sm:bg-blue-400'
  | 'md:bg-blue-400'
  | 'lg:bg-blue-400'
  | 'xl:bg-blue-400'
  | 'hover:bg-blue-400'
  | 'focus:bg-blue-400'
  | 'group-hover:bg-blue-400'
  | 'checked:bg-blue-400'
  | 'sm:bg-blue-500'
  | 'md:bg-blue-500'
  | 'lg:bg-blue-500'
  | 'xl:bg-blue-500'
  | 'hover:bg-blue-500'
  | 'focus:bg-blue-500'
  | 'group-hover:bg-blue-500'
  | 'checked:bg-blue-500'
  | 'sm:bg-blue-600'
  | 'md:bg-blue-600'
  | 'lg:bg-blue-600'
  | 'xl:bg-blue-600'
  | 'hover:bg-blue-600'
  | 'focus:bg-blue-600'
  | 'group-hover:bg-blue-600'
  | 'checked:bg-blue-600'
  | 'sm:bg-blue-700'
  | 'md:bg-blue-700'
  | 'lg:bg-blue-700'
  | 'xl:bg-blue-700'
  | 'hover:bg-blue-700'
  | 'focus:bg-blue-700'
  | 'group-hover:bg-blue-700'
  | 'checked:bg-blue-700'
  | 'sm:bg-blue-800'
  | 'md:bg-blue-800'
  | 'lg:bg-blue-800'
  | 'xl:bg-blue-800'
  | 'hover:bg-blue-800'
  | 'focus:bg-blue-800'
  | 'group-hover:bg-blue-800'
  | 'checked:bg-blue-800'
  | 'sm:bg-blue-900'
  | 'md:bg-blue-900'
  | 'lg:bg-blue-900'
  | 'xl:bg-blue-900'
  | 'hover:bg-blue-900'
  | 'focus:bg-blue-900'
  | 'group-hover:bg-blue-900'
  | 'checked:bg-blue-900'
  | 'sm:bg-indigo-100'
  | 'md:bg-indigo-100'
  | 'lg:bg-indigo-100'
  | 'xl:bg-indigo-100'
  | 'hover:bg-indigo-100'
  | 'focus:bg-indigo-100'
  | 'group-hover:bg-indigo-100'
  | 'checked:bg-indigo-100'
  | 'sm:bg-indigo-200'
  | 'md:bg-indigo-200'
  | 'lg:bg-indigo-200'
  | 'xl:bg-indigo-200'
  | 'hover:bg-indigo-200'
  | 'focus:bg-indigo-200'
  | 'group-hover:bg-indigo-200'
  | 'checked:bg-indigo-200'
  | 'sm:bg-indigo-300'
  | 'md:bg-indigo-300'
  | 'lg:bg-indigo-300'
  | 'xl:bg-indigo-300'
  | 'hover:bg-indigo-300'
  | 'focus:bg-indigo-300'
  | 'group-hover:bg-indigo-300'
  | 'checked:bg-indigo-300'
  | 'sm:bg-indigo-400'
  | 'md:bg-indigo-400'
  | 'lg:bg-indigo-400'
  | 'xl:bg-indigo-400'
  | 'hover:bg-indigo-400'
  | 'focus:bg-indigo-400'
  | 'group-hover:bg-indigo-400'
  | 'checked:bg-indigo-400'
  | 'sm:bg-indigo-500'
  | 'md:bg-indigo-500'
  | 'lg:bg-indigo-500'
  | 'xl:bg-indigo-500'
  | 'hover:bg-indigo-500'
  | 'focus:bg-indigo-500'
  | 'group-hover:bg-indigo-500'
  | 'checked:bg-indigo-500'
  | 'sm:bg-indigo-600'
  | 'md:bg-indigo-600'
  | 'lg:bg-indigo-600'
  | 'xl:bg-indigo-600'
  | 'hover:bg-indigo-600'
  | 'focus:bg-indigo-600'
  | 'group-hover:bg-indigo-600'
  | 'checked:bg-indigo-600'
  | 'sm:bg-indigo-700'
  | 'md:bg-indigo-700'
  | 'lg:bg-indigo-700'
  | 'xl:bg-indigo-700'
  | 'hover:bg-indigo-700'
  | 'focus:bg-indigo-700'
  | 'group-hover:bg-indigo-700'
  | 'checked:bg-indigo-700'
  | 'sm:bg-indigo-800'
  | 'md:bg-indigo-800'
  | 'lg:bg-indigo-800'
  | 'xl:bg-indigo-800'
  | 'hover:bg-indigo-800'
  | 'focus:bg-indigo-800'
  | 'group-hover:bg-indigo-800'
  | 'checked:bg-indigo-800'
  | 'sm:bg-indigo-900'
  | 'md:bg-indigo-900'
  | 'lg:bg-indigo-900'
  | 'xl:bg-indigo-900'
  | 'hover:bg-indigo-900'
  | 'focus:bg-indigo-900'
  | 'group-hover:bg-indigo-900'
  | 'checked:bg-indigo-900'
  | 'sm:bg-purple-100'
  | 'md:bg-purple-100'
  | 'lg:bg-purple-100'
  | 'xl:bg-purple-100'
  | 'hover:bg-purple-100'
  | 'focus:bg-purple-100'
  | 'group-hover:bg-purple-100'
  | 'checked:bg-purple-100'
  | 'sm:bg-purple-200'
  | 'md:bg-purple-200'
  | 'lg:bg-purple-200'
  | 'xl:bg-purple-200'
  | 'hover:bg-purple-200'
  | 'focus:bg-purple-200'
  | 'group-hover:bg-purple-200'
  | 'checked:bg-purple-200'
  | 'sm:bg-purple-300'
  | 'md:bg-purple-300'
  | 'lg:bg-purple-300'
  | 'xl:bg-purple-300'
  | 'hover:bg-purple-300'
  | 'focus:bg-purple-300'
  | 'group-hover:bg-purple-300'
  | 'checked:bg-purple-300'
  | 'sm:bg-purple-400'
  | 'md:bg-purple-400'
  | 'lg:bg-purple-400'
  | 'xl:bg-purple-400'
  | 'hover:bg-purple-400'
  | 'focus:bg-purple-400'
  | 'group-hover:bg-purple-400'
  | 'checked:bg-purple-400'
  | 'sm:bg-purple-500'
  | 'md:bg-purple-500'
  | 'lg:bg-purple-500'
  | 'xl:bg-purple-500'
  | 'hover:bg-purple-500'
  | 'focus:bg-purple-500'
  | 'group-hover:bg-purple-500'
  | 'checked:bg-purple-500'
  | 'sm:bg-purple-600'
  | 'md:bg-purple-600'
  | 'lg:bg-purple-600'
  | 'xl:bg-purple-600'
  | 'hover:bg-purple-600'
  | 'focus:bg-purple-600'
  | 'group-hover:bg-purple-600'
  | 'checked:bg-purple-600'
  | 'sm:bg-purple-700'
  | 'md:bg-purple-700'
  | 'lg:bg-purple-700'
  | 'xl:bg-purple-700'
  | 'hover:bg-purple-700'
  | 'focus:bg-purple-700'
  | 'group-hover:bg-purple-700'
  | 'checked:bg-purple-700'
  | 'sm:bg-purple-800'
  | 'md:bg-purple-800'
  | 'lg:bg-purple-800'
  | 'xl:bg-purple-800'
  | 'hover:bg-purple-800'
  | 'focus:bg-purple-800'
  | 'group-hover:bg-purple-800'
  | 'checked:bg-purple-800'
  | 'sm:bg-purple-900'
  | 'md:bg-purple-900'
  | 'lg:bg-purple-900'
  | 'xl:bg-purple-900'
  | 'hover:bg-purple-900'
  | 'focus:bg-purple-900'
  | 'group-hover:bg-purple-900'
  | 'checked:bg-purple-900'
  | 'sm:bg-pink-100'
  | 'md:bg-pink-100'
  | 'lg:bg-pink-100'
  | 'xl:bg-pink-100'
  | 'hover:bg-pink-100'
  | 'focus:bg-pink-100'
  | 'group-hover:bg-pink-100'
  | 'checked:bg-pink-100'
  | 'sm:bg-pink-200'
  | 'md:bg-pink-200'
  | 'lg:bg-pink-200'
  | 'xl:bg-pink-200'
  | 'hover:bg-pink-200'
  | 'focus:bg-pink-200'
  | 'group-hover:bg-pink-200'
  | 'checked:bg-pink-200'
  | 'sm:bg-pink-300'
  | 'md:bg-pink-300'
  | 'lg:bg-pink-300'
  | 'xl:bg-pink-300'
  | 'hover:bg-pink-300'
  | 'focus:bg-pink-300'
  | 'group-hover:bg-pink-300'
  | 'checked:bg-pink-300'
  | 'sm:bg-pink-400'
  | 'md:bg-pink-400'
  | 'lg:bg-pink-400'
  | 'xl:bg-pink-400'
  | 'hover:bg-pink-400'
  | 'focus:bg-pink-400'
  | 'group-hover:bg-pink-400'
  | 'checked:bg-pink-400'
  | 'sm:bg-pink-500'
  | 'md:bg-pink-500'
  | 'lg:bg-pink-500'
  | 'xl:bg-pink-500'
  | 'hover:bg-pink-500'
  | 'focus:bg-pink-500'
  | 'group-hover:bg-pink-500'
  | 'checked:bg-pink-500'
  | 'sm:bg-pink-600'
  | 'md:bg-pink-600'
  | 'lg:bg-pink-600'
  | 'xl:bg-pink-600'
  | 'hover:bg-pink-600'
  | 'focus:bg-pink-600'
  | 'group-hover:bg-pink-600'
  | 'checked:bg-pink-600'
  | 'sm:bg-pink-700'
  | 'md:bg-pink-700'
  | 'lg:bg-pink-700'
  | 'xl:bg-pink-700'
  | 'hover:bg-pink-700'
  | 'focus:bg-pink-700'
  | 'group-hover:bg-pink-700'
  | 'checked:bg-pink-700'
  | 'sm:bg-pink-800'
  | 'md:bg-pink-800'
  | 'lg:bg-pink-800'
  | 'xl:bg-pink-800'
  | 'hover:bg-pink-800'
  | 'focus:bg-pink-800'
  | 'group-hover:bg-pink-800'
  | 'checked:bg-pink-800'
  | 'sm:bg-pink-900'
  | 'md:bg-pink-900'
  | 'lg:bg-pink-900'
  | 'xl:bg-pink-900'
  | 'hover:bg-pink-900'
  | 'focus:bg-pink-900'
  | 'group-hover:bg-pink-900'
  | 'checked:bg-pink-900'
  | 'sm:bg-primary-light'
  | 'md:bg-primary-light'
  | 'lg:bg-primary-light'
  | 'xl:bg-primary-light'
  | 'hover:bg-primary-light'
  | 'focus:bg-primary-light'
  | 'group-hover:bg-primary-light'
  | 'checked:bg-primary-light'
  | 'sm:bg-primary'
  | 'md:bg-primary'
  | 'lg:bg-primary'
  | 'xl:bg-primary'
  | 'hover:bg-primary'
  | 'focus:bg-primary'
  | 'group-hover:bg-primary'
  | 'checked:bg-primary'
  | 'sm:bg-primary-dark'
  | 'md:bg-primary-dark'
  | 'lg:bg-primary-dark'
  | 'xl:bg-primary-dark'
  | 'hover:bg-primary-dark'
  | 'focus:bg-primary-dark'
  | 'group-hover:bg-primary-dark'
  | 'checked:bg-primary-dark'
  | 'sm:bg-accent-dark'
  | 'md:bg-accent-dark'
  | 'lg:bg-accent-dark'
  | 'xl:bg-accent-dark'
  | 'hover:bg-accent-dark'
  | 'focus:bg-accent-dark'
  | 'group-hover:bg-accent-dark'
  | 'checked:bg-accent-dark'
  | 'sm:bg-accent'
  | 'md:bg-accent'
  | 'lg:bg-accent'
  | 'xl:bg-accent'
  | 'hover:bg-accent'
  | 'focus:bg-accent'
  | 'group-hover:bg-accent'
  | 'checked:bg-accent'
  | 'sm:bg-accent-light'
  | 'md:bg-accent-light'
  | 'lg:bg-accent-light'
  | 'xl:bg-accent-light'
  | 'hover:bg-accent-light'
  | 'focus:bg-accent-light'
  | 'group-hover:bg-accent-light'
  | 'checked:bg-accent-light'
  | 'sm:bg-warning-dark'
  | 'md:bg-warning-dark'
  | 'lg:bg-warning-dark'
  | 'xl:bg-warning-dark'
  | 'hover:bg-warning-dark'
  | 'focus:bg-warning-dark'
  | 'group-hover:bg-warning-dark'
  | 'checked:bg-warning-dark'
  | 'sm:bg-warning'
  | 'md:bg-warning'
  | 'lg:bg-warning'
  | 'xl:bg-warning'
  | 'hover:bg-warning'
  | 'focus:bg-warning'
  | 'group-hover:bg-warning'
  | 'checked:bg-warning'
  | 'sm:bg-warning-light'
  | 'md:bg-warning-light'
  | 'lg:bg-warning-light'
  | 'xl:bg-warning-light'
  | 'hover:bg-warning-light'
  | 'focus:bg-warning-light'
  | 'group-hover:bg-warning-light'
  | 'checked:bg-warning-light'
  | 'sm:bg-dark'
  | 'md:bg-dark'
  | 'lg:bg-dark'
  | 'xl:bg-dark'
  | 'hover:bg-dark'
  | 'focus:bg-dark'
  | 'group-hover:bg-dark'
  | 'checked:bg-dark'
  | 'sm:bg-dark-secondary'
  | 'md:bg-dark-secondary'
  | 'lg:bg-dark-secondary'
  | 'xl:bg-dark-secondary'
  | 'hover:bg-dark-secondary'
  | 'focus:bg-dark-secondary'
  | 'group-hover:bg-dark-secondary'
  | 'checked:bg-dark-secondary'
  | 'sm:bg-light'
  | 'md:bg-light'
  | 'lg:bg-light'
  | 'xl:bg-light'
  | 'hover:bg-light'
  | 'focus:bg-light'
  | 'group-hover:bg-light'
  | 'checked:bg-light'
  | 'sm:bg-light-secondary'
  | 'md:bg-light-secondary'
  | 'lg:bg-light-secondary'
  | 'xl:bg-light-secondary'
  | 'hover:bg-light-secondary'
  | 'focus:bg-light-secondary'
  | 'group-hover:bg-light-secondary'
  | 'checked:bg-light-secondary'
  | 'sm:bg-light-hint'
  | 'md:bg-light-hint'
  | 'lg:bg-light-hint'
  | 'xl:bg-light-hint'
  | 'hover:bg-light-hint'
  | 'focus:bg-light-hint'
  | 'group-hover:bg-light-hint'
  | 'checked:bg-light-hint'
  | 'sm:bg-none'
  | 'md:bg-none'
  | 'lg:bg-none'
  | 'xl:bg-none'
  | 'sm:bg-gradient-to-t'
  | 'md:bg-gradient-to-t'
  | 'lg:bg-gradient-to-t'
  | 'xl:bg-gradient-to-t'
  | 'sm:bg-gradient-to-tr'
  | 'md:bg-gradient-to-tr'
  | 'lg:bg-gradient-to-tr'
  | 'xl:bg-gradient-to-tr'
  | 'sm:bg-gradient-to-r'
  | 'md:bg-gradient-to-r'
  | 'lg:bg-gradient-to-r'
  | 'xl:bg-gradient-to-r'
  | 'sm:bg-gradient-to-br'
  | 'md:bg-gradient-to-br'
  | 'lg:bg-gradient-to-br'
  | 'xl:bg-gradient-to-br'
  | 'sm:bg-gradient-to-b'
  | 'md:bg-gradient-to-b'
  | 'lg:bg-gradient-to-b'
  | 'xl:bg-gradient-to-b'
  | 'sm:bg-gradient-to-bl'
  | 'md:bg-gradient-to-bl'
  | 'lg:bg-gradient-to-bl'
  | 'xl:bg-gradient-to-bl'
  | 'sm:bg-gradient-to-l'
  | 'md:bg-gradient-to-l'
  | 'lg:bg-gradient-to-l'
  | 'xl:bg-gradient-to-l'
  | 'sm:bg-gradient-to-tl'
  | 'md:bg-gradient-to-tl'
  | 'lg:bg-gradient-to-tl'
  | 'xl:bg-gradient-to-tl'
  | 'sm:from-transparent'
  | 'md:from-transparent'
  | 'lg:from-transparent'
  | 'xl:from-transparent'
  | 'hover:from-transparent'
  | 'focus:from-transparent'
  | 'sm:via-transparent'
  | 'md:via-transparent'
  | 'lg:via-transparent'
  | 'xl:via-transparent'
  | 'hover:via-transparent'
  | 'focus:via-transparent'
  | 'sm:to-transparent'
  | 'md:to-transparent'
  | 'lg:to-transparent'
  | 'xl:to-transparent'
  | 'hover:to-transparent'
  | 'focus:to-transparent'
  | 'sm:from-current'
  | 'md:from-current'
  | 'lg:from-current'
  | 'xl:from-current'
  | 'hover:from-current'
  | 'focus:from-current'
  | 'sm:via-current'
  | 'md:via-current'
  | 'lg:via-current'
  | 'xl:via-current'
  | 'hover:via-current'
  | 'focus:via-current'
  | 'sm:to-current'
  | 'md:to-current'
  | 'lg:to-current'
  | 'xl:to-current'
  | 'hover:to-current'
  | 'focus:to-current'
  | 'sm:from-black'
  | 'md:from-black'
  | 'lg:from-black'
  | 'xl:from-black'
  | 'hover:from-black'
  | 'focus:from-black'
  | 'sm:via-black'
  | 'md:via-black'
  | 'lg:via-black'
  | 'xl:via-black'
  | 'hover:via-black'
  | 'focus:via-black'
  | 'sm:to-black'
  | 'md:to-black'
  | 'lg:to-black'
  | 'xl:to-black'
  | 'hover:to-black'
  | 'focus:to-black'
  | 'sm:from-white'
  | 'md:from-white'
  | 'lg:from-white'
  | 'xl:from-white'
  | 'hover:from-white'
  | 'focus:from-white'
  | 'sm:via-white'
  | 'md:via-white'
  | 'lg:via-white'
  | 'xl:via-white'
  | 'hover:via-white'
  | 'focus:via-white'
  | 'sm:to-white'
  | 'md:to-white'
  | 'lg:to-white'
  | 'xl:to-white'
  | 'hover:to-white'
  | 'focus:to-white'
  | 'sm:from-gray-100'
  | 'md:from-gray-100'
  | 'lg:from-gray-100'
  | 'xl:from-gray-100'
  | 'hover:from-gray-100'
  | 'focus:from-gray-100'
  | 'sm:via-gray-100'
  | 'md:via-gray-100'
  | 'lg:via-gray-100'
  | 'xl:via-gray-100'
  | 'hover:via-gray-100'
  | 'focus:via-gray-100'
  | 'sm:to-gray-100'
  | 'md:to-gray-100'
  | 'lg:to-gray-100'
  | 'xl:to-gray-100'
  | 'hover:to-gray-100'
  | 'focus:to-gray-100'
  | 'sm:from-gray-200'
  | 'md:from-gray-200'
  | 'lg:from-gray-200'
  | 'xl:from-gray-200'
  | 'hover:from-gray-200'
  | 'focus:from-gray-200'
  | 'sm:via-gray-200'
  | 'md:via-gray-200'
  | 'lg:via-gray-200'
  | 'xl:via-gray-200'
  | 'hover:via-gray-200'
  | 'focus:via-gray-200'
  | 'sm:to-gray-200'
  | 'md:to-gray-200'
  | 'lg:to-gray-200'
  | 'xl:to-gray-200'
  | 'hover:to-gray-200'
  | 'focus:to-gray-200'
  | 'sm:from-gray-300'
  | 'md:from-gray-300'
  | 'lg:from-gray-300'
  | 'xl:from-gray-300'
  | 'hover:from-gray-300'
  | 'focus:from-gray-300'
  | 'sm:via-gray-300'
  | 'md:via-gray-300'
  | 'lg:via-gray-300'
  | 'xl:via-gray-300'
  | 'hover:via-gray-300'
  | 'focus:via-gray-300'
  | 'sm:to-gray-300'
  | 'md:to-gray-300'
  | 'lg:to-gray-300'
  | 'xl:to-gray-300'
  | 'hover:to-gray-300'
  | 'focus:to-gray-300'
  | 'sm:from-gray-400'
  | 'md:from-gray-400'
  | 'lg:from-gray-400'
  | 'xl:from-gray-400'
  | 'hover:from-gray-400'
  | 'focus:from-gray-400'
  | 'sm:via-gray-400'
  | 'md:via-gray-400'
  | 'lg:via-gray-400'
  | 'xl:via-gray-400'
  | 'hover:via-gray-400'
  | 'focus:via-gray-400'
  | 'sm:to-gray-400'
  | 'md:to-gray-400'
  | 'lg:to-gray-400'
  | 'xl:to-gray-400'
  | 'hover:to-gray-400'
  | 'focus:to-gray-400'
  | 'sm:from-gray-500'
  | 'md:from-gray-500'
  | 'lg:from-gray-500'
  | 'xl:from-gray-500'
  | 'hover:from-gray-500'
  | 'focus:from-gray-500'
  | 'sm:via-gray-500'
  | 'md:via-gray-500'
  | 'lg:via-gray-500'
  | 'xl:via-gray-500'
  | 'hover:via-gray-500'
  | 'focus:via-gray-500'
  | 'sm:to-gray-500'
  | 'md:to-gray-500'
  | 'lg:to-gray-500'
  | 'xl:to-gray-500'
  | 'hover:to-gray-500'
  | 'focus:to-gray-500'
  | 'sm:from-gray-600'
  | 'md:from-gray-600'
  | 'lg:from-gray-600'
  | 'xl:from-gray-600'
  | 'hover:from-gray-600'
  | 'focus:from-gray-600'
  | 'sm:via-gray-600'
  | 'md:via-gray-600'
  | 'lg:via-gray-600'
  | 'xl:via-gray-600'
  | 'hover:via-gray-600'
  | 'focus:via-gray-600'
  | 'sm:to-gray-600'
  | 'md:to-gray-600'
  | 'lg:to-gray-600'
  | 'xl:to-gray-600'
  | 'hover:to-gray-600'
  | 'focus:to-gray-600'
  | 'sm:from-gray-700'
  | 'md:from-gray-700'
  | 'lg:from-gray-700'
  | 'xl:from-gray-700'
  | 'hover:from-gray-700'
  | 'focus:from-gray-700'
  | 'sm:via-gray-700'
  | 'md:via-gray-700'
  | 'lg:via-gray-700'
  | 'xl:via-gray-700'
  | 'hover:via-gray-700'
  | 'focus:via-gray-700'
  | 'sm:to-gray-700'
  | 'md:to-gray-700'
  | 'lg:to-gray-700'
  | 'xl:to-gray-700'
  | 'hover:to-gray-700'
  | 'focus:to-gray-700'
  | 'sm:from-gray-800'
  | 'md:from-gray-800'
  | 'lg:from-gray-800'
  | 'xl:from-gray-800'
  | 'hover:from-gray-800'
  | 'focus:from-gray-800'
  | 'sm:via-gray-800'
  | 'md:via-gray-800'
  | 'lg:via-gray-800'
  | 'xl:via-gray-800'
  | 'hover:via-gray-800'
  | 'focus:via-gray-800'
  | 'sm:to-gray-800'
  | 'md:to-gray-800'
  | 'lg:to-gray-800'
  | 'xl:to-gray-800'
  | 'hover:to-gray-800'
  | 'focus:to-gray-800'
  | 'sm:from-gray-900'
  | 'md:from-gray-900'
  | 'lg:from-gray-900'
  | 'xl:from-gray-900'
  | 'hover:from-gray-900'
  | 'focus:from-gray-900'
  | 'sm:via-gray-900'
  | 'md:via-gray-900'
  | 'lg:via-gray-900'
  | 'xl:via-gray-900'
  | 'hover:via-gray-900'
  | 'focus:via-gray-900'
  | 'sm:to-gray-900'
  | 'md:to-gray-900'
  | 'lg:to-gray-900'
  | 'xl:to-gray-900'
  | 'hover:to-gray-900'
  | 'focus:to-gray-900'
  | 'sm:from-red-100'
  | 'md:from-red-100'
  | 'lg:from-red-100'
  | 'xl:from-red-100'
  | 'hover:from-red-100'
  | 'focus:from-red-100'
  | 'sm:via-red-100'
  | 'md:via-red-100'
  | 'lg:via-red-100'
  | 'xl:via-red-100'
  | 'hover:via-red-100'
  | 'focus:via-red-100'
  | 'sm:to-red-100'
  | 'md:to-red-100'
  | 'lg:to-red-100'
  | 'xl:to-red-100'
  | 'hover:to-red-100'
  | 'focus:to-red-100'
  | 'sm:from-red-200'
  | 'md:from-red-200'
  | 'lg:from-red-200'
  | 'xl:from-red-200'
  | 'hover:from-red-200'
  | 'focus:from-red-200'
  | 'sm:via-red-200'
  | 'md:via-red-200'
  | 'lg:via-red-200'
  | 'xl:via-red-200'
  | 'hover:via-red-200'
  | 'focus:via-red-200'
  | 'sm:to-red-200'
  | 'md:to-red-200'
  | 'lg:to-red-200'
  | 'xl:to-red-200'
  | 'hover:to-red-200'
  | 'focus:to-red-200'
  | 'sm:from-red-300'
  | 'md:from-red-300'
  | 'lg:from-red-300'
  | 'xl:from-red-300'
  | 'hover:from-red-300'
  | 'focus:from-red-300'
  | 'sm:via-red-300'
  | 'md:via-red-300'
  | 'lg:via-red-300'
  | 'xl:via-red-300'
  | 'hover:via-red-300'
  | 'focus:via-red-300'
  | 'sm:to-red-300'
  | 'md:to-red-300'
  | 'lg:to-red-300'
  | 'xl:to-red-300'
  | 'hover:to-red-300'
  | 'focus:to-red-300'
  | 'sm:from-red-400'
  | 'md:from-red-400'
  | 'lg:from-red-400'
  | 'xl:from-red-400'
  | 'hover:from-red-400'
  | 'focus:from-red-400'
  | 'sm:via-red-400'
  | 'md:via-red-400'
  | 'lg:via-red-400'
  | 'xl:via-red-400'
  | 'hover:via-red-400'
  | 'focus:via-red-400'
  | 'sm:to-red-400'
  | 'md:to-red-400'
  | 'lg:to-red-400'
  | 'xl:to-red-400'
  | 'hover:to-red-400'
  | 'focus:to-red-400'
  | 'sm:from-red-500'
  | 'md:from-red-500'
  | 'lg:from-red-500'
  | 'xl:from-red-500'
  | 'hover:from-red-500'
  | 'focus:from-red-500'
  | 'sm:via-red-500'
  | 'md:via-red-500'
  | 'lg:via-red-500'
  | 'xl:via-red-500'
  | 'hover:via-red-500'
  | 'focus:via-red-500'
  | 'sm:to-red-500'
  | 'md:to-red-500'
  | 'lg:to-red-500'
  | 'xl:to-red-500'
  | 'hover:to-red-500'
  | 'focus:to-red-500'
  | 'sm:from-red-600'
  | 'md:from-red-600'
  | 'lg:from-red-600'
  | 'xl:from-red-600'
  | 'hover:from-red-600'
  | 'focus:from-red-600'
  | 'sm:via-red-600'
  | 'md:via-red-600'
  | 'lg:via-red-600'
  | 'xl:via-red-600'
  | 'hover:via-red-600'
  | 'focus:via-red-600'
  | 'sm:to-red-600'
  | 'md:to-red-600'
  | 'lg:to-red-600'
  | 'xl:to-red-600'
  | 'hover:to-red-600'
  | 'focus:to-red-600'
  | 'sm:from-red-700'
  | 'md:from-red-700'
  | 'lg:from-red-700'
  | 'xl:from-red-700'
  | 'hover:from-red-700'
  | 'focus:from-red-700'
  | 'sm:via-red-700'
  | 'md:via-red-700'
  | 'lg:via-red-700'
  | 'xl:via-red-700'
  | 'hover:via-red-700'
  | 'focus:via-red-700'
  | 'sm:to-red-700'
  | 'md:to-red-700'
  | 'lg:to-red-700'
  | 'xl:to-red-700'
  | 'hover:to-red-700'
  | 'focus:to-red-700'
  | 'sm:from-red-800'
  | 'md:from-red-800'
  | 'lg:from-red-800'
  | 'xl:from-red-800'
  | 'hover:from-red-800'
  | 'focus:from-red-800'
  | 'sm:via-red-800'
  | 'md:via-red-800'
  | 'lg:via-red-800'
  | 'xl:via-red-800'
  | 'hover:via-red-800'
  | 'focus:via-red-800'
  | 'sm:to-red-800'
  | 'md:to-red-800'
  | 'lg:to-red-800'
  | 'xl:to-red-800'
  | 'hover:to-red-800'
  | 'focus:to-red-800'
  | 'sm:from-red-900'
  | 'md:from-red-900'
  | 'lg:from-red-900'
  | 'xl:from-red-900'
  | 'hover:from-red-900'
  | 'focus:from-red-900'
  | 'sm:via-red-900'
  | 'md:via-red-900'
  | 'lg:via-red-900'
  | 'xl:via-red-900'
  | 'hover:via-red-900'
  | 'focus:via-red-900'
  | 'sm:to-red-900'
  | 'md:to-red-900'
  | 'lg:to-red-900'
  | 'xl:to-red-900'
  | 'hover:to-red-900'
  | 'focus:to-red-900'
  | 'sm:from-orange-100'
  | 'md:from-orange-100'
  | 'lg:from-orange-100'
  | 'xl:from-orange-100'
  | 'hover:from-orange-100'
  | 'focus:from-orange-100'
  | 'sm:via-orange-100'
  | 'md:via-orange-100'
  | 'lg:via-orange-100'
  | 'xl:via-orange-100'
  | 'hover:via-orange-100'
  | 'focus:via-orange-100'
  | 'sm:to-orange-100'
  | 'md:to-orange-100'
  | 'lg:to-orange-100'
  | 'xl:to-orange-100'
  | 'hover:to-orange-100'
  | 'focus:to-orange-100'
  | 'sm:from-orange-200'
  | 'md:from-orange-200'
  | 'lg:from-orange-200'
  | 'xl:from-orange-200'
  | 'hover:from-orange-200'
  | 'focus:from-orange-200'
  | 'sm:via-orange-200'
  | 'md:via-orange-200'
  | 'lg:via-orange-200'
  | 'xl:via-orange-200'
  | 'hover:via-orange-200'
  | 'focus:via-orange-200'
  | 'sm:to-orange-200'
  | 'md:to-orange-200'
  | 'lg:to-orange-200'
  | 'xl:to-orange-200'
  | 'hover:to-orange-200'
  | 'focus:to-orange-200'
  | 'sm:from-orange-300'
  | 'md:from-orange-300'
  | 'lg:from-orange-300'
  | 'xl:from-orange-300'
  | 'hover:from-orange-300'
  | 'focus:from-orange-300'
  | 'sm:via-orange-300'
  | 'md:via-orange-300'
  | 'lg:via-orange-300'
  | 'xl:via-orange-300'
  | 'hover:via-orange-300'
  | 'focus:via-orange-300'
  | 'sm:to-orange-300'
  | 'md:to-orange-300'
  | 'lg:to-orange-300'
  | 'xl:to-orange-300'
  | 'hover:to-orange-300'
  | 'focus:to-orange-300'
  | 'sm:from-orange-400'
  | 'md:from-orange-400'
  | 'lg:from-orange-400'
  | 'xl:from-orange-400'
  | 'hover:from-orange-400'
  | 'focus:from-orange-400'
  | 'sm:via-orange-400'
  | 'md:via-orange-400'
  | 'lg:via-orange-400'
  | 'xl:via-orange-400'
  | 'hover:via-orange-400'
  | 'focus:via-orange-400'
  | 'sm:to-orange-400'
  | 'md:to-orange-400'
  | 'lg:to-orange-400'
  | 'xl:to-orange-400'
  | 'hover:to-orange-400'
  | 'focus:to-orange-400'
  | 'sm:from-orange-500'
  | 'md:from-orange-500'
  | 'lg:from-orange-500'
  | 'xl:from-orange-500'
  | 'hover:from-orange-500'
  | 'focus:from-orange-500'
  | 'sm:via-orange-500'
  | 'md:via-orange-500'
  | 'lg:via-orange-500'
  | 'xl:via-orange-500'
  | 'hover:via-orange-500'
  | 'focus:via-orange-500'
  | 'sm:to-orange-500'
  | 'md:to-orange-500'
  | 'lg:to-orange-500'
  | 'xl:to-orange-500'
  | 'hover:to-orange-500'
  | 'focus:to-orange-500'
  | 'sm:from-orange-600'
  | 'md:from-orange-600'
  | 'lg:from-orange-600'
  | 'xl:from-orange-600'
  | 'hover:from-orange-600'
  | 'focus:from-orange-600'
  | 'sm:via-orange-600'
  | 'md:via-orange-600'
  | 'lg:via-orange-600'
  | 'xl:via-orange-600'
  | 'hover:via-orange-600'
  | 'focus:via-orange-600'
  | 'sm:to-orange-600'
  | 'md:to-orange-600'
  | 'lg:to-orange-600'
  | 'xl:to-orange-600'
  | 'hover:to-orange-600'
  | 'focus:to-orange-600'
  | 'sm:from-orange-700'
  | 'md:from-orange-700'
  | 'lg:from-orange-700'
  | 'xl:from-orange-700'
  | 'hover:from-orange-700'
  | 'focus:from-orange-700'
  | 'sm:via-orange-700'
  | 'md:via-orange-700'
  | 'lg:via-orange-700'
  | 'xl:via-orange-700'
  | 'hover:via-orange-700'
  | 'focus:via-orange-700'
  | 'sm:to-orange-700'
  | 'md:to-orange-700'
  | 'lg:to-orange-700'
  | 'xl:to-orange-700'
  | 'hover:to-orange-700'
  | 'focus:to-orange-700'
  | 'sm:from-orange-800'
  | 'md:from-orange-800'
  | 'lg:from-orange-800'
  | 'xl:from-orange-800'
  | 'hover:from-orange-800'
  | 'focus:from-orange-800'
  | 'sm:via-orange-800'
  | 'md:via-orange-800'
  | 'lg:via-orange-800'
  | 'xl:via-orange-800'
  | 'hover:via-orange-800'
  | 'focus:via-orange-800'
  | 'sm:to-orange-800'
  | 'md:to-orange-800'
  | 'lg:to-orange-800'
  | 'xl:to-orange-800'
  | 'hover:to-orange-800'
  | 'focus:to-orange-800'
  | 'sm:from-orange-900'
  | 'md:from-orange-900'
  | 'lg:from-orange-900'
  | 'xl:from-orange-900'
  | 'hover:from-orange-900'
  | 'focus:from-orange-900'
  | 'sm:via-orange-900'
  | 'md:via-orange-900'
  | 'lg:via-orange-900'
  | 'xl:via-orange-900'
  | 'hover:via-orange-900'
  | 'focus:via-orange-900'
  | 'sm:to-orange-900'
  | 'md:to-orange-900'
  | 'lg:to-orange-900'
  | 'xl:to-orange-900'
  | 'hover:to-orange-900'
  | 'focus:to-orange-900'
  | 'sm:from-yellow-100'
  | 'md:from-yellow-100'
  | 'lg:from-yellow-100'
  | 'xl:from-yellow-100'
  | 'hover:from-yellow-100'
  | 'focus:from-yellow-100'
  | 'sm:via-yellow-100'
  | 'md:via-yellow-100'
  | 'lg:via-yellow-100'
  | 'xl:via-yellow-100'
  | 'hover:via-yellow-100'
  | 'focus:via-yellow-100'
  | 'sm:to-yellow-100'
  | 'md:to-yellow-100'
  | 'lg:to-yellow-100'
  | 'xl:to-yellow-100'
  | 'hover:to-yellow-100'
  | 'focus:to-yellow-100'
  | 'sm:from-yellow-200'
  | 'md:from-yellow-200'
  | 'lg:from-yellow-200'
  | 'xl:from-yellow-200'
  | 'hover:from-yellow-200'
  | 'focus:from-yellow-200'
  | 'sm:via-yellow-200'
  | 'md:via-yellow-200'
  | 'lg:via-yellow-200'
  | 'xl:via-yellow-200'
  | 'hover:via-yellow-200'
  | 'focus:via-yellow-200'
  | 'sm:to-yellow-200'
  | 'md:to-yellow-200'
  | 'lg:to-yellow-200'
  | 'xl:to-yellow-200'
  | 'hover:to-yellow-200'
  | 'focus:to-yellow-200'
  | 'sm:from-yellow-300'
  | 'md:from-yellow-300'
  | 'lg:from-yellow-300'
  | 'xl:from-yellow-300'
  | 'hover:from-yellow-300'
  | 'focus:from-yellow-300'
  | 'sm:via-yellow-300'
  | 'md:via-yellow-300'
  | 'lg:via-yellow-300'
  | 'xl:via-yellow-300'
  | 'hover:via-yellow-300'
  | 'focus:via-yellow-300'
  | 'sm:to-yellow-300'
  | 'md:to-yellow-300'
  | 'lg:to-yellow-300'
  | 'xl:to-yellow-300'
  | 'hover:to-yellow-300'
  | 'focus:to-yellow-300'
  | 'sm:from-yellow-400'
  | 'md:from-yellow-400'
  | 'lg:from-yellow-400'
  | 'xl:from-yellow-400'
  | 'hover:from-yellow-400'
  | 'focus:from-yellow-400'
  | 'sm:via-yellow-400'
  | 'md:via-yellow-400'
  | 'lg:via-yellow-400'
  | 'xl:via-yellow-400'
  | 'hover:via-yellow-400'
  | 'focus:via-yellow-400'
  | 'sm:to-yellow-400'
  | 'md:to-yellow-400'
  | 'lg:to-yellow-400'
  | 'xl:to-yellow-400'
  | 'hover:to-yellow-400'
  | 'focus:to-yellow-400'
  | 'sm:from-yellow-500'
  | 'md:from-yellow-500'
  | 'lg:from-yellow-500'
  | 'xl:from-yellow-500'
  | 'hover:from-yellow-500'
  | 'focus:from-yellow-500'
  | 'sm:via-yellow-500'
  | 'md:via-yellow-500'
  | 'lg:via-yellow-500'
  | 'xl:via-yellow-500'
  | 'hover:via-yellow-500'
  | 'focus:via-yellow-500'
  | 'sm:to-yellow-500'
  | 'md:to-yellow-500'
  | 'lg:to-yellow-500'
  | 'xl:to-yellow-500'
  | 'hover:to-yellow-500'
  | 'focus:to-yellow-500'
  | 'sm:from-yellow-600'
  | 'md:from-yellow-600'
  | 'lg:from-yellow-600'
  | 'xl:from-yellow-600'
  | 'hover:from-yellow-600'
  | 'focus:from-yellow-600'
  | 'sm:via-yellow-600'
  | 'md:via-yellow-600'
  | 'lg:via-yellow-600'
  | 'xl:via-yellow-600'
  | 'hover:via-yellow-600'
  | 'focus:via-yellow-600'
  | 'sm:to-yellow-600'
  | 'md:to-yellow-600'
  | 'lg:to-yellow-600'
  | 'xl:to-yellow-600'
  | 'hover:to-yellow-600'
  | 'focus:to-yellow-600'
  | 'sm:from-yellow-700'
  | 'md:from-yellow-700'
  | 'lg:from-yellow-700'
  | 'xl:from-yellow-700'
  | 'hover:from-yellow-700'
  | 'focus:from-yellow-700'
  | 'sm:via-yellow-700'
  | 'md:via-yellow-700'
  | 'lg:via-yellow-700'
  | 'xl:via-yellow-700'
  | 'hover:via-yellow-700'
  | 'focus:via-yellow-700'
  | 'sm:to-yellow-700'
  | 'md:to-yellow-700'
  | 'lg:to-yellow-700'
  | 'xl:to-yellow-700'
  | 'hover:to-yellow-700'
  | 'focus:to-yellow-700'
  | 'sm:from-yellow-800'
  | 'md:from-yellow-800'
  | 'lg:from-yellow-800'
  | 'xl:from-yellow-800'
  | 'hover:from-yellow-800'
  | 'focus:from-yellow-800'
  | 'sm:via-yellow-800'
  | 'md:via-yellow-800'
  | 'lg:via-yellow-800'
  | 'xl:via-yellow-800'
  | 'hover:via-yellow-800'
  | 'focus:via-yellow-800'
  | 'sm:to-yellow-800'
  | 'md:to-yellow-800'
  | 'lg:to-yellow-800'
  | 'xl:to-yellow-800'
  | 'hover:to-yellow-800'
  | 'focus:to-yellow-800'
  | 'sm:from-yellow-900'
  | 'md:from-yellow-900'
  | 'lg:from-yellow-900'
  | 'xl:from-yellow-900'
  | 'hover:from-yellow-900'
  | 'focus:from-yellow-900'
  | 'sm:via-yellow-900'
  | 'md:via-yellow-900'
  | 'lg:via-yellow-900'
  | 'xl:via-yellow-900'
  | 'hover:via-yellow-900'
  | 'focus:via-yellow-900'
  | 'sm:to-yellow-900'
  | 'md:to-yellow-900'
  | 'lg:to-yellow-900'
  | 'xl:to-yellow-900'
  | 'hover:to-yellow-900'
  | 'focus:to-yellow-900'
  | 'sm:from-green-100'
  | 'md:from-green-100'
  | 'lg:from-green-100'
  | 'xl:from-green-100'
  | 'hover:from-green-100'
  | 'focus:from-green-100'
  | 'sm:via-green-100'
  | 'md:via-green-100'
  | 'lg:via-green-100'
  | 'xl:via-green-100'
  | 'hover:via-green-100'
  | 'focus:via-green-100'
  | 'sm:to-green-100'
  | 'md:to-green-100'
  | 'lg:to-green-100'
  | 'xl:to-green-100'
  | 'hover:to-green-100'
  | 'focus:to-green-100'
  | 'sm:from-green-200'
  | 'md:from-green-200'
  | 'lg:from-green-200'
  | 'xl:from-green-200'
  | 'hover:from-green-200'
  | 'focus:from-green-200'
  | 'sm:via-green-200'
  | 'md:via-green-200'
  | 'lg:via-green-200'
  | 'xl:via-green-200'
  | 'hover:via-green-200'
  | 'focus:via-green-200'
  | 'sm:to-green-200'
  | 'md:to-green-200'
  | 'lg:to-green-200'
  | 'xl:to-green-200'
  | 'hover:to-green-200'
  | 'focus:to-green-200'
  | 'sm:from-green-300'
  | 'md:from-green-300'
  | 'lg:from-green-300'
  | 'xl:from-green-300'
  | 'hover:from-green-300'
  | 'focus:from-green-300'
  | 'sm:via-green-300'
  | 'md:via-green-300'
  | 'lg:via-green-300'
  | 'xl:via-green-300'
  | 'hover:via-green-300'
  | 'focus:via-green-300'
  | 'sm:to-green-300'
  | 'md:to-green-300'
  | 'lg:to-green-300'
  | 'xl:to-green-300'
  | 'hover:to-green-300'
  | 'focus:to-green-300'
  | 'sm:from-green-400'
  | 'md:from-green-400'
  | 'lg:from-green-400'
  | 'xl:from-green-400'
  | 'hover:from-green-400'
  | 'focus:from-green-400'
  | 'sm:via-green-400'
  | 'md:via-green-400'
  | 'lg:via-green-400'
  | 'xl:via-green-400'
  | 'hover:via-green-400'
  | 'focus:via-green-400'
  | 'sm:to-green-400'
  | 'md:to-green-400'
  | 'lg:to-green-400'
  | 'xl:to-green-400'
  | 'hover:to-green-400'
  | 'focus:to-green-400'
  | 'sm:from-green-500'
  | 'md:from-green-500'
  | 'lg:from-green-500'
  | 'xl:from-green-500'
  | 'hover:from-green-500'
  | 'focus:from-green-500'
  | 'sm:via-green-500'
  | 'md:via-green-500'
  | 'lg:via-green-500'
  | 'xl:via-green-500'
  | 'hover:via-green-500'
  | 'focus:via-green-500'
  | 'sm:to-green-500'
  | 'md:to-green-500'
  | 'lg:to-green-500'
  | 'xl:to-green-500'
  | 'hover:to-green-500'
  | 'focus:to-green-500'
  | 'sm:from-green-600'
  | 'md:from-green-600'
  | 'lg:from-green-600'
  | 'xl:from-green-600'
  | 'hover:from-green-600'
  | 'focus:from-green-600'
  | 'sm:via-green-600'
  | 'md:via-green-600'
  | 'lg:via-green-600'
  | 'xl:via-green-600'
  | 'hover:via-green-600'
  | 'focus:via-green-600'
  | 'sm:to-green-600'
  | 'md:to-green-600'
  | 'lg:to-green-600'
  | 'xl:to-green-600'
  | 'hover:to-green-600'
  | 'focus:to-green-600'
  | 'sm:from-green-700'
  | 'md:from-green-700'
  | 'lg:from-green-700'
  | 'xl:from-green-700'
  | 'hover:from-green-700'
  | 'focus:from-green-700'
  | 'sm:via-green-700'
  | 'md:via-green-700'
  | 'lg:via-green-700'
  | 'xl:via-green-700'
  | 'hover:via-green-700'
  | 'focus:via-green-700'
  | 'sm:to-green-700'
  | 'md:to-green-700'
  | 'lg:to-green-700'
  | 'xl:to-green-700'
  | 'hover:to-green-700'
  | 'focus:to-green-700'
  | 'sm:from-green-800'
  | 'md:from-green-800'
  | 'lg:from-green-800'
  | 'xl:from-green-800'
  | 'hover:from-green-800'
  | 'focus:from-green-800'
  | 'sm:via-green-800'
  | 'md:via-green-800'
  | 'lg:via-green-800'
  | 'xl:via-green-800'
  | 'hover:via-green-800'
  | 'focus:via-green-800'
  | 'sm:to-green-800'
  | 'md:to-green-800'
  | 'lg:to-green-800'
  | 'xl:to-green-800'
  | 'hover:to-green-800'
  | 'focus:to-green-800'
  | 'sm:from-green-900'
  | 'md:from-green-900'
  | 'lg:from-green-900'
  | 'xl:from-green-900'
  | 'hover:from-green-900'
  | 'focus:from-green-900'
  | 'sm:via-green-900'
  | 'md:via-green-900'
  | 'lg:via-green-900'
  | 'xl:via-green-900'
  | 'hover:via-green-900'
  | 'focus:via-green-900'
  | 'sm:to-green-900'
  | 'md:to-green-900'
  | 'lg:to-green-900'
  | 'xl:to-green-900'
  | 'hover:to-green-900'
  | 'focus:to-green-900'
  | 'sm:from-teal-100'
  | 'md:from-teal-100'
  | 'lg:from-teal-100'
  | 'xl:from-teal-100'
  | 'hover:from-teal-100'
  | 'focus:from-teal-100'
  | 'sm:via-teal-100'
  | 'md:via-teal-100'
  | 'lg:via-teal-100'
  | 'xl:via-teal-100'
  | 'hover:via-teal-100'
  | 'focus:via-teal-100'
  | 'sm:to-teal-100'
  | 'md:to-teal-100'
  | 'lg:to-teal-100'
  | 'xl:to-teal-100'
  | 'hover:to-teal-100'
  | 'focus:to-teal-100'
  | 'sm:from-teal-200'
  | 'md:from-teal-200'
  | 'lg:from-teal-200'
  | 'xl:from-teal-200'
  | 'hover:from-teal-200'
  | 'focus:from-teal-200'
  | 'sm:via-teal-200'
  | 'md:via-teal-200'
  | 'lg:via-teal-200'
  | 'xl:via-teal-200'
  | 'hover:via-teal-200'
  | 'focus:via-teal-200'
  | 'sm:to-teal-200'
  | 'md:to-teal-200'
  | 'lg:to-teal-200'
  | 'xl:to-teal-200'
  | 'hover:to-teal-200'
  | 'focus:to-teal-200'
  | 'sm:from-teal-300'
  | 'md:from-teal-300'
  | 'lg:from-teal-300'
  | 'xl:from-teal-300'
  | 'hover:from-teal-300'
  | 'focus:from-teal-300'
  | 'sm:via-teal-300'
  | 'md:via-teal-300'
  | 'lg:via-teal-300'
  | 'xl:via-teal-300'
  | 'hover:via-teal-300'
  | 'focus:via-teal-300'
  | 'sm:to-teal-300'
  | 'md:to-teal-300'
  | 'lg:to-teal-300'
  | 'xl:to-teal-300'
  | 'hover:to-teal-300'
  | 'focus:to-teal-300'
  | 'sm:from-teal-400'
  | 'md:from-teal-400'
  | 'lg:from-teal-400'
  | 'xl:from-teal-400'
  | 'hover:from-teal-400'
  | 'focus:from-teal-400'
  | 'sm:via-teal-400'
  | 'md:via-teal-400'
  | 'lg:via-teal-400'
  | 'xl:via-teal-400'
  | 'hover:via-teal-400'
  | 'focus:via-teal-400'
  | 'sm:to-teal-400'
  | 'md:to-teal-400'
  | 'lg:to-teal-400'
  | 'xl:to-teal-400'
  | 'hover:to-teal-400'
  | 'focus:to-teal-400'
  | 'sm:from-teal-500'
  | 'md:from-teal-500'
  | 'lg:from-teal-500'
  | 'xl:from-teal-500'
  | 'hover:from-teal-500'
  | 'focus:from-teal-500'
  | 'sm:via-teal-500'
  | 'md:via-teal-500'
  | 'lg:via-teal-500'
  | 'xl:via-teal-500'
  | 'hover:via-teal-500'
  | 'focus:via-teal-500'
  | 'sm:to-teal-500'
  | 'md:to-teal-500'
  | 'lg:to-teal-500'
  | 'xl:to-teal-500'
  | 'hover:to-teal-500'
  | 'focus:to-teal-500'
  | 'sm:from-teal-600'
  | 'md:from-teal-600'
  | 'lg:from-teal-600'
  | 'xl:from-teal-600'
  | 'hover:from-teal-600'
  | 'focus:from-teal-600'
  | 'sm:via-teal-600'
  | 'md:via-teal-600'
  | 'lg:via-teal-600'
  | 'xl:via-teal-600'
  | 'hover:via-teal-600'
  | 'focus:via-teal-600'
  | 'sm:to-teal-600'
  | 'md:to-teal-600'
  | 'lg:to-teal-600'
  | 'xl:to-teal-600'
  | 'hover:to-teal-600'
  | 'focus:to-teal-600'
  | 'sm:from-teal-700'
  | 'md:from-teal-700'
  | 'lg:from-teal-700'
  | 'xl:from-teal-700'
  | 'hover:from-teal-700'
  | 'focus:from-teal-700'
  | 'sm:via-teal-700'
  | 'md:via-teal-700'
  | 'lg:via-teal-700'
  | 'xl:via-teal-700'
  | 'hover:via-teal-700'
  | 'focus:via-teal-700'
  | 'sm:to-teal-700'
  | 'md:to-teal-700'
  | 'lg:to-teal-700'
  | 'xl:to-teal-700'
  | 'hover:to-teal-700'
  | 'focus:to-teal-700'
  | 'sm:from-teal-800'
  | 'md:from-teal-800'
  | 'lg:from-teal-800'
  | 'xl:from-teal-800'
  | 'hover:from-teal-800'
  | 'focus:from-teal-800'
  | 'sm:via-teal-800'
  | 'md:via-teal-800'
  | 'lg:via-teal-800'
  | 'xl:via-teal-800'
  | 'hover:via-teal-800'
  | 'focus:via-teal-800'
  | 'sm:to-teal-800'
  | 'md:to-teal-800'
  | 'lg:to-teal-800'
  | 'xl:to-teal-800'
  | 'hover:to-teal-800'
  | 'focus:to-teal-800'
  | 'sm:from-teal-900'
  | 'md:from-teal-900'
  | 'lg:from-teal-900'
  | 'xl:from-teal-900'
  | 'hover:from-teal-900'
  | 'focus:from-teal-900'
  | 'sm:via-teal-900'
  | 'md:via-teal-900'
  | 'lg:via-teal-900'
  | 'xl:via-teal-900'
  | 'hover:via-teal-900'
  | 'focus:via-teal-900'
  | 'sm:to-teal-900'
  | 'md:to-teal-900'
  | 'lg:to-teal-900'
  | 'xl:to-teal-900'
  | 'hover:to-teal-900'
  | 'focus:to-teal-900'
  | 'sm:from-blue-100'
  | 'md:from-blue-100'
  | 'lg:from-blue-100'
  | 'xl:from-blue-100'
  | 'hover:from-blue-100'
  | 'focus:from-blue-100'
  | 'sm:via-blue-100'
  | 'md:via-blue-100'
  | 'lg:via-blue-100'
  | 'xl:via-blue-100'
  | 'hover:via-blue-100'
  | 'focus:via-blue-100'
  | 'sm:to-blue-100'
  | 'md:to-blue-100'
  | 'lg:to-blue-100'
  | 'xl:to-blue-100'
  | 'hover:to-blue-100'
  | 'focus:to-blue-100'
  | 'sm:from-blue-200'
  | 'md:from-blue-200'
  | 'lg:from-blue-200'
  | 'xl:from-blue-200'
  | 'hover:from-blue-200'
  | 'focus:from-blue-200'
  | 'sm:via-blue-200'
  | 'md:via-blue-200'
  | 'lg:via-blue-200'
  | 'xl:via-blue-200'
  | 'hover:via-blue-200'
  | 'focus:via-blue-200'
  | 'sm:to-blue-200'
  | 'md:to-blue-200'
  | 'lg:to-blue-200'
  | 'xl:to-blue-200'
  | 'hover:to-blue-200'
  | 'focus:to-blue-200'
  | 'sm:from-blue-300'
  | 'md:from-blue-300'
  | 'lg:from-blue-300'
  | 'xl:from-blue-300'
  | 'hover:from-blue-300'
  | 'focus:from-blue-300'
  | 'sm:via-blue-300'
  | 'md:via-blue-300'
  | 'lg:via-blue-300'
  | 'xl:via-blue-300'
  | 'hover:via-blue-300'
  | 'focus:via-blue-300'
  | 'sm:to-blue-300'
  | 'md:to-blue-300'
  | 'lg:to-blue-300'
  | 'xl:to-blue-300'
  | 'hover:to-blue-300'
  | 'focus:to-blue-300'
  | 'sm:from-blue-400'
  | 'md:from-blue-400'
  | 'lg:from-blue-400'
  | 'xl:from-blue-400'
  | 'hover:from-blue-400'
  | 'focus:from-blue-400'
  | 'sm:via-blue-400'
  | 'md:via-blue-400'
  | 'lg:via-blue-400'
  | 'xl:via-blue-400'
  | 'hover:via-blue-400'
  | 'focus:via-blue-400'
  | 'sm:to-blue-400'
  | 'md:to-blue-400'
  | 'lg:to-blue-400'
  | 'xl:to-blue-400'
  | 'hover:to-blue-400'
  | 'focus:to-blue-400'
  | 'sm:from-blue-500'
  | 'md:from-blue-500'
  | 'lg:from-blue-500'
  | 'xl:from-blue-500'
  | 'hover:from-blue-500'
  | 'focus:from-blue-500'
  | 'sm:via-blue-500'
  | 'md:via-blue-500'
  | 'lg:via-blue-500'
  | 'xl:via-blue-500'
  | 'hover:via-blue-500'
  | 'focus:via-blue-500'
  | 'sm:to-blue-500'
  | 'md:to-blue-500'
  | 'lg:to-blue-500'
  | 'xl:to-blue-500'
  | 'hover:to-blue-500'
  | 'focus:to-blue-500'
  | 'sm:from-blue-600'
  | 'md:from-blue-600'
  | 'lg:from-blue-600'
  | 'xl:from-blue-600'
  | 'hover:from-blue-600'
  | 'focus:from-blue-600'
  | 'sm:via-blue-600'
  | 'md:via-blue-600'
  | 'lg:via-blue-600'
  | 'xl:via-blue-600'
  | 'hover:via-blue-600'
  | 'focus:via-blue-600'
  | 'sm:to-blue-600'
  | 'md:to-blue-600'
  | 'lg:to-blue-600'
  | 'xl:to-blue-600'
  | 'hover:to-blue-600'
  | 'focus:to-blue-600'
  | 'sm:from-blue-700'
  | 'md:from-blue-700'
  | 'lg:from-blue-700'
  | 'xl:from-blue-700'
  | 'hover:from-blue-700'
  | 'focus:from-blue-700'
  | 'sm:via-blue-700'
  | 'md:via-blue-700'
  | 'lg:via-blue-700'
  | 'xl:via-blue-700'
  | 'hover:via-blue-700'
  | 'focus:via-blue-700'
  | 'sm:to-blue-700'
  | 'md:to-blue-700'
  | 'lg:to-blue-700'
  | 'xl:to-blue-700'
  | 'hover:to-blue-700'
  | 'focus:to-blue-700'
  | 'sm:from-blue-800'
  | 'md:from-blue-800'
  | 'lg:from-blue-800'
  | 'xl:from-blue-800'
  | 'hover:from-blue-800'
  | 'focus:from-blue-800'
  | 'sm:via-blue-800'
  | 'md:via-blue-800'
  | 'lg:via-blue-800'
  | 'xl:via-blue-800'
  | 'hover:via-blue-800'
  | 'focus:via-blue-800'
  | 'sm:to-blue-800'
  | 'md:to-blue-800'
  | 'lg:to-blue-800'
  | 'xl:to-blue-800'
  | 'hover:to-blue-800'
  | 'focus:to-blue-800'
  | 'sm:from-blue-900'
  | 'md:from-blue-900'
  | 'lg:from-blue-900'
  | 'xl:from-blue-900'
  | 'hover:from-blue-900'
  | 'focus:from-blue-900'
  | 'sm:via-blue-900'
  | 'md:via-blue-900'
  | 'lg:via-blue-900'
  | 'xl:via-blue-900'
  | 'hover:via-blue-900'
  | 'focus:via-blue-900'
  | 'sm:to-blue-900'
  | 'md:to-blue-900'
  | 'lg:to-blue-900'
  | 'xl:to-blue-900'
  | 'hover:to-blue-900'
  | 'focus:to-blue-900'
  | 'sm:from-indigo-100'
  | 'md:from-indigo-100'
  | 'lg:from-indigo-100'
  | 'xl:from-indigo-100'
  | 'hover:from-indigo-100'
  | 'focus:from-indigo-100'
  | 'sm:via-indigo-100'
  | 'md:via-indigo-100'
  | 'lg:via-indigo-100'
  | 'xl:via-indigo-100'
  | 'hover:via-indigo-100'
  | 'focus:via-indigo-100'
  | 'sm:to-indigo-100'
  | 'md:to-indigo-100'
  | 'lg:to-indigo-100'
  | 'xl:to-indigo-100'
  | 'hover:to-indigo-100'
  | 'focus:to-indigo-100'
  | 'sm:from-indigo-200'
  | 'md:from-indigo-200'
  | 'lg:from-indigo-200'
  | 'xl:from-indigo-200'
  | 'hover:from-indigo-200'
  | 'focus:from-indigo-200'
  | 'sm:via-indigo-200'
  | 'md:via-indigo-200'
  | 'lg:via-indigo-200'
  | 'xl:via-indigo-200'
  | 'hover:via-indigo-200'
  | 'focus:via-indigo-200'
  | 'sm:to-indigo-200'
  | 'md:to-indigo-200'
  | 'lg:to-indigo-200'
  | 'xl:to-indigo-200'
  | 'hover:to-indigo-200'
  | 'focus:to-indigo-200'
  | 'sm:from-indigo-300'
  | 'md:from-indigo-300'
  | 'lg:from-indigo-300'
  | 'xl:from-indigo-300'
  | 'hover:from-indigo-300'
  | 'focus:from-indigo-300'
  | 'sm:via-indigo-300'
  | 'md:via-indigo-300'
  | 'lg:via-indigo-300'
  | 'xl:via-indigo-300'
  | 'hover:via-indigo-300'
  | 'focus:via-indigo-300'
  | 'sm:to-indigo-300'
  | 'md:to-indigo-300'
  | 'lg:to-indigo-300'
  | 'xl:to-indigo-300'
  | 'hover:to-indigo-300'
  | 'focus:to-indigo-300'
  | 'sm:from-indigo-400'
  | 'md:from-indigo-400'
  | 'lg:from-indigo-400'
  | 'xl:from-indigo-400'
  | 'hover:from-indigo-400'
  | 'focus:from-indigo-400'
  | 'sm:via-indigo-400'
  | 'md:via-indigo-400'
  | 'lg:via-indigo-400'
  | 'xl:via-indigo-400'
  | 'hover:via-indigo-400'
  | 'focus:via-indigo-400'
  | 'sm:to-indigo-400'
  | 'md:to-indigo-400'
  | 'lg:to-indigo-400'
  | 'xl:to-indigo-400'
  | 'hover:to-indigo-400'
  | 'focus:to-indigo-400'
  | 'sm:from-indigo-500'
  | 'md:from-indigo-500'
  | 'lg:from-indigo-500'
  | 'xl:from-indigo-500'
  | 'hover:from-indigo-500'
  | 'focus:from-indigo-500'
  | 'sm:via-indigo-500'
  | 'md:via-indigo-500'
  | 'lg:via-indigo-500'
  | 'xl:via-indigo-500'
  | 'hover:via-indigo-500'
  | 'focus:via-indigo-500'
  | 'sm:to-indigo-500'
  | 'md:to-indigo-500'
  | 'lg:to-indigo-500'
  | 'xl:to-indigo-500'
  | 'hover:to-indigo-500'
  | 'focus:to-indigo-500'
  | 'sm:from-indigo-600'
  | 'md:from-indigo-600'
  | 'lg:from-indigo-600'
  | 'xl:from-indigo-600'
  | 'hover:from-indigo-600'
  | 'focus:from-indigo-600'
  | 'sm:via-indigo-600'
  | 'md:via-indigo-600'
  | 'lg:via-indigo-600'
  | 'xl:via-indigo-600'
  | 'hover:via-indigo-600'
  | 'focus:via-indigo-600'
  | 'sm:to-indigo-600'
  | 'md:to-indigo-600'
  | 'lg:to-indigo-600'
  | 'xl:to-indigo-600'
  | 'hover:to-indigo-600'
  | 'focus:to-indigo-600'
  | 'sm:from-indigo-700'
  | 'md:from-indigo-700'
  | 'lg:from-indigo-700'
  | 'xl:from-indigo-700'
  | 'hover:from-indigo-700'
  | 'focus:from-indigo-700'
  | 'sm:via-indigo-700'
  | 'md:via-indigo-700'
  | 'lg:via-indigo-700'
  | 'xl:via-indigo-700'
  | 'hover:via-indigo-700'
  | 'focus:via-indigo-700'
  | 'sm:to-indigo-700'
  | 'md:to-indigo-700'
  | 'lg:to-indigo-700'
  | 'xl:to-indigo-700'
  | 'hover:to-indigo-700'
  | 'focus:to-indigo-700'
  | 'sm:from-indigo-800'
  | 'md:from-indigo-800'
  | 'lg:from-indigo-800'
  | 'xl:from-indigo-800'
  | 'hover:from-indigo-800'
  | 'focus:from-indigo-800'
  | 'sm:via-indigo-800'
  | 'md:via-indigo-800'
  | 'lg:via-indigo-800'
  | 'xl:via-indigo-800'
  | 'hover:via-indigo-800'
  | 'focus:via-indigo-800'
  | 'sm:to-indigo-800'
  | 'md:to-indigo-800'
  | 'lg:to-indigo-800'
  | 'xl:to-indigo-800'
  | 'hover:to-indigo-800'
  | 'focus:to-indigo-800'
  | 'sm:from-indigo-900'
  | 'md:from-indigo-900'
  | 'lg:from-indigo-900'
  | 'xl:from-indigo-900'
  | 'hover:from-indigo-900'
  | 'focus:from-indigo-900'
  | 'sm:via-indigo-900'
  | 'md:via-indigo-900'
  | 'lg:via-indigo-900'
  | 'xl:via-indigo-900'
  | 'hover:via-indigo-900'
  | 'focus:via-indigo-900'
  | 'sm:to-indigo-900'
  | 'md:to-indigo-900'
  | 'lg:to-indigo-900'
  | 'xl:to-indigo-900'
  | 'hover:to-indigo-900'
  | 'focus:to-indigo-900'
  | 'sm:from-purple-100'
  | 'md:from-purple-100'
  | 'lg:from-purple-100'
  | 'xl:from-purple-100'
  | 'hover:from-purple-100'
  | 'focus:from-purple-100'
  | 'sm:via-purple-100'
  | 'md:via-purple-100'
  | 'lg:via-purple-100'
  | 'xl:via-purple-100'
  | 'hover:via-purple-100'
  | 'focus:via-purple-100'
  | 'sm:to-purple-100'
  | 'md:to-purple-100'
  | 'lg:to-purple-100'
  | 'xl:to-purple-100'
  | 'hover:to-purple-100'
  | 'focus:to-purple-100'
  | 'sm:from-purple-200'
  | 'md:from-purple-200'
  | 'lg:from-purple-200'
  | 'xl:from-purple-200'
  | 'hover:from-purple-200'
  | 'focus:from-purple-200'
  | 'sm:via-purple-200'
  | 'md:via-purple-200'
  | 'lg:via-purple-200'
  | 'xl:via-purple-200'
  | 'hover:via-purple-200'
  | 'focus:via-purple-200'
  | 'sm:to-purple-200'
  | 'md:to-purple-200'
  | 'lg:to-purple-200'
  | 'xl:to-purple-200'
  | 'hover:to-purple-200'
  | 'focus:to-purple-200'
  | 'sm:from-purple-300'
  | 'md:from-purple-300'
  | 'lg:from-purple-300'
  | 'xl:from-purple-300'
  | 'hover:from-purple-300'
  | 'focus:from-purple-300'
  | 'sm:via-purple-300'
  | 'md:via-purple-300'
  | 'lg:via-purple-300'
  | 'xl:via-purple-300'
  | 'hover:via-purple-300'
  | 'focus:via-purple-300'
  | 'sm:to-purple-300'
  | 'md:to-purple-300'
  | 'lg:to-purple-300'
  | 'xl:to-purple-300'
  | 'hover:to-purple-300'
  | 'focus:to-purple-300'
  | 'sm:from-purple-400'
  | 'md:from-purple-400'
  | 'lg:from-purple-400'
  | 'xl:from-purple-400'
  | 'hover:from-purple-400'
  | 'focus:from-purple-400'
  | 'sm:via-purple-400'
  | 'md:via-purple-400'
  | 'lg:via-purple-400'
  | 'xl:via-purple-400'
  | 'hover:via-purple-400'
  | 'focus:via-purple-400'
  | 'sm:to-purple-400'
  | 'md:to-purple-400'
  | 'lg:to-purple-400'
  | 'xl:to-purple-400'
  | 'hover:to-purple-400'
  | 'focus:to-purple-400'
  | 'sm:from-purple-500'
  | 'md:from-purple-500'
  | 'lg:from-purple-500'
  | 'xl:from-purple-500'
  | 'hover:from-purple-500'
  | 'focus:from-purple-500'
  | 'sm:via-purple-500'
  | 'md:via-purple-500'
  | 'lg:via-purple-500'
  | 'xl:via-purple-500'
  | 'hover:via-purple-500'
  | 'focus:via-purple-500'
  | 'sm:to-purple-500'
  | 'md:to-purple-500'
  | 'lg:to-purple-500'
  | 'xl:to-purple-500'
  | 'hover:to-purple-500'
  | 'focus:to-purple-500'
  | 'sm:from-purple-600'
  | 'md:from-purple-600'
  | 'lg:from-purple-600'
  | 'xl:from-purple-600'
  | 'hover:from-purple-600'
  | 'focus:from-purple-600'
  | 'sm:via-purple-600'
  | 'md:via-purple-600'
  | 'lg:via-purple-600'
  | 'xl:via-purple-600'
  | 'hover:via-purple-600'
  | 'focus:via-purple-600'
  | 'sm:to-purple-600'
  | 'md:to-purple-600'
  | 'lg:to-purple-600'
  | 'xl:to-purple-600'
  | 'hover:to-purple-600'
  | 'focus:to-purple-600'
  | 'sm:from-purple-700'
  | 'md:from-purple-700'
  | 'lg:from-purple-700'
  | 'xl:from-purple-700'
  | 'hover:from-purple-700'
  | 'focus:from-purple-700'
  | 'sm:via-purple-700'
  | 'md:via-purple-700'
  | 'lg:via-purple-700'
  | 'xl:via-purple-700'
  | 'hover:via-purple-700'
  | 'focus:via-purple-700'
  | 'sm:to-purple-700'
  | 'md:to-purple-700'
  | 'lg:to-purple-700'
  | 'xl:to-purple-700'
  | 'hover:to-purple-700'
  | 'focus:to-purple-700'
  | 'sm:from-purple-800'
  | 'md:from-purple-800'
  | 'lg:from-purple-800'
  | 'xl:from-purple-800'
  | 'hover:from-purple-800'
  | 'focus:from-purple-800'
  | 'sm:via-purple-800'
  | 'md:via-purple-800'
  | 'lg:via-purple-800'
  | 'xl:via-purple-800'
  | 'hover:via-purple-800'
  | 'focus:via-purple-800'
  | 'sm:to-purple-800'
  | 'md:to-purple-800'
  | 'lg:to-purple-800'
  | 'xl:to-purple-800'
  | 'hover:to-purple-800'
  | 'focus:to-purple-800'
  | 'sm:from-purple-900'
  | 'md:from-purple-900'
  | 'lg:from-purple-900'
  | 'xl:from-purple-900'
  | 'hover:from-purple-900'
  | 'focus:from-purple-900'
  | 'sm:via-purple-900'
  | 'md:via-purple-900'
  | 'lg:via-purple-900'
  | 'xl:via-purple-900'
  | 'hover:via-purple-900'
  | 'focus:via-purple-900'
  | 'sm:to-purple-900'
  | 'md:to-purple-900'
  | 'lg:to-purple-900'
  | 'xl:to-purple-900'
  | 'hover:to-purple-900'
  | 'focus:to-purple-900'
  | 'sm:from-pink-100'
  | 'md:from-pink-100'
  | 'lg:from-pink-100'
  | 'xl:from-pink-100'
  | 'hover:from-pink-100'
  | 'focus:from-pink-100'
  | 'sm:via-pink-100'
  | 'md:via-pink-100'
  | 'lg:via-pink-100'
  | 'xl:via-pink-100'
  | 'hover:via-pink-100'
  | 'focus:via-pink-100'
  | 'sm:to-pink-100'
  | 'md:to-pink-100'
  | 'lg:to-pink-100'
  | 'xl:to-pink-100'
  | 'hover:to-pink-100'
  | 'focus:to-pink-100'
  | 'sm:from-pink-200'
  | 'md:from-pink-200'
  | 'lg:from-pink-200'
  | 'xl:from-pink-200'
  | 'hover:from-pink-200'
  | 'focus:from-pink-200'
  | 'sm:via-pink-200'
  | 'md:via-pink-200'
  | 'lg:via-pink-200'
  | 'xl:via-pink-200'
  | 'hover:via-pink-200'
  | 'focus:via-pink-200'
  | 'sm:to-pink-200'
  | 'md:to-pink-200'
  | 'lg:to-pink-200'
  | 'xl:to-pink-200'
  | 'hover:to-pink-200'
  | 'focus:to-pink-200'
  | 'sm:from-pink-300'
  | 'md:from-pink-300'
  | 'lg:from-pink-300'
  | 'xl:from-pink-300'
  | 'hover:from-pink-300'
  | 'focus:from-pink-300'
  | 'sm:via-pink-300'
  | 'md:via-pink-300'
  | 'lg:via-pink-300'
  | 'xl:via-pink-300'
  | 'hover:via-pink-300'
  | 'focus:via-pink-300'
  | 'sm:to-pink-300'
  | 'md:to-pink-300'
  | 'lg:to-pink-300'
  | 'xl:to-pink-300'
  | 'hover:to-pink-300'
  | 'focus:to-pink-300'
  | 'sm:from-pink-400'
  | 'md:from-pink-400'
  | 'lg:from-pink-400'
  | 'xl:from-pink-400'
  | 'hover:from-pink-400'
  | 'focus:from-pink-400'
  | 'sm:via-pink-400'
  | 'md:via-pink-400'
  | 'lg:via-pink-400'
  | 'xl:via-pink-400'
  | 'hover:via-pink-400'
  | 'focus:via-pink-400'
  | 'sm:to-pink-400'
  | 'md:to-pink-400'
  | 'lg:to-pink-400'
  | 'xl:to-pink-400'
  | 'hover:to-pink-400'
  | 'focus:to-pink-400'
  | 'sm:from-pink-500'
  | 'md:from-pink-500'
  | 'lg:from-pink-500'
  | 'xl:from-pink-500'
  | 'hover:from-pink-500'
  | 'focus:from-pink-500'
  | 'sm:via-pink-500'
  | 'md:via-pink-500'
  | 'lg:via-pink-500'
  | 'xl:via-pink-500'
  | 'hover:via-pink-500'
  | 'focus:via-pink-500'
  | 'sm:to-pink-500'
  | 'md:to-pink-500'
  | 'lg:to-pink-500'
  | 'xl:to-pink-500'
  | 'hover:to-pink-500'
  | 'focus:to-pink-500'
  | 'sm:from-pink-600'
  | 'md:from-pink-600'
  | 'lg:from-pink-600'
  | 'xl:from-pink-600'
  | 'hover:from-pink-600'
  | 'focus:from-pink-600'
  | 'sm:via-pink-600'
  | 'md:via-pink-600'
  | 'lg:via-pink-600'
  | 'xl:via-pink-600'
  | 'hover:via-pink-600'
  | 'focus:via-pink-600'
  | 'sm:to-pink-600'
  | 'md:to-pink-600'
  | 'lg:to-pink-600'
  | 'xl:to-pink-600'
  | 'hover:to-pink-600'
  | 'focus:to-pink-600'
  | 'sm:from-pink-700'
  | 'md:from-pink-700'
  | 'lg:from-pink-700'
  | 'xl:from-pink-700'
  | 'hover:from-pink-700'
  | 'focus:from-pink-700'
  | 'sm:via-pink-700'
  | 'md:via-pink-700'
  | 'lg:via-pink-700'
  | 'xl:via-pink-700'
  | 'hover:via-pink-700'
  | 'focus:via-pink-700'
  | 'sm:to-pink-700'
  | 'md:to-pink-700'
  | 'lg:to-pink-700'
  | 'xl:to-pink-700'
  | 'hover:to-pink-700'
  | 'focus:to-pink-700'
  | 'sm:from-pink-800'
  | 'md:from-pink-800'
  | 'lg:from-pink-800'
  | 'xl:from-pink-800'
  | 'hover:from-pink-800'
  | 'focus:from-pink-800'
  | 'sm:via-pink-800'
  | 'md:via-pink-800'
  | 'lg:via-pink-800'
  | 'xl:via-pink-800'
  | 'hover:via-pink-800'
  | 'focus:via-pink-800'
  | 'sm:to-pink-800'
  | 'md:to-pink-800'
  | 'lg:to-pink-800'
  | 'xl:to-pink-800'
  | 'hover:to-pink-800'
  | 'focus:to-pink-800'
  | 'sm:from-pink-900'
  | 'md:from-pink-900'
  | 'lg:from-pink-900'
  | 'xl:from-pink-900'
  | 'hover:from-pink-900'
  | 'focus:from-pink-900'
  | 'sm:via-pink-900'
  | 'md:via-pink-900'
  | 'lg:via-pink-900'
  | 'xl:via-pink-900'
  | 'hover:via-pink-900'
  | 'focus:via-pink-900'
  | 'sm:to-pink-900'
  | 'md:to-pink-900'
  | 'lg:to-pink-900'
  | 'xl:to-pink-900'
  | 'hover:to-pink-900'
  | 'focus:to-pink-900'
  | 'sm:from-primary-light'
  | 'md:from-primary-light'
  | 'lg:from-primary-light'
  | 'xl:from-primary-light'
  | 'hover:from-primary-light'
  | 'focus:from-primary-light'
  | 'sm:via-primary-light'
  | 'md:via-primary-light'
  | 'lg:via-primary-light'
  | 'xl:via-primary-light'
  | 'hover:via-primary-light'
  | 'focus:via-primary-light'
  | 'sm:to-primary-light'
  | 'md:to-primary-light'
  | 'lg:to-primary-light'
  | 'xl:to-primary-light'
  | 'hover:to-primary-light'
  | 'focus:to-primary-light'
  | 'sm:from-primary'
  | 'md:from-primary'
  | 'lg:from-primary'
  | 'xl:from-primary'
  | 'hover:from-primary'
  | 'focus:from-primary'
  | 'sm:via-primary'
  | 'md:via-primary'
  | 'lg:via-primary'
  | 'xl:via-primary'
  | 'hover:via-primary'
  | 'focus:via-primary'
  | 'sm:to-primary'
  | 'md:to-primary'
  | 'lg:to-primary'
  | 'xl:to-primary'
  | 'hover:to-primary'
  | 'focus:to-primary'
  | 'sm:from-primary-dark'
  | 'md:from-primary-dark'
  | 'lg:from-primary-dark'
  | 'xl:from-primary-dark'
  | 'hover:from-primary-dark'
  | 'focus:from-primary-dark'
  | 'sm:via-primary-dark'
  | 'md:via-primary-dark'
  | 'lg:via-primary-dark'
  | 'xl:via-primary-dark'
  | 'hover:via-primary-dark'
  | 'focus:via-primary-dark'
  | 'sm:to-primary-dark'
  | 'md:to-primary-dark'
  | 'lg:to-primary-dark'
  | 'xl:to-primary-dark'
  | 'hover:to-primary-dark'
  | 'focus:to-primary-dark'
  | 'sm:from-accent-dark'
  | 'md:from-accent-dark'
  | 'lg:from-accent-dark'
  | 'xl:from-accent-dark'
  | 'hover:from-accent-dark'
  | 'focus:from-accent-dark'
  | 'sm:via-accent-dark'
  | 'md:via-accent-dark'
  | 'lg:via-accent-dark'
  | 'xl:via-accent-dark'
  | 'hover:via-accent-dark'
  | 'focus:via-accent-dark'
  | 'sm:to-accent-dark'
  | 'md:to-accent-dark'
  | 'lg:to-accent-dark'
  | 'xl:to-accent-dark'
  | 'hover:to-accent-dark'
  | 'focus:to-accent-dark'
  | 'sm:from-accent'
  | 'md:from-accent'
  | 'lg:from-accent'
  | 'xl:from-accent'
  | 'hover:from-accent'
  | 'focus:from-accent'
  | 'sm:via-accent'
  | 'md:via-accent'
  | 'lg:via-accent'
  | 'xl:via-accent'
  | 'hover:via-accent'
  | 'focus:via-accent'
  | 'sm:to-accent'
  | 'md:to-accent'
  | 'lg:to-accent'
  | 'xl:to-accent'
  | 'hover:to-accent'
  | 'focus:to-accent'
  | 'sm:from-accent-light'
  | 'md:from-accent-light'
  | 'lg:from-accent-light'
  | 'xl:from-accent-light'
  | 'hover:from-accent-light'
  | 'focus:from-accent-light'
  | 'sm:via-accent-light'
  | 'md:via-accent-light'
  | 'lg:via-accent-light'
  | 'xl:via-accent-light'
  | 'hover:via-accent-light'
  | 'focus:via-accent-light'
  | 'sm:to-accent-light'
  | 'md:to-accent-light'
  | 'lg:to-accent-light'
  | 'xl:to-accent-light'
  | 'hover:to-accent-light'
  | 'focus:to-accent-light'
  | 'sm:from-warning-dark'
  | 'md:from-warning-dark'
  | 'lg:from-warning-dark'
  | 'xl:from-warning-dark'
  | 'hover:from-warning-dark'
  | 'focus:from-warning-dark'
  | 'sm:via-warning-dark'
  | 'md:via-warning-dark'
  | 'lg:via-warning-dark'
  | 'xl:via-warning-dark'
  | 'hover:via-warning-dark'
  | 'focus:via-warning-dark'
  | 'sm:to-warning-dark'
  | 'md:to-warning-dark'
  | 'lg:to-warning-dark'
  | 'xl:to-warning-dark'
  | 'hover:to-warning-dark'
  | 'focus:to-warning-dark'
  | 'sm:from-warning'
  | 'md:from-warning'
  | 'lg:from-warning'
  | 'xl:from-warning'
  | 'hover:from-warning'
  | 'focus:from-warning'
  | 'sm:via-warning'
  | 'md:via-warning'
  | 'lg:via-warning'
  | 'xl:via-warning'
  | 'hover:via-warning'
  | 'focus:via-warning'
  | 'sm:to-warning'
  | 'md:to-warning'
  | 'lg:to-warning'
  | 'xl:to-warning'
  | 'hover:to-warning'
  | 'focus:to-warning'
  | 'sm:from-warning-light'
  | 'md:from-warning-light'
  | 'lg:from-warning-light'
  | 'xl:from-warning-light'
  | 'hover:from-warning-light'
  | 'focus:from-warning-light'
  | 'sm:via-warning-light'
  | 'md:via-warning-light'
  | 'lg:via-warning-light'
  | 'xl:via-warning-light'
  | 'hover:via-warning-light'
  | 'focus:via-warning-light'
  | 'sm:to-warning-light'
  | 'md:to-warning-light'
  | 'lg:to-warning-light'
  | 'xl:to-warning-light'
  | 'hover:to-warning-light'
  | 'focus:to-warning-light'
  | 'sm:from-dark'
  | 'md:from-dark'
  | 'lg:from-dark'
  | 'xl:from-dark'
  | 'hover:from-dark'
  | 'focus:from-dark'
  | 'sm:via-dark'
  | 'md:via-dark'
  | 'lg:via-dark'
  | 'xl:via-dark'
  | 'hover:via-dark'
  | 'focus:via-dark'
  | 'sm:to-dark'
  | 'md:to-dark'
  | 'lg:to-dark'
  | 'xl:to-dark'
  | 'hover:to-dark'
  | 'focus:to-dark'
  | 'sm:from-dark-secondary'
  | 'md:from-dark-secondary'
  | 'lg:from-dark-secondary'
  | 'xl:from-dark-secondary'
  | 'hover:from-dark-secondary'
  | 'focus:from-dark-secondary'
  | 'sm:via-dark-secondary'
  | 'md:via-dark-secondary'
  | 'lg:via-dark-secondary'
  | 'xl:via-dark-secondary'
  | 'hover:via-dark-secondary'
  | 'focus:via-dark-secondary'
  | 'sm:to-dark-secondary'
  | 'md:to-dark-secondary'
  | 'lg:to-dark-secondary'
  | 'xl:to-dark-secondary'
  | 'hover:to-dark-secondary'
  | 'focus:to-dark-secondary'
  | 'sm:from-light'
  | 'md:from-light'
  | 'lg:from-light'
  | 'xl:from-light'
  | 'hover:from-light'
  | 'focus:from-light'
  | 'sm:via-light'
  | 'md:via-light'
  | 'lg:via-light'
  | 'xl:via-light'
  | 'hover:via-light'
  | 'focus:via-light'
  | 'sm:to-light'
  | 'md:to-light'
  | 'lg:to-light'
  | 'xl:to-light'
  | 'hover:to-light'
  | 'focus:to-light'
  | 'sm:from-light-secondary'
  | 'md:from-light-secondary'
  | 'lg:from-light-secondary'
  | 'xl:from-light-secondary'
  | 'hover:from-light-secondary'
  | 'focus:from-light-secondary'
  | 'sm:via-light-secondary'
  | 'md:via-light-secondary'
  | 'lg:via-light-secondary'
  | 'xl:via-light-secondary'
  | 'hover:via-light-secondary'
  | 'focus:via-light-secondary'
  | 'sm:to-light-secondary'
  | 'md:to-light-secondary'
  | 'lg:to-light-secondary'
  | 'xl:to-light-secondary'
  | 'hover:to-light-secondary'
  | 'focus:to-light-secondary'
  | 'sm:from-light-hint'
  | 'md:from-light-hint'
  | 'lg:from-light-hint'
  | 'xl:from-light-hint'
  | 'hover:from-light-hint'
  | 'focus:from-light-hint'
  | 'sm:via-light-hint'
  | 'md:via-light-hint'
  | 'lg:via-light-hint'
  | 'xl:via-light-hint'
  | 'hover:via-light-hint'
  | 'focus:via-light-hint'
  | 'sm:to-light-hint'
  | 'md:to-light-hint'
  | 'lg:to-light-hint'
  | 'xl:to-light-hint'
  | 'hover:to-light-hint'
  | 'focus:to-light-hint'
  | 'sm:bg-opacity-0'
  | 'md:bg-opacity-0'
  | 'lg:bg-opacity-0'
  | 'xl:bg-opacity-0'
  | 'hover:bg-opacity-0'
  | 'focus:bg-opacity-0'
  | 'sm:bg-opacity-25'
  | 'md:bg-opacity-25'
  | 'lg:bg-opacity-25'
  | 'xl:bg-opacity-25'
  | 'hover:bg-opacity-25'
  | 'focus:bg-opacity-25'
  | 'sm:bg-opacity-50'
  | 'md:bg-opacity-50'
  | 'lg:bg-opacity-50'
  | 'xl:bg-opacity-50'
  | 'hover:bg-opacity-50'
  | 'focus:bg-opacity-50'
  | 'sm:bg-opacity-75'
  | 'md:bg-opacity-75'
  | 'lg:bg-opacity-75'
  | 'xl:bg-opacity-75'
  | 'hover:bg-opacity-75'
  | 'focus:bg-opacity-75'
  | 'sm:bg-opacity-100'
  | 'md:bg-opacity-100'
  | 'lg:bg-opacity-100'
  | 'xl:bg-opacity-100'
  | 'hover:bg-opacity-100'
  | 'focus:bg-opacity-100'
  | 'sm:bg-bottom'
  | 'md:bg-bottom'
  | 'lg:bg-bottom'
  | 'xl:bg-bottom'
  | 'sm:bg-center'
  | 'md:bg-center'
  | 'lg:bg-center'
  | 'xl:bg-center'
  | 'sm:bg-left'
  | 'md:bg-left'
  | 'lg:bg-left'
  | 'xl:bg-left'
  | 'sm:bg-left-bottom'
  | 'md:bg-left-bottom'
  | 'lg:bg-left-bottom'
  | 'xl:bg-left-bottom'
  | 'sm:bg-left-top'
  | 'md:bg-left-top'
  | 'lg:bg-left-top'
  | 'xl:bg-left-top'
  | 'sm:bg-right'
  | 'md:bg-right'
  | 'lg:bg-right'
  | 'xl:bg-right'
  | 'sm:bg-right-bottom'
  | 'md:bg-right-bottom'
  | 'lg:bg-right-bottom'
  | 'xl:bg-right-bottom'
  | 'sm:bg-right-top'
  | 'md:bg-right-top'
  | 'lg:bg-right-top'
  | 'xl:bg-right-top'
  | 'sm:bg-top'
  | 'md:bg-top'
  | 'lg:bg-top'
  | 'xl:bg-top'
  | 'sm:bg-repeat'
  | 'md:bg-repeat'
  | 'lg:bg-repeat'
  | 'xl:bg-repeat'
  | 'sm:bg-no-repeat'
  | 'md:bg-no-repeat'
  | 'lg:bg-no-repeat'
  | 'xl:bg-no-repeat'
  | 'sm:bg-repeat-x'
  | 'md:bg-repeat-x'
  | 'lg:bg-repeat-x'
  | 'xl:bg-repeat-x'
  | 'sm:bg-repeat-y'
  | 'md:bg-repeat-y'
  | 'lg:bg-repeat-y'
  | 'xl:bg-repeat-y'
  | 'sm:bg-repeat-round'
  | 'md:bg-repeat-round'
  | 'lg:bg-repeat-round'
  | 'xl:bg-repeat-round'
  | 'sm:bg-repeat-space'
  | 'md:bg-repeat-space'
  | 'lg:bg-repeat-space'
  | 'xl:bg-repeat-space'
  | 'sm:bg-auto'
  | 'md:bg-auto'
  | 'lg:bg-auto'
  | 'xl:bg-auto'
  | 'sm:bg-cover'
  | 'md:bg-cover'
  | 'lg:bg-cover'
  | 'xl:bg-cover'
  | 'sm:bg-contain'
  | 'md:bg-contain'
  | 'lg:bg-contain'
  | 'xl:bg-contain'
  | 'sm:border-collapse'
  | 'md:border-collapse'
  | 'lg:border-collapse'
  | 'xl:border-collapse'
  | 'sm:border-separate'
  | 'md:border-separate'
  | 'lg:border-separate'
  | 'xl:border-separate'
  | 'sm:border-transparent'
  | 'md:border-transparent'
  | 'lg:border-transparent'
  | 'xl:border-transparent'
  | 'hover:border-transparent'
  | 'focus:border-transparent'
  | 'checked:border-transparent'
  | 'sm:border-current'
  | 'md:border-current'
  | 'lg:border-current'
  | 'xl:border-current'
  | 'hover:border-current'
  | 'focus:border-current'
  | 'checked:border-current'
  | 'sm:border-black'
  | 'md:border-black'
  | 'lg:border-black'
  | 'xl:border-black'
  | 'hover:border-black'
  | 'focus:border-black'
  | 'checked:border-black'
  | 'sm:border-white'
  | 'md:border-white'
  | 'lg:border-white'
  | 'xl:border-white'
  | 'hover:border-white'
  | 'focus:border-white'
  | 'checked:border-white'
  | 'sm:border-gray-100'
  | 'md:border-gray-100'
  | 'lg:border-gray-100'
  | 'xl:border-gray-100'
  | 'hover:border-gray-100'
  | 'focus:border-gray-100'
  | 'checked:border-gray-100'
  | 'sm:border-gray-200'
  | 'md:border-gray-200'
  | 'lg:border-gray-200'
  | 'xl:border-gray-200'
  | 'hover:border-gray-200'
  | 'focus:border-gray-200'
  | 'checked:border-gray-200'
  | 'sm:border-gray-300'
  | 'md:border-gray-300'
  | 'lg:border-gray-300'
  | 'xl:border-gray-300'
  | 'hover:border-gray-300'
  | 'focus:border-gray-300'
  | 'checked:border-gray-300'
  | 'sm:border-gray-400'
  | 'md:border-gray-400'
  | 'lg:border-gray-400'
  | 'xl:border-gray-400'
  | 'hover:border-gray-400'
  | 'focus:border-gray-400'
  | 'checked:border-gray-400'
  | 'sm:border-gray-500'
  | 'md:border-gray-500'
  | 'lg:border-gray-500'
  | 'xl:border-gray-500'
  | 'hover:border-gray-500'
  | 'focus:border-gray-500'
  | 'checked:border-gray-500'
  | 'sm:border-gray-600'
  | 'md:border-gray-600'
  | 'lg:border-gray-600'
  | 'xl:border-gray-600'
  | 'hover:border-gray-600'
  | 'focus:border-gray-600'
  | 'checked:border-gray-600'
  | 'sm:border-gray-700'
  | 'md:border-gray-700'
  | 'lg:border-gray-700'
  | 'xl:border-gray-700'
  | 'hover:border-gray-700'
  | 'focus:border-gray-700'
  | 'checked:border-gray-700'
  | 'sm:border-gray-800'
  | 'md:border-gray-800'
  | 'lg:border-gray-800'
  | 'xl:border-gray-800'
  | 'hover:border-gray-800'
  | 'focus:border-gray-800'
  | 'checked:border-gray-800'
  | 'sm:border-gray-900'
  | 'md:border-gray-900'
  | 'lg:border-gray-900'
  | 'xl:border-gray-900'
  | 'hover:border-gray-900'
  | 'focus:border-gray-900'
  | 'checked:border-gray-900'
  | 'sm:border-red-100'
  | 'md:border-red-100'
  | 'lg:border-red-100'
  | 'xl:border-red-100'
  | 'hover:border-red-100'
  | 'focus:border-red-100'
  | 'checked:border-red-100'
  | 'sm:border-red-200'
  | 'md:border-red-200'
  | 'lg:border-red-200'
  | 'xl:border-red-200'
  | 'hover:border-red-200'
  | 'focus:border-red-200'
  | 'checked:border-red-200'
  | 'sm:border-red-300'
  | 'md:border-red-300'
  | 'lg:border-red-300'
  | 'xl:border-red-300'
  | 'hover:border-red-300'
  | 'focus:border-red-300'
  | 'checked:border-red-300'
  | 'sm:border-red-400'
  | 'md:border-red-400'
  | 'lg:border-red-400'
  | 'xl:border-red-400'
  | 'hover:border-red-400'
  | 'focus:border-red-400'
  | 'checked:border-red-400'
  | 'sm:border-red-500'
  | 'md:border-red-500'
  | 'lg:border-red-500'
  | 'xl:border-red-500'
  | 'hover:border-red-500'
  | 'focus:border-red-500'
  | 'checked:border-red-500'
  | 'sm:border-red-600'
  | 'md:border-red-600'
  | 'lg:border-red-600'
  | 'xl:border-red-600'
  | 'hover:border-red-600'
  | 'focus:border-red-600'
  | 'checked:border-red-600'
  | 'sm:border-red-700'
  | 'md:border-red-700'
  | 'lg:border-red-700'
  | 'xl:border-red-700'
  | 'hover:border-red-700'
  | 'focus:border-red-700'
  | 'checked:border-red-700'
  | 'sm:border-red-800'
  | 'md:border-red-800'
  | 'lg:border-red-800'
  | 'xl:border-red-800'
  | 'hover:border-red-800'
  | 'focus:border-red-800'
  | 'checked:border-red-800'
  | 'sm:border-red-900'
  | 'md:border-red-900'
  | 'lg:border-red-900'
  | 'xl:border-red-900'
  | 'hover:border-red-900'
  | 'focus:border-red-900'
  | 'checked:border-red-900'
  | 'sm:border-orange-100'
  | 'md:border-orange-100'
  | 'lg:border-orange-100'
  | 'xl:border-orange-100'
  | 'hover:border-orange-100'
  | 'focus:border-orange-100'
  | 'checked:border-orange-100'
  | 'sm:border-orange-200'
  | 'md:border-orange-200'
  | 'lg:border-orange-200'
  | 'xl:border-orange-200'
  | 'hover:border-orange-200'
  | 'focus:border-orange-200'
  | 'checked:border-orange-200'
  | 'sm:border-orange-300'
  | 'md:border-orange-300'
  | 'lg:border-orange-300'
  | 'xl:border-orange-300'
  | 'hover:border-orange-300'
  | 'focus:border-orange-300'
  | 'checked:border-orange-300'
  | 'sm:border-orange-400'
  | 'md:border-orange-400'
  | 'lg:border-orange-400'
  | 'xl:border-orange-400'
  | 'hover:border-orange-400'
  | 'focus:border-orange-400'
  | 'checked:border-orange-400'
  | 'sm:border-orange-500'
  | 'md:border-orange-500'
  | 'lg:border-orange-500'
  | 'xl:border-orange-500'
  | 'hover:border-orange-500'
  | 'focus:border-orange-500'
  | 'checked:border-orange-500'
  | 'sm:border-orange-600'
  | 'md:border-orange-600'
  | 'lg:border-orange-600'
  | 'xl:border-orange-600'
  | 'hover:border-orange-600'
  | 'focus:border-orange-600'
  | 'checked:border-orange-600'
  | 'sm:border-orange-700'
  | 'md:border-orange-700'
  | 'lg:border-orange-700'
  | 'xl:border-orange-700'
  | 'hover:border-orange-700'
  | 'focus:border-orange-700'
  | 'checked:border-orange-700'
  | 'sm:border-orange-800'
  | 'md:border-orange-800'
  | 'lg:border-orange-800'
  | 'xl:border-orange-800'
  | 'hover:border-orange-800'
  | 'focus:border-orange-800'
  | 'checked:border-orange-800'
  | 'sm:border-orange-900'
  | 'md:border-orange-900'
  | 'lg:border-orange-900'
  | 'xl:border-orange-900'
  | 'hover:border-orange-900'
  | 'focus:border-orange-900'
  | 'checked:border-orange-900'
  | 'sm:border-yellow-100'
  | 'md:border-yellow-100'
  | 'lg:border-yellow-100'
  | 'xl:border-yellow-100'
  | 'hover:border-yellow-100'
  | 'focus:border-yellow-100'
  | 'checked:border-yellow-100'
  | 'sm:border-yellow-200'
  | 'md:border-yellow-200'
  | 'lg:border-yellow-200'
  | 'xl:border-yellow-200'
  | 'hover:border-yellow-200'
  | 'focus:border-yellow-200'
  | 'checked:border-yellow-200'
  | 'sm:border-yellow-300'
  | 'md:border-yellow-300'
  | 'lg:border-yellow-300'
  | 'xl:border-yellow-300'
  | 'hover:border-yellow-300'
  | 'focus:border-yellow-300'
  | 'checked:border-yellow-300'
  | 'sm:border-yellow-400'
  | 'md:border-yellow-400'
  | 'lg:border-yellow-400'
  | 'xl:border-yellow-400'
  | 'hover:border-yellow-400'
  | 'focus:border-yellow-400'
  | 'checked:border-yellow-400'
  | 'sm:border-yellow-500'
  | 'md:border-yellow-500'
  | 'lg:border-yellow-500'
  | 'xl:border-yellow-500'
  | 'hover:border-yellow-500'
  | 'focus:border-yellow-500'
  | 'checked:border-yellow-500'
  | 'sm:border-yellow-600'
  | 'md:border-yellow-600'
  | 'lg:border-yellow-600'
  | 'xl:border-yellow-600'
  | 'hover:border-yellow-600'
  | 'focus:border-yellow-600'
  | 'checked:border-yellow-600'
  | 'sm:border-yellow-700'
  | 'md:border-yellow-700'
  | 'lg:border-yellow-700'
  | 'xl:border-yellow-700'
  | 'hover:border-yellow-700'
  | 'focus:border-yellow-700'
  | 'checked:border-yellow-700'
  | 'sm:border-yellow-800'
  | 'md:border-yellow-800'
  | 'lg:border-yellow-800'
  | 'xl:border-yellow-800'
  | 'hover:border-yellow-800'
  | 'focus:border-yellow-800'
  | 'checked:border-yellow-800'
  | 'sm:border-yellow-900'
  | 'md:border-yellow-900'
  | 'lg:border-yellow-900'
  | 'xl:border-yellow-900'
  | 'hover:border-yellow-900'
  | 'focus:border-yellow-900'
  | 'checked:border-yellow-900'
  | 'sm:border-green-100'
  | 'md:border-green-100'
  | 'lg:border-green-100'
  | 'xl:border-green-100'
  | 'hover:border-green-100'
  | 'focus:border-green-100'
  | 'checked:border-green-100'
  | 'sm:border-green-200'
  | 'md:border-green-200'
  | 'lg:border-green-200'
  | 'xl:border-green-200'
  | 'hover:border-green-200'
  | 'focus:border-green-200'
  | 'checked:border-green-200'
  | 'sm:border-green-300'
  | 'md:border-green-300'
  | 'lg:border-green-300'
  | 'xl:border-green-300'
  | 'hover:border-green-300'
  | 'focus:border-green-300'
  | 'checked:border-green-300'
  | 'sm:border-green-400'
  | 'md:border-green-400'
  | 'lg:border-green-400'
  | 'xl:border-green-400'
  | 'hover:border-green-400'
  | 'focus:border-green-400'
  | 'checked:border-green-400'
  | 'sm:border-green-500'
  | 'md:border-green-500'
  | 'lg:border-green-500'
  | 'xl:border-green-500'
  | 'hover:border-green-500'
  | 'focus:border-green-500'
  | 'checked:border-green-500'
  | 'sm:border-green-600'
  | 'md:border-green-600'
  | 'lg:border-green-600'
  | 'xl:border-green-600'
  | 'hover:border-green-600'
  | 'focus:border-green-600'
  | 'checked:border-green-600'
  | 'sm:border-green-700'
  | 'md:border-green-700'
  | 'lg:border-green-700'
  | 'xl:border-green-700'
  | 'hover:border-green-700'
  | 'focus:border-green-700'
  | 'checked:border-green-700'
  | 'sm:border-green-800'
  | 'md:border-green-800'
  | 'lg:border-green-800'
  | 'xl:border-green-800'
  | 'hover:border-green-800'
  | 'focus:border-green-800'
  | 'checked:border-green-800'
  | 'sm:border-green-900'
  | 'md:border-green-900'
  | 'lg:border-green-900'
  | 'xl:border-green-900'
  | 'hover:border-green-900'
  | 'focus:border-green-900'
  | 'checked:border-green-900'
  | 'sm:border-teal-100'
  | 'md:border-teal-100'
  | 'lg:border-teal-100'
  | 'xl:border-teal-100'
  | 'hover:border-teal-100'
  | 'focus:border-teal-100'
  | 'checked:border-teal-100'
  | 'sm:border-teal-200'
  | 'md:border-teal-200'
  | 'lg:border-teal-200'
  | 'xl:border-teal-200'
  | 'hover:border-teal-200'
  | 'focus:border-teal-200'
  | 'checked:border-teal-200'
  | 'sm:border-teal-300'
  | 'md:border-teal-300'
  | 'lg:border-teal-300'
  | 'xl:border-teal-300'
  | 'hover:border-teal-300'
  | 'focus:border-teal-300'
  | 'checked:border-teal-300'
  | 'sm:border-teal-400'
  | 'md:border-teal-400'
  | 'lg:border-teal-400'
  | 'xl:border-teal-400'
  | 'hover:border-teal-400'
  | 'focus:border-teal-400'
  | 'checked:border-teal-400'
  | 'sm:border-teal-500'
  | 'md:border-teal-500'
  | 'lg:border-teal-500'
  | 'xl:border-teal-500'
  | 'hover:border-teal-500'
  | 'focus:border-teal-500'
  | 'checked:border-teal-500'
  | 'sm:border-teal-600'
  | 'md:border-teal-600'
  | 'lg:border-teal-600'
  | 'xl:border-teal-600'
  | 'hover:border-teal-600'
  | 'focus:border-teal-600'
  | 'checked:border-teal-600'
  | 'sm:border-teal-700'
  | 'md:border-teal-700'
  | 'lg:border-teal-700'
  | 'xl:border-teal-700'
  | 'hover:border-teal-700'
  | 'focus:border-teal-700'
  | 'checked:border-teal-700'
  | 'sm:border-teal-800'
  | 'md:border-teal-800'
  | 'lg:border-teal-800'
  | 'xl:border-teal-800'
  | 'hover:border-teal-800'
  | 'focus:border-teal-800'
  | 'checked:border-teal-800'
  | 'sm:border-teal-900'
  | 'md:border-teal-900'
  | 'lg:border-teal-900'
  | 'xl:border-teal-900'
  | 'hover:border-teal-900'
  | 'focus:border-teal-900'
  | 'checked:border-teal-900'
  | 'sm:border-blue-100'
  | 'md:border-blue-100'
  | 'lg:border-blue-100'
  | 'xl:border-blue-100'
  | 'hover:border-blue-100'
  | 'focus:border-blue-100'
  | 'checked:border-blue-100'
  | 'sm:border-blue-200'
  | 'md:border-blue-200'
  | 'lg:border-blue-200'
  | 'xl:border-blue-200'
  | 'hover:border-blue-200'
  | 'focus:border-blue-200'
  | 'checked:border-blue-200'
  | 'sm:border-blue-300'
  | 'md:border-blue-300'
  | 'lg:border-blue-300'
  | 'xl:border-blue-300'
  | 'hover:border-blue-300'
  | 'focus:border-blue-300'
  | 'checked:border-blue-300'
  | 'sm:border-blue-400'
  | 'md:border-blue-400'
  | 'lg:border-blue-400'
  | 'xl:border-blue-400'
  | 'hover:border-blue-400'
  | 'focus:border-blue-400'
  | 'checked:border-blue-400'
  | 'sm:border-blue-500'
  | 'md:border-blue-500'
  | 'lg:border-blue-500'
  | 'xl:border-blue-500'
  | 'hover:border-blue-500'
  | 'focus:border-blue-500'
  | 'checked:border-blue-500'
  | 'sm:border-blue-600'
  | 'md:border-blue-600'
  | 'lg:border-blue-600'
  | 'xl:border-blue-600'
  | 'hover:border-blue-600'
  | 'focus:border-blue-600'
  | 'checked:border-blue-600'
  | 'sm:border-blue-700'
  | 'md:border-blue-700'
  | 'lg:border-blue-700'
  | 'xl:border-blue-700'
  | 'hover:border-blue-700'
  | 'focus:border-blue-700'
  | 'checked:border-blue-700'
  | 'sm:border-blue-800'
  | 'md:border-blue-800'
  | 'lg:border-blue-800'
  | 'xl:border-blue-800'
  | 'hover:border-blue-800'
  | 'focus:border-blue-800'
  | 'checked:border-blue-800'
  | 'sm:border-blue-900'
  | 'md:border-blue-900'
  | 'lg:border-blue-900'
  | 'xl:border-blue-900'
  | 'hover:border-blue-900'
  | 'focus:border-blue-900'
  | 'checked:border-blue-900'
  | 'sm:border-indigo-100'
  | 'md:border-indigo-100'
  | 'lg:border-indigo-100'
  | 'xl:border-indigo-100'
  | 'hover:border-indigo-100'
  | 'focus:border-indigo-100'
  | 'checked:border-indigo-100'
  | 'sm:border-indigo-200'
  | 'md:border-indigo-200'
  | 'lg:border-indigo-200'
  | 'xl:border-indigo-200'
  | 'hover:border-indigo-200'
  | 'focus:border-indigo-200'
  | 'checked:border-indigo-200'
  | 'sm:border-indigo-300'
  | 'md:border-indigo-300'
  | 'lg:border-indigo-300'
  | 'xl:border-indigo-300'
  | 'hover:border-indigo-300'
  | 'focus:border-indigo-300'
  | 'checked:border-indigo-300'
  | 'sm:border-indigo-400'
  | 'md:border-indigo-400'
  | 'lg:border-indigo-400'
  | 'xl:border-indigo-400'
  | 'hover:border-indigo-400'
  | 'focus:border-indigo-400'
  | 'checked:border-indigo-400'
  | 'sm:border-indigo-500'
  | 'md:border-indigo-500'
  | 'lg:border-indigo-500'
  | 'xl:border-indigo-500'
  | 'hover:border-indigo-500'
  | 'focus:border-indigo-500'
  | 'checked:border-indigo-500'
  | 'sm:border-indigo-600'
  | 'md:border-indigo-600'
  | 'lg:border-indigo-600'
  | 'xl:border-indigo-600'
  | 'hover:border-indigo-600'
  | 'focus:border-indigo-600'
  | 'checked:border-indigo-600'
  | 'sm:border-indigo-700'
  | 'md:border-indigo-700'
  | 'lg:border-indigo-700'
  | 'xl:border-indigo-700'
  | 'hover:border-indigo-700'
  | 'focus:border-indigo-700'
  | 'checked:border-indigo-700'
  | 'sm:border-indigo-800'
  | 'md:border-indigo-800'
  | 'lg:border-indigo-800'
  | 'xl:border-indigo-800'
  | 'hover:border-indigo-800'
  | 'focus:border-indigo-800'
  | 'checked:border-indigo-800'
  | 'sm:border-indigo-900'
  | 'md:border-indigo-900'
  | 'lg:border-indigo-900'
  | 'xl:border-indigo-900'
  | 'hover:border-indigo-900'
  | 'focus:border-indigo-900'
  | 'checked:border-indigo-900'
  | 'sm:border-purple-100'
  | 'md:border-purple-100'
  | 'lg:border-purple-100'
  | 'xl:border-purple-100'
  | 'hover:border-purple-100'
  | 'focus:border-purple-100'
  | 'checked:border-purple-100'
  | 'sm:border-purple-200'
  | 'md:border-purple-200'
  | 'lg:border-purple-200'
  | 'xl:border-purple-200'
  | 'hover:border-purple-200'
  | 'focus:border-purple-200'
  | 'checked:border-purple-200'
  | 'sm:border-purple-300'
  | 'md:border-purple-300'
  | 'lg:border-purple-300'
  | 'xl:border-purple-300'
  | 'hover:border-purple-300'
  | 'focus:border-purple-300'
  | 'checked:border-purple-300'
  | 'sm:border-purple-400'
  | 'md:border-purple-400'
  | 'lg:border-purple-400'
  | 'xl:border-purple-400'
  | 'hover:border-purple-400'
  | 'focus:border-purple-400'
  | 'checked:border-purple-400'
  | 'sm:border-purple-500'
  | 'md:border-purple-500'
  | 'lg:border-purple-500'
  | 'xl:border-purple-500'
  | 'hover:border-purple-500'
  | 'focus:border-purple-500'
  | 'checked:border-purple-500'
  | 'sm:border-purple-600'
  | 'md:border-purple-600'
  | 'lg:border-purple-600'
  | 'xl:border-purple-600'
  | 'hover:border-purple-600'
  | 'focus:border-purple-600'
  | 'checked:border-purple-600'
  | 'sm:border-purple-700'
  | 'md:border-purple-700'
  | 'lg:border-purple-700'
  | 'xl:border-purple-700'
  | 'hover:border-purple-700'
  | 'focus:border-purple-700'
  | 'checked:border-purple-700'
  | 'sm:border-purple-800'
  | 'md:border-purple-800'
  | 'lg:border-purple-800'
  | 'xl:border-purple-800'
  | 'hover:border-purple-800'
  | 'focus:border-purple-800'
  | 'checked:border-purple-800'
  | 'sm:border-purple-900'
  | 'md:border-purple-900'
  | 'lg:border-purple-900'
  | 'xl:border-purple-900'
  | 'hover:border-purple-900'
  | 'focus:border-purple-900'
  | 'checked:border-purple-900'
  | 'sm:border-pink-100'
  | 'md:border-pink-100'
  | 'lg:border-pink-100'
  | 'xl:border-pink-100'
  | 'hover:border-pink-100'
  | 'focus:border-pink-100'
  | 'checked:border-pink-100'
  | 'sm:border-pink-200'
  | 'md:border-pink-200'
  | 'lg:border-pink-200'
  | 'xl:border-pink-200'
  | 'hover:border-pink-200'
  | 'focus:border-pink-200'
  | 'checked:border-pink-200'
  | 'sm:border-pink-300'
  | 'md:border-pink-300'
  | 'lg:border-pink-300'
  | 'xl:border-pink-300'
  | 'hover:border-pink-300'
  | 'focus:border-pink-300'
  | 'checked:border-pink-300'
  | 'sm:border-pink-400'
  | 'md:border-pink-400'
  | 'lg:border-pink-400'
  | 'xl:border-pink-400'
  | 'hover:border-pink-400'
  | 'focus:border-pink-400'
  | 'checked:border-pink-400'
  | 'sm:border-pink-500'
  | 'md:border-pink-500'
  | 'lg:border-pink-500'
  | 'xl:border-pink-500'
  | 'hover:border-pink-500'
  | 'focus:border-pink-500'
  | 'checked:border-pink-500'
  | 'sm:border-pink-600'
  | 'md:border-pink-600'
  | 'lg:border-pink-600'
  | 'xl:border-pink-600'
  | 'hover:border-pink-600'
  | 'focus:border-pink-600'
  | 'checked:border-pink-600'
  | 'sm:border-pink-700'
  | 'md:border-pink-700'
  | 'lg:border-pink-700'
  | 'xl:border-pink-700'
  | 'hover:border-pink-700'
  | 'focus:border-pink-700'
  | 'checked:border-pink-700'
  | 'sm:border-pink-800'
  | 'md:border-pink-800'
  | 'lg:border-pink-800'
  | 'xl:border-pink-800'
  | 'hover:border-pink-800'
  | 'focus:border-pink-800'
  | 'checked:border-pink-800'
  | 'sm:border-pink-900'
  | 'md:border-pink-900'
  | 'lg:border-pink-900'
  | 'xl:border-pink-900'
  | 'hover:border-pink-900'
  | 'focus:border-pink-900'
  | 'checked:border-pink-900'
  | 'sm:border-opacity-0'
  | 'md:border-opacity-0'
  | 'lg:border-opacity-0'
  | 'xl:border-opacity-0'
  | 'hover:border-opacity-0'
  | 'focus:border-opacity-0'
  | 'sm:border-opacity-25'
  | 'md:border-opacity-25'
  | 'lg:border-opacity-25'
  | 'xl:border-opacity-25'
  | 'hover:border-opacity-25'
  | 'focus:border-opacity-25'
  | 'sm:border-opacity-50'
  | 'md:border-opacity-50'
  | 'lg:border-opacity-50'
  | 'xl:border-opacity-50'
  | 'hover:border-opacity-50'
  | 'focus:border-opacity-50'
  | 'sm:border-opacity-75'
  | 'md:border-opacity-75'
  | 'lg:border-opacity-75'
  | 'xl:border-opacity-75'
  | 'hover:border-opacity-75'
  | 'focus:border-opacity-75'
  | 'sm:border-opacity-100'
  | 'md:border-opacity-100'
  | 'lg:border-opacity-100'
  | 'xl:border-opacity-100'
  | 'hover:border-opacity-100'
  | 'focus:border-opacity-100'
  | 'sm:rounded-t-none'
  | 'md:rounded-t-none'
  | 'lg:rounded-t-none'
  | 'xl:rounded-t-none'
  | 'sm:rounded-r-none'
  | 'md:rounded-r-none'
  | 'lg:rounded-r-none'
  | 'xl:rounded-r-none'
  | 'sm:rounded-b-none'
  | 'md:rounded-b-none'
  | 'lg:rounded-b-none'
  | 'xl:rounded-b-none'
  | 'sm:rounded-l-none'
  | 'md:rounded-l-none'
  | 'lg:rounded-l-none'
  | 'xl:rounded-l-none'
  | 'sm:rounded-tr-none'
  | 'md:rounded-tr-none'
  | 'lg:rounded-tr-none'
  | 'xl:rounded-tr-none'
  | 'sm:rounded-tl-none'
  | 'md:rounded-tl-none'
  | 'lg:rounded-tl-none'
  | 'xl:rounded-tl-none'
  | 'sm:rounded-br-none'
  | 'md:rounded-br-none'
  | 'lg:rounded-br-none'
  | 'xl:rounded-br-none'
  | 'sm:rounded-bl-none'
  | 'md:rounded-bl-none'
  | 'lg:rounded-bl-none'
  | 'xl:rounded-bl-none'
  | 'sm:rounded-none'
  | 'md:rounded-none'
  | 'lg:rounded-none'
  | 'xl:rounded-none'
  | 'sm:rounded-t-sm'
  | 'md:rounded-t-sm'
  | 'lg:rounded-t-sm'
  | 'xl:rounded-t-sm'
  | 'sm:rounded-r-sm'
  | 'md:rounded-r-sm'
  | 'lg:rounded-r-sm'
  | 'xl:rounded-r-sm'
  | 'sm:rounded-b-sm'
  | 'md:rounded-b-sm'
  | 'lg:rounded-b-sm'
  | 'xl:rounded-b-sm'
  | 'sm:rounded-l-sm'
  | 'md:rounded-l-sm'
  | 'lg:rounded-l-sm'
  | 'xl:rounded-l-sm'
  | 'sm:rounded-tr-sm'
  | 'md:rounded-tr-sm'
  | 'lg:rounded-tr-sm'
  | 'xl:rounded-tr-sm'
  | 'sm:rounded-tl-sm'
  | 'md:rounded-tl-sm'
  | 'lg:rounded-tl-sm'
  | 'xl:rounded-tl-sm'
  | 'sm:rounded-br-sm'
  | 'md:rounded-br-sm'
  | 'lg:rounded-br-sm'
  | 'xl:rounded-br-sm'
  | 'sm:rounded-bl-sm'
  | 'md:rounded-bl-sm'
  | 'lg:rounded-bl-sm'
  | 'xl:rounded-bl-sm'
  | 'sm:rounded-sm'
  | 'md:rounded-sm'
  | 'lg:rounded-sm'
  | 'xl:rounded-sm'
  | 'sm:rounded-t'
  | 'md:rounded-t'
  | 'lg:rounded-t'
  | 'xl:rounded-t'
  | 'sm:rounded-r'
  | 'md:rounded-r'
  | 'lg:rounded-r'
  | 'xl:rounded-r'
  | 'sm:rounded-b'
  | 'md:rounded-b'
  | 'lg:rounded-b'
  | 'xl:rounded-b'
  | 'sm:rounded-l'
  | 'md:rounded-l'
  | 'lg:rounded-l'
  | 'xl:rounded-l'
  | 'sm:rounded-tr'
  | 'md:rounded-tr'
  | 'lg:rounded-tr'
  | 'xl:rounded-tr'
  | 'sm:rounded-tl'
  | 'md:rounded-tl'
  | 'lg:rounded-tl'
  | 'xl:rounded-tl'
  | 'sm:rounded-br'
  | 'md:rounded-br'
  | 'lg:rounded-br'
  | 'xl:rounded-br'
  | 'sm:rounded-bl'
  | 'md:rounded-bl'
  | 'lg:rounded-bl'
  | 'xl:rounded-bl'
  | 'sm:rounded'
  | 'md:rounded'
  | 'lg:rounded'
  | 'xl:rounded'
  | 'sm:rounded-t-md'
  | 'md:rounded-t-md'
  | 'lg:rounded-t-md'
  | 'xl:rounded-t-md'
  | 'sm:rounded-r-md'
  | 'md:rounded-r-md'
  | 'lg:rounded-r-md'
  | 'xl:rounded-r-md'
  | 'sm:rounded-b-md'
  | 'md:rounded-b-md'
  | 'lg:rounded-b-md'
  | 'xl:rounded-b-md'
  | 'sm:rounded-l-md'
  | 'md:rounded-l-md'
  | 'lg:rounded-l-md'
  | 'xl:rounded-l-md'
  | 'sm:rounded-tr-md'
  | 'md:rounded-tr-md'
  | 'lg:rounded-tr-md'
  | 'xl:rounded-tr-md'
  | 'sm:rounded-tl-md'
  | 'md:rounded-tl-md'
  | 'lg:rounded-tl-md'
  | 'xl:rounded-tl-md'
  | 'sm:rounded-br-md'
  | 'md:rounded-br-md'
  | 'lg:rounded-br-md'
  | 'xl:rounded-br-md'
  | 'sm:rounded-bl-md'
  | 'md:rounded-bl-md'
  | 'lg:rounded-bl-md'
  | 'xl:rounded-bl-md'
  | 'sm:rounded-md'
  | 'md:rounded-md'
  | 'lg:rounded-md'
  | 'xl:rounded-md'
  | 'sm:rounded-t-lg'
  | 'md:rounded-t-lg'
  | 'lg:rounded-t-lg'
  | 'xl:rounded-t-lg'
  | 'sm:rounded-r-lg'
  | 'md:rounded-r-lg'
  | 'lg:rounded-r-lg'
  | 'xl:rounded-r-lg'
  | 'sm:rounded-b-lg'
  | 'md:rounded-b-lg'
  | 'lg:rounded-b-lg'
  | 'xl:rounded-b-lg'
  | 'sm:rounded-l-lg'
  | 'md:rounded-l-lg'
  | 'lg:rounded-l-lg'
  | 'xl:rounded-l-lg'
  | 'sm:rounded-tr-lg'
  | 'md:rounded-tr-lg'
  | 'lg:rounded-tr-lg'
  | 'xl:rounded-tr-lg'
  | 'sm:rounded-tl-lg'
  | 'md:rounded-tl-lg'
  | 'lg:rounded-tl-lg'
  | 'xl:rounded-tl-lg'
  | 'sm:rounded-br-lg'
  | 'md:rounded-br-lg'
  | 'lg:rounded-br-lg'
  | 'xl:rounded-br-lg'
  | 'sm:rounded-bl-lg'
  | 'md:rounded-bl-lg'
  | 'lg:rounded-bl-lg'
  | 'xl:rounded-bl-lg'
  | 'sm:rounded-lg'
  | 'md:rounded-lg'
  | 'lg:rounded-lg'
  | 'xl:rounded-lg'
  | 'sm:rounded-t-xl'
  | 'md:rounded-t-xl'
  | 'lg:rounded-t-xl'
  | 'xl:rounded-t-xl'
  | 'sm:rounded-r-xl'
  | 'md:rounded-r-xl'
  | 'lg:rounded-r-xl'
  | 'xl:rounded-r-xl'
  | 'sm:rounded-b-xl'
  | 'md:rounded-b-xl'
  | 'lg:rounded-b-xl'
  | 'xl:rounded-b-xl'
  | 'sm:rounded-l-xl'
  | 'md:rounded-l-xl'
  | 'lg:rounded-l-xl'
  | 'xl:rounded-l-xl'
  | 'sm:rounded-tr-xl'
  | 'md:rounded-tr-xl'
  | 'lg:rounded-tr-xl'
  | 'xl:rounded-tr-xl'
  | 'sm:rounded-tl-xl'
  | 'md:rounded-tl-xl'
  | 'lg:rounded-tl-xl'
  | 'xl:rounded-tl-xl'
  | 'sm:rounded-br-xl'
  | 'md:rounded-br-xl'
  | 'lg:rounded-br-xl'
  | 'xl:rounded-br-xl'
  | 'sm:rounded-bl-xl'
  | 'md:rounded-bl-xl'
  | 'lg:rounded-bl-xl'
  | 'xl:rounded-bl-xl'
  | 'sm:rounded-xl'
  | 'md:rounded-xl'
  | 'lg:rounded-xl'
  | 'xl:rounded-xl'
  | 'sm:rounded-t-2xl'
  | 'md:rounded-t-2xl'
  | 'lg:rounded-t-2xl'
  | 'xl:rounded-t-2xl'
  | 'sm:rounded-r-2xl'
  | 'md:rounded-r-2xl'
  | 'lg:rounded-r-2xl'
  | 'xl:rounded-r-2xl'
  | 'sm:rounded-b-2xl'
  | 'md:rounded-b-2xl'
  | 'lg:rounded-b-2xl'
  | 'xl:rounded-b-2xl'
  | 'sm:rounded-l-2xl'
  | 'md:rounded-l-2xl'
  | 'lg:rounded-l-2xl'
  | 'xl:rounded-l-2xl'
  | 'sm:rounded-tr-2xl'
  | 'md:rounded-tr-2xl'
  | 'lg:rounded-tr-2xl'
  | 'xl:rounded-tr-2xl'
  | 'sm:rounded-tl-2xl'
  | 'md:rounded-tl-2xl'
  | 'lg:rounded-tl-2xl'
  | 'xl:rounded-tl-2xl'
  | 'sm:rounded-br-2xl'
  | 'md:rounded-br-2xl'
  | 'lg:rounded-br-2xl'
  | 'xl:rounded-br-2xl'
  | 'sm:rounded-bl-2xl'
  | 'md:rounded-bl-2xl'
  | 'lg:rounded-bl-2xl'
  | 'xl:rounded-bl-2xl'
  | 'sm:rounded-2xl'
  | 'md:rounded-2xl'
  | 'lg:rounded-2xl'
  | 'xl:rounded-2xl'
  | 'sm:rounded-t-3xl'
  | 'md:rounded-t-3xl'
  | 'lg:rounded-t-3xl'
  | 'xl:rounded-t-3xl'
  | 'sm:rounded-r-3xl'
  | 'md:rounded-r-3xl'
  | 'lg:rounded-r-3xl'
  | 'xl:rounded-r-3xl'
  | 'sm:rounded-b-3xl'
  | 'md:rounded-b-3xl'
  | 'lg:rounded-b-3xl'
  | 'xl:rounded-b-3xl'
  | 'sm:rounded-l-3xl'
  | 'md:rounded-l-3xl'
  | 'lg:rounded-l-3xl'
  | 'xl:rounded-l-3xl'
  | 'sm:rounded-tr-3xl'
  | 'md:rounded-tr-3xl'
  | 'lg:rounded-tr-3xl'
  | 'xl:rounded-tr-3xl'
  | 'sm:rounded-tl-3xl'
  | 'md:rounded-tl-3xl'
  | 'lg:rounded-tl-3xl'
  | 'xl:rounded-tl-3xl'
  | 'sm:rounded-br-3xl'
  | 'md:rounded-br-3xl'
  | 'lg:rounded-br-3xl'
  | 'xl:rounded-br-3xl'
  | 'sm:rounded-bl-3xl'
  | 'md:rounded-bl-3xl'
  | 'lg:rounded-bl-3xl'
  | 'xl:rounded-bl-3xl'
  | 'sm:rounded-3xl'
  | 'md:rounded-3xl'
  | 'lg:rounded-3xl'
  | 'xl:rounded-3xl'
  | 'sm:rounded-t-full'
  | 'md:rounded-t-full'
  | 'lg:rounded-t-full'
  | 'xl:rounded-t-full'
  | 'sm:rounded-r-full'
  | 'md:rounded-r-full'
  | 'lg:rounded-r-full'
  | 'xl:rounded-r-full'
  | 'sm:rounded-b-full'
  | 'md:rounded-b-full'
  | 'lg:rounded-b-full'
  | 'xl:rounded-b-full'
  | 'sm:rounded-l-full'
  | 'md:rounded-l-full'
  | 'lg:rounded-l-full'
  | 'xl:rounded-l-full'
  | 'sm:rounded-tr-full'
  | 'md:rounded-tr-full'
  | 'lg:rounded-tr-full'
  | 'xl:rounded-tr-full'
  | 'sm:rounded-tl-full'
  | 'md:rounded-tl-full'
  | 'lg:rounded-tl-full'
  | 'xl:rounded-tl-full'
  | 'sm:rounded-br-full'
  | 'md:rounded-br-full'
  | 'lg:rounded-br-full'
  | 'xl:rounded-br-full'
  | 'sm:rounded-bl-full'
  | 'md:rounded-bl-full'
  | 'lg:rounded-bl-full'
  | 'xl:rounded-bl-full'
  | 'sm:rounded-full'
  | 'md:rounded-full'
  | 'lg:rounded-full'
  | 'xl:rounded-full'
  | 'sm:rounded-t-cardborder'
  | 'md:rounded-t-cardborder'
  | 'lg:rounded-t-cardborder'
  | 'xl:rounded-t-cardborder'
  | 'sm:rounded-r-cardborder'
  | 'md:rounded-r-cardborder'
  | 'lg:rounded-r-cardborder'
  | 'xl:rounded-r-cardborder'
  | 'sm:rounded-b-cardborder'
  | 'md:rounded-b-cardborder'
  | 'lg:rounded-b-cardborder'
  | 'xl:rounded-b-cardborder'
  | 'sm:rounded-l-cardborder'
  | 'md:rounded-l-cardborder'
  | 'lg:rounded-l-cardborder'
  | 'xl:rounded-l-cardborder'
  | 'sm:rounded-tr-cardborder'
  | 'md:rounded-tr-cardborder'
  | 'lg:rounded-tr-cardborder'
  | 'xl:rounded-tr-cardborder'
  | 'sm:rounded-tl-cardborder'
  | 'md:rounded-tl-cardborder'
  | 'lg:rounded-tl-cardborder'
  | 'xl:rounded-tl-cardborder'
  | 'sm:rounded-br-cardborder'
  | 'md:rounded-br-cardborder'
  | 'lg:rounded-br-cardborder'
  | 'xl:rounded-br-cardborder'
  | 'sm:rounded-bl-cardborder'
  | 'md:rounded-bl-cardborder'
  | 'lg:rounded-bl-cardborder'
  | 'xl:rounded-bl-cardborder'
  | 'sm:rounded-cardborder'
  | 'md:rounded-cardborder'
  | 'lg:rounded-cardborder'
  | 'xl:rounded-cardborder'
  | 'sm:border-solid'
  | 'md:border-solid'
  | 'lg:border-solid'
  | 'xl:border-solid'
  | 'sm:border-dashed'
  | 'md:border-dashed'
  | 'lg:border-dashed'
  | 'xl:border-dashed'
  | 'sm:border-dotted'
  | 'md:border-dotted'
  | 'lg:border-dotted'
  | 'xl:border-dotted'
  | 'sm:border-double'
  | 'md:border-double'
  | 'lg:border-double'
  | 'xl:border-double'
  | 'sm:border-none'
  | 'md:border-none'
  | 'lg:border-none'
  | 'xl:border-none'
  | 'sm:border-t-0'
  | 'md:border-t-0'
  | 'lg:border-t-0'
  | 'xl:border-t-0'
  | 'sm:border-r-0'
  | 'md:border-r-0'
  | 'lg:border-r-0'
  | 'xl:border-r-0'
  | 'sm:border-b-0'
  | 'md:border-b-0'
  | 'lg:border-b-0'
  | 'xl:border-b-0'
  | 'sm:border-l-0'
  | 'md:border-l-0'
  | 'lg:border-l-0'
  | 'xl:border-l-0'
  | 'sm:border-0'
  | 'md:border-0'
  | 'lg:border-0'
  | 'xl:border-0'
  | 'sm:border-t-2'
  | 'md:border-t-2'
  | 'lg:border-t-2'
  | 'xl:border-t-2'
  | 'sm:border-r-2'
  | 'md:border-r-2'
  | 'lg:border-r-2'
  | 'xl:border-r-2'
  | 'sm:border-b-2'
  | 'md:border-b-2'
  | 'lg:border-b-2'
  | 'xl:border-b-2'
  | 'sm:border-l-2'
  | 'md:border-l-2'
  | 'lg:border-l-2'
  | 'xl:border-l-2'
  | 'sm:border-2'
  | 'md:border-2'
  | 'lg:border-2'
  | 'xl:border-2'
  | 'sm:border-t-4'
  | 'md:border-t-4'
  | 'lg:border-t-4'
  | 'xl:border-t-4'
  | 'sm:border-r-4'
  | 'md:border-r-4'
  | 'lg:border-r-4'
  | 'xl:border-r-4'
  | 'sm:border-b-4'
  | 'md:border-b-4'
  | 'lg:border-b-4'
  | 'xl:border-b-4'
  | 'sm:border-l-4'
  | 'md:border-l-4'
  | 'lg:border-l-4'
  | 'xl:border-l-4'
  | 'sm:border-4'
  | 'md:border-4'
  | 'lg:border-4'
  | 'xl:border-4'
  | 'sm:border-t-8'
  | 'md:border-t-8'
  | 'lg:border-t-8'
  | 'xl:border-t-8'
  | 'sm:border-r-8'
  | 'md:border-r-8'
  | 'lg:border-r-8'
  | 'xl:border-r-8'
  | 'sm:border-b-8'
  | 'md:border-b-8'
  | 'lg:border-b-8'
  | 'xl:border-b-8'
  | 'sm:border-l-8'
  | 'md:border-l-8'
  | 'lg:border-l-8'
  | 'xl:border-l-8'
  | 'sm:border-8'
  | 'md:border-8'
  | 'lg:border-8'
  | 'xl:border-8'
  | 'sm:border-t'
  | 'md:border-t'
  | 'lg:border-t'
  | 'xl:border-t'
  | 'sm:border-r'
  | 'md:border-r'
  | 'lg:border-r'
  | 'xl:border-r'
  | 'sm:border-b'
  | 'md:border-b'
  | 'lg:border-b'
  | 'xl:border-b'
  | 'sm:border-l'
  | 'md:border-l'
  | 'lg:border-l'
  | 'xl:border-l'
  | 'sm:border'
  | 'md:border'
  | 'lg:border'
  | 'xl:border'
  | 'sm:border-t-cardborder'
  | 'md:border-t-cardborder'
  | 'lg:border-t-cardborder'
  | 'xl:border-t-cardborder'
  | 'sm:border-r-cardborder'
  | 'md:border-r-cardborder'
  | 'lg:border-r-cardborder'
  | 'xl:border-r-cardborder'
  | 'sm:border-b-cardborder'
  | 'md:border-b-cardborder'
  | 'lg:border-b-cardborder'
  | 'xl:border-b-cardborder'
  | 'sm:border-l-cardborder'
  | 'md:border-l-cardborder'
  | 'lg:border-l-cardborder'
  | 'xl:border-l-cardborder'
  | 'sm:border-cardborder'
  | 'md:border-cardborder'
  | 'lg:border-cardborder'
  | 'xl:border-cardborder'
  | 'sm:shadow-xs'
  | 'md:shadow-xs'
  | 'lg:shadow-xs'
  | 'xl:shadow-xs'
  | 'hover:shadow-xs'
  | 'focus:shadow-xs'
  | 'sm:shadow-sm'
  | 'md:shadow-sm'
  | 'lg:shadow-sm'
  | 'xl:shadow-sm'
  | 'hover:shadow-sm'
  | 'focus:shadow-sm'
  | 'sm:shadow'
  | 'md:shadow'
  | 'lg:shadow'
  | 'xl:shadow'
  | 'hover:shadow'
  | 'focus:shadow'
  | 'sm:shadow-md'
  | 'md:shadow-md'
  | 'lg:shadow-md'
  | 'xl:shadow-md'
  | 'hover:shadow-md'
  | 'focus:shadow-md'
  | 'sm:shadow-lg'
  | 'md:shadow-lg'
  | 'lg:shadow-lg'
  | 'xl:shadow-lg'
  | 'hover:shadow-lg'
  | 'focus:shadow-lg'
  | 'sm:shadow-xl'
  | 'md:shadow-xl'
  | 'lg:shadow-xl'
  | 'xl:shadow-xl'
  | 'hover:shadow-xl'
  | 'focus:shadow-xl'
  | 'sm:shadow-2xl'
  | 'md:shadow-2xl'
  | 'lg:shadow-2xl'
  | 'xl:shadow-2xl'
  | 'hover:shadow-2xl'
  | 'focus:shadow-2xl'
  | 'sm:shadow-inner'
  | 'md:shadow-inner'
  | 'lg:shadow-inner'
  | 'xl:shadow-inner'
  | 'hover:shadow-inner'
  | 'focus:shadow-inner'
  | 'sm:shadow-outline'
  | 'md:shadow-outline'
  | 'lg:shadow-outline'
  | 'xl:shadow-outline'
  | 'hover:shadow-outline'
  | 'focus:shadow-outline'
  | 'sm:shadow-none'
  | 'md:shadow-none'
  | 'lg:shadow-none'
  | 'xl:shadow-none'
  | 'hover:shadow-none'
  | 'focus:shadow-none'
  | 'sm:box-border'
  | 'md:box-border'
  | 'lg:box-border'
  | 'xl:box-border'
  | 'sm:box-content'
  | 'md:box-content'
  | 'lg:box-content'
  | 'xl:box-content'
  | 'sm:container'
  | 'md:container'
  | 'lg:container'
  | 'xl:container'
  | 'sm:cursor-auto'
  | 'md:cursor-auto'
  | 'lg:cursor-auto'
  | 'xl:cursor-auto'
  | 'sm:cursor-default'
  | 'md:cursor-default'
  | 'lg:cursor-default'
  | 'xl:cursor-default'
  | 'sm:cursor-pointer'
  | 'md:cursor-pointer'
  | 'lg:cursor-pointer'
  | 'xl:cursor-pointer'
  | 'sm:cursor-wait'
  | 'md:cursor-wait'
  | 'lg:cursor-wait'
  | 'xl:cursor-wait'
  | 'sm:cursor-text'
  | 'md:cursor-text'
  | 'lg:cursor-text'
  | 'xl:cursor-text'
  | 'sm:cursor-move'
  | 'md:cursor-move'
  | 'lg:cursor-move'
  | 'xl:cursor-move'
  | 'sm:cursor-not-allowed'
  | 'md:cursor-not-allowed'
  | 'lg:cursor-not-allowed'
  | 'xl:cursor-not-allowed'
  | 'sm:block'
  | 'md:block'
  | 'lg:block'
  | 'xl:block'
  | 'group-hover:block'
  | 'sm:inline-block'
  | 'md:inline-block'
  | 'lg:inline-block'
  | 'xl:inline-block'
  | 'group-hover:inline-block'
  | 'sm:inline'
  | 'md:inline'
  | 'lg:inline'
  | 'xl:inline'
  | 'group-hover:inline'
  | 'sm:flex'
  | 'md:flex'
  | 'lg:flex'
  | 'xl:flex'
  | 'group-hover:flex'
  | 'sm:inline-flex'
  | 'md:inline-flex'
  | 'lg:inline-flex'
  | 'xl:inline-flex'
  | 'group-hover:inline-flex'
  | 'sm:grid'
  | 'md:grid'
  | 'lg:grid'
  | 'xl:grid'
  | 'group-hover:grid'
  | 'sm:inline-grid'
  | 'md:inline-grid'
  | 'lg:inline-grid'
  | 'xl:inline-grid'
  | 'group-hover:inline-grid'
  | 'sm:table'
  | 'md:table'
  | 'lg:table'
  | 'xl:table'
  | 'group-hover:table'
  | 'sm:table-row'
  | 'md:table-row'
  | 'lg:table-row'
  | 'xl:table-row'
  | 'group-hover:table-row'
  | 'sm:table-cell'
  | 'md:table-cell'
  | 'lg:table-cell'
  | 'xl:table-cell'
  | 'group-hover:table-cell'
  | 'sm:contents'
  | 'md:contents'
  | 'lg:contents'
  | 'xl:contents'
  | 'group-hover:contents'
  | 'sm:hidden'
  | 'md:hidden'
  | 'lg:hidden'
  | 'xl:hidden'
  | 'group-hover:hidden'
  | 'sm:divide-transparent'
  | 'md:divide-transparent'
  | 'lg:divide-transparent'
  | 'xl:divide-transparent'
  | 'sm:divide-current'
  | 'md:divide-current'
  | 'lg:divide-current'
  | 'xl:divide-current'
  | 'sm:divide-black'
  | 'md:divide-black'
  | 'lg:divide-black'
  | 'xl:divide-black'
  | 'sm:divide-white'
  | 'md:divide-white'
  | 'lg:divide-white'
  | 'xl:divide-white'
  | 'sm:divide-gray-100'
  | 'md:divide-gray-100'
  | 'lg:divide-gray-100'
  | 'xl:divide-gray-100'
  | 'sm:divide-gray-200'
  | 'md:divide-gray-200'
  | 'lg:divide-gray-200'
  | 'xl:divide-gray-200'
  | 'sm:divide-gray-300'
  | 'md:divide-gray-300'
  | 'lg:divide-gray-300'
  | 'xl:divide-gray-300'
  | 'sm:divide-gray-400'
  | 'md:divide-gray-400'
  | 'lg:divide-gray-400'
  | 'xl:divide-gray-400'
  | 'sm:divide-gray-500'
  | 'md:divide-gray-500'
  | 'lg:divide-gray-500'
  | 'xl:divide-gray-500'
  | 'sm:divide-gray-600'
  | 'md:divide-gray-600'
  | 'lg:divide-gray-600'
  | 'xl:divide-gray-600'
  | 'sm:divide-gray-700'
  | 'md:divide-gray-700'
  | 'lg:divide-gray-700'
  | 'xl:divide-gray-700'
  | 'sm:divide-gray-800'
  | 'md:divide-gray-800'
  | 'lg:divide-gray-800'
  | 'xl:divide-gray-800'
  | 'sm:divide-gray-900'
  | 'md:divide-gray-900'
  | 'lg:divide-gray-900'
  | 'xl:divide-gray-900'
  | 'sm:divide-red-100'
  | 'md:divide-red-100'
  | 'lg:divide-red-100'
  | 'xl:divide-red-100'
  | 'sm:divide-red-200'
  | 'md:divide-red-200'
  | 'lg:divide-red-200'
  | 'xl:divide-red-200'
  | 'sm:divide-red-300'
  | 'md:divide-red-300'
  | 'lg:divide-red-300'
  | 'xl:divide-red-300'
  | 'sm:divide-red-400'
  | 'md:divide-red-400'
  | 'lg:divide-red-400'
  | 'xl:divide-red-400'
  | 'sm:divide-red-500'
  | 'md:divide-red-500'
  | 'lg:divide-red-500'
  | 'xl:divide-red-500'
  | 'sm:divide-red-600'
  | 'md:divide-red-600'
  | 'lg:divide-red-600'
  | 'xl:divide-red-600'
  | 'sm:divide-red-700'
  | 'md:divide-red-700'
  | 'lg:divide-red-700'
  | 'xl:divide-red-700'
  | 'sm:divide-red-800'
  | 'md:divide-red-800'
  | 'lg:divide-red-800'
  | 'xl:divide-red-800'
  | 'sm:divide-red-900'
  | 'md:divide-red-900'
  | 'lg:divide-red-900'
  | 'xl:divide-red-900'
  | 'sm:divide-orange-100'
  | 'md:divide-orange-100'
  | 'lg:divide-orange-100'
  | 'xl:divide-orange-100'
  | 'sm:divide-orange-200'
  | 'md:divide-orange-200'
  | 'lg:divide-orange-200'
  | 'xl:divide-orange-200'
  | 'sm:divide-orange-300'
  | 'md:divide-orange-300'
  | 'lg:divide-orange-300'
  | 'xl:divide-orange-300'
  | 'sm:divide-orange-400'
  | 'md:divide-orange-400'
  | 'lg:divide-orange-400'
  | 'xl:divide-orange-400'
  | 'sm:divide-orange-500'
  | 'md:divide-orange-500'
  | 'lg:divide-orange-500'
  | 'xl:divide-orange-500'
  | 'sm:divide-orange-600'
  | 'md:divide-orange-600'
  | 'lg:divide-orange-600'
  | 'xl:divide-orange-600'
  | 'sm:divide-orange-700'
  | 'md:divide-orange-700'
  | 'lg:divide-orange-700'
  | 'xl:divide-orange-700'
  | 'sm:divide-orange-800'
  | 'md:divide-orange-800'
  | 'lg:divide-orange-800'
  | 'xl:divide-orange-800'
  | 'sm:divide-orange-900'
  | 'md:divide-orange-900'
  | 'lg:divide-orange-900'
  | 'xl:divide-orange-900'
  | 'sm:divide-yellow-100'
  | 'md:divide-yellow-100'
  | 'lg:divide-yellow-100'
  | 'xl:divide-yellow-100'
  | 'sm:divide-yellow-200'
  | 'md:divide-yellow-200'
  | 'lg:divide-yellow-200'
  | 'xl:divide-yellow-200'
  | 'sm:divide-yellow-300'
  | 'md:divide-yellow-300'
  | 'lg:divide-yellow-300'
  | 'xl:divide-yellow-300'
  | 'sm:divide-yellow-400'
  | 'md:divide-yellow-400'
  | 'lg:divide-yellow-400'
  | 'xl:divide-yellow-400'
  | 'sm:divide-yellow-500'
  | 'md:divide-yellow-500'
  | 'lg:divide-yellow-500'
  | 'xl:divide-yellow-500'
  | 'sm:divide-yellow-600'
  | 'md:divide-yellow-600'
  | 'lg:divide-yellow-600'
  | 'xl:divide-yellow-600'
  | 'sm:divide-yellow-700'
  | 'md:divide-yellow-700'
  | 'lg:divide-yellow-700'
  | 'xl:divide-yellow-700'
  | 'sm:divide-yellow-800'
  | 'md:divide-yellow-800'
  | 'lg:divide-yellow-800'
  | 'xl:divide-yellow-800'
  | 'sm:divide-yellow-900'
  | 'md:divide-yellow-900'
  | 'lg:divide-yellow-900'
  | 'xl:divide-yellow-900'
  | 'sm:divide-green-100'
  | 'md:divide-green-100'
  | 'lg:divide-green-100'
  | 'xl:divide-green-100'
  | 'sm:divide-green-200'
  | 'md:divide-green-200'
  | 'lg:divide-green-200'
  | 'xl:divide-green-200'
  | 'sm:divide-green-300'
  | 'md:divide-green-300'
  | 'lg:divide-green-300'
  | 'xl:divide-green-300'
  | 'sm:divide-green-400'
  | 'md:divide-green-400'
  | 'lg:divide-green-400'
  | 'xl:divide-green-400'
  | 'sm:divide-green-500'
  | 'md:divide-green-500'
  | 'lg:divide-green-500'
  | 'xl:divide-green-500'
  | 'sm:divide-green-600'
  | 'md:divide-green-600'
  | 'lg:divide-green-600'
  | 'xl:divide-green-600'
  | 'sm:divide-green-700'
  | 'md:divide-green-700'
  | 'lg:divide-green-700'
  | 'xl:divide-green-700'
  | 'sm:divide-green-800'
  | 'md:divide-green-800'
  | 'lg:divide-green-800'
  | 'xl:divide-green-800'
  | 'sm:divide-green-900'
  | 'md:divide-green-900'
  | 'lg:divide-green-900'
  | 'xl:divide-green-900'
  | 'sm:divide-teal-100'
  | 'md:divide-teal-100'
  | 'lg:divide-teal-100'
  | 'xl:divide-teal-100'
  | 'sm:divide-teal-200'
  | 'md:divide-teal-200'
  | 'lg:divide-teal-200'
  | 'xl:divide-teal-200'
  | 'sm:divide-teal-300'
  | 'md:divide-teal-300'
  | 'lg:divide-teal-300'
  | 'xl:divide-teal-300'
  | 'sm:divide-teal-400'
  | 'md:divide-teal-400'
  | 'lg:divide-teal-400'
  | 'xl:divide-teal-400'
  | 'sm:divide-teal-500'
  | 'md:divide-teal-500'
  | 'lg:divide-teal-500'
  | 'xl:divide-teal-500'
  | 'sm:divide-teal-600'
  | 'md:divide-teal-600'
  | 'lg:divide-teal-600'
  | 'xl:divide-teal-600'
  | 'sm:divide-teal-700'
  | 'md:divide-teal-700'
  | 'lg:divide-teal-700'
  | 'xl:divide-teal-700'
  | 'sm:divide-teal-800'
  | 'md:divide-teal-800'
  | 'lg:divide-teal-800'
  | 'xl:divide-teal-800'
  | 'sm:divide-teal-900'
  | 'md:divide-teal-900'
  | 'lg:divide-teal-900'
  | 'xl:divide-teal-900'
  | 'sm:divide-blue-100'
  | 'md:divide-blue-100'
  | 'lg:divide-blue-100'
  | 'xl:divide-blue-100'
  | 'sm:divide-blue-200'
  | 'md:divide-blue-200'
  | 'lg:divide-blue-200'
  | 'xl:divide-blue-200'
  | 'sm:divide-blue-300'
  | 'md:divide-blue-300'
  | 'lg:divide-blue-300'
  | 'xl:divide-blue-300'
  | 'sm:divide-blue-400'
  | 'md:divide-blue-400'
  | 'lg:divide-blue-400'
  | 'xl:divide-blue-400'
  | 'sm:divide-blue-500'
  | 'md:divide-blue-500'
  | 'lg:divide-blue-500'
  | 'xl:divide-blue-500'
  | 'sm:divide-blue-600'
  | 'md:divide-blue-600'
  | 'lg:divide-blue-600'
  | 'xl:divide-blue-600'
  | 'sm:divide-blue-700'
  | 'md:divide-blue-700'
  | 'lg:divide-blue-700'
  | 'xl:divide-blue-700'
  | 'sm:divide-blue-800'
  | 'md:divide-blue-800'
  | 'lg:divide-blue-800'
  | 'xl:divide-blue-800'
  | 'sm:divide-blue-900'
  | 'md:divide-blue-900'
  | 'lg:divide-blue-900'
  | 'xl:divide-blue-900'
  | 'sm:divide-indigo-100'
  | 'md:divide-indigo-100'
  | 'lg:divide-indigo-100'
  | 'xl:divide-indigo-100'
  | 'sm:divide-indigo-200'
  | 'md:divide-indigo-200'
  | 'lg:divide-indigo-200'
  | 'xl:divide-indigo-200'
  | 'sm:divide-indigo-300'
  | 'md:divide-indigo-300'
  | 'lg:divide-indigo-300'
  | 'xl:divide-indigo-300'
  | 'sm:divide-indigo-400'
  | 'md:divide-indigo-400'
  | 'lg:divide-indigo-400'
  | 'xl:divide-indigo-400'
  | 'sm:divide-indigo-500'
  | 'md:divide-indigo-500'
  | 'lg:divide-indigo-500'
  | 'xl:divide-indigo-500'
  | 'sm:divide-indigo-600'
  | 'md:divide-indigo-600'
  | 'lg:divide-indigo-600'
  | 'xl:divide-indigo-600'
  | 'sm:divide-indigo-700'
  | 'md:divide-indigo-700'
  | 'lg:divide-indigo-700'
  | 'xl:divide-indigo-700'
  | 'sm:divide-indigo-800'
  | 'md:divide-indigo-800'
  | 'lg:divide-indigo-800'
  | 'xl:divide-indigo-800'
  | 'sm:divide-indigo-900'
  | 'md:divide-indigo-900'
  | 'lg:divide-indigo-900'
  | 'xl:divide-indigo-900'
  | 'sm:divide-purple-100'
  | 'md:divide-purple-100'
  | 'lg:divide-purple-100'
  | 'xl:divide-purple-100'
  | 'sm:divide-purple-200'
  | 'md:divide-purple-200'
  | 'lg:divide-purple-200'
  | 'xl:divide-purple-200'
  | 'sm:divide-purple-300'
  | 'md:divide-purple-300'
  | 'lg:divide-purple-300'
  | 'xl:divide-purple-300'
  | 'sm:divide-purple-400'
  | 'md:divide-purple-400'
  | 'lg:divide-purple-400'
  | 'xl:divide-purple-400'
  | 'sm:divide-purple-500'
  | 'md:divide-purple-500'
  | 'lg:divide-purple-500'
  | 'xl:divide-purple-500'
  | 'sm:divide-purple-600'
  | 'md:divide-purple-600'
  | 'lg:divide-purple-600'
  | 'xl:divide-purple-600'
  | 'sm:divide-purple-700'
  | 'md:divide-purple-700'
  | 'lg:divide-purple-700'
  | 'xl:divide-purple-700'
  | 'sm:divide-purple-800'
  | 'md:divide-purple-800'
  | 'lg:divide-purple-800'
  | 'xl:divide-purple-800'
  | 'sm:divide-purple-900'
  | 'md:divide-purple-900'
  | 'lg:divide-purple-900'
  | 'xl:divide-purple-900'
  | 'sm:divide-pink-100'
  | 'md:divide-pink-100'
  | 'lg:divide-pink-100'
  | 'xl:divide-pink-100'
  | 'sm:divide-pink-200'
  | 'md:divide-pink-200'
  | 'lg:divide-pink-200'
  | 'xl:divide-pink-200'
  | 'sm:divide-pink-300'
  | 'md:divide-pink-300'
  | 'lg:divide-pink-300'
  | 'xl:divide-pink-300'
  | 'sm:divide-pink-400'
  | 'md:divide-pink-400'
  | 'lg:divide-pink-400'
  | 'xl:divide-pink-400'
  | 'sm:divide-pink-500'
  | 'md:divide-pink-500'
  | 'lg:divide-pink-500'
  | 'xl:divide-pink-500'
  | 'sm:divide-pink-600'
  | 'md:divide-pink-600'
  | 'lg:divide-pink-600'
  | 'xl:divide-pink-600'
  | 'sm:divide-pink-700'
  | 'md:divide-pink-700'
  | 'lg:divide-pink-700'
  | 'xl:divide-pink-700'
  | 'sm:divide-pink-800'
  | 'md:divide-pink-800'
  | 'lg:divide-pink-800'
  | 'xl:divide-pink-800'
  | 'sm:divide-pink-900'
  | 'md:divide-pink-900'
  | 'lg:divide-pink-900'
  | 'xl:divide-pink-900'
  | 'sm:divide-opacity-0'
  | 'md:divide-opacity-0'
  | 'lg:divide-opacity-0'
  | 'xl:divide-opacity-0'
  | 'sm:divide-opacity-25'
  | 'md:divide-opacity-25'
  | 'lg:divide-opacity-25'
  | 'xl:divide-opacity-25'
  | 'sm:divide-opacity-50'
  | 'md:divide-opacity-50'
  | 'lg:divide-opacity-50'
  | 'xl:divide-opacity-50'
  | 'sm:divide-opacity-75'
  | 'md:divide-opacity-75'
  | 'lg:divide-opacity-75'
  | 'xl:divide-opacity-75'
  | 'sm:divide-opacity-100'
  | 'md:divide-opacity-100'
  | 'lg:divide-opacity-100'
  | 'xl:divide-opacity-100'
  | 'sm:divide-solid'
  | 'md:divide-solid'
  | 'lg:divide-solid'
  | 'xl:divide-solid'
  | 'sm:divide-dashed'
  | 'md:divide-dashed'
  | 'lg:divide-dashed'
  | 'xl:divide-dashed'
  | 'sm:divide-dotted'
  | 'md:divide-dotted'
  | 'lg:divide-dotted'
  | 'xl:divide-dotted'
  | 'sm:divide-double'
  | 'md:divide-double'
  | 'lg:divide-double'
  | 'xl:divide-double'
  | 'sm:divide-none'
  | 'md:divide-none'
  | 'lg:divide-none'
  | 'xl:divide-none'
  | 'sm:divide-x-0'
  | 'md:divide-x-0'
  | 'lg:divide-x-0'
  | 'xl:divide-x-0'
  | 'sm:divide-y-0'
  | 'md:divide-y-0'
  | 'lg:divide-y-0'
  | 'xl:divide-y-0'
  | 'sm:divide-x-2'
  | 'md:divide-x-2'
  | 'lg:divide-x-2'
  | 'xl:divide-x-2'
  | 'sm:divide-y-2'
  | 'md:divide-y-2'
  | 'lg:divide-y-2'
  | 'xl:divide-y-2'
  | 'sm:divide-x-4'
  | 'md:divide-x-4'
  | 'lg:divide-x-4'
  | 'xl:divide-x-4'
  | 'sm:divide-y-4'
  | 'md:divide-y-4'
  | 'lg:divide-y-4'
  | 'xl:divide-y-4'
  | 'sm:divide-x-8'
  | 'md:divide-x-8'
  | 'lg:divide-x-8'
  | 'xl:divide-x-8'
  | 'sm:divide-y-8'
  | 'md:divide-y-8'
  | 'lg:divide-y-8'
  | 'xl:divide-y-8'
  | 'sm:divide-x'
  | 'md:divide-x'
  | 'lg:divide-x'
  | 'xl:divide-x'
  | 'sm:divide-y'
  | 'md:divide-y'
  | 'lg:divide-y'
  | 'xl:divide-y'
  | 'sm:divide-x-cardborder'
  | 'md:divide-x-cardborder'
  | 'lg:divide-x-cardborder'
  | 'xl:divide-x-cardborder'
  | 'sm:divide-y-cardborder'
  | 'md:divide-y-cardborder'
  | 'lg:divide-y-cardborder'
  | 'xl:divide-y-cardborder'
  | 'sm:divide-x-reverse'
  | 'md:divide-x-reverse'
  | 'lg:divide-x-reverse'
  | 'xl:divide-x-reverse'
  | 'sm:divide-y-reverse'
  | 'md:divide-y-reverse'
  | 'lg:divide-y-reverse'
  | 'xl:divide-y-reverse'
  | 'sm:fill-current'
  | 'md:fill-current'
  | 'lg:fill-current'
  | 'xl:fill-current'
  | 'sm:flex-initial'
  | 'md:flex-initial'
  | 'lg:flex-initial'
  | 'xl:flex-initial'
  | 'sm:flex-1'
  | 'md:flex-1'
  | 'lg:flex-1'
  | 'xl:flex-1'
  | 'sm:flex-auto'
  | 'md:flex-auto'
  | 'lg:flex-auto'
  | 'xl:flex-auto'
  | 'sm:flex-none'
  | 'md:flex-none'
  | 'lg:flex-none'
  | 'xl:flex-none'
  | 'sm:flex-row'
  | 'md:flex-row'
  | 'lg:flex-row'
  | 'xl:flex-row'
  | 'sm:flex-row-reverse'
  | 'md:flex-row-reverse'
  | 'lg:flex-row-reverse'
  | 'xl:flex-row-reverse'
  | 'sm:flex-col'
  | 'md:flex-col'
  | 'lg:flex-col'
  | 'xl:flex-col'
  | 'sm:flex-col-reverse'
  | 'md:flex-col-reverse'
  | 'lg:flex-col-reverse'
  | 'xl:flex-col-reverse'
  | 'sm:flex-grow-0'
  | 'md:flex-grow-0'
  | 'lg:flex-grow-0'
  | 'xl:flex-grow-0'
  | 'sm:flex-grow'
  | 'md:flex-grow'
  | 'lg:flex-grow'
  | 'xl:flex-grow'
  | 'sm:flex-shrink-0'
  | 'md:flex-shrink-0'
  | 'lg:flex-shrink-0'
  | 'xl:flex-shrink-0'
  | 'sm:flex-shrink'
  | 'md:flex-shrink'
  | 'lg:flex-shrink'
  | 'xl:flex-shrink'
  | 'sm:flex-no-wrap'
  | 'md:flex-no-wrap'
  | 'lg:flex-no-wrap'
  | 'xl:flex-no-wrap'
  | 'sm:flex-wrap'
  | 'md:flex-wrap'
  | 'lg:flex-wrap'
  | 'xl:flex-wrap'
  | 'sm:flex-wrap-reverse'
  | 'md:flex-wrap-reverse'
  | 'lg:flex-wrap-reverse'
  | 'xl:flex-wrap-reverse'
  | 'sm:float-right'
  | 'md:float-right'
  | 'lg:float-right'
  | 'xl:float-right'
  | 'sm:float-left'
  | 'md:float-left'
  | 'lg:float-left'
  | 'xl:float-left'
  | 'sm:float-none'
  | 'md:float-none'
  | 'lg:float-none'
  | 'xl:float-none'
  | 'sm:clearfix'
  | 'md:clearfix'
  | 'lg:clearfix'
  | 'xl:clearfix'
  | 'sm:clear-left'
  | 'md:clear-left'
  | 'lg:clear-left'
  | 'xl:clear-left'
  | 'sm:clear-right'
  | 'md:clear-right'
  | 'lg:clear-right'
  | 'xl:clear-right'
  | 'sm:clear-both'
  | 'md:clear-both'
  | 'lg:clear-both'
  | 'xl:clear-both'
  | 'sm:clear-none'
  | 'md:clear-none'
  | 'lg:clear-none'
  | 'xl:clear-none'
  | 'sm:font-sans'
  | 'md:font-sans'
  | 'lg:font-sans'
  | 'xl:font-sans'
  | 'sm:font-serif'
  | 'md:font-serif'
  | 'lg:font-serif'
  | 'xl:font-serif'
  | 'sm:font-mono'
  | 'md:font-mono'
  | 'lg:font-mono'
  | 'xl:font-mono'
  | 'sm:text-xs'
  | 'md:text-xs'
  | 'lg:text-xs'
  | 'xl:text-xs'
  | 'sm:text-sm'
  | 'md:text-sm'
  | 'lg:text-sm'
  | 'xl:text-sm'
  | 'sm:text-base'
  | 'md:text-base'
  | 'lg:text-base'
  | 'xl:text-base'
  | 'sm:text-lg'
  | 'md:text-lg'
  | 'lg:text-lg'
  | 'xl:text-lg'
  | 'sm:text-xl'
  | 'md:text-xl'
  | 'lg:text-xl'
  | 'xl:text-xl'
  | 'sm:text-2xl'
  | 'md:text-2xl'
  | 'lg:text-2xl'
  | 'xl:text-2xl'
  | 'sm:text-3xl'
  | 'md:text-3xl'
  | 'lg:text-3xl'
  | 'xl:text-3xl'
  | 'sm:text-4xl'
  | 'md:text-4xl'
  | 'lg:text-4xl'
  | 'xl:text-4xl'
  | 'sm:text-5xl'
  | 'md:text-5xl'
  | 'lg:text-5xl'
  | 'xl:text-5xl'
  | 'sm:text-6xl'
  | 'md:text-6xl'
  | 'lg:text-6xl'
  | 'xl:text-6xl'
  | 'sm:antialiased'
  | 'md:antialiased'
  | 'lg:antialiased'
  | 'xl:antialiased'
  | 'sm:subpixel-antialiased'
  | 'md:subpixel-antialiased'
  | 'lg:subpixel-antialiased'
  | 'xl:subpixel-antialiased'
  | 'sm:normal-nums'
  | 'md:normal-nums'
  | 'lg:normal-nums'
  | 'xl:normal-nums'
  | 'sm:ordinal'
  | 'md:ordinal'
  | 'lg:ordinal'
  | 'xl:ordinal'
  | 'sm:slashed-zero'
  | 'md:slashed-zero'
  | 'lg:slashed-zero'
  | 'xl:slashed-zero'
  | 'sm:lining-nums'
  | 'md:lining-nums'
  | 'lg:lining-nums'
  | 'xl:lining-nums'
  | 'sm:oldstyle-nums'
  | 'md:oldstyle-nums'
  | 'lg:oldstyle-nums'
  | 'xl:oldstyle-nums'
  | 'sm:proportional-nums'
  | 'md:proportional-nums'
  | 'lg:proportional-nums'
  | 'xl:proportional-nums'
  | 'sm:tabular-nums'
  | 'md:tabular-nums'
  | 'lg:tabular-nums'
  | 'xl:tabular-nums'
  | 'sm:diagonal-fractions'
  | 'md:diagonal-fractions'
  | 'lg:diagonal-fractions'
  | 'xl:diagonal-fractions'
  | 'sm:stacked-fractions'
  | 'md:stacked-fractions'
  | 'lg:stacked-fractions'
  | 'xl:stacked-fractions'
  | 'sm:italic'
  | 'md:italic'
  | 'lg:italic'
  | 'xl:italic'
  | 'sm:non-italic'
  | 'md:non-italic'
  | 'lg:non-italic'
  | 'xl:non-italic'
  | 'sm:font-hairline'
  | 'md:font-hairline'
  | 'lg:font-hairline'
  | 'xl:font-hairline'
  | 'hover:font-hairline'
  | 'focus:font-hairline'
  | 'sm:font-thin'
  | 'md:font-thin'
  | 'lg:font-thin'
  | 'xl:font-thin'
  | 'hover:font-thin'
  | 'focus:font-thin'
  | 'sm:font-light'
  | 'md:font-light'
  | 'lg:font-light'
  | 'xl:font-light'
  | 'hover:font-light'
  | 'focus:font-light'
  | 'sm:font-normal'
  | 'md:font-normal'
  | 'lg:font-normal'
  | 'xl:font-normal'
  | 'hover:font-normal'
  | 'focus:font-normal'
  | 'sm:font-medium'
  | 'md:font-medium'
  | 'lg:font-medium'
  | 'xl:font-medium'
  | 'hover:font-medium'
  | 'focus:font-medium'
  | 'sm:font-semibold'
  | 'md:font-semibold'
  | 'lg:font-semibold'
  | 'xl:font-semibold'
  | 'hover:font-semibold'
  | 'focus:font-semibold'
  | 'sm:font-bold'
  | 'md:font-bold'
  | 'lg:font-bold'
  | 'xl:font-bold'
  | 'hover:font-bold'
  | 'focus:font-bold'
  | 'sm:font-extrabold'
  | 'md:font-extrabold'
  | 'lg:font-extrabold'
  | 'xl:font-extrabold'
  | 'hover:font-extrabold'
  | 'focus:font-extrabold'
  | 'sm:font-black'
  | 'md:font-black'
  | 'lg:font-black'
  | 'xl:font-black'
  | 'hover:font-black'
  | 'focus:font-black'
  | 'sm:h-0'
  | 'md:h-0'
  | 'lg:h-0'
  | 'xl:h-0'
  | 'sm:h-1'
  | 'md:h-1'
  | 'lg:h-1'
  | 'xl:h-1'
  | 'sm:h-2'
  | 'md:h-2'
  | 'lg:h-2'
  | 'xl:h-2'
  | 'sm:h-3'
  | 'md:h-3'
  | 'lg:h-3'
  | 'xl:h-3'
  | 'sm:h-4'
  | 'md:h-4'
  | 'lg:h-4'
  | 'xl:h-4'
  | 'sm:h-5'
  | 'md:h-5'
  | 'lg:h-5'
  | 'xl:h-5'
  | 'sm:h-6'
  | 'md:h-6'
  | 'lg:h-6'
  | 'xl:h-6'
  | 'sm:h-8'
  | 'md:h-8'
  | 'lg:h-8'
  | 'xl:h-8'
  | 'sm:h-10'
  | 'md:h-10'
  | 'lg:h-10'
  | 'xl:h-10'
  | 'sm:h-12'
  | 'md:h-12'
  | 'lg:h-12'
  | 'xl:h-12'
  | 'sm:h-16'
  | 'md:h-16'
  | 'lg:h-16'
  | 'xl:h-16'
  | 'sm:h-20'
  | 'md:h-20'
  | 'lg:h-20'
  | 'xl:h-20'
  | 'sm:h-24'
  | 'md:h-24'
  | 'lg:h-24'
  | 'xl:h-24'
  | 'sm:h-32'
  | 'md:h-32'
  | 'lg:h-32'
  | 'xl:h-32'
  | 'sm:h-40'
  | 'md:h-40'
  | 'lg:h-40'
  | 'xl:h-40'
  | 'sm:h-48'
  | 'md:h-48'
  | 'lg:h-48'
  | 'xl:h-48'
  | 'sm:h-56'
  | 'md:h-56'
  | 'lg:h-56'
  | 'xl:h-56'
  | 'sm:h-64'
  | 'md:h-64'
  | 'lg:h-64'
  | 'xl:h-64'
  | 'sm:h-auto'
  | 'md:h-auto'
  | 'lg:h-auto'
  | 'xl:h-auto'
  | 'sm:h-px'
  | 'md:h-px'
  | 'lg:h-px'
  | 'xl:h-px'
  | 'sm:h-full'
  | 'md:h-full'
  | 'lg:h-full'
  | 'xl:h-full'
  | 'sm:h-screen'
  | 'md:h-screen'
  | 'lg:h-screen'
  | 'xl:h-screen'
  | 'sm:inset-0'
  | 'md:inset-0'
  | 'lg:inset-0'
  | 'xl:inset-0'
  | 'sm:inset-x-0'
  | 'md:inset-x-0'
  | 'lg:inset-x-0'
  | 'xl:inset-x-0'
  | 'sm:inset-y-0'
  | 'md:inset-y-0'
  | 'lg:inset-y-0'
  | 'xl:inset-y-0'
  | 'sm:top-0'
  | 'md:top-0'
  | 'lg:top-0'
  | 'xl:top-0'
  | 'sm:right-0'
  | 'md:right-0'
  | 'lg:right-0'
  | 'xl:right-0'
  | 'sm:bottom-0'
  | 'md:bottom-0'
  | 'lg:bottom-0'
  | 'xl:bottom-0'
  | 'sm:left-0'
  | 'md:left-0'
  | 'lg:left-0'
  | 'xl:left-0'
  | 'sm:inset-100'
  | 'md:inset-100'
  | 'lg:inset-100'
  | 'xl:inset-100'
  | 'sm:inset-x-100'
  | 'md:inset-x-100'
  | 'lg:inset-x-100'
  | 'xl:inset-x-100'
  | 'sm:inset-y-100'
  | 'md:inset-y-100'
  | 'lg:inset-y-100'
  | 'xl:inset-y-100'
  | 'sm:top-100'
  | 'md:top-100'
  | 'lg:top-100'
  | 'xl:top-100'
  | 'sm:right-100'
  | 'md:right-100'
  | 'lg:right-100'
  | 'xl:right-100'
  | 'sm:bottom-100'
  | 'md:bottom-100'
  | 'lg:bottom-100'
  | 'xl:bottom-100'
  | 'sm:left-100'
  | 'md:left-100'
  | 'lg:left-100'
  | 'xl:left-100'
  | 'sm:inset-auto'
  | 'md:inset-auto'
  | 'lg:inset-auto'
  | 'xl:inset-auto'
  | 'sm:inset-x-auto'
  | 'md:inset-x-auto'
  | 'lg:inset-x-auto'
  | 'xl:inset-x-auto'
  | 'sm:inset-y-auto'
  | 'md:inset-y-auto'
  | 'lg:inset-y-auto'
  | 'xl:inset-y-auto'
  | 'sm:top-auto'
  | 'md:top-auto'
  | 'lg:top-auto'
  | 'xl:top-auto'
  | 'sm:right-auto'
  | 'md:right-auto'
  | 'lg:right-auto'
  | 'xl:right-auto'
  | 'sm:bottom-auto'
  | 'md:bottom-auto'
  | 'lg:bottom-auto'
  | 'xl:bottom-auto'
  | 'sm:left-auto'
  | 'md:left-auto'
  | 'lg:left-auto'
  | 'xl:left-auto'
  | 'sm:-inset-50'
  | 'md:-inset-50'
  | 'lg:-inset-50'
  | 'xl:-inset-50'
  | 'sm:-inset-x-50'
  | 'md:-inset-x-50'
  | 'lg:-inset-x-50'
  | 'xl:-inset-x-50'
  | 'sm:-inset-y-50'
  | 'md:-inset-y-50'
  | 'lg:-inset-y-50'
  | 'xl:-inset-y-50'
  | 'sm:-top-50'
  | 'md:-top-50'
  | 'lg:-top-50'
  | 'xl:-top-50'
  | 'sm:-right-50'
  | 'md:-right-50'
  | 'lg:-right-50'
  | 'xl:-right-50'
  | 'sm:-bottom-50'
  | 'md:-bottom-50'
  | 'lg:-bottom-50'
  | 'xl:-bottom-50'
  | 'sm:-left-50'
  | 'md:-left-50'
  | 'lg:-left-50'
  | 'xl:-left-50'
  | 'sm:justify-start'
  | 'md:justify-start'
  | 'lg:justify-start'
  | 'xl:justify-start'
  | 'sm:justify-center'
  | 'md:justify-center'
  | 'lg:justify-center'
  | 'xl:justify-center'
  | 'sm:justify-end'
  | 'md:justify-end'
  | 'lg:justify-end'
  | 'xl:justify-end'
  | 'sm:justify-between'
  | 'md:justify-between'
  | 'lg:justify-between'
  | 'xl:justify-between'
  | 'sm:justify-around'
  | 'md:justify-around'
  | 'lg:justify-around'
  | 'xl:justify-around'
  | 'sm:justify-items-auto'
  | 'md:justify-items-auto'
  | 'lg:justify-items-auto'
  | 'xl:justify-items-auto'
  | 'sm:justify-items-start'
  | 'md:justify-items-start'
  | 'lg:justify-items-start'
  | 'xl:justify-items-start'
  | 'sm:justify-items-center'
  | 'md:justify-items-center'
  | 'lg:justify-items-center'
  | 'xl:justify-items-center'
  | 'sm:justify-items-end'
  | 'md:justify-items-end'
  | 'lg:justify-items-end'
  | 'xl:justify-items-end'
  | 'sm:justify-items-stretch'
  | 'md:justify-items-stretch'
  | 'lg:justify-items-stretch'
  | 'xl:justify-items-stretch'
  | 'sm:justify-self-auto'
  | 'md:justify-self-auto'
  | 'lg:justify-self-auto'
  | 'xl:justify-self-auto'
  | 'sm:justify-self-start'
  | 'md:justify-self-start'
  | 'lg:justify-self-start'
  | 'xl:justify-self-start'
  | 'sm:justify-self-center'
  | 'md:justify-self-center'
  | 'lg:justify-self-center'
  | 'xl:justify-self-center'
  | 'sm:justify-self-end'
  | 'md:justify-self-end'
  | 'lg:justify-self-end'
  | 'xl:justify-self-end'
  | 'sm:justify-self-stretch'
  | 'md:justify-self-stretch'
  | 'lg:justify-self-stretch'
  | 'xl:justify-self-stretch'
  | 'sm:tracking-tighter'
  | 'md:tracking-tighter'
  | 'lg:tracking-tighter'
  | 'xl:tracking-tighter'
  | 'sm:tracking-tight'
  | 'md:tracking-tight'
  | 'lg:tracking-tight'
  | 'xl:tracking-tight'
  | 'sm:tracking-normal'
  | 'md:tracking-normal'
  | 'lg:tracking-normal'
  | 'xl:tracking-normal'
  | 'sm:tracking-wide'
  | 'md:tracking-wide'
  | 'lg:tracking-wide'
  | 'xl:tracking-wide'
  | 'sm:tracking-wider'
  | 'md:tracking-wider'
  | 'lg:tracking-wider'
  | 'xl:tracking-wider'
  | 'sm:tracking-widest'
  | 'md:tracking-widest'
  | 'lg:tracking-widest'
  | 'xl:tracking-widest'
  | 'sm:leading-3'
  | 'md:leading-3'
  | 'lg:leading-3'
  | 'xl:leading-3'
  | 'sm:leading-4'
  | 'md:leading-4'
  | 'lg:leading-4'
  | 'xl:leading-4'
  | 'sm:leading-5'
  | 'md:leading-5'
  | 'lg:leading-5'
  | 'xl:leading-5'
  | 'sm:leading-6'
  | 'md:leading-6'
  | 'lg:leading-6'
  | 'xl:leading-6'
  | 'sm:leading-7'
  | 'md:leading-7'
  | 'lg:leading-7'
  | 'xl:leading-7'
  | 'sm:leading-8'
  | 'md:leading-8'
  | 'lg:leading-8'
  | 'xl:leading-8'
  | 'sm:leading-9'
  | 'md:leading-9'
  | 'lg:leading-9'
  | 'xl:leading-9'
  | 'sm:leading-10'
  | 'md:leading-10'
  | 'lg:leading-10'
  | 'xl:leading-10'
  | 'sm:leading-none'
  | 'md:leading-none'
  | 'lg:leading-none'
  | 'xl:leading-none'
  | 'sm:leading-tight'
  | 'md:leading-tight'
  | 'lg:leading-tight'
  | 'xl:leading-tight'
  | 'sm:leading-snug'
  | 'md:leading-snug'
  | 'lg:leading-snug'
  | 'xl:leading-snug'
  | 'sm:leading-normal'
  | 'md:leading-normal'
  | 'lg:leading-normal'
  | 'xl:leading-normal'
  | 'sm:leading-relaxed'
  | 'md:leading-relaxed'
  | 'lg:leading-relaxed'
  | 'xl:leading-relaxed'
  | 'sm:leading-loose'
  | 'md:leading-loose'
  | 'lg:leading-loose'
  | 'xl:leading-loose'
  | 'sm:list-inside'
  | 'md:list-inside'
  | 'lg:list-inside'
  | 'xl:list-inside'
  | 'sm:list-outside'
  | 'md:list-outside'
  | 'lg:list-outside'
  | 'xl:list-outside'
  | 'sm:list-none'
  | 'md:list-none'
  | 'lg:list-none'
  | 'xl:list-none'
  | 'sm:list-disc'
  | 'md:list-disc'
  | 'lg:list-disc'
  | 'xl:list-disc'
  | 'sm:list-decimal'
  | 'md:list-decimal'
  | 'lg:list-decimal'
  | 'xl:list-decimal'
  | 'sm:m-0'
  | 'md:m-0'
  | 'lg:m-0'
  | 'xl:m-0'
  | 'hover:m-0'
  | 'focus:m-0'
  | 'sm:m-1'
  | 'md:m-1'
  | 'lg:m-1'
  | 'xl:m-1'
  | 'hover:m-1'
  | 'focus:m-1'
  | 'sm:m-2'
  | 'md:m-2'
  | 'lg:m-2'
  | 'xl:m-2'
  | 'hover:m-2'
  | 'focus:m-2'
  | 'sm:m-3'
  | 'md:m-3'
  | 'lg:m-3'
  | 'xl:m-3'
  | 'hover:m-3'
  | 'focus:m-3'
  | 'sm:m-4'
  | 'md:m-4'
  | 'lg:m-4'
  | 'xl:m-4'
  | 'hover:m-4'
  | 'focus:m-4'
  | 'sm:m-5'
  | 'md:m-5'
  | 'lg:m-5'
  | 'xl:m-5'
  | 'hover:m-5'
  | 'focus:m-5'
  | 'sm:m-6'
  | 'md:m-6'
  | 'lg:m-6'
  | 'xl:m-6'
  | 'hover:m-6'
  | 'focus:m-6'
  | 'sm:m-8'
  | 'md:m-8'
  | 'lg:m-8'
  | 'xl:m-8'
  | 'hover:m-8'
  | 'focus:m-8'
  | 'sm:m-10'
  | 'md:m-10'
  | 'lg:m-10'
  | 'xl:m-10'
  | 'hover:m-10'
  | 'focus:m-10'
  | 'sm:m-12'
  | 'md:m-12'
  | 'lg:m-12'
  | 'xl:m-12'
  | 'hover:m-12'
  | 'focus:m-12'
  | 'sm:m-16'
  | 'md:m-16'
  | 'lg:m-16'
  | 'xl:m-16'
  | 'hover:m-16'
  | 'focus:m-16'
  | 'sm:m-20'
  | 'md:m-20'
  | 'lg:m-20'
  | 'xl:m-20'
  | 'hover:m-20'
  | 'focus:m-20'
  | 'sm:m-24'
  | 'md:m-24'
  | 'lg:m-24'
  | 'xl:m-24'
  | 'hover:m-24'
  | 'focus:m-24'
  | 'sm:m-32'
  | 'md:m-32'
  | 'lg:m-32'
  | 'xl:m-32'
  | 'hover:m-32'
  | 'focus:m-32'
  | 'sm:m-40'
  | 'md:m-40'
  | 'lg:m-40'
  | 'xl:m-40'
  | 'hover:m-40'
  | 'focus:m-40'
  | 'sm:m-48'
  | 'md:m-48'
  | 'lg:m-48'
  | 'xl:m-48'
  | 'hover:m-48'
  | 'focus:m-48'
  | 'sm:m-56'
  | 'md:m-56'
  | 'lg:m-56'
  | 'xl:m-56'
  | 'hover:m-56'
  | 'focus:m-56'
  | 'sm:m-64'
  | 'md:m-64'
  | 'lg:m-64'
  | 'xl:m-64'
  | 'hover:m-64'
  | 'focus:m-64'
  | 'sm:m-auto'
  | 'md:m-auto'
  | 'lg:m-auto'
  | 'xl:m-auto'
  | 'hover:m-auto'
  | 'focus:m-auto'
  | 'sm:m-px'
  | 'md:m-px'
  | 'lg:m-px'
  | 'xl:m-px'
  | 'hover:m-px'
  | 'focus:m-px'
  | 'sm:-m-0'
  | 'md:-m-0'
  | 'lg:-m-0'
  | 'xl:-m-0'
  | 'hover:-m-0'
  | 'focus:-m-0'
  | 'sm:-m-1'
  | 'md:-m-1'
  | 'lg:-m-1'
  | 'xl:-m-1'
  | 'hover:-m-1'
  | 'focus:-m-1'
  | 'sm:-m-2'
  | 'md:-m-2'
  | 'lg:-m-2'
  | 'xl:-m-2'
  | 'hover:-m-2'
  | 'focus:-m-2'
  | 'sm:-m-3'
  | 'md:-m-3'
  | 'lg:-m-3'
  | 'xl:-m-3'
  | 'hover:-m-3'
  | 'focus:-m-3'
  | 'sm:-m-4'
  | 'md:-m-4'
  | 'lg:-m-4'
  | 'xl:-m-4'
  | 'hover:-m-4'
  | 'focus:-m-4'
  | 'sm:-m-5'
  | 'md:-m-5'
  | 'lg:-m-5'
  | 'xl:-m-5'
  | 'hover:-m-5'
  | 'focus:-m-5'
  | 'sm:-m-6'
  | 'md:-m-6'
  | 'lg:-m-6'
  | 'xl:-m-6'
  | 'hover:-m-6'
  | 'focus:-m-6'
  | 'sm:-m-8'
  | 'md:-m-8'
  | 'lg:-m-8'
  | 'xl:-m-8'
  | 'hover:-m-8'
  | 'focus:-m-8'
  | 'sm:-m-10'
  | 'md:-m-10'
  | 'lg:-m-10'
  | 'xl:-m-10'
  | 'hover:-m-10'
  | 'focus:-m-10'
  | 'sm:-m-12'
  | 'md:-m-12'
  | 'lg:-m-12'
  | 'xl:-m-12'
  | 'hover:-m-12'
  | 'focus:-m-12'
  | 'sm:-m-16'
  | 'md:-m-16'
  | 'lg:-m-16'
  | 'xl:-m-16'
  | 'hover:-m-16'
  | 'focus:-m-16'
  | 'sm:-m-20'
  | 'md:-m-20'
  | 'lg:-m-20'
  | 'xl:-m-20'
  | 'hover:-m-20'
  | 'focus:-m-20'
  | 'sm:-m-24'
  | 'md:-m-24'
  | 'lg:-m-24'
  | 'xl:-m-24'
  | 'hover:-m-24'
  | 'focus:-m-24'
  | 'sm:-m-32'
  | 'md:-m-32'
  | 'lg:-m-32'
  | 'xl:-m-32'
  | 'hover:-m-32'
  | 'focus:-m-32'
  | 'sm:-m-40'
  | 'md:-m-40'
  | 'lg:-m-40'
  | 'xl:-m-40'
  | 'hover:-m-40'
  | 'focus:-m-40'
  | 'sm:-m-48'
  | 'md:-m-48'
  | 'lg:-m-48'
  | 'xl:-m-48'
  | 'hover:-m-48'
  | 'focus:-m-48'
  | 'sm:-m-56'
  | 'md:-m-56'
  | 'lg:-m-56'
  | 'xl:-m-56'
  | 'hover:-m-56'
  | 'focus:-m-56'
  | 'sm:-m-64'
  | 'md:-m-64'
  | 'lg:-m-64'
  | 'xl:-m-64'
  | 'hover:-m-64'
  | 'focus:-m-64'
  | 'sm:-m-px'
  | 'md:-m-px'
  | 'lg:-m-px'
  | 'xl:-m-px'
  | 'hover:-m-px'
  | 'focus:-m-px'
  | 'sm:my-0'
  | 'md:my-0'
  | 'lg:my-0'
  | 'xl:my-0'
  | 'hover:my-0'
  | 'focus:my-0'
  | 'sm:my-1'
  | 'md:my-1'
  | 'lg:my-1'
  | 'xl:my-1'
  | 'hover:my-1'
  | 'focus:my-1'
  | 'sm:my-2'
  | 'md:my-2'
  | 'lg:my-2'
  | 'xl:my-2'
  | 'hover:my-2'
  | 'focus:my-2'
  | 'sm:my-3'
  | 'md:my-3'
  | 'lg:my-3'
  | 'xl:my-3'
  | 'hover:my-3'
  | 'focus:my-3'
  | 'sm:my-4'
  | 'md:my-4'
  | 'lg:my-4'
  | 'xl:my-4'
  | 'hover:my-4'
  | 'focus:my-4'
  | 'sm:my-5'
  | 'md:my-5'
  | 'lg:my-5'
  | 'xl:my-5'
  | 'hover:my-5'
  | 'focus:my-5'
  | 'sm:my-6'
  | 'md:my-6'
  | 'lg:my-6'
  | 'xl:my-6'
  | 'hover:my-6'
  | 'focus:my-6'
  | 'sm:my-8'
  | 'md:my-8'
  | 'lg:my-8'
  | 'xl:my-8'
  | 'hover:my-8'
  | 'focus:my-8'
  | 'sm:my-10'
  | 'md:my-10'
  | 'lg:my-10'
  | 'xl:my-10'
  | 'hover:my-10'
  | 'focus:my-10'
  | 'sm:my-12'
  | 'md:my-12'
  | 'lg:my-12'
  | 'xl:my-12'
  | 'hover:my-12'
  | 'focus:my-12'
  | 'sm:my-16'
  | 'md:my-16'
  | 'lg:my-16'
  | 'xl:my-16'
  | 'hover:my-16'
  | 'focus:my-16'
  | 'sm:my-20'
  | 'md:my-20'
  | 'lg:my-20'
  | 'xl:my-20'
  | 'hover:my-20'
  | 'focus:my-20'
  | 'sm:my-24'
  | 'md:my-24'
  | 'lg:my-24'
  | 'xl:my-24'
  | 'hover:my-24'
  | 'focus:my-24'
  | 'sm:my-32'
  | 'md:my-32'
  | 'lg:my-32'
  | 'xl:my-32'
  | 'hover:my-32'
  | 'focus:my-32'
  | 'sm:my-40'
  | 'md:my-40'
  | 'lg:my-40'
  | 'xl:my-40'
  | 'hover:my-40'
  | 'focus:my-40'
  | 'sm:my-48'
  | 'md:my-48'
  | 'lg:my-48'
  | 'xl:my-48'
  | 'hover:my-48'
  | 'focus:my-48'
  | 'sm:my-56'
  | 'md:my-56'
  | 'lg:my-56'
  | 'xl:my-56'
  | 'hover:my-56'
  | 'focus:my-56'
  | 'sm:my-64'
  | 'md:my-64'
  | 'lg:my-64'
  | 'xl:my-64'
  | 'hover:my-64'
  | 'focus:my-64'
  | 'sm:my-auto'
  | 'md:my-auto'
  | 'lg:my-auto'
  | 'xl:my-auto'
  | 'hover:my-auto'
  | 'focus:my-auto'
  | 'sm:my-px'
  | 'md:my-px'
  | 'lg:my-px'
  | 'xl:my-px'
  | 'hover:my-px'
  | 'focus:my-px'
  | 'sm:-my-0'
  | 'md:-my-0'
  | 'lg:-my-0'
  | 'xl:-my-0'
  | 'hover:-my-0'
  | 'focus:-my-0'
  | 'sm:-my-1'
  | 'md:-my-1'
  | 'lg:-my-1'
  | 'xl:-my-1'
  | 'hover:-my-1'
  | 'focus:-my-1'
  | 'sm:-my-2'
  | 'md:-my-2'
  | 'lg:-my-2'
  | 'xl:-my-2'
  | 'hover:-my-2'
  | 'focus:-my-2'
  | 'sm:-my-3'
  | 'md:-my-3'
  | 'lg:-my-3'
  | 'xl:-my-3'
  | 'hover:-my-3'
  | 'focus:-my-3'
  | 'sm:-my-4'
  | 'md:-my-4'
  | 'lg:-my-4'
  | 'xl:-my-4'
  | 'hover:-my-4'
  | 'focus:-my-4'
  | 'sm:-my-5'
  | 'md:-my-5'
  | 'lg:-my-5'
  | 'xl:-my-5'
  | 'hover:-my-5'
  | 'focus:-my-5'
  | 'sm:-my-6'
  | 'md:-my-6'
  | 'lg:-my-6'
  | 'xl:-my-6'
  | 'hover:-my-6'
  | 'focus:-my-6'
  | 'sm:-my-8'
  | 'md:-my-8'
  | 'lg:-my-8'
  | 'xl:-my-8'
  | 'hover:-my-8'
  | 'focus:-my-8'
  | 'sm:-my-10'
  | 'md:-my-10'
  | 'lg:-my-10'
  | 'xl:-my-10'
  | 'hover:-my-10'
  | 'focus:-my-10'
  | 'sm:-my-12'
  | 'md:-my-12'
  | 'lg:-my-12'
  | 'xl:-my-12'
  | 'hover:-my-12'
  | 'focus:-my-12'
  | 'sm:-my-16'
  | 'md:-my-16'
  | 'lg:-my-16'
  | 'xl:-my-16'
  | 'hover:-my-16'
  | 'focus:-my-16'
  | 'sm:-my-20'
  | 'md:-my-20'
  | 'lg:-my-20'
  | 'xl:-my-20'
  | 'hover:-my-20'
  | 'focus:-my-20'
  | 'sm:-my-24'
  | 'md:-my-24'
  | 'lg:-my-24'
  | 'xl:-my-24'
  | 'hover:-my-24'
  | 'focus:-my-24'
  | 'sm:-my-32'
  | 'md:-my-32'
  | 'lg:-my-32'
  | 'xl:-my-32'
  | 'hover:-my-32'
  | 'focus:-my-32'
  | 'sm:-my-40'
  | 'md:-my-40'
  | 'lg:-my-40'
  | 'xl:-my-40'
  | 'hover:-my-40'
  | 'focus:-my-40'
  | 'sm:-my-48'
  | 'md:-my-48'
  | 'lg:-my-48'
  | 'xl:-my-48'
  | 'hover:-my-48'
  | 'focus:-my-48'
  | 'sm:-my-56'
  | 'md:-my-56'
  | 'lg:-my-56'
  | 'xl:-my-56'
  | 'hover:-my-56'
  | 'focus:-my-56'
  | 'sm:-my-64'
  | 'md:-my-64'
  | 'lg:-my-64'
  | 'xl:-my-64'
  | 'hover:-my-64'
  | 'focus:-my-64'
  | 'sm:-my-px'
  | 'md:-my-px'
  | 'lg:-my-px'
  | 'xl:-my-px'
  | 'hover:-my-px'
  | 'focus:-my-px'
  | 'sm:mx-0'
  | 'md:mx-0'
  | 'lg:mx-0'
  | 'xl:mx-0'
  | 'hover:mx-0'
  | 'focus:mx-0'
  | 'sm:mx-1'
  | 'md:mx-1'
  | 'lg:mx-1'
  | 'xl:mx-1'
  | 'hover:mx-1'
  | 'focus:mx-1'
  | 'sm:mx-2'
  | 'md:mx-2'
  | 'lg:mx-2'
  | 'xl:mx-2'
  | 'hover:mx-2'
  | 'focus:mx-2'
  | 'sm:mx-3'
  | 'md:mx-3'
  | 'lg:mx-3'
  | 'xl:mx-3'
  | 'hover:mx-3'
  | 'focus:mx-3'
  | 'sm:mx-4'
  | 'md:mx-4'
  | 'lg:mx-4'
  | 'xl:mx-4'
  | 'hover:mx-4'
  | 'focus:mx-4'
  | 'sm:mx-5'
  | 'md:mx-5'
  | 'lg:mx-5'
  | 'xl:mx-5'
  | 'hover:mx-5'
  | 'focus:mx-5'
  | 'sm:mx-6'
  | 'md:mx-6'
  | 'lg:mx-6'
  | 'xl:mx-6'
  | 'hover:mx-6'
  | 'focus:mx-6'
  | 'sm:mx-8'
  | 'md:mx-8'
  | 'lg:mx-8'
  | 'xl:mx-8'
  | 'hover:mx-8'
  | 'focus:mx-8'
  | 'sm:mx-10'
  | 'md:mx-10'
  | 'lg:mx-10'
  | 'xl:mx-10'
  | 'hover:mx-10'
  | 'focus:mx-10'
  | 'sm:mx-12'
  | 'md:mx-12'
  | 'lg:mx-12'
  | 'xl:mx-12'
  | 'hover:mx-12'
  | 'focus:mx-12'
  | 'sm:mx-16'
  | 'md:mx-16'
  | 'lg:mx-16'
  | 'xl:mx-16'
  | 'hover:mx-16'
  | 'focus:mx-16'
  | 'sm:mx-20'
  | 'md:mx-20'
  | 'lg:mx-20'
  | 'xl:mx-20'
  | 'hover:mx-20'
  | 'focus:mx-20'
  | 'sm:mx-24'
  | 'md:mx-24'
  | 'lg:mx-24'
  | 'xl:mx-24'
  | 'hover:mx-24'
  | 'focus:mx-24'
  | 'sm:mx-32'
  | 'md:mx-32'
  | 'lg:mx-32'
  | 'xl:mx-32'
  | 'hover:mx-32'
  | 'focus:mx-32'
  | 'sm:mx-40'
  | 'md:mx-40'
  | 'lg:mx-40'
  | 'xl:mx-40'
  | 'hover:mx-40'
  | 'focus:mx-40'
  | 'sm:mx-48'
  | 'md:mx-48'
  | 'lg:mx-48'
  | 'xl:mx-48'
  | 'hover:mx-48'
  | 'focus:mx-48'
  | 'sm:mx-56'
  | 'md:mx-56'
  | 'lg:mx-56'
  | 'xl:mx-56'
  | 'hover:mx-56'
  | 'focus:mx-56'
  | 'sm:mx-64'
  | 'md:mx-64'
  | 'lg:mx-64'
  | 'xl:mx-64'
  | 'hover:mx-64'
  | 'focus:mx-64'
  | 'sm:mx-auto'
  | 'md:mx-auto'
  | 'lg:mx-auto'
  | 'xl:mx-auto'
  | 'hover:mx-auto'
  | 'focus:mx-auto'
  | 'sm:mx-px'
  | 'md:mx-px'
  | 'lg:mx-px'
  | 'xl:mx-px'
  | 'hover:mx-px'
  | 'focus:mx-px'
  | 'sm:-mx-0'
  | 'md:-mx-0'
  | 'lg:-mx-0'
  | 'xl:-mx-0'
  | 'hover:-mx-0'
  | 'focus:-mx-0'
  | 'sm:-mx-1'
  | 'md:-mx-1'
  | 'lg:-mx-1'
  | 'xl:-mx-1'
  | 'hover:-mx-1'
  | 'focus:-mx-1'
  | 'sm:-mx-2'
  | 'md:-mx-2'
  | 'lg:-mx-2'
  | 'xl:-mx-2'
  | 'hover:-mx-2'
  | 'focus:-mx-2'
  | 'sm:-mx-3'
  | 'md:-mx-3'
  | 'lg:-mx-3'
  | 'xl:-mx-3'
  | 'hover:-mx-3'
  | 'focus:-mx-3'
  | 'sm:-mx-4'
  | 'md:-mx-4'
  | 'lg:-mx-4'
  | 'xl:-mx-4'
  | 'hover:-mx-4'
  | 'focus:-mx-4'
  | 'sm:-mx-5'
  | 'md:-mx-5'
  | 'lg:-mx-5'
  | 'xl:-mx-5'
  | 'hover:-mx-5'
  | 'focus:-mx-5'
  | 'sm:-mx-6'
  | 'md:-mx-6'
  | 'lg:-mx-6'
  | 'xl:-mx-6'
  | 'hover:-mx-6'
  | 'focus:-mx-6'
  | 'sm:-mx-8'
  | 'md:-mx-8'
  | 'lg:-mx-8'
  | 'xl:-mx-8'
  | 'hover:-mx-8'
  | 'focus:-mx-8'
  | 'sm:-mx-10'
  | 'md:-mx-10'
  | 'lg:-mx-10'
  | 'xl:-mx-10'
  | 'hover:-mx-10'
  | 'focus:-mx-10'
  | 'sm:-mx-12'
  | 'md:-mx-12'
  | 'lg:-mx-12'
  | 'xl:-mx-12'
  | 'hover:-mx-12'
  | 'focus:-mx-12'
  | 'sm:-mx-16'
  | 'md:-mx-16'
  | 'lg:-mx-16'
  | 'xl:-mx-16'
  | 'hover:-mx-16'
  | 'focus:-mx-16'
  | 'sm:-mx-20'
  | 'md:-mx-20'
  | 'lg:-mx-20'
  | 'xl:-mx-20'
  | 'hover:-mx-20'
  | 'focus:-mx-20'
  | 'sm:-mx-24'
  | 'md:-mx-24'
  | 'lg:-mx-24'
  | 'xl:-mx-24'
  | 'hover:-mx-24'
  | 'focus:-mx-24'
  | 'sm:-mx-32'
  | 'md:-mx-32'
  | 'lg:-mx-32'
  | 'xl:-mx-32'
  | 'hover:-mx-32'
  | 'focus:-mx-32'
  | 'sm:-mx-40'
  | 'md:-mx-40'
  | 'lg:-mx-40'
  | 'xl:-mx-40'
  | 'hover:-mx-40'
  | 'focus:-mx-40'
  | 'sm:-mx-48'
  | 'md:-mx-48'
  | 'lg:-mx-48'
  | 'xl:-mx-48'
  | 'hover:-mx-48'
  | 'focus:-mx-48'
  | 'sm:-mx-56'
  | 'md:-mx-56'
  | 'lg:-mx-56'
  | 'xl:-mx-56'
  | 'hover:-mx-56'
  | 'focus:-mx-56'
  | 'sm:-mx-64'
  | 'md:-mx-64'
  | 'lg:-mx-64'
  | 'xl:-mx-64'
  | 'hover:-mx-64'
  | 'focus:-mx-64'
  | 'sm:-mx-px'
  | 'md:-mx-px'
  | 'lg:-mx-px'
  | 'xl:-mx-px'
  | 'hover:-mx-px'
  | 'focus:-mx-px'
  | 'sm:mt-0'
  | 'md:mt-0'
  | 'lg:mt-0'
  | 'xl:mt-0'
  | 'hover:mt-0'
  | 'focus:mt-0'
  | 'sm:mt-1'
  | 'md:mt-1'
  | 'lg:mt-1'
  | 'xl:mt-1'
  | 'hover:mt-1'
  | 'focus:mt-1'
  | 'sm:mt-2'
  | 'md:mt-2'
  | 'lg:mt-2'
  | 'xl:mt-2'
  | 'hover:mt-2'
  | 'focus:mt-2'
  | 'sm:mt-3'
  | 'md:mt-3'
  | 'lg:mt-3'
  | 'xl:mt-3'
  | 'hover:mt-3'
  | 'focus:mt-3'
  | 'sm:mt-4'
  | 'md:mt-4'
  | 'lg:mt-4'
  | 'xl:mt-4'
  | 'hover:mt-4'
  | 'focus:mt-4'
  | 'sm:mt-5'
  | 'md:mt-5'
  | 'lg:mt-5'
  | 'xl:mt-5'
  | 'hover:mt-5'
  | 'focus:mt-5'
  | 'sm:mt-6'
  | 'md:mt-6'
  | 'lg:mt-6'
  | 'xl:mt-6'
  | 'hover:mt-6'
  | 'focus:mt-6'
  | 'sm:mt-8'
  | 'md:mt-8'
  | 'lg:mt-8'
  | 'xl:mt-8'
  | 'hover:mt-8'
  | 'focus:mt-8'
  | 'sm:mt-10'
  | 'md:mt-10'
  | 'lg:mt-10'
  | 'xl:mt-10'
  | 'hover:mt-10'
  | 'focus:mt-10'
  | 'sm:mt-12'
  | 'md:mt-12'
  | 'lg:mt-12'
  | 'xl:mt-12'
  | 'hover:mt-12'
  | 'focus:mt-12'
  | 'sm:mt-16'
  | 'md:mt-16'
  | 'lg:mt-16'
  | 'xl:mt-16'
  | 'hover:mt-16'
  | 'focus:mt-16'
  | 'sm:mt-20'
  | 'md:mt-20'
  | 'lg:mt-20'
  | 'xl:mt-20'
  | 'hover:mt-20'
  | 'focus:mt-20'
  | 'sm:mt-24'
  | 'md:mt-24'
  | 'lg:mt-24'
  | 'xl:mt-24'
  | 'hover:mt-24'
  | 'focus:mt-24'
  | 'sm:mt-32'
  | 'md:mt-32'
  | 'lg:mt-32'
  | 'xl:mt-32'
  | 'hover:mt-32'
  | 'focus:mt-32'
  | 'sm:mt-40'
  | 'md:mt-40'
  | 'lg:mt-40'
  | 'xl:mt-40'
  | 'hover:mt-40'
  | 'focus:mt-40'
  | 'sm:mt-48'
  | 'md:mt-48'
  | 'lg:mt-48'
  | 'xl:mt-48'
  | 'hover:mt-48'
  | 'focus:mt-48'
  | 'sm:mt-56'
  | 'md:mt-56'
  | 'lg:mt-56'
  | 'xl:mt-56'
  | 'hover:mt-56'
  | 'focus:mt-56'
  | 'sm:mt-64'
  | 'md:mt-64'
  | 'lg:mt-64'
  | 'xl:mt-64'
  | 'hover:mt-64'
  | 'focus:mt-64'
  | 'sm:mt-auto'
  | 'md:mt-auto'
  | 'lg:mt-auto'
  | 'xl:mt-auto'
  | 'hover:mt-auto'
  | 'focus:mt-auto'
  | 'sm:mt-px'
  | 'md:mt-px'
  | 'lg:mt-px'
  | 'xl:mt-px'
  | 'hover:mt-px'
  | 'focus:mt-px'
  | 'sm:-mt-0'
  | 'md:-mt-0'
  | 'lg:-mt-0'
  | 'xl:-mt-0'
  | 'hover:-mt-0'
  | 'focus:-mt-0'
  | 'sm:-mt-1'
  | 'md:-mt-1'
  | 'lg:-mt-1'
  | 'xl:-mt-1'
  | 'hover:-mt-1'
  | 'focus:-mt-1'
  | 'sm:-mt-2'
  | 'md:-mt-2'
  | 'lg:-mt-2'
  | 'xl:-mt-2'
  | 'hover:-mt-2'
  | 'focus:-mt-2'
  | 'sm:-mt-3'
  | 'md:-mt-3'
  | 'lg:-mt-3'
  | 'xl:-mt-3'
  | 'hover:-mt-3'
  | 'focus:-mt-3'
  | 'sm:-mt-4'
  | 'md:-mt-4'
  | 'lg:-mt-4'
  | 'xl:-mt-4'
  | 'hover:-mt-4'
  | 'focus:-mt-4'
  | 'sm:-mt-5'
  | 'md:-mt-5'
  | 'lg:-mt-5'
  | 'xl:-mt-5'
  | 'hover:-mt-5'
  | 'focus:-mt-5'
  | 'sm:-mt-6'
  | 'md:-mt-6'
  | 'lg:-mt-6'
  | 'xl:-mt-6'
  | 'hover:-mt-6'
  | 'focus:-mt-6'
  | 'sm:-mt-8'
  | 'md:-mt-8'
  | 'lg:-mt-8'
  | 'xl:-mt-8'
  | 'hover:-mt-8'
  | 'focus:-mt-8'
  | 'sm:-mt-10'
  | 'md:-mt-10'
  | 'lg:-mt-10'
  | 'xl:-mt-10'
  | 'hover:-mt-10'
  | 'focus:-mt-10'
  | 'sm:-mt-12'
  | 'md:-mt-12'
  | 'lg:-mt-12'
  | 'xl:-mt-12'
  | 'hover:-mt-12'
  | 'focus:-mt-12'
  | 'sm:-mt-16'
  | 'md:-mt-16'
  | 'lg:-mt-16'
  | 'xl:-mt-16'
  | 'hover:-mt-16'
  | 'focus:-mt-16'
  | 'sm:-mt-20'
  | 'md:-mt-20'
  | 'lg:-mt-20'
  | 'xl:-mt-20'
  | 'hover:-mt-20'
  | 'focus:-mt-20'
  | 'sm:-mt-24'
  | 'md:-mt-24'
  | 'lg:-mt-24'
  | 'xl:-mt-24'
  | 'hover:-mt-24'
  | 'focus:-mt-24'
  | 'sm:-mt-32'
  | 'md:-mt-32'
  | 'lg:-mt-32'
  | 'xl:-mt-32'
  | 'hover:-mt-32'
  | 'focus:-mt-32'
  | 'sm:-mt-40'
  | 'md:-mt-40'
  | 'lg:-mt-40'
  | 'xl:-mt-40'
  | 'hover:-mt-40'
  | 'focus:-mt-40'
  | 'sm:-mt-48'
  | 'md:-mt-48'
  | 'lg:-mt-48'
  | 'xl:-mt-48'
  | 'hover:-mt-48'
  | 'focus:-mt-48'
  | 'sm:-mt-56'
  | 'md:-mt-56'
  | 'lg:-mt-56'
  | 'xl:-mt-56'
  | 'hover:-mt-56'
  | 'focus:-mt-56'
  | 'sm:-mt-64'
  | 'md:-mt-64'
  | 'lg:-mt-64'
  | 'xl:-mt-64'
  | 'hover:-mt-64'
  | 'focus:-mt-64'
  | 'sm:-mt-px'
  | 'md:-mt-px'
  | 'lg:-mt-px'
  | 'xl:-mt-px'
  | 'hover:-mt-px'
  | 'focus:-mt-px'
  | 'sm:mr-0'
  | 'md:mr-0'
  | 'lg:mr-0'
  | 'xl:mr-0'
  | 'hover:mr-0'
  | 'focus:mr-0'
  | 'sm:mr-1'
  | 'md:mr-1'
  | 'lg:mr-1'
  | 'xl:mr-1'
  | 'hover:mr-1'
  | 'focus:mr-1'
  | 'sm:mr-2'
  | 'md:mr-2'
  | 'lg:mr-2'
  | 'xl:mr-2'
  | 'hover:mr-2'
  | 'focus:mr-2'
  | 'sm:mr-3'
  | 'md:mr-3'
  | 'lg:mr-3'
  | 'xl:mr-3'
  | 'hover:mr-3'
  | 'focus:mr-3'
  | 'sm:mr-4'
  | 'md:mr-4'
  | 'lg:mr-4'
  | 'xl:mr-4'
  | 'hover:mr-4'
  | 'focus:mr-4'
  | 'sm:mr-5'
  | 'md:mr-5'
  | 'lg:mr-5'
  | 'xl:mr-5'
  | 'hover:mr-5'
  | 'focus:mr-5'
  | 'sm:mr-6'
  | 'md:mr-6'
  | 'lg:mr-6'
  | 'xl:mr-6'
  | 'hover:mr-6'
  | 'focus:mr-6'
  | 'sm:mr-8'
  | 'md:mr-8'
  | 'lg:mr-8'
  | 'xl:mr-8'
  | 'hover:mr-8'
  | 'focus:mr-8'
  | 'sm:mr-10'
  | 'md:mr-10'
  | 'lg:mr-10'
  | 'xl:mr-10'
  | 'hover:mr-10'
  | 'focus:mr-10'
  | 'sm:mr-12'
  | 'md:mr-12'
  | 'lg:mr-12'
  | 'xl:mr-12'
  | 'hover:mr-12'
  | 'focus:mr-12'
  | 'sm:mr-16'
  | 'md:mr-16'
  | 'lg:mr-16'
  | 'xl:mr-16'
  | 'hover:mr-16'
  | 'focus:mr-16'
  | 'sm:mr-20'
  | 'md:mr-20'
  | 'lg:mr-20'
  | 'xl:mr-20'
  | 'hover:mr-20'
  | 'focus:mr-20'
  | 'sm:mr-24'
  | 'md:mr-24'
  | 'lg:mr-24'
  | 'xl:mr-24'
  | 'hover:mr-24'
  | 'focus:mr-24'
  | 'sm:mr-32'
  | 'md:mr-32'
  | 'lg:mr-32'
  | 'xl:mr-32'
  | 'hover:mr-32'
  | 'focus:mr-32'
  | 'sm:mr-40'
  | 'md:mr-40'
  | 'lg:mr-40'
  | 'xl:mr-40'
  | 'hover:mr-40'
  | 'focus:mr-40'
  | 'sm:mr-48'
  | 'md:mr-48'
  | 'lg:mr-48'
  | 'xl:mr-48'
  | 'hover:mr-48'
  | 'focus:mr-48'
  | 'sm:mr-56'
  | 'md:mr-56'
  | 'lg:mr-56'
  | 'xl:mr-56'
  | 'hover:mr-56'
  | 'focus:mr-56'
  | 'sm:mr-64'
  | 'md:mr-64'
  | 'lg:mr-64'
  | 'xl:mr-64'
  | 'hover:mr-64'
  | 'focus:mr-64'
  | 'sm:mr-auto'
  | 'md:mr-auto'
  | 'lg:mr-auto'
  | 'xl:mr-auto'
  | 'hover:mr-auto'
  | 'focus:mr-auto'
  | 'sm:mr-px'
  | 'md:mr-px'
  | 'lg:mr-px'
  | 'xl:mr-px'
  | 'hover:mr-px'
  | 'focus:mr-px'
  | 'sm:-mr-0'
  | 'md:-mr-0'
  | 'lg:-mr-0'
  | 'xl:-mr-0'
  | 'hover:-mr-0'
  | 'focus:-mr-0'
  | 'sm:-mr-1'
  | 'md:-mr-1'
  | 'lg:-mr-1'
  | 'xl:-mr-1'
  | 'hover:-mr-1'
  | 'focus:-mr-1'
  | 'sm:-mr-2'
  | 'md:-mr-2'
  | 'lg:-mr-2'
  | 'xl:-mr-2'
  | 'hover:-mr-2'
  | 'focus:-mr-2'
  | 'sm:-mr-3'
  | 'md:-mr-3'
  | 'lg:-mr-3'
  | 'xl:-mr-3'
  | 'hover:-mr-3'
  | 'focus:-mr-3'
  | 'sm:-mr-4'
  | 'md:-mr-4'
  | 'lg:-mr-4'
  | 'xl:-mr-4'
  | 'hover:-mr-4'
  | 'focus:-mr-4'
  | 'sm:-mr-5'
  | 'md:-mr-5'
  | 'lg:-mr-5'
  | 'xl:-mr-5'
  | 'hover:-mr-5'
  | 'focus:-mr-5'
  | 'sm:-mr-6'
  | 'md:-mr-6'
  | 'lg:-mr-6'
  | 'xl:-mr-6'
  | 'hover:-mr-6'
  | 'focus:-mr-6'
  | 'sm:-mr-8'
  | 'md:-mr-8'
  | 'lg:-mr-8'
  | 'xl:-mr-8'
  | 'hover:-mr-8'
  | 'focus:-mr-8'
  | 'sm:-mr-10'
  | 'md:-mr-10'
  | 'lg:-mr-10'
  | 'xl:-mr-10'
  | 'hover:-mr-10'
  | 'focus:-mr-10'
  | 'sm:-mr-12'
  | 'md:-mr-12'
  | 'lg:-mr-12'
  | 'xl:-mr-12'
  | 'hover:-mr-12'
  | 'focus:-mr-12'
  | 'sm:-mr-16'
  | 'md:-mr-16'
  | 'lg:-mr-16'
  | 'xl:-mr-16'
  | 'hover:-mr-16'
  | 'focus:-mr-16'
  | 'sm:-mr-20'
  | 'md:-mr-20'
  | 'lg:-mr-20'
  | 'xl:-mr-20'
  | 'hover:-mr-20'
  | 'focus:-mr-20'
  | 'sm:-mr-24'
  | 'md:-mr-24'
  | 'lg:-mr-24'
  | 'xl:-mr-24'
  | 'hover:-mr-24'
  | 'focus:-mr-24'
  | 'sm:-mr-32'
  | 'md:-mr-32'
  | 'lg:-mr-32'
  | 'xl:-mr-32'
  | 'hover:-mr-32'
  | 'focus:-mr-32'
  | 'sm:-mr-40'
  | 'md:-mr-40'
  | 'lg:-mr-40'
  | 'xl:-mr-40'
  | 'hover:-mr-40'
  | 'focus:-mr-40'
  | 'sm:-mr-48'
  | 'md:-mr-48'
  | 'lg:-mr-48'
  | 'xl:-mr-48'
  | 'hover:-mr-48'
  | 'focus:-mr-48'
  | 'sm:-mr-56'
  | 'md:-mr-56'
  | 'lg:-mr-56'
  | 'xl:-mr-56'
  | 'hover:-mr-56'
  | 'focus:-mr-56'
  | 'sm:-mr-64'
  | 'md:-mr-64'
  | 'lg:-mr-64'
  | 'xl:-mr-64'
  | 'hover:-mr-64'
  | 'focus:-mr-64'
  | 'sm:-mr-px'
  | 'md:-mr-px'
  | 'lg:-mr-px'
  | 'xl:-mr-px'
  | 'hover:-mr-px'
  | 'focus:-mr-px'
  | 'sm:mb-0'
  | 'md:mb-0'
  | 'lg:mb-0'
  | 'xl:mb-0'
  | 'hover:mb-0'
  | 'focus:mb-0'
  | 'sm:mb-1'
  | 'md:mb-1'
  | 'lg:mb-1'
  | 'xl:mb-1'
  | 'hover:mb-1'
  | 'focus:mb-1'
  | 'sm:mb-2'
  | 'md:mb-2'
  | 'lg:mb-2'
  | 'xl:mb-2'
  | 'hover:mb-2'
  | 'focus:mb-2'
  | 'sm:mb-3'
  | 'md:mb-3'
  | 'lg:mb-3'
  | 'xl:mb-3'
  | 'hover:mb-3'
  | 'focus:mb-3'
  | 'sm:mb-4'
  | 'md:mb-4'
  | 'lg:mb-4'
  | 'xl:mb-4'
  | 'hover:mb-4'
  | 'focus:mb-4'
  | 'sm:mb-5'
  | 'md:mb-5'
  | 'lg:mb-5'
  | 'xl:mb-5'
  | 'hover:mb-5'
  | 'focus:mb-5'
  | 'sm:mb-6'
  | 'md:mb-6'
  | 'lg:mb-6'
  | 'xl:mb-6'
  | 'hover:mb-6'
  | 'focus:mb-6'
  | 'sm:mb-8'
  | 'md:mb-8'
  | 'lg:mb-8'
  | 'xl:mb-8'
  | 'hover:mb-8'
  | 'focus:mb-8'
  | 'sm:mb-10'
  | 'md:mb-10'
  | 'lg:mb-10'
  | 'xl:mb-10'
  | 'hover:mb-10'
  | 'focus:mb-10'
  | 'sm:mb-12'
  | 'md:mb-12'
  | 'lg:mb-12'
  | 'xl:mb-12'
  | 'hover:mb-12'
  | 'focus:mb-12'
  | 'sm:mb-16'
  | 'md:mb-16'
  | 'lg:mb-16'
  | 'xl:mb-16'
  | 'hover:mb-16'
  | 'focus:mb-16'
  | 'sm:mb-20'
  | 'md:mb-20'
  | 'lg:mb-20'
  | 'xl:mb-20'
  | 'hover:mb-20'
  | 'focus:mb-20'
  | 'sm:mb-24'
  | 'md:mb-24'
  | 'lg:mb-24'
  | 'xl:mb-24'
  | 'hover:mb-24'
  | 'focus:mb-24'
  | 'sm:mb-32'
  | 'md:mb-32'
  | 'lg:mb-32'
  | 'xl:mb-32'
  | 'hover:mb-32'
  | 'focus:mb-32'
  | 'sm:mb-40'
  | 'md:mb-40'
  | 'lg:mb-40'
  | 'xl:mb-40'
  | 'hover:mb-40'
  | 'focus:mb-40'
  | 'sm:mb-48'
  | 'md:mb-48'
  | 'lg:mb-48'
  | 'xl:mb-48'
  | 'hover:mb-48'
  | 'focus:mb-48'
  | 'sm:mb-56'
  | 'md:mb-56'
  | 'lg:mb-56'
  | 'xl:mb-56'
  | 'hover:mb-56'
  | 'focus:mb-56'
  | 'sm:mb-64'
  | 'md:mb-64'
  | 'lg:mb-64'
  | 'xl:mb-64'
  | 'hover:mb-64'
  | 'focus:mb-64'
  | 'sm:mb-auto'
  | 'md:mb-auto'
  | 'lg:mb-auto'
  | 'xl:mb-auto'
  | 'hover:mb-auto'
  | 'focus:mb-auto'
  | 'sm:mb-px'
  | 'md:mb-px'
  | 'lg:mb-px'
  | 'xl:mb-px'
  | 'hover:mb-px'
  | 'focus:mb-px'
  | 'sm:-mb-0'
  | 'md:-mb-0'
  | 'lg:-mb-0'
  | 'xl:-mb-0'
  | 'hover:-mb-0'
  | 'focus:-mb-0'
  | 'sm:-mb-1'
  | 'md:-mb-1'
  | 'lg:-mb-1'
  | 'xl:-mb-1'
  | 'hover:-mb-1'
  | 'focus:-mb-1'
  | 'sm:-mb-2'
  | 'md:-mb-2'
  | 'lg:-mb-2'
  | 'xl:-mb-2'
  | 'hover:-mb-2'
  | 'focus:-mb-2'
  | 'sm:-mb-3'
  | 'md:-mb-3'
  | 'lg:-mb-3'
  | 'xl:-mb-3'
  | 'hover:-mb-3'
  | 'focus:-mb-3'
  | 'sm:-mb-4'
  | 'md:-mb-4'
  | 'lg:-mb-4'
  | 'xl:-mb-4'
  | 'hover:-mb-4'
  | 'focus:-mb-4'
  | 'sm:-mb-5'
  | 'md:-mb-5'
  | 'lg:-mb-5'
  | 'xl:-mb-5'
  | 'hover:-mb-5'
  | 'focus:-mb-5'
  | 'sm:-mb-6'
  | 'md:-mb-6'
  | 'lg:-mb-6'
  | 'xl:-mb-6'
  | 'hover:-mb-6'
  | 'focus:-mb-6'
  | 'sm:-mb-8'
  | 'md:-mb-8'
  | 'lg:-mb-8'
  | 'xl:-mb-8'
  | 'hover:-mb-8'
  | 'focus:-mb-8'
  | 'sm:-mb-10'
  | 'md:-mb-10'
  | 'lg:-mb-10'
  | 'xl:-mb-10'
  | 'hover:-mb-10'
  | 'focus:-mb-10'
  | 'sm:-mb-12'
  | 'md:-mb-12'
  | 'lg:-mb-12'
  | 'xl:-mb-12'
  | 'hover:-mb-12'
  | 'focus:-mb-12'
  | 'sm:-mb-16'
  | 'md:-mb-16'
  | 'lg:-mb-16'
  | 'xl:-mb-16'
  | 'hover:-mb-16'
  | 'focus:-mb-16'
  | 'sm:-mb-20'
  | 'md:-mb-20'
  | 'lg:-mb-20'
  | 'xl:-mb-20'
  | 'hover:-mb-20'
  | 'focus:-mb-20'
  | 'sm:-mb-24'
  | 'md:-mb-24'
  | 'lg:-mb-24'
  | 'xl:-mb-24'
  | 'hover:-mb-24'
  | 'focus:-mb-24'
  | 'sm:-mb-32'
  | 'md:-mb-32'
  | 'lg:-mb-32'
  | 'xl:-mb-32'
  | 'hover:-mb-32'
  | 'focus:-mb-32'
  | 'sm:-mb-40'
  | 'md:-mb-40'
  | 'lg:-mb-40'
  | 'xl:-mb-40'
  | 'hover:-mb-40'
  | 'focus:-mb-40'
  | 'sm:-mb-48'
  | 'md:-mb-48'
  | 'lg:-mb-48'
  | 'xl:-mb-48'
  | 'hover:-mb-48'
  | 'focus:-mb-48'
  | 'sm:-mb-56'
  | 'md:-mb-56'
  | 'lg:-mb-56'
  | 'xl:-mb-56'
  | 'hover:-mb-56'
  | 'focus:-mb-56'
  | 'sm:-mb-64'
  | 'md:-mb-64'
  | 'lg:-mb-64'
  | 'xl:-mb-64'
  | 'hover:-mb-64'
  | 'focus:-mb-64'
  | 'sm:-mb-px'
  | 'md:-mb-px'
  | 'lg:-mb-px'
  | 'xl:-mb-px'
  | 'hover:-mb-px'
  | 'focus:-mb-px'
  | 'sm:ml-0'
  | 'md:ml-0'
  | 'lg:ml-0'
  | 'xl:ml-0'
  | 'hover:ml-0'
  | 'focus:ml-0'
  | 'sm:ml-1'
  | 'md:ml-1'
  | 'lg:ml-1'
  | 'xl:ml-1'
  | 'hover:ml-1'
  | 'focus:ml-1'
  | 'sm:ml-2'
  | 'md:ml-2'
  | 'lg:ml-2'
  | 'xl:ml-2'
  | 'hover:ml-2'
  | 'focus:ml-2'
  | 'sm:ml-3'
  | 'md:ml-3'
  | 'lg:ml-3'
  | 'xl:ml-3'
  | 'hover:ml-3'
  | 'focus:ml-3'
  | 'sm:ml-4'
  | 'md:ml-4'
  | 'lg:ml-4'
  | 'xl:ml-4'
  | 'hover:ml-4'
  | 'focus:ml-4'
  | 'sm:ml-5'
  | 'md:ml-5'
  | 'lg:ml-5'
  | 'xl:ml-5'
  | 'hover:ml-5'
  | 'focus:ml-5'
  | 'sm:ml-6'
  | 'md:ml-6'
  | 'lg:ml-6'
  | 'xl:ml-6'
  | 'hover:ml-6'
  | 'focus:ml-6'
  | 'sm:ml-8'
  | 'md:ml-8'
  | 'lg:ml-8'
  | 'xl:ml-8'
  | 'hover:ml-8'
  | 'focus:ml-8'
  | 'sm:ml-10'
  | 'md:ml-10'
  | 'lg:ml-10'
  | 'xl:ml-10'
  | 'hover:ml-10'
  | 'focus:ml-10'
  | 'sm:ml-12'
  | 'md:ml-12'
  | 'lg:ml-12'
  | 'xl:ml-12'
  | 'hover:ml-12'
  | 'focus:ml-12'
  | 'sm:ml-16'
  | 'md:ml-16'
  | 'lg:ml-16'
  | 'xl:ml-16'
  | 'hover:ml-16'
  | 'focus:ml-16'
  | 'sm:ml-20'
  | 'md:ml-20'
  | 'lg:ml-20'
  | 'xl:ml-20'
  | 'hover:ml-20'
  | 'focus:ml-20'
  | 'sm:ml-24'
  | 'md:ml-24'
  | 'lg:ml-24'
  | 'xl:ml-24'
  | 'hover:ml-24'
  | 'focus:ml-24'
  | 'sm:ml-32'
  | 'md:ml-32'
  | 'lg:ml-32'
  | 'xl:ml-32'
  | 'hover:ml-32'
  | 'focus:ml-32'
  | 'sm:ml-40'
  | 'md:ml-40'
  | 'lg:ml-40'
  | 'xl:ml-40'
  | 'hover:ml-40'
  | 'focus:ml-40'
  | 'sm:ml-48'
  | 'md:ml-48'
  | 'lg:ml-48'
  | 'xl:ml-48'
  | 'hover:ml-48'
  | 'focus:ml-48'
  | 'sm:ml-56'
  | 'md:ml-56'
  | 'lg:ml-56'
  | 'xl:ml-56'
  | 'hover:ml-56'
  | 'focus:ml-56'
  | 'sm:ml-64'
  | 'md:ml-64'
  | 'lg:ml-64'
  | 'xl:ml-64'
  | 'hover:ml-64'
  | 'focus:ml-64'
  | 'sm:ml-auto'
  | 'md:ml-auto'
  | 'lg:ml-auto'
  | 'xl:ml-auto'
  | 'hover:ml-auto'
  | 'focus:ml-auto'
  | 'sm:ml-px'
  | 'md:ml-px'
  | 'lg:ml-px'
  | 'xl:ml-px'
  | 'hover:ml-px'
  | 'focus:ml-px'
  | 'sm:-ml-0'
  | 'md:-ml-0'
  | 'lg:-ml-0'
  | 'xl:-ml-0'
  | 'hover:-ml-0'
  | 'focus:-ml-0'
  | 'sm:-ml-1'
  | 'md:-ml-1'
  | 'lg:-ml-1'
  | 'xl:-ml-1'
  | 'hover:-ml-1'
  | 'focus:-ml-1'
  | 'sm:-ml-2'
  | 'md:-ml-2'
  | 'lg:-ml-2'
  | 'xl:-ml-2'
  | 'hover:-ml-2'
  | 'focus:-ml-2'
  | 'sm:-ml-3'
  | 'md:-ml-3'
  | 'lg:-ml-3'
  | 'xl:-ml-3'
  | 'hover:-ml-3'
  | 'focus:-ml-3'
  | 'sm:-ml-4'
  | 'md:-ml-4'
  | 'lg:-ml-4'
  | 'xl:-ml-4'
  | 'hover:-ml-4'
  | 'focus:-ml-4'
  | 'sm:-ml-5'
  | 'md:-ml-5'
  | 'lg:-ml-5'
  | 'xl:-ml-5'
  | 'hover:-ml-5'
  | 'focus:-ml-5'
  | 'sm:-ml-6'
  | 'md:-ml-6'
  | 'lg:-ml-6'
  | 'xl:-ml-6'
  | 'hover:-ml-6'
  | 'focus:-ml-6'
  | 'sm:-ml-8'
  | 'md:-ml-8'
  | 'lg:-ml-8'
  | 'xl:-ml-8'
  | 'hover:-ml-8'
  | 'focus:-ml-8'
  | 'sm:-ml-10'
  | 'md:-ml-10'
  | 'lg:-ml-10'
  | 'xl:-ml-10'
  | 'hover:-ml-10'
  | 'focus:-ml-10'
  | 'sm:-ml-12'
  | 'md:-ml-12'
  | 'lg:-ml-12'
  | 'xl:-ml-12'
  | 'hover:-ml-12'
  | 'focus:-ml-12'
  | 'sm:-ml-16'
  | 'md:-ml-16'
  | 'lg:-ml-16'
  | 'xl:-ml-16'
  | 'hover:-ml-16'
  | 'focus:-ml-16'
  | 'sm:-ml-20'
  | 'md:-ml-20'
  | 'lg:-ml-20'
  | 'xl:-ml-20'
  | 'hover:-ml-20'
  | 'focus:-ml-20'
  | 'sm:-ml-24'
  | 'md:-ml-24'
  | 'lg:-ml-24'
  | 'xl:-ml-24'
  | 'hover:-ml-24'
  | 'focus:-ml-24'
  | 'sm:-ml-32'
  | 'md:-ml-32'
  | 'lg:-ml-32'
  | 'xl:-ml-32'
  | 'hover:-ml-32'
  | 'focus:-ml-32'
  | 'sm:-ml-40'
  | 'md:-ml-40'
  | 'lg:-ml-40'
  | 'xl:-ml-40'
  | 'hover:-ml-40'
  | 'focus:-ml-40'
  | 'sm:-ml-48'
  | 'md:-ml-48'
  | 'lg:-ml-48'
  | 'xl:-ml-48'
  | 'hover:-ml-48'
  | 'focus:-ml-48'
  | 'sm:-ml-56'
  | 'md:-ml-56'
  | 'lg:-ml-56'
  | 'xl:-ml-56'
  | 'hover:-ml-56'
  | 'focus:-ml-56'
  | 'sm:-ml-64'
  | 'md:-ml-64'
  | 'lg:-ml-64'
  | 'xl:-ml-64'
  | 'hover:-ml-64'
  | 'focus:-ml-64'
  | 'sm:-ml-px'
  | 'md:-ml-px'
  | 'lg:-ml-px'
  | 'xl:-ml-px'
  | 'hover:-ml-px'
  | 'focus:-ml-px'
  | 'sm:max-h-full'
  | 'md:max-h-full'
  | 'lg:max-h-full'
  | 'xl:max-h-full'
  | 'sm:max-h-screen'
  | 'md:max-h-screen'
  | 'lg:max-h-screen'
  | 'xl:max-h-screen'
  | 'sm:max-w-none'
  | 'md:max-w-none'
  | 'lg:max-w-none'
  | 'xl:max-w-none'
  | 'sm:max-w-xs'
  | 'md:max-w-xs'
  | 'lg:max-w-xs'
  | 'xl:max-w-xs'
  | 'sm:max-w-sm'
  | 'md:max-w-sm'
  | 'lg:max-w-sm'
  | 'xl:max-w-sm'
  | 'sm:max-w-md'
  | 'md:max-w-md'
  | 'lg:max-w-md'
  | 'xl:max-w-md'
  | 'sm:max-w-lg'
  | 'md:max-w-lg'
  | 'lg:max-w-lg'
  | 'xl:max-w-lg'
  | 'sm:max-w-xl'
  | 'md:max-w-xl'
  | 'lg:max-w-xl'
  | 'xl:max-w-xl'
  | 'sm:max-w-2xl'
  | 'md:max-w-2xl'
  | 'lg:max-w-2xl'
  | 'xl:max-w-2xl'
  | 'sm:max-w-3xl'
  | 'md:max-w-3xl'
  | 'lg:max-w-3xl'
  | 'xl:max-w-3xl'
  | 'sm:max-w-4xl'
  | 'md:max-w-4xl'
  | 'lg:max-w-4xl'
  | 'xl:max-w-4xl'
  | 'sm:max-w-5xl'
  | 'md:max-w-5xl'
  | 'lg:max-w-5xl'
  | 'xl:max-w-5xl'
  | 'sm:max-w-6xl'
  | 'md:max-w-6xl'
  | 'lg:max-w-6xl'
  | 'xl:max-w-6xl'
  | 'sm:max-w-full'
  | 'md:max-w-full'
  | 'lg:max-w-full'
  | 'xl:max-w-full'
  | 'sm:max-w-screen-sm'
  | 'md:max-w-screen-sm'
  | 'lg:max-w-screen-sm'
  | 'xl:max-w-screen-sm'
  | 'sm:max-w-screen-md'
  | 'md:max-w-screen-md'
  | 'lg:max-w-screen-md'
  | 'xl:max-w-screen-md'
  | 'sm:max-w-screen-lg'
  | 'md:max-w-screen-lg'
  | 'lg:max-w-screen-lg'
  | 'xl:max-w-screen-lg'
  | 'sm:max-w-screen-xl'
  | 'md:max-w-screen-xl'
  | 'lg:max-w-screen-xl'
  | 'xl:max-w-screen-xl'
  | 'sm:min-h-0'
  | 'md:min-h-0'
  | 'lg:min-h-0'
  | 'xl:min-h-0'
  | 'sm:min-h-full'
  | 'md:min-h-full'
  | 'lg:min-h-full'
  | 'xl:min-h-full'
  | 'sm:min-h-screen'
  | 'md:min-h-screen'
  | 'lg:min-h-screen'
  | 'xl:min-h-screen'
  | 'sm:min-w-0'
  | 'md:min-w-0'
  | 'lg:min-w-0'
  | 'xl:min-w-0'
  | 'sm:min-w-full'
  | 'md:min-w-full'
  | 'lg:min-w-full'
  | 'xl:min-w-full'
  | 'sm:object-contain'
  | 'md:object-contain'
  | 'lg:object-contain'
  | 'xl:object-contain'
  | 'sm:object-cover'
  | 'md:object-cover'
  | 'lg:object-cover'
  | 'xl:object-cover'
  | 'sm:object-fill'
  | 'md:object-fill'
  | 'lg:object-fill'
  | 'xl:object-fill'
  | 'sm:object-none'
  | 'md:object-none'
  | 'lg:object-none'
  | 'xl:object-none'
  | 'sm:object-scale-down'
  | 'md:object-scale-down'
  | 'lg:object-scale-down'
  | 'xl:object-scale-down'
  | 'sm:object-bottom'
  | 'md:object-bottom'
  | 'lg:object-bottom'
  | 'xl:object-bottom'
  | 'sm:object-center'
  | 'md:object-center'
  | 'lg:object-center'
  | 'xl:object-center'
  | 'sm:object-left'
  | 'md:object-left'
  | 'lg:object-left'
  | 'xl:object-left'
  | 'sm:object-left-bottom'
  | 'md:object-left-bottom'
  | 'lg:object-left-bottom'
  | 'xl:object-left-bottom'
  | 'sm:object-left-top'
  | 'md:object-left-top'
  | 'lg:object-left-top'
  | 'xl:object-left-top'
  | 'sm:object-right'
  | 'md:object-right'
  | 'lg:object-right'
  | 'xl:object-right'
  | 'sm:object-right-bottom'
  | 'md:object-right-bottom'
  | 'lg:object-right-bottom'
  | 'xl:object-right-bottom'
  | 'sm:object-right-top'
  | 'md:object-right-top'
  | 'lg:object-right-top'
  | 'xl:object-right-top'
  | 'sm:object-top'
  | 'md:object-top'
  | 'lg:object-top'
  | 'xl:object-top'
  | 'sm:opacity-0'
  | 'md:opacity-0'
  | 'lg:opacity-0'
  | 'xl:opacity-0'
  | 'hover:opacity-0'
  | 'focus:opacity-0'
  | 'group-hover:opacity-0'
  | 'sm:opacity-25'
  | 'md:opacity-25'
  | 'lg:opacity-25'
  | 'xl:opacity-25'
  | 'hover:opacity-25'
  | 'focus:opacity-25'
  | 'group-hover:opacity-25'
  | 'sm:opacity-50'
  | 'md:opacity-50'
  | 'lg:opacity-50'
  | 'xl:opacity-50'
  | 'hover:opacity-50'
  | 'focus:opacity-50'
  | 'group-hover:opacity-50'
  | 'sm:opacity-75'
  | 'md:opacity-75'
  | 'lg:opacity-75'
  | 'xl:opacity-75'
  | 'hover:opacity-75'
  | 'focus:opacity-75'
  | 'group-hover:opacity-75'
  | 'sm:opacity-100'
  | 'md:opacity-100'
  | 'lg:opacity-100'
  | 'xl:opacity-100'
  | 'hover:opacity-100'
  | 'focus:opacity-100'
  | 'group-hover:opacity-100'
  | 'sm:order-1'
  | 'md:order-1'
  | 'lg:order-1'
  | 'xl:order-1'
  | 'sm:order-2'
  | 'md:order-2'
  | 'lg:order-2'
  | 'xl:order-2'
  | 'sm:order-3'
  | 'md:order-3'
  | 'lg:order-3'
  | 'xl:order-3'
  | 'sm:order-4'
  | 'md:order-4'
  | 'lg:order-4'
  | 'xl:order-4'
  | 'sm:order-5'
  | 'md:order-5'
  | 'lg:order-5'
  | 'xl:order-5'
  | 'sm:order-6'
  | 'md:order-6'
  | 'lg:order-6'
  | 'xl:order-6'
  | 'sm:order-7'
  | 'md:order-7'
  | 'lg:order-7'
  | 'xl:order-7'
  | 'sm:order-8'
  | 'md:order-8'
  | 'lg:order-8'
  | 'xl:order-8'
  | 'sm:order-9'
  | 'md:order-9'
  | 'lg:order-9'
  | 'xl:order-9'
  | 'sm:order-10'
  | 'md:order-10'
  | 'lg:order-10'
  | 'xl:order-10'
  | 'sm:order-11'
  | 'md:order-11'
  | 'lg:order-11'
  | 'xl:order-11'
  | 'sm:order-12'
  | 'md:order-12'
  | 'lg:order-12'
  | 'xl:order-12'
  | 'sm:order-first'
  | 'md:order-first'
  | 'lg:order-first'
  | 'xl:order-first'
  | 'sm:order-last'
  | 'md:order-last'
  | 'lg:order-last'
  | 'xl:order-last'
  | 'sm:order-none'
  | 'md:order-none'
  | 'lg:order-none'
  | 'xl:order-none'
  | 'sm:outline-none'
  | 'md:outline-none'
  | 'lg:outline-none'
  | 'xl:outline-none'
  | 'focus:outline-none'
  | 'sm:outline-white'
  | 'md:outline-white'
  | 'lg:outline-white'
  | 'xl:outline-white'
  | 'focus:outline-white'
  | 'sm:outline-black'
  | 'md:outline-black'
  | 'lg:outline-black'
  | 'xl:outline-black'
  | 'focus:outline-black'
  | 'sm:overflow-auto'
  | 'md:overflow-auto'
  | 'lg:overflow-auto'
  | 'xl:overflow-auto'
  | 'sm:overflow-hidden'
  | 'md:overflow-hidden'
  | 'lg:overflow-hidden'
  | 'xl:overflow-hidden'
  | 'sm:overflow-visible'
  | 'md:overflow-visible'
  | 'lg:overflow-visible'
  | 'xl:overflow-visible'
  | 'sm:overflow-scroll'
  | 'md:overflow-scroll'
  | 'lg:overflow-scroll'
  | 'xl:overflow-scroll'
  | 'sm:overflow-x-auto'
  | 'md:overflow-x-auto'
  | 'lg:overflow-x-auto'
  | 'xl:overflow-x-auto'
  | 'sm:overflow-y-auto'
  | 'md:overflow-y-auto'
  | 'lg:overflow-y-auto'
  | 'xl:overflow-y-auto'
  | 'sm:overflow-x-hidden'
  | 'md:overflow-x-hidden'
  | 'lg:overflow-x-hidden'
  | 'xl:overflow-x-hidden'
  | 'sm:overflow-y-hidden'
  | 'md:overflow-y-hidden'
  | 'lg:overflow-y-hidden'
  | 'xl:overflow-y-hidden'
  | 'sm:overflow-x-visible'
  | 'md:overflow-x-visible'
  | 'lg:overflow-x-visible'
  | 'xl:overflow-x-visible'
  | 'sm:overflow-y-visible'
  | 'md:overflow-y-visible'
  | 'lg:overflow-y-visible'
  | 'xl:overflow-y-visible'
  | 'sm:overflow-x-scroll'
  | 'md:overflow-x-scroll'
  | 'lg:overflow-x-scroll'
  | 'xl:overflow-x-scroll'
  | 'sm:overflow-y-scroll'
  | 'md:overflow-y-scroll'
  | 'lg:overflow-y-scroll'
  | 'xl:overflow-y-scroll'
  | 'sm:scrolling-touch'
  | 'md:scrolling-touch'
  | 'lg:scrolling-touch'
  | 'xl:scrolling-touch'
  | 'sm:scrolling-auto'
  | 'md:scrolling-auto'
  | 'lg:scrolling-auto'
  | 'xl:scrolling-auto'
  | 'sm:overscroll-auto'
  | 'md:overscroll-auto'
  | 'lg:overscroll-auto'
  | 'xl:overscroll-auto'
  | 'sm:overscroll-contain'
  | 'md:overscroll-contain'
  | 'lg:overscroll-contain'
  | 'xl:overscroll-contain'
  | 'sm:overscroll-none'
  | 'md:overscroll-none'
  | 'lg:overscroll-none'
  | 'xl:overscroll-none'
  | 'sm:overscroll-y-auto'
  | 'md:overscroll-y-auto'
  | 'lg:overscroll-y-auto'
  | 'xl:overscroll-y-auto'
  | 'sm:overscroll-y-contain'
  | 'md:overscroll-y-contain'
  | 'lg:overscroll-y-contain'
  | 'xl:overscroll-y-contain'
  | 'sm:overscroll-y-none'
  | 'md:overscroll-y-none'
  | 'lg:overscroll-y-none'
  | 'xl:overscroll-y-none'
  | 'sm:overscroll-x-auto'
  | 'md:overscroll-x-auto'
  | 'lg:overscroll-x-auto'
  | 'xl:overscroll-x-auto'
  | 'sm:overscroll-x-contain'
  | 'md:overscroll-x-contain'
  | 'lg:overscroll-x-contain'
  | 'xl:overscroll-x-contain'
  | 'sm:overscroll-x-none'
  | 'md:overscroll-x-none'
  | 'lg:overscroll-x-none'
  | 'xl:overscroll-x-none'
  | 'sm:p-0'
  | 'md:p-0'
  | 'lg:p-0'
  | 'xl:p-0'
  | 'sm:p-1'
  | 'md:p-1'
  | 'lg:p-1'
  | 'xl:p-1'
  | 'sm:p-2'
  | 'md:p-2'
  | 'lg:p-2'
  | 'xl:p-2'
  | 'sm:p-3'
  | 'md:p-3'
  | 'lg:p-3'
  | 'xl:p-3'
  | 'sm:p-4'
  | 'md:p-4'
  | 'lg:p-4'
  | 'xl:p-4'
  | 'sm:p-5'
  | 'md:p-5'
  | 'lg:p-5'
  | 'xl:p-5'
  | 'sm:p-6'
  | 'md:p-6'
  | 'lg:p-6'
  | 'xl:p-6'
  | 'sm:p-8'
  | 'md:p-8'
  | 'lg:p-8'
  | 'xl:p-8'
  | 'sm:p-10'
  | 'md:p-10'
  | 'lg:p-10'
  | 'xl:p-10'
  | 'sm:p-12'
  | 'md:p-12'
  | 'lg:p-12'
  | 'xl:p-12'
  | 'sm:p-16'
  | 'md:p-16'
  | 'lg:p-16'
  | 'xl:p-16'
  | 'sm:p-20'
  | 'md:p-20'
  | 'lg:p-20'
  | 'xl:p-20'
  | 'sm:p-24'
  | 'md:p-24'
  | 'lg:p-24'
  | 'xl:p-24'
  | 'sm:p-32'
  | 'md:p-32'
  | 'lg:p-32'
  | 'xl:p-32'
  | 'sm:p-40'
  | 'md:p-40'
  | 'lg:p-40'
  | 'xl:p-40'
  | 'sm:p-48'
  | 'md:p-48'
  | 'lg:p-48'
  | 'xl:p-48'
  | 'sm:p-56'
  | 'md:p-56'
  | 'lg:p-56'
  | 'xl:p-56'
  | 'sm:p-64'
  | 'md:p-64'
  | 'lg:p-64'
  | 'xl:p-64'
  | 'sm:p-px'
  | 'md:p-px'
  | 'lg:p-px'
  | 'xl:p-px'
  | 'sm:p-cardIndexRatio'
  | 'md:p-cardIndexRatio'
  | 'lg:p-cardIndexRatio'
  | 'xl:p-cardIndexRatio'
  | 'sm:py-0'
  | 'md:py-0'
  | 'lg:py-0'
  | 'xl:py-0'
  | 'sm:py-1'
  | 'md:py-1'
  | 'lg:py-1'
  | 'xl:py-1'
  | 'sm:py-2'
  | 'md:py-2'
  | 'lg:py-2'
  | 'xl:py-2'
  | 'sm:py-3'
  | 'md:py-3'
  | 'lg:py-3'
  | 'xl:py-3'
  | 'sm:py-4'
  | 'md:py-4'
  | 'lg:py-4'
  | 'xl:py-4'
  | 'sm:py-5'
  | 'md:py-5'
  | 'lg:py-5'
  | 'xl:py-5'
  | 'sm:py-6'
  | 'md:py-6'
  | 'lg:py-6'
  | 'xl:py-6'
  | 'sm:py-8'
  | 'md:py-8'
  | 'lg:py-8'
  | 'xl:py-8'
  | 'sm:py-10'
  | 'md:py-10'
  | 'lg:py-10'
  | 'xl:py-10'
  | 'sm:py-12'
  | 'md:py-12'
  | 'lg:py-12'
  | 'xl:py-12'
  | 'sm:py-16'
  | 'md:py-16'
  | 'lg:py-16'
  | 'xl:py-16'
  | 'sm:py-20'
  | 'md:py-20'
  | 'lg:py-20'
  | 'xl:py-20'
  | 'sm:py-24'
  | 'md:py-24'
  | 'lg:py-24'
  | 'xl:py-24'
  | 'sm:py-32'
  | 'md:py-32'
  | 'lg:py-32'
  | 'xl:py-32'
  | 'sm:py-40'
  | 'md:py-40'
  | 'lg:py-40'
  | 'xl:py-40'
  | 'sm:py-48'
  | 'md:py-48'
  | 'lg:py-48'
  | 'xl:py-48'
  | 'sm:py-56'
  | 'md:py-56'
  | 'lg:py-56'
  | 'xl:py-56'
  | 'sm:py-64'
  | 'md:py-64'
  | 'lg:py-64'
  | 'xl:py-64'
  | 'sm:py-px'
  | 'md:py-px'
  | 'lg:py-px'
  | 'xl:py-px'
  | 'sm:py-cardIndexRatio'
  | 'md:py-cardIndexRatio'
  | 'lg:py-cardIndexRatio'
  | 'xl:py-cardIndexRatio'
  | 'sm:px-0'
  | 'md:px-0'
  | 'lg:px-0'
  | 'xl:px-0'
  | 'sm:px-1'
  | 'md:px-1'
  | 'lg:px-1'
  | 'xl:px-1'
  | 'sm:px-2'
  | 'md:px-2'
  | 'lg:px-2'
  | 'xl:px-2'
  | 'sm:px-3'
  | 'md:px-3'
  | 'lg:px-3'
  | 'xl:px-3'
  | 'sm:px-4'
  | 'md:px-4'
  | 'lg:px-4'
  | 'xl:px-4'
  | 'sm:px-5'
  | 'md:px-5'
  | 'lg:px-5'
  | 'xl:px-5'
  | 'sm:px-6'
  | 'md:px-6'
  | 'lg:px-6'
  | 'xl:px-6'
  | 'sm:px-8'
  | 'md:px-8'
  | 'lg:px-8'
  | 'xl:px-8'
  | 'sm:px-10'
  | 'md:px-10'
  | 'lg:px-10'
  | 'xl:px-10'
  | 'sm:px-12'
  | 'md:px-12'
  | 'lg:px-12'
  | 'xl:px-12'
  | 'sm:px-16'
  | 'md:px-16'
  | 'lg:px-16'
  | 'xl:px-16'
  | 'sm:px-20'
  | 'md:px-20'
  | 'lg:px-20'
  | 'xl:px-20'
  | 'sm:px-24'
  | 'md:px-24'
  | 'lg:px-24'
  | 'xl:px-24'
  | 'sm:px-32'
  | 'md:px-32'
  | 'lg:px-32'
  | 'xl:px-32'
  | 'sm:px-40'
  | 'md:px-40'
  | 'lg:px-40'
  | 'xl:px-40'
  | 'sm:px-48'
  | 'md:px-48'
  | 'lg:px-48'
  | 'xl:px-48'
  | 'sm:px-56'
  | 'md:px-56'
  | 'lg:px-56'
  | 'xl:px-56'
  | 'sm:px-64'
  | 'md:px-64'
  | 'lg:px-64'
  | 'xl:px-64'
  | 'sm:px-px'
  | 'md:px-px'
  | 'lg:px-px'
  | 'xl:px-px'
  | 'sm:px-cardIndexRatio'
  | 'md:px-cardIndexRatio'
  | 'lg:px-cardIndexRatio'
  | 'xl:px-cardIndexRatio'
  | 'sm:pt-0'
  | 'md:pt-0'
  | 'lg:pt-0'
  | 'xl:pt-0'
  | 'sm:pt-1'
  | 'md:pt-1'
  | 'lg:pt-1'
  | 'xl:pt-1'
  | 'sm:pt-2'
  | 'md:pt-2'
  | 'lg:pt-2'
  | 'xl:pt-2'
  | 'sm:pt-3'
  | 'md:pt-3'
  | 'lg:pt-3'
  | 'xl:pt-3'
  | 'sm:pt-4'
  | 'md:pt-4'
  | 'lg:pt-4'
  | 'xl:pt-4'
  | 'sm:pt-5'
  | 'md:pt-5'
  | 'lg:pt-5'
  | 'xl:pt-5'
  | 'sm:pt-6'
  | 'md:pt-6'
  | 'lg:pt-6'
  | 'xl:pt-6'
  | 'sm:pt-8'
  | 'md:pt-8'
  | 'lg:pt-8'
  | 'xl:pt-8'
  | 'sm:pt-10'
  | 'md:pt-10'
  | 'lg:pt-10'
  | 'xl:pt-10'
  | 'sm:pt-12'
  | 'md:pt-12'
  | 'lg:pt-12'
  | 'xl:pt-12'
  | 'sm:pt-16'
  | 'md:pt-16'
  | 'lg:pt-16'
  | 'xl:pt-16'
  | 'sm:pt-20'
  | 'md:pt-20'
  | 'lg:pt-20'
  | 'xl:pt-20'
  | 'sm:pt-24'
  | 'md:pt-24'
  | 'lg:pt-24'
  | 'xl:pt-24'
  | 'sm:pt-32'
  | 'md:pt-32'
  | 'lg:pt-32'
  | 'xl:pt-32'
  | 'sm:pt-40'
  | 'md:pt-40'
  | 'lg:pt-40'
  | 'xl:pt-40'
  | 'sm:pt-48'
  | 'md:pt-48'
  | 'lg:pt-48'
  | 'xl:pt-48'
  | 'sm:pt-56'
  | 'md:pt-56'
  | 'lg:pt-56'
  | 'xl:pt-56'
  | 'sm:pt-64'
  | 'md:pt-64'
  | 'lg:pt-64'
  | 'xl:pt-64'
  | 'sm:pt-px'
  | 'md:pt-px'
  | 'lg:pt-px'
  | 'xl:pt-px'
  | 'sm:pt-cardIndexRatio'
  | 'md:pt-cardIndexRatio'
  | 'lg:pt-cardIndexRatio'
  | 'xl:pt-cardIndexRatio'
  | 'sm:pr-0'
  | 'md:pr-0'
  | 'lg:pr-0'
  | 'xl:pr-0'
  | 'sm:pr-1'
  | 'md:pr-1'
  | 'lg:pr-1'
  | 'xl:pr-1'
  | 'sm:pr-2'
  | 'md:pr-2'
  | 'lg:pr-2'
  | 'xl:pr-2'
  | 'sm:pr-3'
  | 'md:pr-3'
  | 'lg:pr-3'
  | 'xl:pr-3'
  | 'sm:pr-4'
  | 'md:pr-4'
  | 'lg:pr-4'
  | 'xl:pr-4'
  | 'sm:pr-5'
  | 'md:pr-5'
  | 'lg:pr-5'
  | 'xl:pr-5'
  | 'sm:pr-6'
  | 'md:pr-6'
  | 'lg:pr-6'
  | 'xl:pr-6'
  | 'sm:pr-8'
  | 'md:pr-8'
  | 'lg:pr-8'
  | 'xl:pr-8'
  | 'sm:pr-10'
  | 'md:pr-10'
  | 'lg:pr-10'
  | 'xl:pr-10'
  | 'sm:pr-12'
  | 'md:pr-12'
  | 'lg:pr-12'
  | 'xl:pr-12'
  | 'sm:pr-16'
  | 'md:pr-16'
  | 'lg:pr-16'
  | 'xl:pr-16'
  | 'sm:pr-20'
  | 'md:pr-20'
  | 'lg:pr-20'
  | 'xl:pr-20'
  | 'sm:pr-24'
  | 'md:pr-24'
  | 'lg:pr-24'
  | 'xl:pr-24'
  | 'sm:pr-32'
  | 'md:pr-32'
  | 'lg:pr-32'
  | 'xl:pr-32'
  | 'sm:pr-40'
  | 'md:pr-40'
  | 'lg:pr-40'
  | 'xl:pr-40'
  | 'sm:pr-48'
  | 'md:pr-48'
  | 'lg:pr-48'
  | 'xl:pr-48'
  | 'sm:pr-56'
  | 'md:pr-56'
  | 'lg:pr-56'
  | 'xl:pr-56'
  | 'sm:pr-64'
  | 'md:pr-64'
  | 'lg:pr-64'
  | 'xl:pr-64'
  | 'sm:pr-px'
  | 'md:pr-px'
  | 'lg:pr-px'
  | 'xl:pr-px'
  | 'sm:pr-cardIndexRatio'
  | 'md:pr-cardIndexRatio'
  | 'lg:pr-cardIndexRatio'
  | 'xl:pr-cardIndexRatio'
  | 'sm:pb-0'
  | 'md:pb-0'
  | 'lg:pb-0'
  | 'xl:pb-0'
  | 'sm:pb-1'
  | 'md:pb-1'
  | 'lg:pb-1'
  | 'xl:pb-1'
  | 'sm:pb-2'
  | 'md:pb-2'
  | 'lg:pb-2'
  | 'xl:pb-2'
  | 'sm:pb-3'
  | 'md:pb-3'
  | 'lg:pb-3'
  | 'xl:pb-3'
  | 'sm:pb-4'
  | 'md:pb-4'
  | 'lg:pb-4'
  | 'xl:pb-4'
  | 'sm:pb-5'
  | 'md:pb-5'
  | 'lg:pb-5'
  | 'xl:pb-5'
  | 'sm:pb-6'
  | 'md:pb-6'
  | 'lg:pb-6'
  | 'xl:pb-6'
  | 'sm:pb-8'
  | 'md:pb-8'
  | 'lg:pb-8'
  | 'xl:pb-8'
  | 'sm:pb-10'
  | 'md:pb-10'
  | 'lg:pb-10'
  | 'xl:pb-10'
  | 'sm:pb-12'
  | 'md:pb-12'
  | 'lg:pb-12'
  | 'xl:pb-12'
  | 'sm:pb-16'
  | 'md:pb-16'
  | 'lg:pb-16'
  | 'xl:pb-16'
  | 'sm:pb-20'
  | 'md:pb-20'
  | 'lg:pb-20'
  | 'xl:pb-20'
  | 'sm:pb-24'
  | 'md:pb-24'
  | 'lg:pb-24'
  | 'xl:pb-24'
  | 'sm:pb-32'
  | 'md:pb-32'
  | 'lg:pb-32'
  | 'xl:pb-32'
  | 'sm:pb-40'
  | 'md:pb-40'
  | 'lg:pb-40'
  | 'xl:pb-40'
  | 'sm:pb-48'
  | 'md:pb-48'
  | 'lg:pb-48'
  | 'xl:pb-48'
  | 'sm:pb-56'
  | 'md:pb-56'
  | 'lg:pb-56'
  | 'xl:pb-56'
  | 'sm:pb-64'
  | 'md:pb-64'
  | 'lg:pb-64'
  | 'xl:pb-64'
  | 'sm:pb-px'
  | 'md:pb-px'
  | 'lg:pb-px'
  | 'xl:pb-px'
  | 'sm:pb-cardIndexRatio'
  | 'md:pb-cardIndexRatio'
  | 'lg:pb-cardIndexRatio'
  | 'xl:pb-cardIndexRatio'
  | 'sm:pl-0'
  | 'md:pl-0'
  | 'lg:pl-0'
  | 'xl:pl-0'
  | 'sm:pl-1'
  | 'md:pl-1'
  | 'lg:pl-1'
  | 'xl:pl-1'
  | 'sm:pl-2'
  | 'md:pl-2'
  | 'lg:pl-2'
  | 'xl:pl-2'
  | 'sm:pl-3'
  | 'md:pl-3'
  | 'lg:pl-3'
  | 'xl:pl-3'
  | 'sm:pl-4'
  | 'md:pl-4'
  | 'lg:pl-4'
  | 'xl:pl-4'
  | 'sm:pl-5'
  | 'md:pl-5'
  | 'lg:pl-5'
  | 'xl:pl-5'
  | 'sm:pl-6'
  | 'md:pl-6'
  | 'lg:pl-6'
  | 'xl:pl-6'
  | 'sm:pl-8'
  | 'md:pl-8'
  | 'lg:pl-8'
  | 'xl:pl-8'
  | 'sm:pl-10'
  | 'md:pl-10'
  | 'lg:pl-10'
  | 'xl:pl-10'
  | 'sm:pl-12'
  | 'md:pl-12'
  | 'lg:pl-12'
  | 'xl:pl-12'
  | 'sm:pl-16'
  | 'md:pl-16'
  | 'lg:pl-16'
  | 'xl:pl-16'
  | 'sm:pl-20'
  | 'md:pl-20'
  | 'lg:pl-20'
  | 'xl:pl-20'
  | 'sm:pl-24'
  | 'md:pl-24'
  | 'lg:pl-24'
  | 'xl:pl-24'
  | 'sm:pl-32'
  | 'md:pl-32'
  | 'lg:pl-32'
  | 'xl:pl-32'
  | 'sm:pl-40'
  | 'md:pl-40'
  | 'lg:pl-40'
  | 'xl:pl-40'
  | 'sm:pl-48'
  | 'md:pl-48'
  | 'lg:pl-48'
  | 'xl:pl-48'
  | 'sm:pl-56'
  | 'md:pl-56'
  | 'lg:pl-56'
  | 'xl:pl-56'
  | 'sm:pl-64'
  | 'md:pl-64'
  | 'lg:pl-64'
  | 'xl:pl-64'
  | 'sm:pl-px'
  | 'md:pl-px'
  | 'lg:pl-px'
  | 'xl:pl-px'
  | 'sm:pl-cardIndexRatio'
  | 'md:pl-cardIndexRatio'
  | 'lg:pl-cardIndexRatio'
  | 'xl:pl-cardIndexRatio'
  | 'sm:place-content-start'
  | 'md:place-content-start'
  | 'lg:place-content-start'
  | 'xl:place-content-start'
  | 'sm:place-content-center'
  | 'md:place-content-center'
  | 'lg:place-content-center'
  | 'xl:place-content-center'
  | 'sm:place-content-end'
  | 'md:place-content-end'
  | 'lg:place-content-end'
  | 'xl:place-content-end'
  | 'sm:place-content-between'
  | 'md:place-content-between'
  | 'lg:place-content-between'
  | 'xl:place-content-between'
  | 'sm:place-content-around'
  | 'md:place-content-around'
  | 'lg:place-content-around'
  | 'xl:place-content-around'
  | 'sm:place-content-evenly'
  | 'md:place-content-evenly'
  | 'lg:place-content-evenly'
  | 'xl:place-content-evenly'
  | 'sm:place-content-stretch'
  | 'md:place-content-stretch'
  | 'lg:place-content-stretch'
  | 'xl:place-content-stretch'
  | 'sm:place-items-auto'
  | 'md:place-items-auto'
  | 'lg:place-items-auto'
  | 'xl:place-items-auto'
  | 'sm:place-items-start'
  | 'md:place-items-start'
  | 'lg:place-items-start'
  | 'xl:place-items-start'
  | 'sm:place-items-center'
  | 'md:place-items-center'
  | 'lg:place-items-center'
  | 'xl:place-items-center'
  | 'sm:place-items-end'
  | 'md:place-items-end'
  | 'lg:place-items-end'
  | 'xl:place-items-end'
  | 'sm:place-items-stretch'
  | 'md:place-items-stretch'
  | 'lg:place-items-stretch'
  | 'xl:place-items-stretch'
  | 'sm:place-self-auto'
  | 'md:place-self-auto'
  | 'lg:place-self-auto'
  | 'xl:place-self-auto'
  | 'sm:place-self-start'
  | 'md:place-self-start'
  | 'lg:place-self-start'
  | 'xl:place-self-start'
  | 'sm:place-self-center'
  | 'md:place-self-center'
  | 'lg:place-self-center'
  | 'xl:place-self-center'
  | 'sm:place-self-end'
  | 'md:place-self-end'
  | 'lg:place-self-end'
  | 'xl:place-self-end'
  | 'sm:place-self-stretch'
  | 'md:place-self-stretch'
  | 'lg:place-self-stretch'
  | 'xl:place-self-stretch'
  | 'sm:placeholder-transparent'
  | 'md:placeholder-transparent'
  | 'lg:placeholder-transparent'
  | 'xl:placeholder-transparent'
  | 'focus:placeholder-transparent'
  | 'sm:placeholder-current'
  | 'md:placeholder-current'
  | 'lg:placeholder-current'
  | 'xl:placeholder-current'
  | 'focus:placeholder-current'
  | 'sm:placeholder-black'
  | 'md:placeholder-black'
  | 'lg:placeholder-black'
  | 'xl:placeholder-black'
  | 'focus:placeholder-black'
  | 'sm:placeholder-white'
  | 'md:placeholder-white'
  | 'lg:placeholder-white'
  | 'xl:placeholder-white'
  | 'focus:placeholder-white'
  | 'sm:placeholder-gray-100'
  | 'md:placeholder-gray-100'
  | 'lg:placeholder-gray-100'
  | 'xl:placeholder-gray-100'
  | 'focus:placeholder-gray-100'
  | 'sm:placeholder-gray-200'
  | 'md:placeholder-gray-200'
  | 'lg:placeholder-gray-200'
  | 'xl:placeholder-gray-200'
  | 'focus:placeholder-gray-200'
  | 'sm:placeholder-gray-300'
  | 'md:placeholder-gray-300'
  | 'lg:placeholder-gray-300'
  | 'xl:placeholder-gray-300'
  | 'focus:placeholder-gray-300'
  | 'sm:placeholder-gray-400'
  | 'md:placeholder-gray-400'
  | 'lg:placeholder-gray-400'
  | 'xl:placeholder-gray-400'
  | 'focus:placeholder-gray-400'
  | 'sm:placeholder-gray-500'
  | 'md:placeholder-gray-500'
  | 'lg:placeholder-gray-500'
  | 'xl:placeholder-gray-500'
  | 'focus:placeholder-gray-500'
  | 'sm:placeholder-gray-600'
  | 'md:placeholder-gray-600'
  | 'lg:placeholder-gray-600'
  | 'xl:placeholder-gray-600'
  | 'focus:placeholder-gray-600'
  | 'sm:placeholder-gray-700'
  | 'md:placeholder-gray-700'
  | 'lg:placeholder-gray-700'
  | 'xl:placeholder-gray-700'
  | 'focus:placeholder-gray-700'
  | 'sm:placeholder-gray-800'
  | 'md:placeholder-gray-800'
  | 'lg:placeholder-gray-800'
  | 'xl:placeholder-gray-800'
  | 'focus:placeholder-gray-800'
  | 'sm:placeholder-gray-900'
  | 'md:placeholder-gray-900'
  | 'lg:placeholder-gray-900'
  | 'xl:placeholder-gray-900'
  | 'focus:placeholder-gray-900'
  | 'sm:placeholder-red-100'
  | 'md:placeholder-red-100'
  | 'lg:placeholder-red-100'
  | 'xl:placeholder-red-100'
  | 'focus:placeholder-red-100'
  | 'sm:placeholder-red-200'
  | 'md:placeholder-red-200'
  | 'lg:placeholder-red-200'
  | 'xl:placeholder-red-200'
  | 'focus:placeholder-red-200'
  | 'sm:placeholder-red-300'
  | 'md:placeholder-red-300'
  | 'lg:placeholder-red-300'
  | 'xl:placeholder-red-300'
  | 'focus:placeholder-red-300'
  | 'sm:placeholder-red-400'
  | 'md:placeholder-red-400'
  | 'lg:placeholder-red-400'
  | 'xl:placeholder-red-400'
  | 'focus:placeholder-red-400'
  | 'sm:placeholder-red-500'
  | 'md:placeholder-red-500'
  | 'lg:placeholder-red-500'
  | 'xl:placeholder-red-500'
  | 'focus:placeholder-red-500'
  | 'sm:placeholder-red-600'
  | 'md:placeholder-red-600'
  | 'lg:placeholder-red-600'
  | 'xl:placeholder-red-600'
  | 'focus:placeholder-red-600'
  | 'sm:placeholder-red-700'
  | 'md:placeholder-red-700'
  | 'lg:placeholder-red-700'
  | 'xl:placeholder-red-700'
  | 'focus:placeholder-red-700'
  | 'sm:placeholder-red-800'
  | 'md:placeholder-red-800'
  | 'lg:placeholder-red-800'
  | 'xl:placeholder-red-800'
  | 'focus:placeholder-red-800'
  | 'sm:placeholder-red-900'
  | 'md:placeholder-red-900'
  | 'lg:placeholder-red-900'
  | 'xl:placeholder-red-900'
  | 'focus:placeholder-red-900'
  | 'sm:placeholder-orange-100'
  | 'md:placeholder-orange-100'
  | 'lg:placeholder-orange-100'
  | 'xl:placeholder-orange-100'
  | 'focus:placeholder-orange-100'
  | 'sm:placeholder-orange-200'
  | 'md:placeholder-orange-200'
  | 'lg:placeholder-orange-200'
  | 'xl:placeholder-orange-200'
  | 'focus:placeholder-orange-200'
  | 'sm:placeholder-orange-300'
  | 'md:placeholder-orange-300'
  | 'lg:placeholder-orange-300'
  | 'xl:placeholder-orange-300'
  | 'focus:placeholder-orange-300'
  | 'sm:placeholder-orange-400'
  | 'md:placeholder-orange-400'
  | 'lg:placeholder-orange-400'
  | 'xl:placeholder-orange-400'
  | 'focus:placeholder-orange-400'
  | 'sm:placeholder-orange-500'
  | 'md:placeholder-orange-500'
  | 'lg:placeholder-orange-500'
  | 'xl:placeholder-orange-500'
  | 'focus:placeholder-orange-500'
  | 'sm:placeholder-orange-600'
  | 'md:placeholder-orange-600'
  | 'lg:placeholder-orange-600'
  | 'xl:placeholder-orange-600'
  | 'focus:placeholder-orange-600'
  | 'sm:placeholder-orange-700'
  | 'md:placeholder-orange-700'
  | 'lg:placeholder-orange-700'
  | 'xl:placeholder-orange-700'
  | 'focus:placeholder-orange-700'
  | 'sm:placeholder-orange-800'
  | 'md:placeholder-orange-800'
  | 'lg:placeholder-orange-800'
  | 'xl:placeholder-orange-800'
  | 'focus:placeholder-orange-800'
  | 'sm:placeholder-orange-900'
  | 'md:placeholder-orange-900'
  | 'lg:placeholder-orange-900'
  | 'xl:placeholder-orange-900'
  | 'focus:placeholder-orange-900'
  | 'sm:placeholder-yellow-100'
  | 'md:placeholder-yellow-100'
  | 'lg:placeholder-yellow-100'
  | 'xl:placeholder-yellow-100'
  | 'focus:placeholder-yellow-100'
  | 'sm:placeholder-yellow-200'
  | 'md:placeholder-yellow-200'
  | 'lg:placeholder-yellow-200'
  | 'xl:placeholder-yellow-200'
  | 'focus:placeholder-yellow-200'
  | 'sm:placeholder-yellow-300'
  | 'md:placeholder-yellow-300'
  | 'lg:placeholder-yellow-300'
  | 'xl:placeholder-yellow-300'
  | 'focus:placeholder-yellow-300'
  | 'sm:placeholder-yellow-400'
  | 'md:placeholder-yellow-400'
  | 'lg:placeholder-yellow-400'
  | 'xl:placeholder-yellow-400'
  | 'focus:placeholder-yellow-400'
  | 'sm:placeholder-yellow-500'
  | 'md:placeholder-yellow-500'
  | 'lg:placeholder-yellow-500'
  | 'xl:placeholder-yellow-500'
  | 'focus:placeholder-yellow-500'
  | 'sm:placeholder-yellow-600'
  | 'md:placeholder-yellow-600'
  | 'lg:placeholder-yellow-600'
  | 'xl:placeholder-yellow-600'
  | 'focus:placeholder-yellow-600'
  | 'sm:placeholder-yellow-700'
  | 'md:placeholder-yellow-700'
  | 'lg:placeholder-yellow-700'
  | 'xl:placeholder-yellow-700'
  | 'focus:placeholder-yellow-700'
  | 'sm:placeholder-yellow-800'
  | 'md:placeholder-yellow-800'
  | 'lg:placeholder-yellow-800'
  | 'xl:placeholder-yellow-800'
  | 'focus:placeholder-yellow-800'
  | 'sm:placeholder-yellow-900'
  | 'md:placeholder-yellow-900'
  | 'lg:placeholder-yellow-900'
  | 'xl:placeholder-yellow-900'
  | 'focus:placeholder-yellow-900'
  | 'sm:placeholder-green-100'
  | 'md:placeholder-green-100'
  | 'lg:placeholder-green-100'
  | 'xl:placeholder-green-100'
  | 'focus:placeholder-green-100'
  | 'sm:placeholder-green-200'
  | 'md:placeholder-green-200'
  | 'lg:placeholder-green-200'
  | 'xl:placeholder-green-200'
  | 'focus:placeholder-green-200'
  | 'sm:placeholder-green-300'
  | 'md:placeholder-green-300'
  | 'lg:placeholder-green-300'
  | 'xl:placeholder-green-300'
  | 'focus:placeholder-green-300'
  | 'sm:placeholder-green-400'
  | 'md:placeholder-green-400'
  | 'lg:placeholder-green-400'
  | 'xl:placeholder-green-400'
  | 'focus:placeholder-green-400'
  | 'sm:placeholder-green-500'
  | 'md:placeholder-green-500'
  | 'lg:placeholder-green-500'
  | 'xl:placeholder-green-500'
  | 'focus:placeholder-green-500'
  | 'sm:placeholder-green-600'
  | 'md:placeholder-green-600'
  | 'lg:placeholder-green-600'
  | 'xl:placeholder-green-600'
  | 'focus:placeholder-green-600'
  | 'sm:placeholder-green-700'
  | 'md:placeholder-green-700'
  | 'lg:placeholder-green-700'
  | 'xl:placeholder-green-700'
  | 'focus:placeholder-green-700'
  | 'sm:placeholder-green-800'
  | 'md:placeholder-green-800'
  | 'lg:placeholder-green-800'
  | 'xl:placeholder-green-800'
  | 'focus:placeholder-green-800'
  | 'sm:placeholder-green-900'
  | 'md:placeholder-green-900'
  | 'lg:placeholder-green-900'
  | 'xl:placeholder-green-900'
  | 'focus:placeholder-green-900'
  | 'sm:placeholder-teal-100'
  | 'md:placeholder-teal-100'
  | 'lg:placeholder-teal-100'
  | 'xl:placeholder-teal-100'
  | 'focus:placeholder-teal-100'
  | 'sm:placeholder-teal-200'
  | 'md:placeholder-teal-200'
  | 'lg:placeholder-teal-200'
  | 'xl:placeholder-teal-200'
  | 'focus:placeholder-teal-200'
  | 'sm:placeholder-teal-300'
  | 'md:placeholder-teal-300'
  | 'lg:placeholder-teal-300'
  | 'xl:placeholder-teal-300'
  | 'focus:placeholder-teal-300'
  | 'sm:placeholder-teal-400'
  | 'md:placeholder-teal-400'
  | 'lg:placeholder-teal-400'
  | 'xl:placeholder-teal-400'
  | 'focus:placeholder-teal-400'
  | 'sm:placeholder-teal-500'
  | 'md:placeholder-teal-500'
  | 'lg:placeholder-teal-500'
  | 'xl:placeholder-teal-500'
  | 'focus:placeholder-teal-500'
  | 'sm:placeholder-teal-600'
  | 'md:placeholder-teal-600'
  | 'lg:placeholder-teal-600'
  | 'xl:placeholder-teal-600'
  | 'focus:placeholder-teal-600'
  | 'sm:placeholder-teal-700'
  | 'md:placeholder-teal-700'
  | 'lg:placeholder-teal-700'
  | 'xl:placeholder-teal-700'
  | 'focus:placeholder-teal-700'
  | 'sm:placeholder-teal-800'
  | 'md:placeholder-teal-800'
  | 'lg:placeholder-teal-800'
  | 'xl:placeholder-teal-800'
  | 'focus:placeholder-teal-800'
  | 'sm:placeholder-teal-900'
  | 'md:placeholder-teal-900'
  | 'lg:placeholder-teal-900'
  | 'xl:placeholder-teal-900'
  | 'focus:placeholder-teal-900'
  | 'sm:placeholder-blue-100'
  | 'md:placeholder-blue-100'
  | 'lg:placeholder-blue-100'
  | 'xl:placeholder-blue-100'
  | 'focus:placeholder-blue-100'
  | 'sm:placeholder-blue-200'
  | 'md:placeholder-blue-200'
  | 'lg:placeholder-blue-200'
  | 'xl:placeholder-blue-200'
  | 'focus:placeholder-blue-200'
  | 'sm:placeholder-blue-300'
  | 'md:placeholder-blue-300'
  | 'lg:placeholder-blue-300'
  | 'xl:placeholder-blue-300'
  | 'focus:placeholder-blue-300'
  | 'sm:placeholder-blue-400'
  | 'md:placeholder-blue-400'
  | 'lg:placeholder-blue-400'
  | 'xl:placeholder-blue-400'
  | 'focus:placeholder-blue-400'
  | 'sm:placeholder-blue-500'
  | 'md:placeholder-blue-500'
  | 'lg:placeholder-blue-500'
  | 'xl:placeholder-blue-500'
  | 'focus:placeholder-blue-500'
  | 'sm:placeholder-blue-600'
  | 'md:placeholder-blue-600'
  | 'lg:placeholder-blue-600'
  | 'xl:placeholder-blue-600'
  | 'focus:placeholder-blue-600'
  | 'sm:placeholder-blue-700'
  | 'md:placeholder-blue-700'
  | 'lg:placeholder-blue-700'
  | 'xl:placeholder-blue-700'
  | 'focus:placeholder-blue-700'
  | 'sm:placeholder-blue-800'
  | 'md:placeholder-blue-800'
  | 'lg:placeholder-blue-800'
  | 'xl:placeholder-blue-800'
  | 'focus:placeholder-blue-800'
  | 'sm:placeholder-blue-900'
  | 'md:placeholder-blue-900'
  | 'lg:placeholder-blue-900'
  | 'xl:placeholder-blue-900'
  | 'focus:placeholder-blue-900'
  | 'sm:placeholder-indigo-100'
  | 'md:placeholder-indigo-100'
  | 'lg:placeholder-indigo-100'
  | 'xl:placeholder-indigo-100'
  | 'focus:placeholder-indigo-100'
  | 'sm:placeholder-indigo-200'
  | 'md:placeholder-indigo-200'
  | 'lg:placeholder-indigo-200'
  | 'xl:placeholder-indigo-200'
  | 'focus:placeholder-indigo-200'
  | 'sm:placeholder-indigo-300'
  | 'md:placeholder-indigo-300'
  | 'lg:placeholder-indigo-300'
  | 'xl:placeholder-indigo-300'
  | 'focus:placeholder-indigo-300'
  | 'sm:placeholder-indigo-400'
  | 'md:placeholder-indigo-400'
  | 'lg:placeholder-indigo-400'
  | 'xl:placeholder-indigo-400'
  | 'focus:placeholder-indigo-400'
  | 'sm:placeholder-indigo-500'
  | 'md:placeholder-indigo-500'
  | 'lg:placeholder-indigo-500'
  | 'xl:placeholder-indigo-500'
  | 'focus:placeholder-indigo-500'
  | 'sm:placeholder-indigo-600'
  | 'md:placeholder-indigo-600'
  | 'lg:placeholder-indigo-600'
  | 'xl:placeholder-indigo-600'
  | 'focus:placeholder-indigo-600'
  | 'sm:placeholder-indigo-700'
  | 'md:placeholder-indigo-700'
  | 'lg:placeholder-indigo-700'
  | 'xl:placeholder-indigo-700'
  | 'focus:placeholder-indigo-700'
  | 'sm:placeholder-indigo-800'
  | 'md:placeholder-indigo-800'
  | 'lg:placeholder-indigo-800'
  | 'xl:placeholder-indigo-800'
  | 'focus:placeholder-indigo-800'
  | 'sm:placeholder-indigo-900'
  | 'md:placeholder-indigo-900'
  | 'lg:placeholder-indigo-900'
  | 'xl:placeholder-indigo-900'
  | 'focus:placeholder-indigo-900'
  | 'sm:placeholder-purple-100'
  | 'md:placeholder-purple-100'
  | 'lg:placeholder-purple-100'
  | 'xl:placeholder-purple-100'
  | 'focus:placeholder-purple-100'
  | 'sm:placeholder-purple-200'
  | 'md:placeholder-purple-200'
  | 'lg:placeholder-purple-200'
  | 'xl:placeholder-purple-200'
  | 'focus:placeholder-purple-200'
  | 'sm:placeholder-purple-300'
  | 'md:placeholder-purple-300'
  | 'lg:placeholder-purple-300'
  | 'xl:placeholder-purple-300'
  | 'focus:placeholder-purple-300'
  | 'sm:placeholder-purple-400'
  | 'md:placeholder-purple-400'
  | 'lg:placeholder-purple-400'
  | 'xl:placeholder-purple-400'
  | 'focus:placeholder-purple-400'
  | 'sm:placeholder-purple-500'
  | 'md:placeholder-purple-500'
  | 'lg:placeholder-purple-500'
  | 'xl:placeholder-purple-500'
  | 'focus:placeholder-purple-500'
  | 'sm:placeholder-purple-600'
  | 'md:placeholder-purple-600'
  | 'lg:placeholder-purple-600'
  | 'xl:placeholder-purple-600'
  | 'focus:placeholder-purple-600'
  | 'sm:placeholder-purple-700'
  | 'md:placeholder-purple-700'
  | 'lg:placeholder-purple-700'
  | 'xl:placeholder-purple-700'
  | 'focus:placeholder-purple-700'
  | 'sm:placeholder-purple-800'
  | 'md:placeholder-purple-800'
  | 'lg:placeholder-purple-800'
  | 'xl:placeholder-purple-800'
  | 'focus:placeholder-purple-800'
  | 'sm:placeholder-purple-900'
  | 'md:placeholder-purple-900'
  | 'lg:placeholder-purple-900'
  | 'xl:placeholder-purple-900'
  | 'focus:placeholder-purple-900'
  | 'sm:placeholder-pink-100'
  | 'md:placeholder-pink-100'
  | 'lg:placeholder-pink-100'
  | 'xl:placeholder-pink-100'
  | 'focus:placeholder-pink-100'
  | 'sm:placeholder-pink-200'
  | 'md:placeholder-pink-200'
  | 'lg:placeholder-pink-200'
  | 'xl:placeholder-pink-200'
  | 'focus:placeholder-pink-200'
  | 'sm:placeholder-pink-300'
  | 'md:placeholder-pink-300'
  | 'lg:placeholder-pink-300'
  | 'xl:placeholder-pink-300'
  | 'focus:placeholder-pink-300'
  | 'sm:placeholder-pink-400'
  | 'md:placeholder-pink-400'
  | 'lg:placeholder-pink-400'
  | 'xl:placeholder-pink-400'
  | 'focus:placeholder-pink-400'
  | 'sm:placeholder-pink-500'
  | 'md:placeholder-pink-500'
  | 'lg:placeholder-pink-500'
  | 'xl:placeholder-pink-500'
  | 'focus:placeholder-pink-500'
  | 'sm:placeholder-pink-600'
  | 'md:placeholder-pink-600'
  | 'lg:placeholder-pink-600'
  | 'xl:placeholder-pink-600'
  | 'focus:placeholder-pink-600'
  | 'sm:placeholder-pink-700'
  | 'md:placeholder-pink-700'
  | 'lg:placeholder-pink-700'
  | 'xl:placeholder-pink-700'
  | 'focus:placeholder-pink-700'
  | 'sm:placeholder-pink-800'
  | 'md:placeholder-pink-800'
  | 'lg:placeholder-pink-800'
  | 'xl:placeholder-pink-800'
  | 'focus:placeholder-pink-800'
  | 'sm:placeholder-pink-900'
  | 'md:placeholder-pink-900'
  | 'lg:placeholder-pink-900'
  | 'xl:placeholder-pink-900'
  | 'focus:placeholder-pink-900'
  | 'sm:placeholder-primary-light'
  | 'md:placeholder-primary-light'
  | 'lg:placeholder-primary-light'
  | 'xl:placeholder-primary-light'
  | 'focus:placeholder-primary-light'
  | 'sm:placeholder-primary'
  | 'md:placeholder-primary'
  | 'lg:placeholder-primary'
  | 'xl:placeholder-primary'
  | 'focus:placeholder-primary'
  | 'sm:placeholder-primary-dark'
  | 'md:placeholder-primary-dark'
  | 'lg:placeholder-primary-dark'
  | 'xl:placeholder-primary-dark'
  | 'focus:placeholder-primary-dark'
  | 'sm:placeholder-accent-dark'
  | 'md:placeholder-accent-dark'
  | 'lg:placeholder-accent-dark'
  | 'xl:placeholder-accent-dark'
  | 'focus:placeholder-accent-dark'
  | 'sm:placeholder-accent'
  | 'md:placeholder-accent'
  | 'lg:placeholder-accent'
  | 'xl:placeholder-accent'
  | 'focus:placeholder-accent'
  | 'sm:placeholder-accent-light'
  | 'md:placeholder-accent-light'
  | 'lg:placeholder-accent-light'
  | 'xl:placeholder-accent-light'
  | 'focus:placeholder-accent-light'
  | 'sm:placeholder-warning-dark'
  | 'md:placeholder-warning-dark'
  | 'lg:placeholder-warning-dark'
  | 'xl:placeholder-warning-dark'
  | 'focus:placeholder-warning-dark'
  | 'sm:placeholder-warning'
  | 'md:placeholder-warning'
  | 'lg:placeholder-warning'
  | 'xl:placeholder-warning'
  | 'focus:placeholder-warning'
  | 'sm:placeholder-warning-light'
  | 'md:placeholder-warning-light'
  | 'lg:placeholder-warning-light'
  | 'xl:placeholder-warning-light'
  | 'focus:placeholder-warning-light'
  | 'sm:placeholder-dark'
  | 'md:placeholder-dark'
  | 'lg:placeholder-dark'
  | 'xl:placeholder-dark'
  | 'focus:placeholder-dark'
  | 'sm:placeholder-dark-secondary'
  | 'md:placeholder-dark-secondary'
  | 'lg:placeholder-dark-secondary'
  | 'xl:placeholder-dark-secondary'
  | 'focus:placeholder-dark-secondary'
  | 'sm:placeholder-light'
  | 'md:placeholder-light'
  | 'lg:placeholder-light'
  | 'xl:placeholder-light'
  | 'focus:placeholder-light'
  | 'sm:placeholder-light-secondary'
  | 'md:placeholder-light-secondary'
  | 'lg:placeholder-light-secondary'
  | 'xl:placeholder-light-secondary'
  | 'focus:placeholder-light-secondary'
  | 'sm:placeholder-light-hint'
  | 'md:placeholder-light-hint'
  | 'lg:placeholder-light-hint'
  | 'xl:placeholder-light-hint'
  | 'focus:placeholder-light-hint'
  | 'sm:placeholder-opacity-0'
  | 'md:placeholder-opacity-0'
  | 'lg:placeholder-opacity-0'
  | 'xl:placeholder-opacity-0'
  | 'focus:placeholder-opacity-0'
  | 'sm:placeholder-opacity-25'
  | 'md:placeholder-opacity-25'
  | 'lg:placeholder-opacity-25'
  | 'xl:placeholder-opacity-25'
  | 'focus:placeholder-opacity-25'
  | 'sm:placeholder-opacity-50'
  | 'md:placeholder-opacity-50'
  | 'lg:placeholder-opacity-50'
  | 'xl:placeholder-opacity-50'
  | 'focus:placeholder-opacity-50'
  | 'sm:placeholder-opacity-75'
  | 'md:placeholder-opacity-75'
  | 'lg:placeholder-opacity-75'
  | 'xl:placeholder-opacity-75'
  | 'focus:placeholder-opacity-75'
  | 'sm:placeholder-opacity-100'
  | 'md:placeholder-opacity-100'
  | 'lg:placeholder-opacity-100'
  | 'xl:placeholder-opacity-100'
  | 'focus:placeholder-opacity-100'
  | 'sm:pointer-events-none'
  | 'md:pointer-events-none'
  | 'lg:pointer-events-none'
  | 'xl:pointer-events-none'
  | 'sm:pointer-events-auto'
  | 'md:pointer-events-auto'
  | 'lg:pointer-events-auto'
  | 'xl:pointer-events-auto'
  | 'sm:static'
  | 'md:static'
  | 'lg:static'
  | 'xl:static'
  | 'sm:fixed'
  | 'md:fixed'
  | 'lg:fixed'
  | 'xl:fixed'
  | 'sm:absolute'
  | 'md:absolute'
  | 'lg:absolute'
  | 'xl:absolute'
  | 'sm:relative'
  | 'md:relative'
  | 'lg:relative'
  | 'xl:relative'
  | 'sm:sticky'
  | 'md:sticky'
  | 'lg:sticky'
  | 'xl:sticky'
  | 'sm:resize-none'
  | 'md:resize-none'
  | 'lg:resize-none'
  | 'xl:resize-none'
  | 'sm:resize'
  | 'md:resize'
  | 'lg:resize'
  | 'xl:resize'
  | 'sm:resize-y'
  | 'md:resize-y'
  | 'lg:resize-y'
  | 'xl:resize-y'
  | 'sm:resize-x'
  | 'md:resize-x'
  | 'lg:resize-x'
  | 'xl:resize-x'
  | 'sm:space-x-0'
  | 'md:space-x-0'
  | 'lg:space-x-0'
  | 'xl:space-x-0'
  | 'sm:space-x-1'
  | 'md:space-x-1'
  | 'lg:space-x-1'
  | 'xl:space-x-1'
  | 'sm:space-x-2'
  | 'md:space-x-2'
  | 'lg:space-x-2'
  | 'xl:space-x-2'
  | 'sm:space-x-3'
  | 'md:space-x-3'
  | 'lg:space-x-3'
  | 'xl:space-x-3'
  | 'sm:space-x-4'
  | 'md:space-x-4'
  | 'lg:space-x-4'
  | 'xl:space-x-4'
  | 'sm:space-x-5'
  | 'md:space-x-5'
  | 'lg:space-x-5'
  | 'xl:space-x-5'
  | 'sm:space-x-6'
  | 'md:space-x-6'
  | 'lg:space-x-6'
  | 'xl:space-x-6'
  | 'sm:space-x-8'
  | 'md:space-x-8'
  | 'lg:space-x-8'
  | 'xl:space-x-8'
  | 'sm:space-x-10'
  | 'md:space-x-10'
  | 'lg:space-x-10'
  | 'xl:space-x-10'
  | 'sm:space-x-12'
  | 'md:space-x-12'
  | 'lg:space-x-12'
  | 'xl:space-x-12'
  | 'sm:space-x-16'
  | 'md:space-x-16'
  | 'lg:space-x-16'
  | 'xl:space-x-16'
  | 'sm:space-x-20'
  | 'md:space-x-20'
  | 'lg:space-x-20'
  | 'xl:space-x-20'
  | 'sm:space-x-24'
  | 'md:space-x-24'
  | 'lg:space-x-24'
  | 'xl:space-x-24'
  | 'sm:space-x-32'
  | 'md:space-x-32'
  | 'lg:space-x-32'
  | 'xl:space-x-32'
  | 'sm:space-x-40'
  | 'md:space-x-40'
  | 'lg:space-x-40'
  | 'xl:space-x-40'
  | 'sm:space-x-48'
  | 'md:space-x-48'
  | 'lg:space-x-48'
  | 'xl:space-x-48'
  | 'sm:space-x-56'
  | 'md:space-x-56'
  | 'lg:space-x-56'
  | 'xl:space-x-56'
  | 'sm:space-x-64'
  | 'md:space-x-64'
  | 'lg:space-x-64'
  | 'xl:space-x-64'
  | 'sm:space-x-px'
  | 'md:space-x-px'
  | 'lg:space-x-px'
  | 'xl:space-x-px'
  | 'sm:-space-x-0'
  | 'md:-space-x-0'
  | 'lg:-space-x-0'
  | 'xl:-space-x-0'
  | 'sm:-space-x-1'
  | 'md:-space-x-1'
  | 'lg:-space-x-1'
  | 'xl:-space-x-1'
  | 'sm:-space-x-2'
  | 'md:-space-x-2'
  | 'lg:-space-x-2'
  | 'xl:-space-x-2'
  | 'sm:-space-x-3'
  | 'md:-space-x-3'
  | 'lg:-space-x-3'
  | 'xl:-space-x-3'
  | 'sm:-space-x-4'
  | 'md:-space-x-4'
  | 'lg:-space-x-4'
  | 'xl:-space-x-4'
  | 'sm:-space-x-5'
  | 'md:-space-x-5'
  | 'lg:-space-x-5'
  | 'xl:-space-x-5'
  | 'sm:-space-x-6'
  | 'md:-space-x-6'
  | 'lg:-space-x-6'
  | 'xl:-space-x-6'
  | 'sm:-space-x-8'
  | 'md:-space-x-8'
  | 'lg:-space-x-8'
  | 'xl:-space-x-8'
  | 'sm:-space-x-10'
  | 'md:-space-x-10'
  | 'lg:-space-x-10'
  | 'xl:-space-x-10'
  | 'sm:-space-x-12'
  | 'md:-space-x-12'
  | 'lg:-space-x-12'
  | 'xl:-space-x-12'
  | 'sm:-space-x-16'
  | 'md:-space-x-16'
  | 'lg:-space-x-16'
  | 'xl:-space-x-16'
  | 'sm:-space-x-20'
  | 'md:-space-x-20'
  | 'lg:-space-x-20'
  | 'xl:-space-x-20'
  | 'sm:-space-x-24'
  | 'md:-space-x-24'
  | 'lg:-space-x-24'
  | 'xl:-space-x-24'
  | 'sm:-space-x-32'
  | 'md:-space-x-32'
  | 'lg:-space-x-32'
  | 'xl:-space-x-32'
  | 'sm:-space-x-40'
  | 'md:-space-x-40'
  | 'lg:-space-x-40'
  | 'xl:-space-x-40'
  | 'sm:-space-x-48'
  | 'md:-space-x-48'
  | 'lg:-space-x-48'
  | 'xl:-space-x-48'
  | 'sm:-space-x-56'
  | 'md:-space-x-56'
  | 'lg:-space-x-56'
  | 'xl:-space-x-56'
  | 'sm:-space-x-64'
  | 'md:-space-x-64'
  | 'lg:-space-x-64'
  | 'xl:-space-x-64'
  | 'sm:-space-x-px'
  | 'md:-space-x-px'
  | 'lg:-space-x-px'
  | 'xl:-space-x-px'
  | 'sm:space-x-reverse'
  | 'md:space-x-reverse'
  | 'lg:space-x-reverse'
  | 'xl:space-x-reverse'
  | 'sm:space-y-0'
  | 'md:space-y-0'
  | 'lg:space-y-0'
  | 'xl:space-y-0'
  | 'sm:space-y-1'
  | 'md:space-y-1'
  | 'lg:space-y-1'
  | 'xl:space-y-1'
  | 'sm:space-y-2'
  | 'md:space-y-2'
  | 'lg:space-y-2'
  | 'xl:space-y-2'
  | 'sm:space-y-3'
  | 'md:space-y-3'
  | 'lg:space-y-3'
  | 'xl:space-y-3'
  | 'sm:space-y-4'
  | 'md:space-y-4'
  | 'lg:space-y-4'
  | 'xl:space-y-4'
  | 'sm:space-y-5'
  | 'md:space-y-5'
  | 'lg:space-y-5'
  | 'xl:space-y-5'
  | 'sm:space-y-6'
  | 'md:space-y-6'
  | 'lg:space-y-6'
  | 'xl:space-y-6'
  | 'sm:space-y-8'
  | 'md:space-y-8'
  | 'lg:space-y-8'
  | 'xl:space-y-8'
  | 'sm:space-y-10'
  | 'md:space-y-10'
  | 'lg:space-y-10'
  | 'xl:space-y-10'
  | 'sm:space-y-12'
  | 'md:space-y-12'
  | 'lg:space-y-12'
  | 'xl:space-y-12'
  | 'sm:space-y-16'
  | 'md:space-y-16'
  | 'lg:space-y-16'
  | 'xl:space-y-16'
  | 'sm:space-y-20'
  | 'md:space-y-20'
  | 'lg:space-y-20'
  | 'xl:space-y-20'
  | 'sm:space-y-24'
  | 'md:space-y-24'
  | 'lg:space-y-24'
  | 'xl:space-y-24'
  | 'sm:space-y-32'
  | 'md:space-y-32'
  | 'lg:space-y-32'
  | 'xl:space-y-32'
  | 'sm:space-y-40'
  | 'md:space-y-40'
  | 'lg:space-y-40'
  | 'xl:space-y-40'
  | 'sm:space-y-48'
  | 'md:space-y-48'
  | 'lg:space-y-48'
  | 'xl:space-y-48'
  | 'sm:space-y-56'
  | 'md:space-y-56'
  | 'lg:space-y-56'
  | 'xl:space-y-56'
  | 'sm:space-y-64'
  | 'md:space-y-64'
  | 'lg:space-y-64'
  | 'xl:space-y-64'
  | 'sm:space-y-px'
  | 'md:space-y-px'
  | 'lg:space-y-px'
  | 'xl:space-y-px'
  | 'sm:-space-y-0'
  | 'md:-space-y-0'
  | 'lg:-space-y-0'
  | 'xl:-space-y-0'
  | 'sm:-space-y-1'
  | 'md:-space-y-1'
  | 'lg:-space-y-1'
  | 'xl:-space-y-1'
  | 'sm:-space-y-2'
  | 'md:-space-y-2'
  | 'lg:-space-y-2'
  | 'xl:-space-y-2'
  | 'sm:-space-y-3'
  | 'md:-space-y-3'
  | 'lg:-space-y-3'
  | 'xl:-space-y-3'
  | 'sm:-space-y-4'
  | 'md:-space-y-4'
  | 'lg:-space-y-4'
  | 'xl:-space-y-4'
  | 'sm:-space-y-5'
  | 'md:-space-y-5'
  | 'lg:-space-y-5'
  | 'xl:-space-y-5'
  | 'sm:-space-y-6'
  | 'md:-space-y-6'
  | 'lg:-space-y-6'
  | 'xl:-space-y-6'
  | 'sm:-space-y-8'
  | 'md:-space-y-8'
  | 'lg:-space-y-8'
  | 'xl:-space-y-8'
  | 'sm:-space-y-10'
  | 'md:-space-y-10'
  | 'lg:-space-y-10'
  | 'xl:-space-y-10'
  | 'sm:-space-y-12'
  | 'md:-space-y-12'
  | 'lg:-space-y-12'
  | 'xl:-space-y-12'
  | 'sm:-space-y-16'
  | 'md:-space-y-16'
  | 'lg:-space-y-16'
  | 'xl:-space-y-16'
  | 'sm:-space-y-20'
  | 'md:-space-y-20'
  | 'lg:-space-y-20'
  | 'xl:-space-y-20'
  | 'sm:-space-y-24'
  | 'md:-space-y-24'
  | 'lg:-space-y-24'
  | 'xl:-space-y-24'
  | 'sm:-space-y-32'
  | 'md:-space-y-32'
  | 'lg:-space-y-32'
  | 'xl:-space-y-32'
  | 'sm:-space-y-40'
  | 'md:-space-y-40'
  | 'lg:-space-y-40'
  | 'xl:-space-y-40'
  | 'sm:-space-y-48'
  | 'md:-space-y-48'
  | 'lg:-space-y-48'
  | 'xl:-space-y-48'
  | 'sm:-space-y-56'
  | 'md:-space-y-56'
  | 'lg:-space-y-56'
  | 'xl:-space-y-56'
  | 'sm:-space-y-64'
  | 'md:-space-y-64'
  | 'lg:-space-y-64'
  | 'xl:-space-y-64'
  | 'sm:-space-y-px'
  | 'md:-space-y-px'
  | 'lg:-space-y-px'
  | 'xl:-space-y-px'
  | 'sm:space-y-reverse'
  | 'md:space-y-reverse'
  | 'lg:space-y-reverse'
  | 'xl:space-y-reverse'
  | 'sm:stroke-current'
  | 'md:stroke-current'
  | 'lg:stroke-current'
  | 'xl:stroke-current'
  | 'sm:stroke-0'
  | 'md:stroke-0'
  | 'lg:stroke-0'
  | 'xl:stroke-0'
  | 'sm:stroke-1'
  | 'md:stroke-1'
  | 'lg:stroke-1'
  | 'xl:stroke-1'
  | 'sm:stroke-2'
  | 'md:stroke-2'
  | 'lg:stroke-2'
  | 'xl:stroke-2'
  | 'sm:table-auto'
  | 'md:table-auto'
  | 'lg:table-auto'
  | 'xl:table-auto'
  | 'sm:table-fixed'
  | 'md:table-fixed'
  | 'lg:table-fixed'
  | 'xl:table-fixed'
  | 'sm:text-left'
  | 'md:text-left'
  | 'lg:text-left'
  | 'xl:text-left'
  | 'sm:text-center'
  | 'md:text-center'
  | 'lg:text-center'
  | 'xl:text-center'
  | 'sm:text-right'
  | 'md:text-right'
  | 'lg:text-right'
  | 'xl:text-right'
  | 'sm:text-justify'
  | 'md:text-justify'
  | 'lg:text-justify'
  | 'xl:text-justify'
  | 'sm:text-transparent'
  | 'md:text-transparent'
  | 'lg:text-transparent'
  | 'xl:text-transparent'
  | 'hover:text-transparent'
  | 'focus:text-transparent'
  | 'group-hover:text-transparent'
  | 'sm:text-current'
  | 'md:text-current'
  | 'lg:text-current'
  | 'xl:text-current'
  | 'hover:text-current'
  | 'focus:text-current'
  | 'group-hover:text-current'
  | 'sm:text-black'
  | 'md:text-black'
  | 'lg:text-black'
  | 'xl:text-black'
  | 'hover:text-black'
  | 'focus:text-black'
  | 'group-hover:text-black'
  | 'sm:text-white'
  | 'md:text-white'
  | 'lg:text-white'
  | 'xl:text-white'
  | 'hover:text-white'
  | 'focus:text-white'
  | 'group-hover:text-white'
  | 'sm:text-gray-100'
  | 'md:text-gray-100'
  | 'lg:text-gray-100'
  | 'xl:text-gray-100'
  | 'hover:text-gray-100'
  | 'focus:text-gray-100'
  | 'group-hover:text-gray-100'
  | 'sm:text-gray-200'
  | 'md:text-gray-200'
  | 'lg:text-gray-200'
  | 'xl:text-gray-200'
  | 'hover:text-gray-200'
  | 'focus:text-gray-200'
  | 'group-hover:text-gray-200'
  | 'sm:text-gray-300'
  | 'md:text-gray-300'
  | 'lg:text-gray-300'
  | 'xl:text-gray-300'
  | 'hover:text-gray-300'
  | 'focus:text-gray-300'
  | 'group-hover:text-gray-300'
  | 'sm:text-gray-400'
  | 'md:text-gray-400'
  | 'lg:text-gray-400'
  | 'xl:text-gray-400'
  | 'hover:text-gray-400'
  | 'focus:text-gray-400'
  | 'group-hover:text-gray-400'
  | 'sm:text-gray-500'
  | 'md:text-gray-500'
  | 'lg:text-gray-500'
  | 'xl:text-gray-500'
  | 'hover:text-gray-500'
  | 'focus:text-gray-500'
  | 'group-hover:text-gray-500'
  | 'sm:text-gray-600'
  | 'md:text-gray-600'
  | 'lg:text-gray-600'
  | 'xl:text-gray-600'
  | 'hover:text-gray-600'
  | 'focus:text-gray-600'
  | 'group-hover:text-gray-600'
  | 'sm:text-gray-700'
  | 'md:text-gray-700'
  | 'lg:text-gray-700'
  | 'xl:text-gray-700'
  | 'hover:text-gray-700'
  | 'focus:text-gray-700'
  | 'group-hover:text-gray-700'
  | 'sm:text-gray-800'
  | 'md:text-gray-800'
  | 'lg:text-gray-800'
  | 'xl:text-gray-800'
  | 'hover:text-gray-800'
  | 'focus:text-gray-800'
  | 'group-hover:text-gray-800'
  | 'sm:text-gray-900'
  | 'md:text-gray-900'
  | 'lg:text-gray-900'
  | 'xl:text-gray-900'
  | 'hover:text-gray-900'
  | 'focus:text-gray-900'
  | 'group-hover:text-gray-900'
  | 'sm:text-red-100'
  | 'md:text-red-100'
  | 'lg:text-red-100'
  | 'xl:text-red-100'
  | 'hover:text-red-100'
  | 'focus:text-red-100'
  | 'group-hover:text-red-100'
  | 'sm:text-red-200'
  | 'md:text-red-200'
  | 'lg:text-red-200'
  | 'xl:text-red-200'
  | 'hover:text-red-200'
  | 'focus:text-red-200'
  | 'group-hover:text-red-200'
  | 'sm:text-red-300'
  | 'md:text-red-300'
  | 'lg:text-red-300'
  | 'xl:text-red-300'
  | 'hover:text-red-300'
  | 'focus:text-red-300'
  | 'group-hover:text-red-300'
  | 'sm:text-red-400'
  | 'md:text-red-400'
  | 'lg:text-red-400'
  | 'xl:text-red-400'
  | 'hover:text-red-400'
  | 'focus:text-red-400'
  | 'group-hover:text-red-400'
  | 'sm:text-red-500'
  | 'md:text-red-500'
  | 'lg:text-red-500'
  | 'xl:text-red-500'
  | 'hover:text-red-500'
  | 'focus:text-red-500'
  | 'group-hover:text-red-500'
  | 'sm:text-red-600'
  | 'md:text-red-600'
  | 'lg:text-red-600'
  | 'xl:text-red-600'
  | 'hover:text-red-600'
  | 'focus:text-red-600'
  | 'group-hover:text-red-600'
  | 'sm:text-red-700'
  | 'md:text-red-700'
  | 'lg:text-red-700'
  | 'xl:text-red-700'
  | 'hover:text-red-700'
  | 'focus:text-red-700'
  | 'group-hover:text-red-700'
  | 'sm:text-red-800'
  | 'md:text-red-800'
  | 'lg:text-red-800'
  | 'xl:text-red-800'
  | 'hover:text-red-800'
  | 'focus:text-red-800'
  | 'group-hover:text-red-800'
  | 'sm:text-red-900'
  | 'md:text-red-900'
  | 'lg:text-red-900'
  | 'xl:text-red-900'
  | 'hover:text-red-900'
  | 'focus:text-red-900'
  | 'group-hover:text-red-900'
  | 'sm:text-orange-100'
  | 'md:text-orange-100'
  | 'lg:text-orange-100'
  | 'xl:text-orange-100'
  | 'hover:text-orange-100'
  | 'focus:text-orange-100'
  | 'group-hover:text-orange-100'
  | 'sm:text-orange-200'
  | 'md:text-orange-200'
  | 'lg:text-orange-200'
  | 'xl:text-orange-200'
  | 'hover:text-orange-200'
  | 'focus:text-orange-200'
  | 'group-hover:text-orange-200'
  | 'sm:text-orange-300'
  | 'md:text-orange-300'
  | 'lg:text-orange-300'
  | 'xl:text-orange-300'
  | 'hover:text-orange-300'
  | 'focus:text-orange-300'
  | 'group-hover:text-orange-300'
  | 'sm:text-orange-400'
  | 'md:text-orange-400'
  | 'lg:text-orange-400'
  | 'xl:text-orange-400'
  | 'hover:text-orange-400'
  | 'focus:text-orange-400'
  | 'group-hover:text-orange-400'
  | 'sm:text-orange-500'
  | 'md:text-orange-500'
  | 'lg:text-orange-500'
  | 'xl:text-orange-500'
  | 'hover:text-orange-500'
  | 'focus:text-orange-500'
  | 'group-hover:text-orange-500'
  | 'sm:text-orange-600'
  | 'md:text-orange-600'
  | 'lg:text-orange-600'
  | 'xl:text-orange-600'
  | 'hover:text-orange-600'
  | 'focus:text-orange-600'
  | 'group-hover:text-orange-600'
  | 'sm:text-orange-700'
  | 'md:text-orange-700'
  | 'lg:text-orange-700'
  | 'xl:text-orange-700'
  | 'hover:text-orange-700'
  | 'focus:text-orange-700'
  | 'group-hover:text-orange-700'
  | 'sm:text-orange-800'
  | 'md:text-orange-800'
  | 'lg:text-orange-800'
  | 'xl:text-orange-800'
  | 'hover:text-orange-800'
  | 'focus:text-orange-800'
  | 'group-hover:text-orange-800'
  | 'sm:text-orange-900'
  | 'md:text-orange-900'
  | 'lg:text-orange-900'
  | 'xl:text-orange-900'
  | 'hover:text-orange-900'
  | 'focus:text-orange-900'
  | 'group-hover:text-orange-900'
  | 'sm:text-yellow-100'
  | 'md:text-yellow-100'
  | 'lg:text-yellow-100'
  | 'xl:text-yellow-100'
  | 'hover:text-yellow-100'
  | 'focus:text-yellow-100'
  | 'group-hover:text-yellow-100'
  | 'sm:text-yellow-200'
  | 'md:text-yellow-200'
  | 'lg:text-yellow-200'
  | 'xl:text-yellow-200'
  | 'hover:text-yellow-200'
  | 'focus:text-yellow-200'
  | 'group-hover:text-yellow-200'
  | 'sm:text-yellow-300'
  | 'md:text-yellow-300'
  | 'lg:text-yellow-300'
  | 'xl:text-yellow-300'
  | 'hover:text-yellow-300'
  | 'focus:text-yellow-300'
  | 'group-hover:text-yellow-300'
  | 'sm:text-yellow-400'
  | 'md:text-yellow-400'
  | 'lg:text-yellow-400'
  | 'xl:text-yellow-400'
  | 'hover:text-yellow-400'
  | 'focus:text-yellow-400'
  | 'group-hover:text-yellow-400'
  | 'sm:text-yellow-500'
  | 'md:text-yellow-500'
  | 'lg:text-yellow-500'
  | 'xl:text-yellow-500'
  | 'hover:text-yellow-500'
  | 'focus:text-yellow-500'
  | 'group-hover:text-yellow-500'
  | 'sm:text-yellow-600'
  | 'md:text-yellow-600'
  | 'lg:text-yellow-600'
  | 'xl:text-yellow-600'
  | 'hover:text-yellow-600'
  | 'focus:text-yellow-600'
  | 'group-hover:text-yellow-600'
  | 'sm:text-yellow-700'
  | 'md:text-yellow-700'
  | 'lg:text-yellow-700'
  | 'xl:text-yellow-700'
  | 'hover:text-yellow-700'
  | 'focus:text-yellow-700'
  | 'group-hover:text-yellow-700'
  | 'sm:text-yellow-800'
  | 'md:text-yellow-800'
  | 'lg:text-yellow-800'
  | 'xl:text-yellow-800'
  | 'hover:text-yellow-800'
  | 'focus:text-yellow-800'
  | 'group-hover:text-yellow-800'
  | 'sm:text-yellow-900'
  | 'md:text-yellow-900'
  | 'lg:text-yellow-900'
  | 'xl:text-yellow-900'
  | 'hover:text-yellow-900'
  | 'focus:text-yellow-900'
  | 'group-hover:text-yellow-900'
  | 'sm:text-green-100'
  | 'md:text-green-100'
  | 'lg:text-green-100'
  | 'xl:text-green-100'
  | 'hover:text-green-100'
  | 'focus:text-green-100'
  | 'group-hover:text-green-100'
  | 'sm:text-green-200'
  | 'md:text-green-200'
  | 'lg:text-green-200'
  | 'xl:text-green-200'
  | 'hover:text-green-200'
  | 'focus:text-green-200'
  | 'group-hover:text-green-200'
  | 'sm:text-green-300'
  | 'md:text-green-300'
  | 'lg:text-green-300'
  | 'xl:text-green-300'
  | 'hover:text-green-300'
  | 'focus:text-green-300'
  | 'group-hover:text-green-300'
  | 'sm:text-green-400'
  | 'md:text-green-400'
  | 'lg:text-green-400'
  | 'xl:text-green-400'
  | 'hover:text-green-400'
  | 'focus:text-green-400'
  | 'group-hover:text-green-400'
  | 'sm:text-green-500'
  | 'md:text-green-500'
  | 'lg:text-green-500'
  | 'xl:text-green-500'
  | 'hover:text-green-500'
  | 'focus:text-green-500'
  | 'group-hover:text-green-500'
  | 'sm:text-green-600'
  | 'md:text-green-600'
  | 'lg:text-green-600'
  | 'xl:text-green-600'
  | 'hover:text-green-600'
  | 'focus:text-green-600'
  | 'group-hover:text-green-600'
  | 'sm:text-green-700'
  | 'md:text-green-700'
  | 'lg:text-green-700'
  | 'xl:text-green-700'
  | 'hover:text-green-700'
  | 'focus:text-green-700'
  | 'group-hover:text-green-700'
  | 'sm:text-green-800'
  | 'md:text-green-800'
  | 'lg:text-green-800'
  | 'xl:text-green-800'
  | 'hover:text-green-800'
  | 'focus:text-green-800'
  | 'group-hover:text-green-800'
  | 'sm:text-green-900'
  | 'md:text-green-900'
  | 'lg:text-green-900'
  | 'xl:text-green-900'
  | 'hover:text-green-900'
  | 'focus:text-green-900'
  | 'group-hover:text-green-900'
  | 'sm:text-teal-100'
  | 'md:text-teal-100'
  | 'lg:text-teal-100'
  | 'xl:text-teal-100'
  | 'hover:text-teal-100'
  | 'focus:text-teal-100'
  | 'group-hover:text-teal-100'
  | 'sm:text-teal-200'
  | 'md:text-teal-200'
  | 'lg:text-teal-200'
  | 'xl:text-teal-200'
  | 'hover:text-teal-200'
  | 'focus:text-teal-200'
  | 'group-hover:text-teal-200'
  | 'sm:text-teal-300'
  | 'md:text-teal-300'
  | 'lg:text-teal-300'
  | 'xl:text-teal-300'
  | 'hover:text-teal-300'
  | 'focus:text-teal-300'
  | 'group-hover:text-teal-300'
  | 'sm:text-teal-400'
  | 'md:text-teal-400'
  | 'lg:text-teal-400'
  | 'xl:text-teal-400'
  | 'hover:text-teal-400'
  | 'focus:text-teal-400'
  | 'group-hover:text-teal-400'
  | 'sm:text-teal-500'
  | 'md:text-teal-500'
  | 'lg:text-teal-500'
  | 'xl:text-teal-500'
  | 'hover:text-teal-500'
  | 'focus:text-teal-500'
  | 'group-hover:text-teal-500'
  | 'sm:text-teal-600'
  | 'md:text-teal-600'
  | 'lg:text-teal-600'
  | 'xl:text-teal-600'
  | 'hover:text-teal-600'
  | 'focus:text-teal-600'
  | 'group-hover:text-teal-600'
  | 'sm:text-teal-700'
  | 'md:text-teal-700'
  | 'lg:text-teal-700'
  | 'xl:text-teal-700'
  | 'hover:text-teal-700'
  | 'focus:text-teal-700'
  | 'group-hover:text-teal-700'
  | 'sm:text-teal-800'
  | 'md:text-teal-800'
  | 'lg:text-teal-800'
  | 'xl:text-teal-800'
  | 'hover:text-teal-800'
  | 'focus:text-teal-800'
  | 'group-hover:text-teal-800'
  | 'sm:text-teal-900'
  | 'md:text-teal-900'
  | 'lg:text-teal-900'
  | 'xl:text-teal-900'
  | 'hover:text-teal-900'
  | 'focus:text-teal-900'
  | 'group-hover:text-teal-900'
  | 'sm:text-blue-100'
  | 'md:text-blue-100'
  | 'lg:text-blue-100'
  | 'xl:text-blue-100'
  | 'hover:text-blue-100'
  | 'focus:text-blue-100'
  | 'group-hover:text-blue-100'
  | 'sm:text-blue-200'
  | 'md:text-blue-200'
  | 'lg:text-blue-200'
  | 'xl:text-blue-200'
  | 'hover:text-blue-200'
  | 'focus:text-blue-200'
  | 'group-hover:text-blue-200'
  | 'sm:text-blue-300'
  | 'md:text-blue-300'
  | 'lg:text-blue-300'
  | 'xl:text-blue-300'
  | 'hover:text-blue-300'
  | 'focus:text-blue-300'
  | 'group-hover:text-blue-300'
  | 'sm:text-blue-400'
  | 'md:text-blue-400'
  | 'lg:text-blue-400'
  | 'xl:text-blue-400'
  | 'hover:text-blue-400'
  | 'focus:text-blue-400'
  | 'group-hover:text-blue-400'
  | 'sm:text-blue-500'
  | 'md:text-blue-500'
  | 'lg:text-blue-500'
  | 'xl:text-blue-500'
  | 'hover:text-blue-500'
  | 'focus:text-blue-500'
  | 'group-hover:text-blue-500'
  | 'sm:text-blue-600'
  | 'md:text-blue-600'
  | 'lg:text-blue-600'
  | 'xl:text-blue-600'
  | 'hover:text-blue-600'
  | 'focus:text-blue-600'
  | 'group-hover:text-blue-600'
  | 'sm:text-blue-700'
  | 'md:text-blue-700'
  | 'lg:text-blue-700'
  | 'xl:text-blue-700'
  | 'hover:text-blue-700'
  | 'focus:text-blue-700'
  | 'group-hover:text-blue-700'
  | 'sm:text-blue-800'
  | 'md:text-blue-800'
  | 'lg:text-blue-800'
  | 'xl:text-blue-800'
  | 'hover:text-blue-800'
  | 'focus:text-blue-800'
  | 'group-hover:text-blue-800'
  | 'sm:text-blue-900'
  | 'md:text-blue-900'
  | 'lg:text-blue-900'
  | 'xl:text-blue-900'
  | 'hover:text-blue-900'
  | 'focus:text-blue-900'
  | 'group-hover:text-blue-900'
  | 'sm:text-indigo-100'
  | 'md:text-indigo-100'
  | 'lg:text-indigo-100'
  | 'xl:text-indigo-100'
  | 'hover:text-indigo-100'
  | 'focus:text-indigo-100'
  | 'group-hover:text-indigo-100'
  | 'sm:text-indigo-200'
  | 'md:text-indigo-200'
  | 'lg:text-indigo-200'
  | 'xl:text-indigo-200'
  | 'hover:text-indigo-200'
  | 'focus:text-indigo-200'
  | 'group-hover:text-indigo-200'
  | 'sm:text-indigo-300'
  | 'md:text-indigo-300'
  | 'lg:text-indigo-300'
  | 'xl:text-indigo-300'
  | 'hover:text-indigo-300'
  | 'focus:text-indigo-300'
  | 'group-hover:text-indigo-300'
  | 'sm:text-indigo-400'
  | 'md:text-indigo-400'
  | 'lg:text-indigo-400'
  | 'xl:text-indigo-400'
  | 'hover:text-indigo-400'
  | 'focus:text-indigo-400'
  | 'group-hover:text-indigo-400'
  | 'sm:text-indigo-500'
  | 'md:text-indigo-500'
  | 'lg:text-indigo-500'
  | 'xl:text-indigo-500'
  | 'hover:text-indigo-500'
  | 'focus:text-indigo-500'
  | 'group-hover:text-indigo-500'
  | 'sm:text-indigo-600'
  | 'md:text-indigo-600'
  | 'lg:text-indigo-600'
  | 'xl:text-indigo-600'
  | 'hover:text-indigo-600'
  | 'focus:text-indigo-600'
  | 'group-hover:text-indigo-600'
  | 'sm:text-indigo-700'
  | 'md:text-indigo-700'
  | 'lg:text-indigo-700'
  | 'xl:text-indigo-700'
  | 'hover:text-indigo-700'
  | 'focus:text-indigo-700'
  | 'group-hover:text-indigo-700'
  | 'sm:text-indigo-800'
  | 'md:text-indigo-800'
  | 'lg:text-indigo-800'
  | 'xl:text-indigo-800'
  | 'hover:text-indigo-800'
  | 'focus:text-indigo-800'
  | 'group-hover:text-indigo-800'
  | 'sm:text-indigo-900'
  | 'md:text-indigo-900'
  | 'lg:text-indigo-900'
  | 'xl:text-indigo-900'
  | 'hover:text-indigo-900'
  | 'focus:text-indigo-900'
  | 'group-hover:text-indigo-900'
  | 'sm:text-purple-100'
  | 'md:text-purple-100'
  | 'lg:text-purple-100'
  | 'xl:text-purple-100'
  | 'hover:text-purple-100'
  | 'focus:text-purple-100'
  | 'group-hover:text-purple-100'
  | 'sm:text-purple-200'
  | 'md:text-purple-200'
  | 'lg:text-purple-200'
  | 'xl:text-purple-200'
  | 'hover:text-purple-200'
  | 'focus:text-purple-200'
  | 'group-hover:text-purple-200'
  | 'sm:text-purple-300'
  | 'md:text-purple-300'
  | 'lg:text-purple-300'
  | 'xl:text-purple-300'
  | 'hover:text-purple-300'
  | 'focus:text-purple-300'
  | 'group-hover:text-purple-300'
  | 'sm:text-purple-400'
  | 'md:text-purple-400'
  | 'lg:text-purple-400'
  | 'xl:text-purple-400'
  | 'hover:text-purple-400'
  | 'focus:text-purple-400'
  | 'group-hover:text-purple-400'
  | 'sm:text-purple-500'
  | 'md:text-purple-500'
  | 'lg:text-purple-500'
  | 'xl:text-purple-500'
  | 'hover:text-purple-500'
  | 'focus:text-purple-500'
  | 'group-hover:text-purple-500'
  | 'sm:text-purple-600'
  | 'md:text-purple-600'
  | 'lg:text-purple-600'
  | 'xl:text-purple-600'
  | 'hover:text-purple-600'
  | 'focus:text-purple-600'
  | 'group-hover:text-purple-600'
  | 'sm:text-purple-700'
  | 'md:text-purple-700'
  | 'lg:text-purple-700'
  | 'xl:text-purple-700'
  | 'hover:text-purple-700'
  | 'focus:text-purple-700'
  | 'group-hover:text-purple-700'
  | 'sm:text-purple-800'
  | 'md:text-purple-800'
  | 'lg:text-purple-800'
  | 'xl:text-purple-800'
  | 'hover:text-purple-800'
  | 'focus:text-purple-800'
  | 'group-hover:text-purple-800'
  | 'sm:text-purple-900'
  | 'md:text-purple-900'
  | 'lg:text-purple-900'
  | 'xl:text-purple-900'
  | 'hover:text-purple-900'
  | 'focus:text-purple-900'
  | 'group-hover:text-purple-900'
  | 'sm:text-pink-100'
  | 'md:text-pink-100'
  | 'lg:text-pink-100'
  | 'xl:text-pink-100'
  | 'hover:text-pink-100'
  | 'focus:text-pink-100'
  | 'group-hover:text-pink-100'
  | 'sm:text-pink-200'
  | 'md:text-pink-200'
  | 'lg:text-pink-200'
  | 'xl:text-pink-200'
  | 'hover:text-pink-200'
  | 'focus:text-pink-200'
  | 'group-hover:text-pink-200'
  | 'sm:text-pink-300'
  | 'md:text-pink-300'
  | 'lg:text-pink-300'
  | 'xl:text-pink-300'
  | 'hover:text-pink-300'
  | 'focus:text-pink-300'
  | 'group-hover:text-pink-300'
  | 'sm:text-pink-400'
  | 'md:text-pink-400'
  | 'lg:text-pink-400'
  | 'xl:text-pink-400'
  | 'hover:text-pink-400'
  | 'focus:text-pink-400'
  | 'group-hover:text-pink-400'
  | 'sm:text-pink-500'
  | 'md:text-pink-500'
  | 'lg:text-pink-500'
  | 'xl:text-pink-500'
  | 'hover:text-pink-500'
  | 'focus:text-pink-500'
  | 'group-hover:text-pink-500'
  | 'sm:text-pink-600'
  | 'md:text-pink-600'
  | 'lg:text-pink-600'
  | 'xl:text-pink-600'
  | 'hover:text-pink-600'
  | 'focus:text-pink-600'
  | 'group-hover:text-pink-600'
  | 'sm:text-pink-700'
  | 'md:text-pink-700'
  | 'lg:text-pink-700'
  | 'xl:text-pink-700'
  | 'hover:text-pink-700'
  | 'focus:text-pink-700'
  | 'group-hover:text-pink-700'
  | 'sm:text-pink-800'
  | 'md:text-pink-800'
  | 'lg:text-pink-800'
  | 'xl:text-pink-800'
  | 'hover:text-pink-800'
  | 'focus:text-pink-800'
  | 'group-hover:text-pink-800'
  | 'sm:text-pink-900'
  | 'md:text-pink-900'
  | 'lg:text-pink-900'
  | 'xl:text-pink-900'
  | 'hover:text-pink-900'
  | 'focus:text-pink-900'
  | 'group-hover:text-pink-900'
  | 'sm:text-primary-light'
  | 'md:text-primary-light'
  | 'lg:text-primary-light'
  | 'xl:text-primary-light'
  | 'hover:text-primary-light'
  | 'focus:text-primary-light'
  | 'group-hover:text-primary-light'
  | 'sm:text-primary'
  | 'md:text-primary'
  | 'lg:text-primary'
  | 'xl:text-primary'
  | 'hover:text-primary'
  | 'focus:text-primary'
  | 'group-hover:text-primary'
  | 'sm:text-primary-dark'
  | 'md:text-primary-dark'
  | 'lg:text-primary-dark'
  | 'xl:text-primary-dark'
  | 'hover:text-primary-dark'
  | 'focus:text-primary-dark'
  | 'group-hover:text-primary-dark'
  | 'sm:text-accent-dark'
  | 'md:text-accent-dark'
  | 'lg:text-accent-dark'
  | 'xl:text-accent-dark'
  | 'hover:text-accent-dark'
  | 'focus:text-accent-dark'
  | 'group-hover:text-accent-dark'
  | 'sm:text-accent'
  | 'md:text-accent'
  | 'lg:text-accent'
  | 'xl:text-accent'
  | 'hover:text-accent'
  | 'focus:text-accent'
  | 'group-hover:text-accent'
  | 'sm:text-accent-light'
  | 'md:text-accent-light'
  | 'lg:text-accent-light'
  | 'xl:text-accent-light'
  | 'hover:text-accent-light'
  | 'focus:text-accent-light'
  | 'group-hover:text-accent-light'
  | 'sm:text-warning-dark'
  | 'md:text-warning-dark'
  | 'lg:text-warning-dark'
  | 'xl:text-warning-dark'
  | 'hover:text-warning-dark'
  | 'focus:text-warning-dark'
  | 'group-hover:text-warning-dark'
  | 'sm:text-warning'
  | 'md:text-warning'
  | 'lg:text-warning'
  | 'xl:text-warning'
  | 'hover:text-warning'
  | 'focus:text-warning'
  | 'group-hover:text-warning'
  | 'sm:text-warning-light'
  | 'md:text-warning-light'
  | 'lg:text-warning-light'
  | 'xl:text-warning-light'
  | 'hover:text-warning-light'
  | 'focus:text-warning-light'
  | 'group-hover:text-warning-light'
  | 'sm:text-dark'
  | 'md:text-dark'
  | 'lg:text-dark'
  | 'xl:text-dark'
  | 'hover:text-dark'
  | 'focus:text-dark'
  | 'group-hover:text-dark'
  | 'sm:text-dark-secondary'
  | 'md:text-dark-secondary'
  | 'lg:text-dark-secondary'
  | 'xl:text-dark-secondary'
  | 'hover:text-dark-secondary'
  | 'focus:text-dark-secondary'
  | 'group-hover:text-dark-secondary'
  | 'sm:text-light'
  | 'md:text-light'
  | 'lg:text-light'
  | 'xl:text-light'
  | 'hover:text-light'
  | 'focus:text-light'
  | 'group-hover:text-light'
  | 'sm:text-light-secondary'
  | 'md:text-light-secondary'
  | 'lg:text-light-secondary'
  | 'xl:text-light-secondary'
  | 'hover:text-light-secondary'
  | 'focus:text-light-secondary'
  | 'group-hover:text-light-secondary'
  | 'sm:text-light-hint'
  | 'md:text-light-hint'
  | 'lg:text-light-hint'
  | 'xl:text-light-hint'
  | 'hover:text-light-hint'
  | 'focus:text-light-hint'
  | 'group-hover:text-light-hint'
  | 'sm:text-opacity-0'
  | 'md:text-opacity-0'
  | 'lg:text-opacity-0'
  | 'xl:text-opacity-0'
  | 'hover:text-opacity-0'
  | 'focus:text-opacity-0'
  | 'sm:text-opacity-25'
  | 'md:text-opacity-25'
  | 'lg:text-opacity-25'
  | 'xl:text-opacity-25'
  | 'hover:text-opacity-25'
  | 'focus:text-opacity-25'
  | 'sm:text-opacity-50'
  | 'md:text-opacity-50'
  | 'lg:text-opacity-50'
  | 'xl:text-opacity-50'
  | 'hover:text-opacity-50'
  | 'focus:text-opacity-50'
  | 'sm:text-opacity-75'
  | 'md:text-opacity-75'
  | 'lg:text-opacity-75'
  | 'xl:text-opacity-75'
  | 'hover:text-opacity-75'
  | 'focus:text-opacity-75'
  | 'sm:text-opacity-100'
  | 'md:text-opacity-100'
  | 'lg:text-opacity-100'
  | 'xl:text-opacity-100'
  | 'hover:text-opacity-100'
  | 'focus:text-opacity-100'
  | 'sm:underline'
  | 'md:underline'
  | 'lg:underline'
  | 'xl:underline'
  | 'hover:underline'
  | 'focus:underline'
  | 'sm:line-through'
  | 'md:line-through'
  | 'lg:line-through'
  | 'xl:line-through'
  | 'hover:line-through'
  | 'focus:line-through'
  | 'sm:no-underline'
  | 'md:no-underline'
  | 'lg:no-underline'
  | 'xl:no-underline'
  | 'hover:no-underline'
  | 'focus:no-underline'
  | 'sm:uppercase'
  | 'md:uppercase'
  | 'lg:uppercase'
  | 'xl:uppercase'
  | 'sm:lowercase'
  | 'md:lowercase'
  | 'lg:lowercase'
  | 'xl:lowercase'
  | 'sm:capitalize'
  | 'md:capitalize'
  | 'lg:capitalize'
  | 'xl:capitalize'
  | 'sm:normal-case'
  | 'md:normal-case'
  | 'lg:normal-case'
  | 'xl:normal-case'
  | 'sm:select-none'
  | 'md:select-none'
  | 'lg:select-none'
  | 'xl:select-none'
  | 'sm:select-text'
  | 'md:select-text'
  | 'lg:select-text'
  | 'xl:select-text'
  | 'sm:select-all'
  | 'md:select-all'
  | 'lg:select-all'
  | 'xl:select-all'
  | 'sm:select-auto'
  | 'md:select-auto'
  | 'lg:select-auto'
  | 'xl:select-auto'
  | 'sm:align-baseline'
  | 'md:align-baseline'
  | 'lg:align-baseline'
  | 'xl:align-baseline'
  | 'sm:align-top'
  | 'md:align-top'
  | 'lg:align-top'
  | 'xl:align-top'
  | 'sm:align-middle'
  | 'md:align-middle'
  | 'lg:align-middle'
  | 'xl:align-middle'
  | 'sm:align-bottom'
  | 'md:align-bottom'
  | 'lg:align-bottom'
  | 'xl:align-bottom'
  | 'sm:align-text-top'
  | 'md:align-text-top'
  | 'lg:align-text-top'
  | 'xl:align-text-top'
  | 'sm:align-text-bottom'
  | 'md:align-text-bottom'
  | 'lg:align-text-bottom'
  | 'xl:align-text-bottom'
  | 'sm:visible'
  | 'md:visible'
  | 'lg:visible'
  | 'xl:visible'
  | 'sm:invisible'
  | 'md:invisible'
  | 'lg:invisible'
  | 'xl:invisible'
  | 'sm:whitespace-normal'
  | 'md:whitespace-normal'
  | 'lg:whitespace-normal'
  | 'xl:whitespace-normal'
  | 'sm:whitespace-no-wrap'
  | 'md:whitespace-no-wrap'
  | 'lg:whitespace-no-wrap'
  | 'xl:whitespace-no-wrap'
  | 'sm:whitespace-pre'
  | 'md:whitespace-pre'
  | 'lg:whitespace-pre'
  | 'xl:whitespace-pre'
  | 'sm:whitespace-pre-line'
  | 'md:whitespace-pre-line'
  | 'lg:whitespace-pre-line'
  | 'xl:whitespace-pre-line'
  | 'sm:whitespace-pre-wrap'
  | 'md:whitespace-pre-wrap'
  | 'lg:whitespace-pre-wrap'
  | 'xl:whitespace-pre-wrap'
  | 'sm:w-0'
  | 'md:w-0'
  | 'lg:w-0'
  | 'xl:w-0'
  | 'sm:w-1'
  | 'md:w-1'
  | 'lg:w-1'
  | 'xl:w-1'
  | 'sm:w-2'
  | 'md:w-2'
  | 'lg:w-2'
  | 'xl:w-2'
  | 'sm:w-3'
  | 'md:w-3'
  | 'lg:w-3'
  | 'xl:w-3'
  | 'sm:w-4'
  | 'md:w-4'
  | 'lg:w-4'
  | 'xl:w-4'
  | 'sm:w-5'
  | 'md:w-5'
  | 'lg:w-5'
  | 'xl:w-5'
  | 'sm:w-6'
  | 'md:w-6'
  | 'lg:w-6'
  | 'xl:w-6'
  | 'sm:w-8'
  | 'md:w-8'
  | 'lg:w-8'
  | 'xl:w-8'
  | 'sm:w-10'
  | 'md:w-10'
  | 'lg:w-10'
  | 'xl:w-10'
  | 'sm:w-12'
  | 'md:w-12'
  | 'lg:w-12'
  | 'xl:w-12'
  | 'sm:w-16'
  | 'md:w-16'
  | 'lg:w-16'
  | 'xl:w-16'
  | 'sm:w-20'
  | 'md:w-20'
  | 'lg:w-20'
  | 'xl:w-20'
  | 'sm:w-24'
  | 'md:w-24'
  | 'lg:w-24'
  | 'xl:w-24'
  | 'sm:w-32'
  | 'md:w-32'
  | 'lg:w-32'
  | 'xl:w-32'
  | 'sm:w-40'
  | 'md:w-40'
  | 'lg:w-40'
  | 'xl:w-40'
  | 'sm:w-48'
  | 'md:w-48'
  | 'lg:w-48'
  | 'xl:w-48'
  | 'sm:w-56'
  | 'md:w-56'
  | 'lg:w-56'
  | 'xl:w-56'
  | 'sm:w-64'
  | 'md:w-64'
  | 'lg:w-64'
  | 'xl:w-64'
  | 'sm:w-auto'
  | 'md:w-auto'
  | 'lg:w-auto'
  | 'xl:w-auto'
  | 'sm:w-px'
  | 'md:w-px'
  | 'lg:w-px'
  | 'xl:w-px'
  | 'sm:w-1/2'
  | 'md:w-1/2'
  | 'lg:w-1/2'
  | 'xl:w-1/2'
  | 'sm:w-1/3'
  | 'md:w-1/3'
  | 'lg:w-1/3'
  | 'xl:w-1/3'
  | 'sm:w-2/3'
  | 'md:w-2/3'
  | 'lg:w-2/3'
  | 'xl:w-2/3'
  | 'sm:w-1/4'
  | 'md:w-1/4'
  | 'lg:w-1/4'
  | 'xl:w-1/4'
  | 'sm:w-2/4'
  | 'md:w-2/4'
  | 'lg:w-2/4'
  | 'xl:w-2/4'
  | 'sm:w-3/4'
  | 'md:w-3/4'
  | 'lg:w-3/4'
  | 'xl:w-3/4'
  | 'sm:w-1/5'
  | 'md:w-1/5'
  | 'lg:w-1/5'
  | 'xl:w-1/5'
  | 'sm:w-2/5'
  | 'md:w-2/5'
  | 'lg:w-2/5'
  | 'xl:w-2/5'
  | 'sm:w-3/5'
  | 'md:w-3/5'
  | 'lg:w-3/5'
  | 'xl:w-3/5'
  | 'sm:w-4/5'
  | 'md:w-4/5'
  | 'lg:w-4/5'
  | 'xl:w-4/5'
  | 'sm:w-1/6'
  | 'md:w-1/6'
  | 'lg:w-1/6'
  | 'xl:w-1/6'
  | 'sm:w-2/6'
  | 'md:w-2/6'
  | 'lg:w-2/6'
  | 'xl:w-2/6'
  | 'sm:w-3/6'
  | 'md:w-3/6'
  | 'lg:w-3/6'
  | 'xl:w-3/6'
  | 'sm:w-4/6'
  | 'md:w-4/6'
  | 'lg:w-4/6'
  | 'xl:w-4/6'
  | 'sm:w-5/6'
  | 'md:w-5/6'
  | 'lg:w-5/6'
  | 'xl:w-5/6'
  | 'sm:w-1/12'
  | 'md:w-1/12'
  | 'lg:w-1/12'
  | 'xl:w-1/12'
  | 'sm:w-2/12'
  | 'md:w-2/12'
  | 'lg:w-2/12'
  | 'xl:w-2/12'
  | 'sm:w-3/12'
  | 'md:w-3/12'
  | 'lg:w-3/12'
  | 'xl:w-3/12'
  | 'sm:w-4/12'
  | 'md:w-4/12'
  | 'lg:w-4/12'
  | 'xl:w-4/12'
  | 'sm:w-5/12'
  | 'md:w-5/12'
  | 'lg:w-5/12'
  | 'xl:w-5/12'
  | 'sm:w-6/12'
  | 'md:w-6/12'
  | 'lg:w-6/12'
  | 'xl:w-6/12'
  | 'sm:w-7/12'
  | 'md:w-7/12'
  | 'lg:w-7/12'
  | 'xl:w-7/12'
  | 'sm:w-8/12'
  | 'md:w-8/12'
  | 'lg:w-8/12'
  | 'xl:w-8/12'
  | 'sm:w-9/12'
  | 'md:w-9/12'
  | 'lg:w-9/12'
  | 'xl:w-9/12'
  | 'sm:w-10/12'
  | 'md:w-10/12'
  | 'lg:w-10/12'
  | 'xl:w-10/12'
  | 'sm:w-11/12'
  | 'md:w-11/12'
  | 'lg:w-11/12'
  | 'xl:w-11/12'
  | 'sm:w-full'
  | 'md:w-full'
  | 'lg:w-full'
  | 'xl:w-full'
  | 'sm:w-screen'
  | 'md:w-screen'
  | 'lg:w-screen'
  | 'xl:w-screen'
  | 'sm:w-33vw'
  | 'md:w-33vw'
  | 'lg:w-33vw'
  | 'xl:w-33vw'
  | 'sm:w-40vw'
  | 'md:w-40vw'
  | 'lg:w-40vw'
  | 'xl:w-40vw'
  | 'sm:w-50vw'
  | 'md:w-50vw'
  | 'lg:w-50vw'
  | 'xl:w-50vw'
  | 'sm:break-normal'
  | 'md:break-normal'
  | 'lg:break-normal'
  | 'xl:break-normal'
  | 'sm:break-words'
  | 'md:break-words'
  | 'lg:break-words'
  | 'xl:break-words'
  | 'sm:break-all'
  | 'md:break-all'
  | 'lg:break-all'
  | 'xl:break-all'
  | 'sm:truncate'
  | 'md:truncate'
  | 'lg:truncate'
  | 'xl:truncate'
  | 'sm:z-0'
  | 'md:z-0'
  | 'lg:z-0'
  | 'xl:z-0'
  | 'sm:z-10'
  | 'md:z-10'
  | 'lg:z-10'
  | 'xl:z-10'
  | 'sm:z-20'
  | 'md:z-20'
  | 'lg:z-20'
  | 'xl:z-20'
  | 'sm:z-30'
  | 'md:z-30'
  | 'lg:z-30'
  | 'xl:z-30'
  | 'sm:z-40'
  | 'md:z-40'
  | 'lg:z-40'
  | 'xl:z-40'
  | 'sm:z-50'
  | 'md:z-50'
  | 'lg:z-50'
  | 'xl:z-50'
  | 'sm:z-auto'
  | 'md:z-auto'
  | 'lg:z-auto'
  | 'xl:z-auto'
  | 'sm:gap-0'
  | 'md:gap-0'
  | 'lg:gap-0'
  | 'xl:gap-0'
  | 'sm:gap-1'
  | 'md:gap-1'
  | 'lg:gap-1'
  | 'xl:gap-1'
  | 'sm:gap-2'
  | 'md:gap-2'
  | 'lg:gap-2'
  | 'xl:gap-2'
  | 'sm:gap-3'
  | 'md:gap-3'
  | 'lg:gap-3'
  | 'xl:gap-3'
  | 'sm:gap-4'
  | 'md:gap-4'
  | 'lg:gap-4'
  | 'xl:gap-4'
  | 'sm:gap-5'
  | 'md:gap-5'
  | 'lg:gap-5'
  | 'xl:gap-5'
  | 'sm:gap-6'
  | 'md:gap-6'
  | 'lg:gap-6'
  | 'xl:gap-6'
  | 'sm:gap-8'
  | 'md:gap-8'
  | 'lg:gap-8'
  | 'xl:gap-8'
  | 'sm:gap-10'
  | 'md:gap-10'
  | 'lg:gap-10'
  | 'xl:gap-10'
  | 'sm:gap-12'
  | 'md:gap-12'
  | 'lg:gap-12'
  | 'xl:gap-12'
  | 'sm:gap-16'
  | 'md:gap-16'
  | 'lg:gap-16'
  | 'xl:gap-16'
  | 'sm:gap-20'
  | 'md:gap-20'
  | 'lg:gap-20'
  | 'xl:gap-20'
  | 'sm:gap-24'
  | 'md:gap-24'
  | 'lg:gap-24'
  | 'xl:gap-24'
  | 'sm:gap-32'
  | 'md:gap-32'
  | 'lg:gap-32'
  | 'xl:gap-32'
  | 'sm:gap-40'
  | 'md:gap-40'
  | 'lg:gap-40'
  | 'xl:gap-40'
  | 'sm:gap-48'
  | 'md:gap-48'
  | 'lg:gap-48'
  | 'xl:gap-48'
  | 'sm:gap-56'
  | 'md:gap-56'
  | 'lg:gap-56'
  | 'xl:gap-56'
  | 'sm:gap-64'
  | 'md:gap-64'
  | 'lg:gap-64'
  | 'xl:gap-64'
  | 'sm:gap-px'
  | 'md:gap-px'
  | 'lg:gap-px'
  | 'xl:gap-px'
  | 'sm:gap-cardIndexRatio'
  | 'md:gap-cardIndexRatio'
  | 'lg:gap-cardIndexRatio'
  | 'xl:gap-cardIndexRatio'
  | 'sm:gap-y-0'
  | 'md:gap-y-0'
  | 'lg:gap-y-0'
  | 'xl:gap-y-0'
  | 'sm:gap-y-1'
  | 'md:gap-y-1'
  | 'lg:gap-y-1'
  | 'xl:gap-y-1'
  | 'sm:gap-y-2'
  | 'md:gap-y-2'
  | 'lg:gap-y-2'
  | 'xl:gap-y-2'
  | 'sm:gap-y-3'
  | 'md:gap-y-3'
  | 'lg:gap-y-3'
  | 'xl:gap-y-3'
  | 'sm:gap-y-4'
  | 'md:gap-y-4'
  | 'lg:gap-y-4'
  | 'xl:gap-y-4'
  | 'sm:gap-y-5'
  | 'md:gap-y-5'
  | 'lg:gap-y-5'
  | 'xl:gap-y-5'
  | 'sm:gap-y-6'
  | 'md:gap-y-6'
  | 'lg:gap-y-6'
  | 'xl:gap-y-6'
  | 'sm:gap-y-8'
  | 'md:gap-y-8'
  | 'lg:gap-y-8'
  | 'xl:gap-y-8'
  | 'sm:gap-y-10'
  | 'md:gap-y-10'
  | 'lg:gap-y-10'
  | 'xl:gap-y-10'
  | 'sm:gap-y-12'
  | 'md:gap-y-12'
  | 'lg:gap-y-12'
  | 'xl:gap-y-12'
  | 'sm:gap-y-16'
  | 'md:gap-y-16'
  | 'lg:gap-y-16'
  | 'xl:gap-y-16'
  | 'sm:gap-y-20'
  | 'md:gap-y-20'
  | 'lg:gap-y-20'
  | 'xl:gap-y-20'
  | 'sm:gap-y-24'
  | 'md:gap-y-24'
  | 'lg:gap-y-24'
  | 'xl:gap-y-24'
  | 'sm:gap-y-32'
  | 'md:gap-y-32'
  | 'lg:gap-y-32'
  | 'xl:gap-y-32'
  | 'sm:gap-y-40'
  | 'md:gap-y-40'
  | 'lg:gap-y-40'
  | 'xl:gap-y-40'
  | 'sm:gap-y-48'
  | 'md:gap-y-48'
  | 'lg:gap-y-48'
  | 'xl:gap-y-48'
  | 'sm:gap-y-56'
  | 'md:gap-y-56'
  | 'lg:gap-y-56'
  | 'xl:gap-y-56'
  | 'sm:gap-y-64'
  | 'md:gap-y-64'
  | 'lg:gap-y-64'
  | 'xl:gap-y-64'
  | 'sm:gap-y-px'
  | 'md:gap-y-px'
  | 'lg:gap-y-px'
  | 'xl:gap-y-px'
  | 'sm:gap-y-cardIndexRatio'
  | 'md:gap-y-cardIndexRatio'
  | 'lg:gap-y-cardIndexRatio'
  | 'xl:gap-y-cardIndexRatio'
  | 'sm:gap-x-0'
  | 'md:gap-x-0'
  | 'lg:gap-x-0'
  | 'xl:gap-x-0'
  | 'sm:gap-x-1'
  | 'md:gap-x-1'
  | 'lg:gap-x-1'
  | 'xl:gap-x-1'
  | 'sm:gap-x-2'
  | 'md:gap-x-2'
  | 'lg:gap-x-2'
  | 'xl:gap-x-2'
  | 'sm:gap-x-3'
  | 'md:gap-x-3'
  | 'lg:gap-x-3'
  | 'xl:gap-x-3'
  | 'sm:gap-x-4'
  | 'md:gap-x-4'
  | 'lg:gap-x-4'
  | 'xl:gap-x-4'
  | 'sm:gap-x-5'
  | 'md:gap-x-5'
  | 'lg:gap-x-5'
  | 'xl:gap-x-5'
  | 'sm:gap-x-6'
  | 'md:gap-x-6'
  | 'lg:gap-x-6'
  | 'xl:gap-x-6'
  | 'sm:gap-x-8'
  | 'md:gap-x-8'
  | 'lg:gap-x-8'
  | 'xl:gap-x-8'
  | 'sm:gap-x-10'
  | 'md:gap-x-10'
  | 'lg:gap-x-10'
  | 'xl:gap-x-10'
  | 'sm:gap-x-12'
  | 'md:gap-x-12'
  | 'lg:gap-x-12'
  | 'xl:gap-x-12'
  | 'sm:gap-x-16'
  | 'md:gap-x-16'
  | 'lg:gap-x-16'
  | 'xl:gap-x-16'
  | 'sm:gap-x-20'
  | 'md:gap-x-20'
  | 'lg:gap-x-20'
  | 'xl:gap-x-20'
  | 'sm:gap-x-24'
  | 'md:gap-x-24'
  | 'lg:gap-x-24'
  | 'xl:gap-x-24'
  | 'sm:gap-x-32'
  | 'md:gap-x-32'
  | 'lg:gap-x-32'
  | 'xl:gap-x-32'
  | 'sm:gap-x-40'
  | 'md:gap-x-40'
  | 'lg:gap-x-40'
  | 'xl:gap-x-40'
  | 'sm:gap-x-48'
  | 'md:gap-x-48'
  | 'lg:gap-x-48'
  | 'xl:gap-x-48'
  | 'sm:gap-x-56'
  | 'md:gap-x-56'
  | 'lg:gap-x-56'
  | 'xl:gap-x-56'
  | 'sm:gap-x-64'
  | 'md:gap-x-64'
  | 'lg:gap-x-64'
  | 'xl:gap-x-64'
  | 'sm:gap-x-px'
  | 'md:gap-x-px'
  | 'lg:gap-x-px'
  | 'xl:gap-x-px'
  | 'sm:gap-x-cardIndexRatio'
  | 'md:gap-x-cardIndexRatio'
  | 'lg:gap-x-cardIndexRatio'
  | 'xl:gap-x-cardIndexRatio'
  | 'sm:grid-flow-row'
  | 'md:grid-flow-row'
  | 'lg:grid-flow-row'
  | 'xl:grid-flow-row'
  | 'sm:grid-flow-col'
  | 'md:grid-flow-col'
  | 'lg:grid-flow-col'
  | 'xl:grid-flow-col'
  | 'sm:grid-flow-row-dense'
  | 'md:grid-flow-row-dense'
  | 'lg:grid-flow-row-dense'
  | 'xl:grid-flow-row-dense'
  | 'sm:grid-flow-col-dense'
  | 'md:grid-flow-col-dense'
  | 'lg:grid-flow-col-dense'
  | 'xl:grid-flow-col-dense'
  | 'sm:grid-cols-1'
  | 'md:grid-cols-1'
  | 'lg:grid-cols-1'
  | 'xl:grid-cols-1'
  | 'sm:grid-cols-2'
  | 'md:grid-cols-2'
  | 'lg:grid-cols-2'
  | 'xl:grid-cols-2'
  | 'sm:grid-cols-3'
  | 'md:grid-cols-3'
  | 'lg:grid-cols-3'
  | 'xl:grid-cols-3'
  | 'sm:grid-cols-4'
  | 'md:grid-cols-4'
  | 'lg:grid-cols-4'
  | 'xl:grid-cols-4'
  | 'sm:grid-cols-5'
  | 'md:grid-cols-5'
  | 'lg:grid-cols-5'
  | 'xl:grid-cols-5'
  | 'sm:grid-cols-6'
  | 'md:grid-cols-6'
  | 'lg:grid-cols-6'
  | 'xl:grid-cols-6'
  | 'sm:grid-cols-7'
  | 'md:grid-cols-7'
  | 'lg:grid-cols-7'
  | 'xl:grid-cols-7'
  | 'sm:grid-cols-8'
  | 'md:grid-cols-8'
  | 'lg:grid-cols-8'
  | 'xl:grid-cols-8'
  | 'sm:grid-cols-9'
  | 'md:grid-cols-9'
  | 'lg:grid-cols-9'
  | 'xl:grid-cols-9'
  | 'sm:grid-cols-10'
  | 'md:grid-cols-10'
  | 'lg:grid-cols-10'
  | 'xl:grid-cols-10'
  | 'sm:grid-cols-11'
  | 'md:grid-cols-11'
  | 'lg:grid-cols-11'
  | 'xl:grid-cols-11'
  | 'sm:grid-cols-12'
  | 'md:grid-cols-12'
  | 'lg:grid-cols-12'
  | 'xl:grid-cols-12'
  | 'sm:grid-cols-none'
  | 'md:grid-cols-none'
  | 'lg:grid-cols-none'
  | 'xl:grid-cols-none'
  | 'sm:auto-cols-auto'
  | 'md:auto-cols-auto'
  | 'lg:auto-cols-auto'
  | 'xl:auto-cols-auto'
  | 'sm:auto-cols-min'
  | 'md:auto-cols-min'
  | 'lg:auto-cols-min'
  | 'xl:auto-cols-min'
  | 'sm:auto-cols-max'
  | 'md:auto-cols-max'
  | 'lg:auto-cols-max'
  | 'xl:auto-cols-max'
  | 'sm:auto-cols-fr'
  | 'md:auto-cols-fr'
  | 'lg:auto-cols-fr'
  | 'xl:auto-cols-fr'
  | 'sm:col-auto'
  | 'md:col-auto'
  | 'lg:col-auto'
  | 'xl:col-auto'
  | 'sm:col-span-1'
  | 'md:col-span-1'
  | 'lg:col-span-1'
  | 'xl:col-span-1'
  | 'sm:col-span-2'
  | 'md:col-span-2'
  | 'lg:col-span-2'
  | 'xl:col-span-2'
  | 'sm:col-span-3'
  | 'md:col-span-3'
  | 'lg:col-span-3'
  | 'xl:col-span-3'
  | 'sm:col-span-4'
  | 'md:col-span-4'
  | 'lg:col-span-4'
  | 'xl:col-span-4'
  | 'sm:col-span-5'
  | 'md:col-span-5'
  | 'lg:col-span-5'
  | 'xl:col-span-5'
  | 'sm:col-span-6'
  | 'md:col-span-6'
  | 'lg:col-span-6'
  | 'xl:col-span-6'
  | 'sm:col-span-7'
  | 'md:col-span-7'
  | 'lg:col-span-7'
  | 'xl:col-span-7'
  | 'sm:col-span-8'
  | 'md:col-span-8'
  | 'lg:col-span-8'
  | 'xl:col-span-8'
  | 'sm:col-span-9'
  | 'md:col-span-9'
  | 'lg:col-span-9'
  | 'xl:col-span-9'
  | 'sm:col-span-10'
  | 'md:col-span-10'
  | 'lg:col-span-10'
  | 'xl:col-span-10'
  | 'sm:col-span-11'
  | 'md:col-span-11'
  | 'lg:col-span-11'
  | 'xl:col-span-11'
  | 'sm:col-span-12'
  | 'md:col-span-12'
  | 'lg:col-span-12'
  | 'xl:col-span-12'
  | 'sm:col-span-full'
  | 'md:col-span-full'
  | 'lg:col-span-full'
  | 'xl:col-span-full'
  | 'sm:col-start-1'
  | 'md:col-start-1'
  | 'lg:col-start-1'
  | 'xl:col-start-1'
  | 'sm:col-start-2'
  | 'md:col-start-2'
  | 'lg:col-start-2'
  | 'xl:col-start-2'
  | 'sm:col-start-3'
  | 'md:col-start-3'
  | 'lg:col-start-3'
  | 'xl:col-start-3'
  | 'sm:col-start-4'
  | 'md:col-start-4'
  | 'lg:col-start-4'
  | 'xl:col-start-4'
  | 'sm:col-start-5'
  | 'md:col-start-5'
  | 'lg:col-start-5'
  | 'xl:col-start-5'
  | 'sm:col-start-6'
  | 'md:col-start-6'
  | 'lg:col-start-6'
  | 'xl:col-start-6'
  | 'sm:col-start-7'
  | 'md:col-start-7'
  | 'lg:col-start-7'
  | 'xl:col-start-7'
  | 'sm:col-start-8'
  | 'md:col-start-8'
  | 'lg:col-start-8'
  | 'xl:col-start-8'
  | 'sm:col-start-9'
  | 'md:col-start-9'
  | 'lg:col-start-9'
  | 'xl:col-start-9'
  | 'sm:col-start-10'
  | 'md:col-start-10'
  | 'lg:col-start-10'
  | 'xl:col-start-10'
  | 'sm:col-start-11'
  | 'md:col-start-11'
  | 'lg:col-start-11'
  | 'xl:col-start-11'
  | 'sm:col-start-12'
  | 'md:col-start-12'
  | 'lg:col-start-12'
  | 'xl:col-start-12'
  | 'sm:col-start-13'
  | 'md:col-start-13'
  | 'lg:col-start-13'
  | 'xl:col-start-13'
  | 'sm:col-start-auto'
  | 'md:col-start-auto'
  | 'lg:col-start-auto'
  | 'xl:col-start-auto'
  | 'sm:col-end-1'
  | 'md:col-end-1'
  | 'lg:col-end-1'
  | 'xl:col-end-1'
  | 'sm:col-end-2'
  | 'md:col-end-2'
  | 'lg:col-end-2'
  | 'xl:col-end-2'
  | 'sm:col-end-3'
  | 'md:col-end-3'
  | 'lg:col-end-3'
  | 'xl:col-end-3'
  | 'sm:col-end-4'
  | 'md:col-end-4'
  | 'lg:col-end-4'
  | 'xl:col-end-4'
  | 'sm:col-end-5'
  | 'md:col-end-5'
  | 'lg:col-end-5'
  | 'xl:col-end-5'
  | 'sm:col-end-6'
  | 'md:col-end-6'
  | 'lg:col-end-6'
  | 'xl:col-end-6'
  | 'sm:col-end-7'
  | 'md:col-end-7'
  | 'lg:col-end-7'
  | 'xl:col-end-7'
  | 'sm:col-end-8'
  | 'md:col-end-8'
  | 'lg:col-end-8'
  | 'xl:col-end-8'
  | 'sm:col-end-9'
  | 'md:col-end-9'
  | 'lg:col-end-9'
  | 'xl:col-end-9'
  | 'sm:col-end-10'
  | 'md:col-end-10'
  | 'lg:col-end-10'
  | 'xl:col-end-10'
  | 'sm:col-end-11'
  | 'md:col-end-11'
  | 'lg:col-end-11'
  | 'xl:col-end-11'
  | 'sm:col-end-12'
  | 'md:col-end-12'
  | 'lg:col-end-12'
  | 'xl:col-end-12'
  | 'sm:col-end-13'
  | 'md:col-end-13'
  | 'lg:col-end-13'
  | 'xl:col-end-13'
  | 'sm:col-end-auto'
  | 'md:col-end-auto'
  | 'lg:col-end-auto'
  | 'xl:col-end-auto'
  | 'sm:grid-rows-1'
  | 'md:grid-rows-1'
  | 'lg:grid-rows-1'
  | 'xl:grid-rows-1'
  | 'sm:grid-rows-2'
  | 'md:grid-rows-2'
  | 'lg:grid-rows-2'
  | 'xl:grid-rows-2'
  | 'sm:grid-rows-3'
  | 'md:grid-rows-3'
  | 'lg:grid-rows-3'
  | 'xl:grid-rows-3'
  | 'sm:grid-rows-4'
  | 'md:grid-rows-4'
  | 'lg:grid-rows-4'
  | 'xl:grid-rows-4'
  | 'sm:grid-rows-5'
  | 'md:grid-rows-5'
  | 'lg:grid-rows-5'
  | 'xl:grid-rows-5'
  | 'sm:grid-rows-6'
  | 'md:grid-rows-6'
  | 'lg:grid-rows-6'
  | 'xl:grid-rows-6'
  | 'sm:grid-rows-none'
  | 'md:grid-rows-none'
  | 'lg:grid-rows-none'
  | 'xl:grid-rows-none'
  | 'sm:auto-rows-auto'
  | 'md:auto-rows-auto'
  | 'lg:auto-rows-auto'
  | 'xl:auto-rows-auto'
  | 'sm:auto-rows-min'
  | 'md:auto-rows-min'
  | 'lg:auto-rows-min'
  | 'xl:auto-rows-min'
  | 'sm:auto-rows-max'
  | 'md:auto-rows-max'
  | 'lg:auto-rows-max'
  | 'xl:auto-rows-max'
  | 'sm:auto-rows-fr'
  | 'md:auto-rows-fr'
  | 'lg:auto-rows-fr'
  | 'xl:auto-rows-fr'
  | 'sm:row-auto'
  | 'md:row-auto'
  | 'lg:row-auto'
  | 'xl:row-auto'
  | 'sm:row-span-1'
  | 'md:row-span-1'
  | 'lg:row-span-1'
  | 'xl:row-span-1'
  | 'sm:row-span-2'
  | 'md:row-span-2'
  | 'lg:row-span-2'
  | 'xl:row-span-2'
  | 'sm:row-span-3'
  | 'md:row-span-3'
  | 'lg:row-span-3'
  | 'xl:row-span-3'
  | 'sm:row-span-4'
  | 'md:row-span-4'
  | 'lg:row-span-4'
  | 'xl:row-span-4'
  | 'sm:row-span-5'
  | 'md:row-span-5'
  | 'lg:row-span-5'
  | 'xl:row-span-5'
  | 'sm:row-span-6'
  | 'md:row-span-6'
  | 'lg:row-span-6'
  | 'xl:row-span-6'
  | 'sm:row-span-full'
  | 'md:row-span-full'
  | 'lg:row-span-full'
  | 'xl:row-span-full'
  | 'sm:row-start-1'
  | 'md:row-start-1'
  | 'lg:row-start-1'
  | 'xl:row-start-1'
  | 'sm:row-start-2'
  | 'md:row-start-2'
  | 'lg:row-start-2'
  | 'xl:row-start-2'
  | 'sm:row-start-3'
  | 'md:row-start-3'
  | 'lg:row-start-3'
  | 'xl:row-start-3'
  | 'sm:row-start-4'
  | 'md:row-start-4'
  | 'lg:row-start-4'
  | 'xl:row-start-4'
  | 'sm:row-start-5'
  | 'md:row-start-5'
  | 'lg:row-start-5'
  | 'xl:row-start-5'
  | 'sm:row-start-6'
  | 'md:row-start-6'
  | 'lg:row-start-6'
  | 'xl:row-start-6'
  | 'sm:row-start-7'
  | 'md:row-start-7'
  | 'lg:row-start-7'
  | 'xl:row-start-7'
  | 'sm:row-start-auto'
  | 'md:row-start-auto'
  | 'lg:row-start-auto'
  | 'xl:row-start-auto'
  | 'sm:row-end-1'
  | 'md:row-end-1'
  | 'lg:row-end-1'
  | 'xl:row-end-1'
  | 'sm:row-end-2'
  | 'md:row-end-2'
  | 'lg:row-end-2'
  | 'xl:row-end-2'
  | 'sm:row-end-3'
  | 'md:row-end-3'
  | 'lg:row-end-3'
  | 'xl:row-end-3'
  | 'sm:row-end-4'
  | 'md:row-end-4'
  | 'lg:row-end-4'
  | 'xl:row-end-4'
  | 'sm:row-end-5'
  | 'md:row-end-5'
  | 'lg:row-end-5'
  | 'xl:row-end-5'
  | 'sm:row-end-6'
  | 'md:row-end-6'
  | 'lg:row-end-6'
  | 'xl:row-end-6'
  | 'sm:row-end-7'
  | 'md:row-end-7'
  | 'lg:row-end-7'
  | 'xl:row-end-7'
  | 'sm:row-end-auto'
  | 'md:row-end-auto'
  | 'lg:row-end-auto'
  | 'xl:row-end-auto'
  | 'sm:origin-center'
  | 'md:origin-center'
  | 'lg:origin-center'
  | 'xl:origin-center'
  | 'sm:origin-top'
  | 'md:origin-top'
  | 'lg:origin-top'
  | 'xl:origin-top'
  | 'sm:origin-top-right'
  | 'md:origin-top-right'
  | 'lg:origin-top-right'
  | 'xl:origin-top-right'
  | 'sm:origin-right'
  | 'md:origin-right'
  | 'lg:origin-right'
  | 'xl:origin-right'
  | 'sm:origin-bottom-right'
  | 'md:origin-bottom-right'
  | 'lg:origin-bottom-right'
  | 'xl:origin-bottom-right'
  | 'sm:origin-bottom'
  | 'md:origin-bottom'
  | 'lg:origin-bottom'
  | 'xl:origin-bottom'
  | 'sm:origin-bottom-left'
  | 'md:origin-bottom-left'
  | 'lg:origin-bottom-left'
  | 'xl:origin-bottom-left'
  | 'sm:origin-left'
  | 'md:origin-left'
  | 'lg:origin-left'
  | 'xl:origin-left'
  | 'sm:origin-top-left'
  | 'md:origin-top-left'
  | 'lg:origin-top-left'
  | 'xl:origin-top-left'
  | 'sm:scale-0'
  | 'md:scale-0'
  | 'lg:scale-0'
  | 'xl:scale-0'
  | 'hover:scale-0'
  | 'focus:scale-0'
  | 'sm:scale-50'
  | 'md:scale-50'
  | 'lg:scale-50'
  | 'xl:scale-50'
  | 'hover:scale-50'
  | 'focus:scale-50'
  | 'sm:scale-75'
  | 'md:scale-75'
  | 'lg:scale-75'
  | 'xl:scale-75'
  | 'hover:scale-75'
  | 'focus:scale-75'
  | 'sm:scale-90'
  | 'md:scale-90'
  | 'lg:scale-90'
  | 'xl:scale-90'
  | 'hover:scale-90'
  | 'focus:scale-90'
  | 'sm:scale-95'
  | 'md:scale-95'
  | 'lg:scale-95'
  | 'xl:scale-95'
  | 'hover:scale-95'
  | 'focus:scale-95'
  | 'sm:scale-100'
  | 'md:scale-100'
  | 'lg:scale-100'
  | 'xl:scale-100'
  | 'hover:scale-100'
  | 'focus:scale-100'
  | 'sm:scale-105'
  | 'md:scale-105'
  | 'lg:scale-105'
  | 'xl:scale-105'
  | 'hover:scale-105'
  | 'focus:scale-105'
  | 'sm:scale-110'
  | 'md:scale-110'
  | 'lg:scale-110'
  | 'xl:scale-110'
  | 'hover:scale-110'
  | 'focus:scale-110'
  | 'sm:scale-125'
  | 'md:scale-125'
  | 'lg:scale-125'
  | 'xl:scale-125'
  | 'hover:scale-125'
  | 'focus:scale-125'
  | 'sm:scale-150'
  | 'md:scale-150'
  | 'lg:scale-150'
  | 'xl:scale-150'
  | 'hover:scale-150'
  | 'focus:scale-150'
  | 'sm:scale-x-0'
  | 'md:scale-x-0'
  | 'lg:scale-x-0'
  | 'xl:scale-x-0'
  | 'hover:scale-x-0'
  | 'focus:scale-x-0'
  | 'sm:scale-x-50'
  | 'md:scale-x-50'
  | 'lg:scale-x-50'
  | 'xl:scale-x-50'
  | 'hover:scale-x-50'
  | 'focus:scale-x-50'
  | 'sm:scale-x-75'
  | 'md:scale-x-75'
  | 'lg:scale-x-75'
  | 'xl:scale-x-75'
  | 'hover:scale-x-75'
  | 'focus:scale-x-75'
  | 'sm:scale-x-90'
  | 'md:scale-x-90'
  | 'lg:scale-x-90'
  | 'xl:scale-x-90'
  | 'hover:scale-x-90'
  | 'focus:scale-x-90'
  | 'sm:scale-x-95'
  | 'md:scale-x-95'
  | 'lg:scale-x-95'
  | 'xl:scale-x-95'
  | 'hover:scale-x-95'
  | 'focus:scale-x-95'
  | 'sm:scale-x-100'
  | 'md:scale-x-100'
  | 'lg:scale-x-100'
  | 'xl:scale-x-100'
  | 'hover:scale-x-100'
  | 'focus:scale-x-100'
  | 'sm:scale-x-105'
  | 'md:scale-x-105'
  | 'lg:scale-x-105'
  | 'xl:scale-x-105'
  | 'hover:scale-x-105'
  | 'focus:scale-x-105'
  | 'sm:scale-x-110'
  | 'md:scale-x-110'
  | 'lg:scale-x-110'
  | 'xl:scale-x-110'
  | 'hover:scale-x-110'
  | 'focus:scale-x-110'
  | 'sm:scale-x-125'
  | 'md:scale-x-125'
  | 'lg:scale-x-125'
  | 'xl:scale-x-125'
  | 'hover:scale-x-125'
  | 'focus:scale-x-125'
  | 'sm:scale-x-150'
  | 'md:scale-x-150'
  | 'lg:scale-x-150'
  | 'xl:scale-x-150'
  | 'hover:scale-x-150'
  | 'focus:scale-x-150'
  | 'sm:scale-y-0'
  | 'md:scale-y-0'
  | 'lg:scale-y-0'
  | 'xl:scale-y-0'
  | 'hover:scale-y-0'
  | 'focus:scale-y-0'
  | 'sm:scale-y-50'
  | 'md:scale-y-50'
  | 'lg:scale-y-50'
  | 'xl:scale-y-50'
  | 'hover:scale-y-50'
  | 'focus:scale-y-50'
  | 'sm:scale-y-75'
  | 'md:scale-y-75'
  | 'lg:scale-y-75'
  | 'xl:scale-y-75'
  | 'hover:scale-y-75'
  | 'focus:scale-y-75'
  | 'sm:scale-y-90'
  | 'md:scale-y-90'
  | 'lg:scale-y-90'
  | 'xl:scale-y-90'
  | 'hover:scale-y-90'
  | 'focus:scale-y-90'
  | 'sm:scale-y-95'
  | 'md:scale-y-95'
  | 'lg:scale-y-95'
  | 'xl:scale-y-95'
  | 'hover:scale-y-95'
  | 'focus:scale-y-95'
  | 'sm:scale-y-100'
  | 'md:scale-y-100'
  | 'lg:scale-y-100'
  | 'xl:scale-y-100'
  | 'hover:scale-y-100'
  | 'focus:scale-y-100'
  | 'sm:scale-y-105'
  | 'md:scale-y-105'
  | 'lg:scale-y-105'
  | 'xl:scale-y-105'
  | 'hover:scale-y-105'
  | 'focus:scale-y-105'
  | 'sm:scale-y-110'
  | 'md:scale-y-110'
  | 'lg:scale-y-110'
  | 'xl:scale-y-110'
  | 'hover:scale-y-110'
  | 'focus:scale-y-110'
  | 'sm:scale-y-125'
  | 'md:scale-y-125'
  | 'lg:scale-y-125'
  | 'xl:scale-y-125'
  | 'hover:scale-y-125'
  | 'focus:scale-y-125'
  | 'sm:scale-y-150'
  | 'md:scale-y-150'
  | 'lg:scale-y-150'
  | 'xl:scale-y-150'
  | 'hover:scale-y-150'
  | 'focus:scale-y-150'
  | 'sm:rotate-0'
  | 'md:rotate-0'
  | 'lg:rotate-0'
  | 'xl:rotate-0'
  | 'hover:rotate-0'
  | 'focus:rotate-0'
  | 'sm:rotate-1'
  | 'md:rotate-1'
  | 'lg:rotate-1'
  | 'xl:rotate-1'
  | 'hover:rotate-1'
  | 'focus:rotate-1'
  | 'sm:rotate-2'
  | 'md:rotate-2'
  | 'lg:rotate-2'
  | 'xl:rotate-2'
  | 'hover:rotate-2'
  | 'focus:rotate-2'
  | 'sm:rotate-3'
  | 'md:rotate-3'
  | 'lg:rotate-3'
  | 'xl:rotate-3'
  | 'hover:rotate-3'
  | 'focus:rotate-3'
  | 'sm:rotate-6'
  | 'md:rotate-6'
  | 'lg:rotate-6'
  | 'xl:rotate-6'
  | 'hover:rotate-6'
  | 'focus:rotate-6'
  | 'sm:rotate-12'
  | 'md:rotate-12'
  | 'lg:rotate-12'
  | 'xl:rotate-12'
  | 'hover:rotate-12'
  | 'focus:rotate-12'
  | 'sm:rotate-45'
  | 'md:rotate-45'
  | 'lg:rotate-45'
  | 'xl:rotate-45'
  | 'hover:rotate-45'
  | 'focus:rotate-45'
  | 'sm:rotate-90'
  | 'md:rotate-90'
  | 'lg:rotate-90'
  | 'xl:rotate-90'
  | 'hover:rotate-90'
  | 'focus:rotate-90'
  | 'sm:rotate-180'
  | 'md:rotate-180'
  | 'lg:rotate-180'
  | 'xl:rotate-180'
  | 'hover:rotate-180'
  | 'focus:rotate-180'
  | 'sm:-rotate-180'
  | 'md:-rotate-180'
  | 'lg:-rotate-180'
  | 'xl:-rotate-180'
  | 'hover:-rotate-180'
  | 'focus:-rotate-180'
  | 'sm:-rotate-90'
  | 'md:-rotate-90'
  | 'lg:-rotate-90'
  | 'xl:-rotate-90'
  | 'hover:-rotate-90'
  | 'focus:-rotate-90'
  | 'sm:-rotate-45'
  | 'md:-rotate-45'
  | 'lg:-rotate-45'
  | 'xl:-rotate-45'
  | 'hover:-rotate-45'
  | 'focus:-rotate-45'
  | 'sm:-rotate-12'
  | 'md:-rotate-12'
  | 'lg:-rotate-12'
  | 'xl:-rotate-12'
  | 'hover:-rotate-12'
  | 'focus:-rotate-12'
  | 'sm:-rotate-6'
  | 'md:-rotate-6'
  | 'lg:-rotate-6'
  | 'xl:-rotate-6'
  | 'hover:-rotate-6'
  | 'focus:-rotate-6'
  | 'sm:-rotate-3'
  | 'md:-rotate-3'
  | 'lg:-rotate-3'
  | 'xl:-rotate-3'
  | 'hover:-rotate-3'
  | 'focus:-rotate-3'
  | 'sm:-rotate-2'
  | 'md:-rotate-2'
  | 'lg:-rotate-2'
  | 'xl:-rotate-2'
  | 'hover:-rotate-2'
  | 'focus:-rotate-2'
  | 'sm:-rotate-1'
  | 'md:-rotate-1'
  | 'lg:-rotate-1'
  | 'xl:-rotate-1'
  | 'hover:-rotate-1'
  | 'focus:-rotate-1'
  | 'sm:translate-x-0'
  | 'md:translate-x-0'
  | 'lg:translate-x-0'
  | 'xl:translate-x-0'
  | 'hover:translate-x-0'
  | 'focus:translate-x-0'
  | 'sm:translate-x-1'
  | 'md:translate-x-1'
  | 'lg:translate-x-1'
  | 'xl:translate-x-1'
  | 'hover:translate-x-1'
  | 'focus:translate-x-1'
  | 'sm:translate-x-2'
  | 'md:translate-x-2'
  | 'lg:translate-x-2'
  | 'xl:translate-x-2'
  | 'hover:translate-x-2'
  | 'focus:translate-x-2'
  | 'sm:translate-x-3'
  | 'md:translate-x-3'
  | 'lg:translate-x-3'
  | 'xl:translate-x-3'
  | 'hover:translate-x-3'
  | 'focus:translate-x-3'
  | 'sm:translate-x-4'
  | 'md:translate-x-4'
  | 'lg:translate-x-4'
  | 'xl:translate-x-4'
  | 'hover:translate-x-4'
  | 'focus:translate-x-4'
  | 'sm:translate-x-5'
  | 'md:translate-x-5'
  | 'lg:translate-x-5'
  | 'xl:translate-x-5'
  | 'hover:translate-x-5'
  | 'focus:translate-x-5'
  | 'sm:translate-x-6'
  | 'md:translate-x-6'
  | 'lg:translate-x-6'
  | 'xl:translate-x-6'
  | 'hover:translate-x-6'
  | 'focus:translate-x-6'
  | 'sm:translate-x-8'
  | 'md:translate-x-8'
  | 'lg:translate-x-8'
  | 'xl:translate-x-8'
  | 'hover:translate-x-8'
  | 'focus:translate-x-8'
  | 'sm:translate-x-10'
  | 'md:translate-x-10'
  | 'lg:translate-x-10'
  | 'xl:translate-x-10'
  | 'hover:translate-x-10'
  | 'focus:translate-x-10'
  | 'sm:translate-x-12'
  | 'md:translate-x-12'
  | 'lg:translate-x-12'
  | 'xl:translate-x-12'
  | 'hover:translate-x-12'
  | 'focus:translate-x-12'
  | 'sm:translate-x-16'
  | 'md:translate-x-16'
  | 'lg:translate-x-16'
  | 'xl:translate-x-16'
  | 'hover:translate-x-16'
  | 'focus:translate-x-16'
  | 'sm:translate-x-20'
  | 'md:translate-x-20'
  | 'lg:translate-x-20'
  | 'xl:translate-x-20'
  | 'hover:translate-x-20'
  | 'focus:translate-x-20'
  | 'sm:translate-x-24'
  | 'md:translate-x-24'
  | 'lg:translate-x-24'
  | 'xl:translate-x-24'
  | 'hover:translate-x-24'
  | 'focus:translate-x-24'
  | 'sm:translate-x-32'
  | 'md:translate-x-32'
  | 'lg:translate-x-32'
  | 'xl:translate-x-32'
  | 'hover:translate-x-32'
  | 'focus:translate-x-32'
  | 'sm:translate-x-40'
  | 'md:translate-x-40'
  | 'lg:translate-x-40'
  | 'xl:translate-x-40'
  | 'hover:translate-x-40'
  | 'focus:translate-x-40'
  | 'sm:translate-x-48'
  | 'md:translate-x-48'
  | 'lg:translate-x-48'
  | 'xl:translate-x-48'
  | 'hover:translate-x-48'
  | 'focus:translate-x-48'
  | 'sm:translate-x-56'
  | 'md:translate-x-56'
  | 'lg:translate-x-56'
  | 'xl:translate-x-56'
  | 'hover:translate-x-56'
  | 'focus:translate-x-56'
  | 'sm:translate-x-64'
  | 'md:translate-x-64'
  | 'lg:translate-x-64'
  | 'xl:translate-x-64'
  | 'hover:translate-x-64'
  | 'focus:translate-x-64'
  | 'sm:translate-x-px'
  | 'md:translate-x-px'
  | 'lg:translate-x-px'
  | 'xl:translate-x-px'
  | 'hover:translate-x-px'
  | 'focus:translate-x-px'
  | 'sm:-translate-x-0'
  | 'md:-translate-x-0'
  | 'lg:-translate-x-0'
  | 'xl:-translate-x-0'
  | 'hover:-translate-x-0'
  | 'focus:-translate-x-0'
  | 'sm:-translate-x-1'
  | 'md:-translate-x-1'
  | 'lg:-translate-x-1'
  | 'xl:-translate-x-1'
  | 'hover:-translate-x-1'
  | 'focus:-translate-x-1'
  | 'sm:-translate-x-2'
  | 'md:-translate-x-2'
  | 'lg:-translate-x-2'
  | 'xl:-translate-x-2'
  | 'hover:-translate-x-2'
  | 'focus:-translate-x-2'
  | 'sm:-translate-x-3'
  | 'md:-translate-x-3'
  | 'lg:-translate-x-3'
  | 'xl:-translate-x-3'
  | 'hover:-translate-x-3'
  | 'focus:-translate-x-3'
  | 'sm:-translate-x-4'
  | 'md:-translate-x-4'
  | 'lg:-translate-x-4'
  | 'xl:-translate-x-4'
  | 'hover:-translate-x-4'
  | 'focus:-translate-x-4'
  | 'sm:-translate-x-5'
  | 'md:-translate-x-5'
  | 'lg:-translate-x-5'
  | 'xl:-translate-x-5'
  | 'hover:-translate-x-5'
  | 'focus:-translate-x-5'
  | 'sm:-translate-x-6'
  | 'md:-translate-x-6'
  | 'lg:-translate-x-6'
  | 'xl:-translate-x-6'
  | 'hover:-translate-x-6'
  | 'focus:-translate-x-6'
  | 'sm:-translate-x-8'
  | 'md:-translate-x-8'
  | 'lg:-translate-x-8'
  | 'xl:-translate-x-8'
  | 'hover:-translate-x-8'
  | 'focus:-translate-x-8'
  | 'sm:-translate-x-10'
  | 'md:-translate-x-10'
  | 'lg:-translate-x-10'
  | 'xl:-translate-x-10'
  | 'hover:-translate-x-10'
  | 'focus:-translate-x-10'
  | 'sm:-translate-x-12'
  | 'md:-translate-x-12'
  | 'lg:-translate-x-12'
  | 'xl:-translate-x-12'
  | 'hover:-translate-x-12'
  | 'focus:-translate-x-12'
  | 'sm:-translate-x-16'
  | 'md:-translate-x-16'
  | 'lg:-translate-x-16'
  | 'xl:-translate-x-16'
  | 'hover:-translate-x-16'
  | 'focus:-translate-x-16'
  | 'sm:-translate-x-20'
  | 'md:-translate-x-20'
  | 'lg:-translate-x-20'
  | 'xl:-translate-x-20'
  | 'hover:-translate-x-20'
  | 'focus:-translate-x-20'
  | 'sm:-translate-x-24'
  | 'md:-translate-x-24'
  | 'lg:-translate-x-24'
  | 'xl:-translate-x-24'
  | 'hover:-translate-x-24'
  | 'focus:-translate-x-24'
  | 'sm:-translate-x-32'
  | 'md:-translate-x-32'
  | 'lg:-translate-x-32'
  | 'xl:-translate-x-32'
  | 'hover:-translate-x-32'
  | 'focus:-translate-x-32'
  | 'sm:-translate-x-40'
  | 'md:-translate-x-40'
  | 'lg:-translate-x-40'
  | 'xl:-translate-x-40'
  | 'hover:-translate-x-40'
  | 'focus:-translate-x-40'
  | 'sm:-translate-x-48'
  | 'md:-translate-x-48'
  | 'lg:-translate-x-48'
  | 'xl:-translate-x-48'
  | 'hover:-translate-x-48'
  | 'focus:-translate-x-48'
  | 'sm:-translate-x-56'
  | 'md:-translate-x-56'
  | 'lg:-translate-x-56'
  | 'xl:-translate-x-56'
  | 'hover:-translate-x-56'
  | 'focus:-translate-x-56'
  | 'sm:-translate-x-64'
  | 'md:-translate-x-64'
  | 'lg:-translate-x-64'
  | 'xl:-translate-x-64'
  | 'hover:-translate-x-64'
  | 'focus:-translate-x-64'
  | 'sm:-translate-x-px'
  | 'md:-translate-x-px'
  | 'lg:-translate-x-px'
  | 'xl:-translate-x-px'
  | 'hover:-translate-x-px'
  | 'focus:-translate-x-px'
  | 'sm:-translate-x-full'
  | 'md:-translate-x-full'
  | 'lg:-translate-x-full'
  | 'xl:-translate-x-full'
  | 'hover:-translate-x-full'
  | 'focus:-translate-x-full'
  | 'sm:-translate-x-1/2'
  | 'md:-translate-x-1/2'
  | 'lg:-translate-x-1/2'
  | 'xl:-translate-x-1/2'
  | 'hover:-translate-x-1/2'
  | 'focus:-translate-x-1/2'
  | 'sm:translate-x-1/2'
  | 'md:translate-x-1/2'
  | 'lg:translate-x-1/2'
  | 'xl:translate-x-1/2'
  | 'hover:translate-x-1/2'
  | 'focus:translate-x-1/2'
  | 'sm:translate-x-full'
  | 'md:translate-x-full'
  | 'lg:translate-x-full'
  | 'xl:translate-x-full'
  | 'hover:translate-x-full'
  | 'focus:translate-x-full'
  | 'sm:translate-y-0'
  | 'md:translate-y-0'
  | 'lg:translate-y-0'
  | 'xl:translate-y-0'
  | 'hover:translate-y-0'
  | 'focus:translate-y-0'
  | 'sm:translate-y-1'
  | 'md:translate-y-1'
  | 'lg:translate-y-1'
  | 'xl:translate-y-1'
  | 'hover:translate-y-1'
  | 'focus:translate-y-1'
  | 'sm:translate-y-2'
  | 'md:translate-y-2'
  | 'lg:translate-y-2'
  | 'xl:translate-y-2'
  | 'hover:translate-y-2'
  | 'focus:translate-y-2'
  | 'sm:translate-y-3'
  | 'md:translate-y-3'
  | 'lg:translate-y-3'
  | 'xl:translate-y-3'
  | 'hover:translate-y-3'
  | 'focus:translate-y-3'
  | 'sm:translate-y-4'
  | 'md:translate-y-4'
  | 'lg:translate-y-4'
  | 'xl:translate-y-4'
  | 'hover:translate-y-4'
  | 'focus:translate-y-4'
  | 'sm:translate-y-5'
  | 'md:translate-y-5'
  | 'lg:translate-y-5'
  | 'xl:translate-y-5'
  | 'hover:translate-y-5'
  | 'focus:translate-y-5'
  | 'sm:translate-y-6'
  | 'md:translate-y-6'
  | 'lg:translate-y-6'
  | 'xl:translate-y-6'
  | 'hover:translate-y-6'
  | 'focus:translate-y-6'
  | 'sm:translate-y-8'
  | 'md:translate-y-8'
  | 'lg:translate-y-8'
  | 'xl:translate-y-8'
  | 'hover:translate-y-8'
  | 'focus:translate-y-8'
  | 'sm:translate-y-10'
  | 'md:translate-y-10'
  | 'lg:translate-y-10'
  | 'xl:translate-y-10'
  | 'hover:translate-y-10'
  | 'focus:translate-y-10'
  | 'sm:translate-y-12'
  | 'md:translate-y-12'
  | 'lg:translate-y-12'
  | 'xl:translate-y-12'
  | 'hover:translate-y-12'
  | 'focus:translate-y-12'
  | 'sm:translate-y-16'
  | 'md:translate-y-16'
  | 'lg:translate-y-16'
  | 'xl:translate-y-16'
  | 'hover:translate-y-16'
  | 'focus:translate-y-16'
  | 'sm:translate-y-20'
  | 'md:translate-y-20'
  | 'lg:translate-y-20'
  | 'xl:translate-y-20'
  | 'hover:translate-y-20'
  | 'focus:translate-y-20'
  | 'sm:translate-y-24'
  | 'md:translate-y-24'
  | 'lg:translate-y-24'
  | 'xl:translate-y-24'
  | 'hover:translate-y-24'
  | 'focus:translate-y-24'
  | 'sm:translate-y-32'
  | 'md:translate-y-32'
  | 'lg:translate-y-32'
  | 'xl:translate-y-32'
  | 'hover:translate-y-32'
  | 'focus:translate-y-32'
  | 'sm:translate-y-40'
  | 'md:translate-y-40'
  | 'lg:translate-y-40'
  | 'xl:translate-y-40'
  | 'hover:translate-y-40'
  | 'focus:translate-y-40'
  | 'sm:translate-y-48'
  | 'md:translate-y-48'
  | 'lg:translate-y-48'
  | 'xl:translate-y-48'
  | 'hover:translate-y-48'
  | 'focus:translate-y-48'
  | 'sm:translate-y-56'
  | 'md:translate-y-56'
  | 'lg:translate-y-56'
  | 'xl:translate-y-56'
  | 'hover:translate-y-56'
  | 'focus:translate-y-56'
  | 'sm:translate-y-64'
  | 'md:translate-y-64'
  | 'lg:translate-y-64'
  | 'xl:translate-y-64'
  | 'hover:translate-y-64'
  | 'focus:translate-y-64'
  | 'sm:translate-y-px'
  | 'md:translate-y-px'
  | 'lg:translate-y-px'
  | 'xl:translate-y-px'
  | 'hover:translate-y-px'
  | 'focus:translate-y-px'
  | 'sm:-translate-y-0'
  | 'md:-translate-y-0'
  | 'lg:-translate-y-0'
  | 'xl:-translate-y-0'
  | 'hover:-translate-y-0'
  | 'focus:-translate-y-0'
  | 'sm:-translate-y-1'
  | 'md:-translate-y-1'
  | 'lg:-translate-y-1'
  | 'xl:-translate-y-1'
  | 'hover:-translate-y-1'
  | 'focus:-translate-y-1'
  | 'sm:-translate-y-2'
  | 'md:-translate-y-2'
  | 'lg:-translate-y-2'
  | 'xl:-translate-y-2'
  | 'hover:-translate-y-2'
  | 'focus:-translate-y-2'
  | 'sm:-translate-y-3'
  | 'md:-translate-y-3'
  | 'lg:-translate-y-3'
  | 'xl:-translate-y-3'
  | 'hover:-translate-y-3'
  | 'focus:-translate-y-3'
  | 'sm:-translate-y-4'
  | 'md:-translate-y-4'
  | 'lg:-translate-y-4'
  | 'xl:-translate-y-4'
  | 'hover:-translate-y-4'
  | 'focus:-translate-y-4'
  | 'sm:-translate-y-5'
  | 'md:-translate-y-5'
  | 'lg:-translate-y-5'
  | 'xl:-translate-y-5'
  | 'hover:-translate-y-5'
  | 'focus:-translate-y-5'
  | 'sm:-translate-y-6'
  | 'md:-translate-y-6'
  | 'lg:-translate-y-6'
  | 'xl:-translate-y-6'
  | 'hover:-translate-y-6'
  | 'focus:-translate-y-6'
  | 'sm:-translate-y-8'
  | 'md:-translate-y-8'
  | 'lg:-translate-y-8'
  | 'xl:-translate-y-8'
  | 'hover:-translate-y-8'
  | 'focus:-translate-y-8'
  | 'sm:-translate-y-10'
  | 'md:-translate-y-10'
  | 'lg:-translate-y-10'
  | 'xl:-translate-y-10'
  | 'hover:-translate-y-10'
  | 'focus:-translate-y-10'
  | 'sm:-translate-y-12'
  | 'md:-translate-y-12'
  | 'lg:-translate-y-12'
  | 'xl:-translate-y-12'
  | 'hover:-translate-y-12'
  | 'focus:-translate-y-12'
  | 'sm:-translate-y-16'
  | 'md:-translate-y-16'
  | 'lg:-translate-y-16'
  | 'xl:-translate-y-16'
  | 'hover:-translate-y-16'
  | 'focus:-translate-y-16'
  | 'sm:-translate-y-20'
  | 'md:-translate-y-20'
  | 'lg:-translate-y-20'
  | 'xl:-translate-y-20'
  | 'hover:-translate-y-20'
  | 'focus:-translate-y-20'
  | 'sm:-translate-y-24'
  | 'md:-translate-y-24'
  | 'lg:-translate-y-24'
  | 'xl:-translate-y-24'
  | 'hover:-translate-y-24'
  | 'focus:-translate-y-24'
  | 'sm:-translate-y-32'
  | 'md:-translate-y-32'
  | 'lg:-translate-y-32'
  | 'xl:-translate-y-32'
  | 'hover:-translate-y-32'
  | 'focus:-translate-y-32'
  | 'sm:-translate-y-40'
  | 'md:-translate-y-40'
  | 'lg:-translate-y-40'
  | 'xl:-translate-y-40'
  | 'hover:-translate-y-40'
  | 'focus:-translate-y-40'
  | 'sm:-translate-y-48'
  | 'md:-translate-y-48'
  | 'lg:-translate-y-48'
  | 'xl:-translate-y-48'
  | 'hover:-translate-y-48'
  | 'focus:-translate-y-48'
  | 'sm:-translate-y-56'
  | 'md:-translate-y-56'
  | 'lg:-translate-y-56'
  | 'xl:-translate-y-56'
  | 'hover:-translate-y-56'
  | 'focus:-translate-y-56'
  | 'sm:-translate-y-64'
  | 'md:-translate-y-64'
  | 'lg:-translate-y-64'
  | 'xl:-translate-y-64'
  | 'hover:-translate-y-64'
  | 'focus:-translate-y-64'
  | 'sm:-translate-y-px'
  | 'md:-translate-y-px'
  | 'lg:-translate-y-px'
  | 'xl:-translate-y-px'
  | 'hover:-translate-y-px'
  | 'focus:-translate-y-px'
  | 'sm:-translate-y-full'
  | 'md:-translate-y-full'
  | 'lg:-translate-y-full'
  | 'xl:-translate-y-full'
  | 'hover:-translate-y-full'
  | 'focus:-translate-y-full'
  | 'sm:-translate-y-1/2'
  | 'md:-translate-y-1/2'
  | 'lg:-translate-y-1/2'
  | 'xl:-translate-y-1/2'
  | 'hover:-translate-y-1/2'
  | 'focus:-translate-y-1/2'
  | 'sm:translate-y-1/2'
  | 'md:translate-y-1/2'
  | 'lg:translate-y-1/2'
  | 'xl:translate-y-1/2'
  | 'hover:translate-y-1/2'
  | 'focus:translate-y-1/2'
  | 'sm:translate-y-full'
  | 'md:translate-y-full'
  | 'lg:translate-y-full'
  | 'xl:translate-y-full'
  | 'hover:translate-y-full'
  | 'focus:translate-y-full'
  | 'sm:skew-x-0'
  | 'md:skew-x-0'
  | 'lg:skew-x-0'
  | 'xl:skew-x-0'
  | 'hover:skew-x-0'
  | 'focus:skew-x-0'
  | 'sm:skew-x-1'
  | 'md:skew-x-1'
  | 'lg:skew-x-1'
  | 'xl:skew-x-1'
  | 'hover:skew-x-1'
  | 'focus:skew-x-1'
  | 'sm:skew-x-2'
  | 'md:skew-x-2'
  | 'lg:skew-x-2'
  | 'xl:skew-x-2'
  | 'hover:skew-x-2'
  | 'focus:skew-x-2'
  | 'sm:skew-x-3'
  | 'md:skew-x-3'
  | 'lg:skew-x-3'
  | 'xl:skew-x-3'
  | 'hover:skew-x-3'
  | 'focus:skew-x-3'
  | 'sm:skew-x-6'
  | 'md:skew-x-6'
  | 'lg:skew-x-6'
  | 'xl:skew-x-6'
  | 'hover:skew-x-6'
  | 'focus:skew-x-6'
  | 'sm:skew-x-12'
  | 'md:skew-x-12'
  | 'lg:skew-x-12'
  | 'xl:skew-x-12'
  | 'hover:skew-x-12'
  | 'focus:skew-x-12'
  | 'sm:-skew-x-12'
  | 'md:-skew-x-12'
  | 'lg:-skew-x-12'
  | 'xl:-skew-x-12'
  | 'hover:-skew-x-12'
  | 'focus:-skew-x-12'
  | 'sm:-skew-x-6'
  | 'md:-skew-x-6'
  | 'lg:-skew-x-6'
  | 'xl:-skew-x-6'
  | 'hover:-skew-x-6'
  | 'focus:-skew-x-6'
  | 'sm:-skew-x-3'
  | 'md:-skew-x-3'
  | 'lg:-skew-x-3'
  | 'xl:-skew-x-3'
  | 'hover:-skew-x-3'
  | 'focus:-skew-x-3'
  | 'sm:-skew-x-2'
  | 'md:-skew-x-2'
  | 'lg:-skew-x-2'
  | 'xl:-skew-x-2'
  | 'hover:-skew-x-2'
  | 'focus:-skew-x-2'
  | 'sm:-skew-x-1'
  | 'md:-skew-x-1'
  | 'lg:-skew-x-1'
  | 'xl:-skew-x-1'
  | 'hover:-skew-x-1'
  | 'focus:-skew-x-1'
  | 'sm:skew-y-0'
  | 'md:skew-y-0'
  | 'lg:skew-y-0'
  | 'xl:skew-y-0'
  | 'hover:skew-y-0'
  | 'focus:skew-y-0'
  | 'sm:skew-y-1'
  | 'md:skew-y-1'
  | 'lg:skew-y-1'
  | 'xl:skew-y-1'
  | 'hover:skew-y-1'
  | 'focus:skew-y-1'
  | 'sm:skew-y-2'
  | 'md:skew-y-2'
  | 'lg:skew-y-2'
  | 'xl:skew-y-2'
  | 'hover:skew-y-2'
  | 'focus:skew-y-2'
  | 'sm:skew-y-3'
  | 'md:skew-y-3'
  | 'lg:skew-y-3'
  | 'xl:skew-y-3'
  | 'hover:skew-y-3'
  | 'focus:skew-y-3'
  | 'sm:skew-y-6'
  | 'md:skew-y-6'
  | 'lg:skew-y-6'
  | 'xl:skew-y-6'
  | 'hover:skew-y-6'
  | 'focus:skew-y-6'
  | 'sm:skew-y-12'
  | 'md:skew-y-12'
  | 'lg:skew-y-12'
  | 'xl:skew-y-12'
  | 'hover:skew-y-12'
  | 'focus:skew-y-12'
  | 'sm:-skew-y-12'
  | 'md:-skew-y-12'
  | 'lg:-skew-y-12'
  | 'xl:-skew-y-12'
  | 'hover:-skew-y-12'
  | 'focus:-skew-y-12'
  | 'sm:-skew-y-6'
  | 'md:-skew-y-6'
  | 'lg:-skew-y-6'
  | 'xl:-skew-y-6'
  | 'hover:-skew-y-6'
  | 'focus:-skew-y-6'
  | 'sm:-skew-y-3'
  | 'md:-skew-y-3'
  | 'lg:-skew-y-3'
  | 'xl:-skew-y-3'
  | 'hover:-skew-y-3'
  | 'focus:-skew-y-3'
  | 'sm:-skew-y-2'
  | 'md:-skew-y-2'
  | 'lg:-skew-y-2'
  | 'xl:-skew-y-2'
  | 'hover:-skew-y-2'
  | 'focus:-skew-y-2'
  | 'sm:-skew-y-1'
  | 'md:-skew-y-1'
  | 'lg:-skew-y-1'
  | 'xl:-skew-y-1'
  | 'hover:-skew-y-1'
  | 'focus:-skew-y-1'
  | 'sm:transition-none'
  | 'md:transition-none'
  | 'lg:transition-none'
  | 'xl:transition-none'
  | 'sm:transition-all'
  | 'md:transition-all'
  | 'lg:transition-all'
  | 'xl:transition-all'
  | 'sm:transition'
  | 'md:transition'
  | 'lg:transition'
  | 'xl:transition'
  | 'sm:transition-colors'
  | 'md:transition-colors'
  | 'lg:transition-colors'
  | 'xl:transition-colors'
  | 'sm:transition-opacity'
  | 'md:transition-opacity'
  | 'lg:transition-opacity'
  | 'xl:transition-opacity'
  | 'sm:transition-shadow'
  | 'md:transition-shadow'
  | 'lg:transition-shadow'
  | 'xl:transition-shadow'
  | 'sm:transition-transform'
  | 'md:transition-transform'
  | 'lg:transition-transform'
  | 'xl:transition-transform'
  | 'sm:transition-height'
  | 'md:transition-height'
  | 'lg:transition-height'
  | 'xl:transition-height'
  | 'sm:transition-spacing'
  | 'md:transition-spacing'
  | 'lg:transition-spacing'
  | 'xl:transition-spacing'
  | 'sm:ease-linear'
  | 'md:ease-linear'
  | 'lg:ease-linear'
  | 'xl:ease-linear'
  | 'sm:ease-in'
  | 'md:ease-in'
  | 'lg:ease-in'
  | 'xl:ease-in'
  | 'sm:ease-out'
  | 'md:ease-out'
  | 'lg:ease-out'
  | 'xl:ease-out'
  | 'sm:ease-in-out'
  | 'md:ease-in-out'
  | 'lg:ease-in-out'
  | 'xl:ease-in-out'
  | 'sm:duration-75'
  | 'md:duration-75'
  | 'lg:duration-75'
  | 'xl:duration-75'
  | 'sm:duration-100'
  | 'md:duration-100'
  | 'lg:duration-100'
  | 'xl:duration-100'
  | 'sm:duration-150'
  | 'md:duration-150'
  | 'lg:duration-150'
  | 'xl:duration-150'
  | 'sm:duration-200'
  | 'md:duration-200'
  | 'lg:duration-200'
  | 'xl:duration-200'
  | 'sm:duration-300'
  | 'md:duration-300'
  | 'lg:duration-300'
  | 'xl:duration-300'
  | 'sm:duration-500'
  | 'md:duration-500'
  | 'lg:duration-500'
  | 'xl:duration-500'
  | 'sm:duration-700'
  | 'md:duration-700'
  | 'lg:duration-700'
  | 'xl:duration-700'
  | 'sm:duration-1000'
  | 'md:duration-1000'
  | 'lg:duration-1000'
  | 'xl:duration-1000'
  | 'sm:delay-75'
  | 'md:delay-75'
  | 'lg:delay-75'
  | 'xl:delay-75'
  | 'sm:delay-100'
  | 'md:delay-100'
  | 'lg:delay-100'
  | 'xl:delay-100'
  | 'sm:delay-150'
  | 'md:delay-150'
  | 'lg:delay-150'
  | 'xl:delay-150'
  | 'sm:delay-200'
  | 'md:delay-200'
  | 'lg:delay-200'
  | 'xl:delay-200'
  | 'sm:delay-300'
  | 'md:delay-300'
  | 'lg:delay-300'
  | 'xl:delay-300'
  | 'sm:delay-500'
  | 'md:delay-500'
  | 'lg:delay-500'
  | 'xl:delay-500'
  | 'sm:delay-700'
  | 'md:delay-700'
  | 'lg:delay-700'
  | 'xl:delay-700'
  | 'sm:delay-1000'
  | 'md:delay-1000'
  | 'lg:delay-1000'
  | 'xl:delay-1000'
  | 'sm:animate-none'
  | 'md:animate-none'
  | 'lg:animate-none'
  | 'xl:animate-none'
  | 'sm:animate-spin'
  | 'md:animate-spin'
  | 'lg:animate-spin'
  | 'xl:animate-spin'
  | 'sm:animate-ping'
  | 'md:animate-ping'
  | 'lg:animate-ping'
  | 'xl:animate-ping'
  | 'sm:animate-pulse'
  | 'md:animate-pulse'
  | 'lg:animate-pulse'
  | 'xl:animate-pulse'
  | 'sm:animate-bounce'
  | 'md:animate-bounce'
  | 'lg:animate-bounce'
  | 'xl:animate-bounce'
  | 'sm:sr-only'
  | 'md:sr-only'
  | 'lg:sr-only'
  | 'xl:sr-only'
  | 'focus:sr-only'
  | 'sm:not-sr-only'
  | 'md:not-sr-only'
  | 'lg:not-sr-only'
  | 'xl:not-sr-only'
  | 'focus:not-sr-only'

export type TCustomFormsPluginClasses =
  | 'form-input'
  | 'form-textarea'
  | 'form-select'
  | 'form-multiselect'
  | 'form-checkbox'
  | 'form-radio'

export type TTypographyPluginClasses =
  | 'prose'
  | 'prose-sm'
  | 'prose-lg'
  | 'prose-xl'
  | 'prose-2xl'

export type TClasses =
  | TLayout
  | TTypography
  | TBackgrounds
  | TBorders
  | TFlexBox
  | TGrid
  | TSpacing
  | TSizing
  | TTables
  | TEffects
  | TTransforms
  | TTransitionsAndAnimations
  | TInteractivity
  | TSVG
  | TAccessibility   
  | TPseudoClasses;


export type TTailwindString = "TAILWIND_STRING"

export type TKey = TClasses | TTailwindString

export type TArg =
  | TClasses
  | null
  | undefined
  | {[key in TKey]?: boolean}
  | TTailwindString

export type TTailwind = (...args: TArg[]) => TTailwindString

export const classnames: TTailwind = classnamesLib as any

export const tw = classnames

export default tw
